.container {
  position: relative;
}
.feedback_container {
  position: absolute;
  width: 100%;
  top: -111px;
  display: block;
}
.arrow {
  position: absolute;
  left: 26%;
  bottom: -35%;
}

@media (max-width: 1400px) {
  .arrow {
    bottom: -20%;
    max-height: 150px;
  }
}

@media (max-width: 900px) {
  .feedback_container {
    // display: none;
    position: relative;
    top: 0;
  }
  .arrow {
    bottom: -25%;
    max-height: 130px;
  }
}
