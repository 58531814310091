.container {
  background-color: #f8f4ef;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  padding: 96px 96px 100px 0px;
}
.form_container {
  flex: 1 0 50%;

  .form_text {
    position: relative;
    .form_title {
      color: #405f6a;
      font-size: 32px;
      line-height: 47px;
      letter-spacing: 0.58px;
      font-family: "Heebo-ExtraBold";
    }
    .form_description {
      margin-top: 11px;
      font-size: 18px;
      line-height: 26.6px;
      max-width: 603px;
    }
    .form_arrow {
      position: absolute;
      bottom: -25%;
      right: 60%;
    }
  }
}

.photo_container {
  position: relative;
  right: -31px;
  width: 50%;
  min-height: 633px;
}

.photo_wrapper {
  position: absolute;
  width: 45%;
  padding-bottom: 30%;
}

// .photo_container img {
//   position: absolute;
//   max-width: calc(400 / 633 * 100%); // 90%
//   max-height: calc(300 / 633 * 100%); // 47.4%
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }
.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo1 {
  top: calc(200 / 633 * 100%);
  left: calc(150 / 633 * 100%);
  z-index: 10;
}

.photo2 {
  top: 0;
  left: calc(50 / 633 * 100%);
  z-index: 5;
}

.photo3 {
  top: calc(50 / 633 * 100%);
  left: calc(300 / 633 * 100%);
}

@media (max-width: "1400px") {
  .photo_wrapper {
    width: 50%;
    padding-bottom: 35%;
  }
}

@media (max-width: "1200px") {
  .photo1 {
    top: calc(150 / 633 * 100%);
    left: calc(150 / 633 * 100%);
  }

  .photo2 {
    top: 0;
    left: calc(50 / 633 * 100%);
  }

  .photo3 {
    top: calc(30 / 633 * 100%);
    left: calc(300 / 633 * 100%);
  }
}

.progress_container {
  padding-top: 82px;
  display: flex;
  // justify-content: space-between;
}
.one_step_progress {
  position: relative;
  z-index: 2;
  // cursor: pointer;
}
.one_step_progress:not(:last-child) {
  padding-left: 34px;
}
.one_step_progress_img_container {
  background-color: #f8f4ef;
  width: 100%;
}
.one_step_progress .one_step_progress_img_container img {
  opacity: 0.25;
}

.one_step_progress:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 1px;
  top: 48%;
  width: 100%;
  z-index: -1;
  background-color: #405f6a;
  opacity: 0.25;
}
.one_step_progress.active .one_step_progress_img_container img {
  opacity: 1;
}
.one_step_progress.active::after {
  opacity: 1;
  background-color: #405f6a;
}
.steps_container {
  justify-content: space-between;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  max-width: 581px;
}
.one_step {
  max-width: 272px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .one_step_img {
    max-width: 18px;
    max-height: 18px;
    width: 100%;
    cursor: pointer;
  }
  .one_step_label {
    line-height: 27px;
    font-size: 18px;
    font-family: "Heebo-Medium";
  }
  .field_container {
    margin-top: 9px;
    height: 53px;
  }
  .miss_text {
    text-decoration: underline;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
}
.one_step:not(:nth-child(1), :nth-child(2)) {
  margin-top: 27px;
}
.one_step:nth-child(2n) {
  margin-right: 10px;
}
.submit_container {
  max-width: unset;
  flex: 1 0 45%;
  align-items: end;
  justify-content: end;
}
@media (max-width: 1536px) {
  .container {
    padding: 52px 32px 64px 0px;
  }
  .one_step_progress {
    position: relative;
    z-index: 2;
    max-width: 15%;
  }
  .one_step_progress:not(:last-child) {
    padding-left: 3%;
  }
  .photo_container {
    right: 0px;
  }
  .one_step_progress_img_container img {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .container {
    padding-right: 0px;
    .form_container {
      padding: 0px 19px;
    }
    .form_text {
      .form_title {
        font-size: 28px;
        line-height: 36px;
      }
      .form_description {
        font-size: 18px;
        line-height: 25.6px;
      }
      .form_arrow {
        bottom: -25%;
        right: 60%;
      }
    }
  }

  .photo_container {
    display: flex;
    align-items: center;
  }
  .one_step {
    flex: 1 0 45%;
  }
  .one_step_progress {
    padding-left: 3%;
    position: relative;
    z-index: 2;
    max-width: 15%;
  }
  .one_step_progress_img_container img {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    padding-bottom: 0px;
  }
  .photo_container {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: unset;
    padding-bottom: 40%;
  }
  .photo_wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    padding-bottom: unset;
  }
  .photo {
    width: 100%;
    padding: unset;
    height: 100%;
  }

  .progress_container {
    justify-content: center;
  }
  .steps_container {
    justify-content: center;
    padding-bottom: 39px;
    margin: 0 auto;
  }
  .submit_container {
    max-width: 272px;
    align-items: center;
  }
  .one_step:not(:nth-child(1), :nth-child(2)) {
    margin-top: 21px;
  }
  // .steps_wrapper{
  //   ma
  // }
}

@media (max-width: 600px) {
  .container {
    padding-top: 38px;
    .form_container {
      padding: 0px;
      .form_text {
        padding: 0px 19px;
        position: relative;
        .form_title {
          color: #405f6a;
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0px;
          font-family: "Heebo-ExtraBold";
        }
        .form_description {
          margin-top: 15px;
          font-size: 16px;
          line-height: 24px;
          max-width: 350px;
        }
        .form_arrow {
          position: absolute;
          max-width: 51px;
          bottom: -15%;
          right: 60%;
        }
      }
    }
  }
  .one_step {
    max-width: 227px;
    .one_step_label {
      font-size: 14px;
      line-height: 21px;
    }
    .field_container {
      margin-top: 4px;
      height: 42px;
    }
  }
  .one_step:not(:nth-child(1)) {
    margin-top: 21px;
  }
  .one_step:nth-child(2n) {
    margin-right: 0px;
  }
  .one_step_progress:not(:last-child) {
    padding-left: 4.265%;
  }
  .steps_container {
    margin-top: 44.62px;
    padding: 0px 19px 33px;
    flex-direction: column;
  }

  .progress_container {
    box-sizing: border-box;
    background-color: #f8f4ee;
    padding: 16px 19px 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 100;
    bottom: 0px;
  }
  .progress_container_last {
    background-color: #e7b1ac;
    color: white;
    padding: 24px 0px;
  }
  .one_step_progress {
    padding-left: 15px;
  }
  .submit_container {
    align-items: start;
  }
  .photo_container img {
    max-height: 242px;
  }
}
