@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.globalWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(97) toRem(17) toRem(81);
  .mainImagesWrapper{
    

  }

  .mainBtnWrapper{
    
      padding-top: toRem(70);
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
  
      .button {
        padding: toRem(12) toRem(24);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(22);
        line-height: toRem(32);
      }
    
  }
}



.container {
    max-width: 101.81rem;
    display: flex;
    flex-direction: column;
    padding: 0 toRem(10);
    
}

.linkPrevPage{
  padding-top: toRem(37);
  padding-bottom: toRem(51);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.375rem;
}
.containerImg{
  // unicode-bidi:bidi-override;
  //   direction:rtl;
  //   float: right;
}

.imagesWrapper{
  width: 100%;
  display: flex;

  gap: toRem(39);
  flex-wrap: wrap;
  // max-width: 85vw;
  max-width: 101.81rem;
  justify-content: center;
}

.addSomeImages {
  margin: 0;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  text-decoration: underline;
  margin-bottom: 1.94rem;
}

.imageRow {
  display: flex;
  
  height: 26rem;
  max-height: 26.35rem;
  align-self: center;
  margin-bottom: 4.97rem;
  // flex-wrap: wrap;

  .profileImageField {
    outline: .19rem solid #405F6A;

    p {
      margin: 0;
      font-family: Heebo-Bold;
      font-size: 1.5rem;
      line-height: 2.19rem;
      color: #405F6A;
      position: absolute;
      top: 1.06rem;
      right: 1.28rem;
    }
  }

  .imageField {
    box-sizing: border-box;
    // width: 19.6vw;
    width: 23.6rem;
    // height: 19.6vw;
    height: 23.6rem;
    position: relative;
    background-color: #EEE9E5;

    img{
      width: 100%;
      height: 100%;
      
      object-fit: cover; 
      user-select: none;
    }
    video{
      width: 100%;
      height: 100%;
      
      // object-fit: cover; 
      user-select: none;
    }


    .interfaceContainer {
      position: absolute;
      bottom: -2.7rem;
      width: 100%;
      height: 2.7rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .downloadButton,
      .trashButton {
        position: relative;
        background-color: white;
        border: none;
        padding: 0;
        cursor: pointer;

        .uploadImg{
          position: absolute;
          z-index: 100;
          top:0;right: 0;
          width: 2rem;
          height: 2rem;
          
        }
      }

      .trashButton {
        margin-bottom: -0.35rem;        
      }
    }
  }
}

/* .imageRow:last-child {
  margin-bottom: 10rem;
} */


.links {
  display: flex;
  margin-top: -.38rem;

  .sortLink,
  .addRowLink {
    margin: 0;
    font-family: Heebo-Regular;
    font-size: 1.38rem;
    line-height: 2rem;
    color: #242323;
    margin-inline-end: 4rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

.youTube{
  display: flex;
  flex-direction: column;

  padding: toRem(49) 0 toRem(90);

  .youTitle{
    padding-bottom: toRem(2);
    font-family: "Heebo-Medium";
    font-size: toRem(24);
    line-height: toRem(35);
    text-decoration: underline;
  }

  .youSTitle{
    padding-bottom: toRem(32);
    font-size: toRem(18);
    line-height: toRem(27);
  }

  .youAllLinks{
    padding-top: toRem(32);
    display: flex;
    flex-direction: column;


    .youRow{
      padding-bottom: toRem(10);
      display: flex;
      align-items: center;

      .save{
        cursor: pointer;
        text-decoration: underline;
        font-size: toRem(20);
        font-family: "Heebo-Medium";
        line-height: toRem(29);
        color: #36606B;
        padding-right: toRem(9);
      }

      .delete{
        padding-right: toRem(10);
        img{
          cursor: pointer;
          width: toRem(23);
          height: toRem(23);
        }
      }
    }

    textarea{
      padding-top: toRem(20);
      display: flex;
      align-items: center;
      // text-align: center;
      background-color: #F7F7F7;
      box-sizing: border-box;
      width: toRem(764);
      height: toRem(56);
      font-size: toRem(14);
      line-height: toRem(16);
      padding-right: toRem(19);
      border: toRem(1) solid #36606B;
      border-radius: toRem(4);
      color: #707070;
      resize: none;
    }
    textarea:focus{
      outline: toRem(2) solid #36606B;
    }
    textarea::placeholder {
      color: rgba(36, 35, 35, 0.42);
    }

  }

  .youAdd{
    font-size: toRem(20);
        line-height: toRem(29);
    cursor: pointer;
  }

}







@media (min-width: 120rem) {
  .firsImageRow {
  
    .imageField {
      width: 23.63rem;
      height: 23.63rem;
    }
  }
}

@media (max-width: toRem(960)) {
  .container {
    max-width: 101.81rem;
    display: flex;
    flex-direction: column;
    padding: 0 ;

  }
  .imagesWrapper{
    width: 100%;
    display: flex;
  
    gap: toRem(14);
    flex-wrap: wrap;
    // max-width: 85vw;
    max-width: 101.81rem;
    justify-content: center;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: toRem(10);
    margin-top: -.38rem;
    padding-bottom: toRem(36);
  
    .sortLink,
    .addRowLink {
      margin: 0;
      font-family: Heebo-Regular;
      font-size: toRem(16);
      line-height: toRem(24);
      color: #242323;
      margin-inline-end: 0rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .linkPrevPage{
    padding-top: toRem(25);
    padding-bottom: toRem(25);
    font-size: toRem(16);
  }

  .desctop{
    display: none;
  }

  .imageRow {
    display: flex;
    
    height: toRem(206);
    max-height: 26.35rem;
    align-self: center;
    margin-bottom: 0;
    // flex-wrap: wrap;
  
    .profileImageField {
      outline: toRem(1) solid #405F6A;
  
      p {
        margin: 0;
        font-family: Heebo-Bold;
        font-size: toRem(14);
        line-height: toRem(21);
        color: #405F6A;
        position: absolute;
        top: toRem(8);
        right: toRem(11);
      }
    }
  
    .imageField {
      box-sizing: border-box;
      width: toRem(147);
      height: toRem(147);
      position: relative;
      // background-color: #FFFFFF;
  
      img{
        width: 100%;
        height: 100%;
        
        object-fit: cover; 
        user-select: none;
      }
      video{
        width: 100%;
        height: 100%;
        
        // object-fit: cover; 
        user-select: none;
      }
  
  
      .interfaceContainer {
        position: absolute;
        bottom: toRem(-17);
        width: 100%;
        height: toRem(18);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        label{
          width: toRem(8);
          height: toRem(16);
        }
        .trashButton {
          position: relative;
          // background-color: #EEE9E5;
          border: none;
          padding: 0;
          cursor: pointer;

          svg{
            width: toRem(11);
            height: toRem(11);
            
          }
        }
        .downloadButton{
          position: relative;
          // background-color: #EEE9E5;
          border: none;
          padding: 0;
          cursor: pointer;

          svg{
            width: toRem(8);
            height: toRem(8);
          }
  
          .uploadImg{
            position: absolute;
            z-index: 100;
            top:0;right: 0;
            width: toRem(10);
            height: toRem(10);
            
          }
          
        }
  
        .trashButton {
          margin-bottom: -0.35rem;        
        }
      }
    }
  }

  .youTube{
    display: flex;
    flex-direction: column;
  
    padding: toRem(0) 0 toRem(74);
  
    .youTitle{
      padding-bottom: toRem(0);
      font-family: "Heebo-Medium";
      font-size: toRem(20);
      line-height: toRem(29);
      text-decoration: underline;
    }
  
    .youSTitle{
      display: none;
      font-size: toRem(18);
      line-height: toRem(27);
    }
  
    .youAllLinks{
      padding-top: toRem(12);
      display: flex;
      flex-direction: column;
  
  
      .youRow{
        padding-bottom: toRem(8);
        display: flex;
        align-items: center;
  
        .save{
          cursor: pointer;
          text-decoration: underline;
          font-size: toRem(12);
          font-family: "Heebo-Medium";
          line-height: toRem(18);
          color: #36606B;
          padding-right: toRem(8);
        }
  
        .delete{
          padding-right: toRem(8.5);
          img{
            cursor: pointer;
            width: toRem(15);
            height: toRem(15);
          }
        }
      }
  
      textarea{
        padding-top: toRem(9);
        padding-bottom: toRem(8);
        display: flex;
        align-items: center;
        // text-align: center;
        background-color: #F7F7F7;
        box-sizing: border-box;
        width: toRem(245);
        height: toRem(47);
        font-size: toRem(12);
        line-height: toRem(14);
        padding-right: toRem(9);
        border: toRem(1) solid #36606B;
        border-radius: toRem(4);
        color: #707070;
        resize: none;
      }
      textarea:focus{
        outline: toRem(2) solid #36606B;
      }
      textarea::placeholder {
        color: rgba(36, 35, 35, 0.42);
      }
  
    }
  
    .youAdd{
      padding-top: toRem(2);
      font-size: toRem(16);
          line-height: toRem(24);
      cursor: pointer;
    }
  
  }
}
