.rootWrapper {
  min-height: 100vh;
  width: 100%;
  background-color: rgba(64, 95, 106, 0.9);
  padding-left: 11.69rem;
  padding-right: 11.69rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.phoneInput{
  direction: ltr !important;
  text-align: end !important;
  padding-inline-end: 0.5rem !important;
}
.userRegContainer {
  display: flex;
  justify-content: space-between;
  max-height: 48.13rem;
  max-width: 96.26rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(64, 95, 106, 0.9);
}

.userRegBackgroundImage {
  height: 100%;
  width: 50%;
}

.adaptiveWrapperFirstBlock,
.adaptiveWrapperSecondBlock {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.userRegBackgroundImageContent {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
}

.closeIconMobile {
  display: none;
}

.userRegFormContainer {
  height: 100%;
  width: 50%;
}

.userRegFormContainerContent {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 6.44rem 4.5rem 1.31rem 4.88rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeIconDesktop {
  top: 2.02rem;
  left: 1.72rem;
}

.regHeader {
  margin: 0;
  font-family: Heebo-Bold;
  font-size: 2rem;
  line-height: 2.94rem;
  color: #e7b1ad;
  height: 11%;
}

.regButtonsWithOtherResContainer {
  display: flex;
  justify-content: space-between;
  height: 3.06rem;
  width: 100%;
  /* display: none; */
}

.regButtonsWithOtherResContainer>div {
  width: 18.63rem;
}

.regButtonsWithOtherResContainer>button {
  position: relative;
  background-color: white;
  height: 3.13rem;
  border: 0.06rem solid #434242;
  border-radius: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 1.13rem;
  line-height: 1.69rem;
  cursor: pointer;
}

.regButtonsWithOtherResContainer>button>img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0.94rem;
}

.regForm {
  display: flex;
  flex-direction: column;
  height: 79%;
  margin-top: 0.94rem;
}

.regFormBlock {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  position: relative;
}

.errorMessage {
  margin: 0;
  position: absolute;
  color: red;
  bottom: 0.007rem;
  right: 0.31rem;
  font-family: Heebo-regular;
  font-size: 0.65rem;
}

.inputContainer>input {
  background-color: #f7f4f4;
  border: none;
  border-radius: 0.31rem;
  height: 4.56rem;
  margin-bottom: 0.94rem;
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #242323;
  padding-inline-start: 1.75rem;
  padding-inline-end: 2.5rem;
  width: 100%;
  box-sizing: border-box;
}

input::placeholder {
  color: #9b9999;
}

.inputContainer>input:focus {
  outline: 0.13px solid #405f6a;
}

input.invalidField {
  background-color: #f3e8e8;
  outline: 0.06rem solid red;
}

.inputPasswordsContainer {
  display: flex;
  column-gap: 0.88rem;
}

.inputPasswordsContainerItem {
  width: 50%;
}

.inputPasswordsContainerItem>input {
  margin-top: 0.81rem;
  margin-bottom: 1rem;
}

.checkbox {
  position: absolute;
  z-index: -10;
  opacity: 0;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 0.63rem;
  user-select: none;
  cursor: pointer;
  font-family: Heebo-Regular;
 // font-size: 1.13rem;
  height: 1.69rem;
  color: #7b7777;
  align-self: flex-start;
  margin-bottom: auto; // delete line
}

.checkboxLabel::before {
  content: "";
  display: inline-block;
  width: 0.94rem;
  height: 0.94rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 0.13rem solid #707070;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70% 70%;
}

.checkbox:checked+.checkboxLabel::before {
  background-image: url("../../../assets/images/checkmark.svg");
}

.checkboxLabel:hover::before {
  border-color: #b1b1b1;
  cursor: pointer;
}

.submitButton {
  background-color: #405f6a;
  width: 11.44rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  color: white;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
  box-shadow: 0px 20px 40px #221B4A24;
}

.goToLoginContainer {
  display: flex;
  justify-content: center;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  height: 9%;
  display: flex;
  align-items: flex-end;
}

.goToLoginContainer>p {
  margin: 0;
  margin-inline-end: 0.63rem;
  color: #7b7777;
}

.goToLoginContainer>a {
  color: #f5b9ab;
}

.hideShowIcon {
  position: absolute;
  top: 2.1rem;
  left: 0.6rem;
  opacity: 0.3;
  cursor: pointer;
}

@media (max-width: 119rem) {
  .userRegFormContainerContent {
    padding: 3.44rem 4.5rem 1.31rem 4.88rem;
  }

  .regForm {
    height: 82%;
  }

  .inputContainer {
    margin-bottom: .7rem;
  }

  .inputContainer>input {
    height: 3rem;
  }

  .inputPasswordsContainerItem>input {
    margin-top: 0.31rem;
    margin-bottom: 1rem;
  }

  .userRegFormContainerContent {
    padding: 3.1rem 3rem 1.31rem 3.1rem;
  }

  .submitButton {
    height: 3rem;
    font-size: 1rem;
    line-height: 1.7rem;
    width: 9.44rem;
  }

  .hideShowIcon {
    top: .8rem;
  }
}

@media (max-width: 100rem) {

  .userRegFormContainerContent {
    padding: 2.34rem 2.5rem 1rem 2.88rem;
  }

  .submitButton {
    height: 2.5rem;
    line-height: 1.5rem;
  }

  .rootWrapper {
    padding-left: 7.69rem;
    padding-right: 7.69rem;
  }

  .closeIconDesktop {
    scale: 65%;
  }

  .regHeader {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }

  .inputContainer>input {
    height: 2rem;
  }

  .goToLoginContainer {
    font-size: 1.05rem;
    line-height: 1.21rem;
  }

  .checkboxLabel {
    font-size: 1rem;
    height: 1.5rem;
  }

  .hideShowIcon {
    top: .3rem;
    left: 0;
    scale: .7;
  }

  .inputContainer>input {
    padding-inline-start: .7rem;
    padding-inline-end: 2rem;
  }
}

@media (max-width: 80rem) {

  .errorMessage {
    font-size: 0.5rem;
  }

  .closeIconDesktop {
    top: 0.3125rem;
    left: 0.3125rem;
  }

  .userRegFormContainerContent {
    padding-top: 0;
  }

  .rootWrapper {
    padding-top: 2.31rem;
    padding-bottom: 2.31rem;
    padding-left: 7rem;
    padding-right: 7rem;
    align-items: normal;
  }

  .regButtonsWithOtherResContainer {
    padding-bottom: 1.25rem;
  }

  .userRegContainer {
    flex-direction: column;
    justify-content: flex-start;
    max-height: none;
  }

  .userRegBackgroundImage {
    height: auto;
    width: 100%;
  }

  .userRegFormContainer {
    height: auto;
    width: 100%;
  }

  .adaptiveWrapperFirstBlock {
    padding-bottom: 76%;
  }

  .adaptiveWrapperSecondBlock {
    padding-bottom: 0;
  }

  .userRegFormContainerContent {
    position: relative;
    padding-top: 2.31rem;
  }

  .regHeader {
    text-align: center;
    margin-bottom: 2.18rem;
  }

  .inputContainer>input {
    margin-bottom: 1.25rem;
    height: 3.06rem;
  }

  .regFormBlock {
    margin-bottom: 2rem;
  }

  .goToLoginContainer {
    flex-direction: column;
    align-items: center;
  }

  .goToLoginContainer>p {
    margin: 0;
  }

  .closeIconMobile {
    display: block;
    top: 0.78rem;
    left: 2.09rem;
    scale: 52%;
  }

  .closeIconDesktop {
    display: none;
  }

  .errorMessage {
    bottom: 0.3rem;
  }

  .submitButton {
    height: 2.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .checkboxContainer {
    margin-bottom: 1.5rem;
  }

  .checkboxLabel {
    top: .8rem;
  }

  .hideShowIcon {
    top: .8rem;
  }

}



@media (max-width: 60rem) {

  .errorMessage {
    font-size: 0.65rem;
  }
}

@media (max-width: 40rem) {
  .rootWrapper {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 6.45rem;
  }

  .closeIconMobile {
    top: -2.6rem;
    left: 0;
    right: -0.4rem;
  }

  .regButtonsWithOtherResContainer>div {
    width: 100%;
  }

  .userRegFormContainerContent {
    padding: 2.4rem 1.31rem 2.4rem 1.31rem;
    padding-top: 2.4rem;
  }

  .regHeader {
    font-size: 1.25rem;
    line-height: 1.56rem;
    color: #f5b9ab;
  }

  .submitButton {
    font-size: 1rem;
  }

  .goToLoginContainer {
    font-size: 0.88rem;
    line-height: 1.31rem;
  }

  .goToLoginRegContainer>a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #f5b9ab;
  }

  .inputContainer>input {
    padding-inline-end: 2rem;
  }

  .errorMessage {
    font-size: 0.5rem;
  }
}