.supplierTitle {
  margin-top: 0rem;
  margin-bottom: 0;
  font-family: Heebo-Bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #434242;
}

.supplierDescription {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0.94rem;
  margin-bottom: 1.88rem;
}

.showText {
  height: auto;
}

.notShowText {
  height: 4.13em;
  overflow: hidden;
}

.readMoreLink {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, white 85%, rgba(255, 255, 255, 0) );
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-inline-start: 1.56rem;
  padding-bottom: 0.125rem;
  text-decoration: underline;
  cursor: pointer;
}

.supplierContacts {
  margin-top: 0.25rem;
}

.supplierLocations,
.supplierContacts {
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2.19rem;
}

.supplierLocationsTitle,
.supplierContactsTitle {
  color:#434242;
  font-family: Heebo-Bold;
}

.supplierContactsLink {
  cursor: pointer;
  color: #F5B9AB;
}

.noDispaly {
  display: none;
}

.supplierLocationsContent {
  color:#707070;
}

@media (max-width: 40rem) {
  .supplierTitle {
    font-family: Assistant-Bold;
    font-size: 1.38rem;
    line-height: 1.89rem;
  }

  .supplierContacts {
    margin-top: 0;
  }

  .supplierLocationsTitle,
  .supplierContactsTitle {
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
  }
  .supplierContactsNumber,
  .supplierLocationsContent {
    color:#707070;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .supplierContactsLink {
    font-size: 1.13rem;
    line-height: 1.69rem;
  }
}
