.arrow {
  cursor: pointer;
  margin: 0 0.625rem;
}
.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  object-fit: contain; 
}
.selectedImage{
  min-height: 25vh;
  max-height: 75vh;
  object-fit: contain; 
  overflow: hidden;
  user-select: none;
}
@media (max-width: 50rem) {
  .arrow {
    cursor: pointer;
    margin: 0 0.3125rem;
  }
}