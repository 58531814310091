@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}
$rem: 16;
.titleContainer {
  display: flex;
  align-items: center;
  margin-top: 3.56rem;
}
.select {
  font-size: 1.5rem;
  width: 19.75rem;
  height: 3.625rem;
}
.desctop{
  display: block;
}
.mobile{
  display: none;
}
.closeIcon{
  position: relative;
  padding-bottom: 0.7rem;
  margin: 10px;
}
.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.closePopup{
  position: relative;
  padding: 1rem;
}
.blockCalendar{
  background-color: rgba(64, 95, 106, 0.9);
}
.infoDialogClose{
  background-color: #FFFFFF;
  height: calc(200rem/$rem);
  border-radius: calc(5rem/$rem);
}
.infoDialogtitle{
  text-align: center;
  display: flex;
  font-family: Heebo-Bold;
  font-size: calc(16rem/$rem);
  color: #242323;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
}
.mainPopup::-webkit-scrollbar { width: 0; }
.dialogButtons{
  display: flex;
}
.buttonVideo{
  cursor: pointer;
  margin-right: calc(10rem/$rem);
  margin-top: calc(30rem/$rem);
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  height: calc(45rem/$rem);
  font-family: Heebo-Regular;
  font-size:  calc(14rem/$rem);
  padding: 0 calc(18rem/$rem);
  color: #FFFFFF;
  border: none;
  box-sizing: border-box;
}
.titleContainer h3 {
  margin: 0;
  font-family: Heebo-Bold;
  font-size: 1.56rem;
  line-height: 2.31rem;
}

.titleContainer p {
  margin: 0;
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
}

.serviceTable {
  // width: 32.5rem;
  width: toRem(654);
  border-spacing: 0em 0.94rem;
}

.tableForTotalValue tr {
  height: 2.5rem;
  user-select: none;
}

.devider {
  background: #707070b2;
  height: 0.06rem;
  border: none;
  margin-top: 0.5rem;
  margin-bottom: 0;
  // width: 32.5rem;
  width: toRem(654);
}

.totalValueCell:first-child {
  width: 40%;
  font-family: Heebo-Bold;
  font-size: 1.63rem;
  line-height: 2.38rem;
  padding-inline-start: 0.75rem;
}

.totalValueCell:last-child {
  width: 17.2%;
}

.totalValueCell:nth-child(2n) {
  text-align: center;
  // width: 100%;
  font-family: Heebo-Bold;
  font-size: 1.63rem;
  line-height: 2.38rem;
}

.buttonsContainer {
  display: flex;
  margin-top: 2.5rem;
}

.contactButton {
  box-shadow: 0px 20px 40px #405F6A24;
  border: none;
  background-color: #405f6a;
  border-radius: 0.31rem;
  color: #ffffff;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  // width: 9.25rem;
  height: 3.5rem;
  justify-content: center;
  margin-inline-end: 1.75rem;
  cursor: pointer;
  white-space: nowrap;
}

.addToCheckListButton,
.addToFavoriteButton {
  border: none;
  background-color: #ffffff;
  margin-inline-end: 1.75rem;
  cursor: pointer;
}

.addToCheckListButton {
  display: block;
  position: relative;
  padding-inline-end: 0;
}

.addToFavoriteButton {
  padding: 0;
}

.checkListButtonDescription {
  display: block;
  position: absolute;
  bottom: -1.06rem;
  white-space: nowrap;
  transform: translate(25%, 0);
  font-family: Heebo-Regular;
  font-size: 0.75rem;
  line-height: 1.13rem;
  color: #242323;
}

.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.infoDialogClose{
  background-color: #FFFFFF;
  height: calc(200rem/$rem);
  border-radius: calc(5rem/$rem);
}
.infoDialogtitle{
  text-align: center;
  display: flex;
  font-family: Heebo-Bold;
  font-size: calc(16rem/$rem);
  color: #242323;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.mainPopup::-webkit-scrollbar { width: 0; }
.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.blockCalendar{
  background-color: rgba(64, 95, 106, 0.9);
}
.buttonsBlock{
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 40%;
  align-items: flex-end;
}
.buttonDialog{
  align-items: center;
  background-color: #405f6a;
  border: none;
  border-radius: 0.31rem;
  box-shadow: 0 20px 40px rgba(64,95,106,.141);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  height: 2.5rem;
  justify-content: center;
  line-height: 2rem;
  // margin-inline-end: 1.75rem;
  white-space: nowrap;
  width: 6.25rem;
}
.shekelSymbol {
  margin-inline-start: 0.19rem;
  box-sizing: border-box;
}

.noPrices{
  display: flex;
  flex-direction: column;
  gap: toRem(15);
  
  .description{
    display: block;
    max-width: toRem(604);
    font-size: toRem(24);
    line-height: toRem(35);
    
    .link{
      font-family: "Heebo-Bold";
      cursor:pointer;
      text-decoration: underline;
    }
  }
}

.venueContainer{

  .venueRows{
    display: flex;
    flex-direction: column;
    gap: toRem(13);
    padding-top: toRem(34);

    .venueRow{
      display: flex;
      font-size: toRem(24);
      line-height: toRem(35);

      .rowRegions{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: toRem(5);
      }

      span:first-child{
        min-width: toRem(200);
      }
    }
    
  }

}
@media (max-width: 60rem) {
  .select {
    width: 15.0625rem;

    height: 3.1875rem;
    font-size: 1.25rem;
  }
}
@media (max-width: toRem(800)) {
  .desctop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .venueContainer{

    .venueRows{
      display: flex;
      flex-direction: column;
      gap: toRem(12);
      padding-top: toRem(36);
  
      .venueRow{
        display: flex;
        font-size: toRem(18);
        line-height: toRem(27);

        .rowRegions{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: toRem(5);
        }
  
        span:first-child{
          min-width: toRem(150);
        }
      }
      
    }
  
  }


  .serviceTable {
    width: 100%;
  }

  .devider {
    width: 100%;
    margin-top: 0.94rem;
  }

  .titleContainer {
    margin-top: 2.19rem;
    margin-bottom: 0.44rem;
  }

  .titleContainerHint {
    display: none;
  }

  .titleContainer h3 {
    font-family: Assistant-Bold;
    font-size: 1.31rem;
    line-height: 1.75rem;
  }

  .serviceTable {
    border-spacing: 0em 0.63rem;
  }

  .totalValueCell:first-child,
  .totalValueCell:nth-child(2n) {
    font-size: 1.13rem;
    line-height: 1.69rem;
  }

  .tableForTotalValue {
    border-spacing: 0rem 0.25rem;
  }

  .tableForTotalValue tr {
    height: 1.88rem;
  }

  .checkListButtonDescription {
    display: none;
  }
  
  .contactButton {
    font-size: 1.13rem;
    line-height: 1.5rem;
    // width: 8.06rem;
    height: 3.13rem;
    margin-inline-end: 0.63rem;
  }
  .description{
    font-size: 0.75rem !important;
    line-height: 1.5 !important;
  }
  .addToCheckListButton,
  .addToFavoriteButton {
    margin-inline-end: 0rem;
    scale: 80%;
  }

  .addToFavoriteButton {
    margin-inline-start: .4rem;
  }

  .shekelSymbol {
    scale: 86%;
    margin-bottom: -0.15rem;
    margin-inline-start: 0;
  }
}
