@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.chosenLink {
  font-family: 'Heebo-Bold';
}

.headerContainerMobile {
  position: relative;
  // height: 5em;
  display: none;
}

.headerMobileContainer {
  z-index: 1201;
  display: none;
  box-sizing: border-box;
  box-shadow: 0rem 0.19rem 0.38rem #00000030;
  height: var(--header-height);

  // svg {
  //   scale: 150%;
  //   margin-inline-start: 0.35rem;
  //   margin-bottom: 0.5rem;
  // }

  .logoImg {
    max-width: 83px;
    // margin-bottom: -0.75rem;
  }
  .notificationImg {
    margin-right: 16px;
    max-width: 25px;
  }
  .menuBack {
    z-index: 5;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: rgb(64 95 106 / 90%);
    overflow: scroll;

    .menuContainer {
      overflow: scroll;
      background-color: white;
      max-width: toRem(290);
      padding-right: toRem(37);

      .logoContainer {
        margin-right: toRem(-18);
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: toRem(31);
        padding-bottom: toRem(51);

        img {
          height: toRem(66);
          width: toRem(144);
        }
      }

      .closeContainer {
        width: 100;
        height: toRem(13);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: toRem(16);
        padding-bottom: toRem(12);

        img {
          cursor: pointer;
          width: toRem(13);
          height: toRem(13);
        }
      }

      .headerListLogin,
      .headerListMenu {
        font-size: toRem(20);
        line-height: toRem(29);
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: toRem(15);
        padding: 0;
        margin: 0;
      }

      .areaButton {
        position: relative;
        display: flex;

        color: #405f6a;
        .label {
          font-family: 'Heebo-Bold';
        }

        .notificationImg {
          position: absolute;
          top: toRem(4);
          right: toRem(-25);
          width: toRem(22);
          height: toRem(22);
        }

        .arrow {
          padding-right: toRem(5);
          display: flex;
          align-items: center;
        }
      }

      .underline {
        border-top: toRem(1) solid #707070;
        width: toRem(118);
        margin-top: toRem(15);
        padding-bottom: toRem(17);
      }

      .headerListLogin {
        padding-bottom: toRem(29);
      }

      .logout {
        cursor: pointer;
        padding-bottom: toRem(55);
        font-size: toRem(16);
        line-height: toRem(24);
      }
    }
  }
}

@media (max-width: 60rem) {
  .headerContainerMobile {
    position: relative;
    height: 5rem;
    display: flex;
  }
  .headerMobileContainer {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-height: 60px;
    padding: 12.5px 20px 10px;
  }
}

// @media (max-width: 25.75rem) {
//   .headerMobileContainer {
//     display: flex;
//     // padding: 1rem 1.25rem 1rem 1.25rem;

//     .logoImg {
//       margin-bottom: -0.38rem;
//     }

//     // svg {
//     //   scale: 100%;
//     //   margin-inline-start: 0rem;
//     //   margin-bottom: 0.5rem;
//     // }
//   }
// }
