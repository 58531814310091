@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.rootWrapper {
  min-height: 100vh;
  width: 100%;
  background-color: rgba(64, 95, 106, 0.9);
  padding-left: 11.69rem;
  padding-right: 11.69rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.hideShowIcon {
  position: absolute;
  top: 1.2rem;
  left: 0.6rem;
  opacity: .13rem;
  cursor: pointer;
}
.supplierLoginContainer {
  display: flex;
  justify-content: space-between;
  max-height: 48.13rem;
  max-width: 96.26rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(64, 95, 106, 0.9);
}

.supplierLoginBackgroundImage {
  height: 100%;
  width: 50%;
}

.supplierLoginBackgroundImageContent {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: .31rem;
}

.adaptiveWrapperFirstBlock,
.adaptiveWrapperSecondBlock {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.supplierLoginFormContainer {
  height: 100%;
  width: 50%;
}

.supplierLoginFormContainerContent {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 6.44rem 4.5rem 3.06rem 4.88rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: .31rem 0 0 .31rem;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
  top: -3rem;
  right: 0;
}

.loginHeader {
  margin: 0;
  font-family: Heebo-Bold;
  font-size: 2rem;
  line-height: 2.94rem;
  color: #e7b1ad;
  height: 13%;
}

.regButtonsWithOtherResContainer {
  display: flex;
  justify-content: space-between;
  height: 15%;
   display: none;
}

.regButtonsWithOtherResContainer > button {
  position: relative;
  background-color: white;
  height: 3.13rem;
  width: 48%;
  border: 0.06rem solid #434242;
  border-radius: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 1.13rem;
  line-height: 1.69rem;
  cursor: pointer;
}

.regButtonsWithOtherResContainer > button > img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0.94rem;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 73%;
}

.loginFormBlock {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  position: relative;
}

.inputContainer > input {
  background-color: #f7f4f4;
  border: none;
  border-radius: 0.31rem;
  height: 4.56rem;
  margin-bottom: 0.94rem;
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #242323;
  padding-inline-start: 1.69rem;
  padding-inline-end: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}

input::placeholder {
  color:#9b9999;
}
.inputContainer > input:focus {
  outline: .13px solid #405F6A;
}

.errorMessage {
  margin: 0;
  position: absolute;
  color: red;
  bottom: 0.007rem;
  right: 0.31rem;
  font-family: Heebo-regular;
  font-size: 0.65rem;
}

input.invalidField {
  background-color: #f3e8e8;
  outline: .06rem solid red;
}

.forgotPasswordLink {
  font-family: Heebo-Regular;
  font-size: 1.13rem;
  line-height: 1.69rem;
  text-decoration: underline;
  color: #7b7777;
}

.submitButton {
  background-color: #405f6a;
  margin-top: 0.44rem;
  width: 6.88rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  color: white;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
}

.submitButton2 {
  background-color: #405f6a;
  margin-top: 0.44rem;
  width: 8.88rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  color: white;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
  box-shadow: 0 1.25rem 2.5rem #221B4A24
}

.goTeRegContainer {
  display: flex;
  justify-content: center;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  height: 9%;
  display: flex;
  align-items: flex-end;
}

.goTeRegContainer > p {
  margin: 0;
  margin-inline-end: 0.63rem;
  color: #7b7777;
}

.goTeRegContainer > a {
  color: #f5b9ab;
}

@media (max-width: 100rem) {
  .loginHeader {
    height: 10%;
  }

  .regButtonsWithOtherResContainer {
    height: 22%;
  }

  .regButtonsWithOtherResContainer {
    flex-direction: column;
  }

  .regButtonsWithOtherResContainer > button {
    margin-bottom: 0.94rem;
  }

  .loginForm {
    height: 67%;
  }

  .regButtonsWithOtherResContainer > button {
    width: 100%;
  }

  .errorMessage {
    bottom: 0.1rem;
  }

  .inputContainer > input {
    height: 3rem;
  }

  .supplierLoginFormContainerContent {
    padding: 3.44rem 3rem 2.06rem 3rem;
  }

  .submitButton {
    height: 2.9rem;
  }
  .submitButton2 {
    height: 2.9rem;
  }
  .hideShowIcon {
    top: .5rem;    
  }
}

@media (max-width: 80rem) {
  .supplierLoginFormContainerContent {
    padding: 2.34rem 3rem 1.63rem 3rem;
  }

  .inputContainer > input {
    height: 2.86rem;
  }

  .submitButton {
    height: 2.5rem;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  .submitButton2 {
    height: 2.5rem;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  .goTeRegContainer {
    flex-direction: column;
    align-items: center;
  }

  .goTeRegContainer > p {
    margin: 0;
  }

  .rootWrapper {
    padding-left: 7.69rem;
    padding-right: 7.69rem;
  }

  .loginHeader {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }

  .loginForm {
    height: 73%;
  }

  .inputContainer > input {
    height: 2.3rem;
    margin-bottom: 1.1rem;
  }

  .forgotPasswordLink {
    font-size: 1rem;
    line-height: 1.45rem;
  }

  .goTeRegContainer {
    font-size: 1.05rem;
    line-height: 1.21rem;
  }
  .hideShowIcon {
    top: .3rem;    
  }
}

@media (max-width: 66.25rem) {
  .supplierLoginFormContainerContent {
    
    padding: 1.5rem 4.5rem 3.06rem 4.88rem;
    
  }
}



@media (max-width: 64rem) {
  .supplierLoginFormContainerContent {
    padding: 1.5rem 3rem 1.03rem 3rem;
  }

  .submitButton {
    height: 2.1rem;
  }
  .submitButton2 {
    height: 2.1rem;
  }
}

@media (max-width: 60rem) {
  .rootWrapper {
    padding-top: 2.31rem;
    padding-bottom: 2.31rem;
    align-items: normal;
  }

  .supplierLoginContainer {
    flex-direction: column;
    justify-content: flex-start;
    max-height: none;
  }

  .supplierLoginBackgroundImage {
    height: auto;
    width: 100%;
  }

  .supplierLoginFormContainer {
    height: auto;
    width: 100%;
  }

  .adaptiveWrapperFirstBlock {
    padding-bottom: 76%;
  }

  .adaptiveWrapperSecondBlock {
    padding-bottom: 0;
  }

  .supplierLoginFormContainerContent {
    position: relative;
    padding-top: 2.31rem;
    border-radius: 0 0 .31rem .31rem;
  }

  .loginHeader {
    text-align: center;
    margin-bottom: 2.18rem;
  }

  .inputContainer > input {
    margin-bottom: 1.25rem;
    height: 3.06rem;
  }

  .loginFormBlock {
    margin-bottom: 2rem;
  }

  .closeIcon {
    top: 0.6rem;
    right: unset;
    left: 2.1rem;
    scale: .75;
  }

  .submitButton {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .submitButton2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .hideShowIcon {
    top: .5rem;    
  }
}
@media (max-height:40rem) {
  .supplierLoginContainer {
    max-height: 29.6rem;
  }
  .closeIcon{
    top: -2rem;
  }
}
@media (max-width: 40rem) {
  .supplierLoginFormContainerContent {
    padding: 2.4rem 1.31rem 2.4rem 1.31rem;
    padding-top: 2.4rem;
  }

  .loginHeader {
    font-size: 1.25rem;
    line-height: 1.56rem;
    color: #f5b9ab;
  }

  .forgotPasswordLink {
    font-size: 0.88rem;
    line-height: 1.31rem;
  }

  .goTeRegContainer {
    font-size: 0.88rem;
    line-height: 1.31rem;
  }

  .goTeRegContainer > a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #f5b9ab;
  }

  .rootWrapper {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .closeIcon {
    scale: 0.51;
  }
}
