@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}
$rem:16;

.dialogWrapper{
  box-sizing: border-box;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: flex-end;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: toRem(17);
  // padding-top: toRem(145);
  // @media (min-width: toRem(961)) {
  //     justify-content: center;
  //     padding-top: toRem(0);
  // }

  
  .filters{
    display: flex;
  }
  .filtersTitle{
    font-family: Heebo-Medium;
    font-size: toRem(18);
    color:#242323;
    margin-right: toRem(8);
  }
  .line{
    border: toRem(1) solid #707070;
    opacity: 0.41;
    margin: 0;
  }
  .contentBlock{
    margin: toRem(19) 0 toRem(11);
  }
  .applyButton{
    text-decoration: underline;
    color: #242323;
    opacity: 0.91;
    font-family: Heebo-Bold;
    font-size: toRem(16);

  }
  .block{
    display: flex;
  }
  .blockTitle{
    font-family: Heebo-Regular;
    font-size: toRem(16);
    color: #242323;
    margin: 0 0 toRem(9) toRem(10);
  }
  .blockContent{
    font-family: Heebo-Bold;
    font-size: toRem(16);
    color: #242323;
  }
  .contentTitle{
    font-family: Heebo-Regular;
    font-size: toRem(16);
    color: #242323;
    margin-bottom: toRem(8);
  }
  .mainDialog{
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    max-width: calc(375rem/$rem);
    min-height: calc(257rem/$rem);
    width: 100%;
    padding: toRem(19) toRem(18) 0;
    border-radius: toRem(8) toRem(8) 0 0;
    overflow-y: auto;

    .closeDialog{
      position: absolute;
      top: toRem(23.5);
      left: toRem(18);
      img{
        cursor: pointer;
        width: toRem(11.5);
        height: toRem(11.5);
      }
    }

    .content{
      color:#3E3D3D;

      .checkbox {
        margin-bottom: 1rem;
        position: relative;
      
        input {
          display: none;
        }
      
        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: 1.5rem;
          right: 2.7rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }
      
        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(18);
          line-height: toRem(27);
          color:#000000;
          margin-inline-start: toRem(31);
        }
      
        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-31);
          top: toRem(2);
          border: .13rem solid #707070;
          border-radius: 50%;
        }
      
        input:checked + label::before {
          background-size: toRem(10);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tabProgress{
        padding-top: toRem(55);
        display: flex;
        gap: toRem(11);

        span{
          width: toRem(35);
          border: 3px solid;
          border-radius: toRem(3);
        }
        
      }

      .tab1{

        .tab1Title{
          padding-bottom: toRem(4);
          font-size: toRem(28);
          line-height: toRem(41);
          font-family: "Heebo-Bold";
        }

        .tab1SubTitle{
          padding-bottom: toRem(14);
          font-size: toRem(24);
          line-height: toRem(35);
        }

        .tab1Underline1{
          width: 100%;
          margin-left: toRem(68);
          box-sizing: border-box;
          border-top: 1px solid rgba(112, 112, 112, .51);
        }

        .tab1Title1{
          padding-top: toRem(17);
          padding-bottom: toRem(29);
          font-family: "Heebo-Bold";
          font-size: toRem(20);
          line-height: toRem(29);
        }

        .tab1ContNumbers{
          padding-bottom: toRem(21);
          display: flex;
          flex-direction: column;
          gap: toRem(17);
          div{
            font-size: toRem(20);
            line-height: toRem(29);
          }
        }

        .tab1Underline2{
          width: 100%;
          margin-left: toRem(68);
          box-sizing: border-box;
          border-top: 1px solid #000000;
        }

        .tab1Cost1{
          padding-top: toRem(17);
          padding-bottom: toRem(6);
          font-size: toRem(22);
          line-height: toRem(32);

          img{
              margin-right: toRem(4);
              padding-bottom: toRem(7);
              width: toRem(8);
              height: toRem(8);
            }
        }

        .tab1Cost2{
          padding-bottom: toRem(15);
          font-family: "Heebo-Medium";
          font-size: toRem(22);
          line-height: toRem(32);

          img{
              margin-right: toRem(4);
              padding-bottom: toRem(7);
              width: toRem(8);
              height: toRem(8);
            }
          
        }

        .tab1CostTotal{
          box-sizing: border-box;
          display: flex;
          max-width: fit-content;
          gap: toRem(12);
          flex-direction: column;
          background: #D3DDDC 0% 0% no-repeat padding-box;
          border-radius: 5px;
          padding: toRem(9) toRem(13) toRem(10) toRem(11);
          margin-bottom: toRem(178);

          .tab1CostTotalTitle{
            display: flex;
            font-size: toRem(20);
            line-height: toRem(29);
            font-family: "Heebo-Bold";
            align-items: flex-end;

            img{
              margin-right: toRem(4);
              padding-bottom: toRem(7);
              width: toRem(8);
              height: toRem(8);
            }
          }

          .tab1CostTotalDescr{
            font-size: toRem(14);
            line-height: toRem(21);
            color: #707070;
          }


        }



        .buttons {
          padding-top: toRem(0);
          padding-bottom: toRem(0);
          display: flex;
          width: fit-content;
          flex-direction: column;
          gap: toRem(15);
      
          .save {
            padding: toRem(15) toRem(29);
            border-radius: toRem(5);
            background-color: #405f6a;
            color: #ffffff;
            box-shadow: 0px 20px 40px #221B4A24;
            cursor: pointer;
            font-size: toRem(18);
            line-height: toRem(27);
          }
        }


        

      }

      .tab2{

        .tab2Title{
          padding-bottom: toRem(7);
          font-size: toRem(20);
          line-height: toRem(29);
          font-family: "Heebo-Bold";
          color:#000000;
        }

        .tab2Text{
          box-sizing: border-box;
          display: flex;
          font-size: toRem(16);
          line-height: toRem(24);
          background: #F6F7F7 0% 0% no-repeat padding-box;
          border-radius: 4px;
          min-height: toRem(345);
          padding: toRem(31) toRem(26);
          margin-bottom: toRem(15);
        }

        .download{
          display: flex;
          gap: toRem(6);
          padding-bottom: toRem(38);

          .downloadLink{
            cursor: pointer;
            font-size: toRem(18);
            line-height: toRem(27);
            text-decoration: underline;
          }

          img{
            cursor: pointer;
            width: toRem(26);
            height: toRem(26);
          }
        }

        .signa{
          position: relative;
          box-sizing: border-box;
          height: toRem(120);
          
          padding: toRem(8) toRem(9);
          margin-bottom: toRem(43);
          display: flex;
          gap: toRem(10);
          width: 100%;
          flex-direction: column;
          background: #F7F7F771 0% 0% no-repeat padding-box;
          border: 1px solid #70707060;
          border-radius: 3px;

          
          span{
            position: absolute;
            font-size: toRem(14);
            line-height: toRem(21);
            top: toRem(8);
            right: toRem(9);
            color: rgba(0, 0, 0, .42);
          }

          .signaContent{
            width: 100%;
            height: 100%;

            .sigCanvas{
              height: toRem(102);
              width: toRem(701);
            }
          }
        }




        .buttons {
          padding-top: toRem(0);
          padding-bottom: toRem(0);
          display: flex;
          flex-direction: column;
          width: fit-content;
          gap: toRem(15);
      
          .save {
            padding: toRem(15) toRem(34);
            border-radius: toRem(5);
            background-color: #405f6a;
            box-shadow: 0px 20px 40px #221B4A24;
            color: #ffffff;
            cursor: pointer;
            font-size: toRem(18);
            line-height: toRem(27);
          }
        }

      }

      

    }
  }

}


@media (min-width: toRem(961)) {
  .mobile{
    display: none;
  }
}



@media (max-width: 60rem) {
  .desctop1{
    display: none;
  }

  .dialogWrapper{
    padding-top: toRem(119);

    .mainDialog{
      position: relative;
      box-sizing: border-box;
      display: flex;
      border-radius: toRem(4);
      flex-direction: column;
      background-color: white;
      max-width: toRem(337);
      width: 100%;
      padding: toRem(56) toRem(18) 0 0;
      box-shadow: 0px 10px 20px #00000029;
      max-height: calc(100vh - toRem(119));
      overflow-y: auto;
  
      .closeDialog{
        position: absolute;
        top: toRem(12);
        left: toRem(10);
        img{
          cursor: pointer;
          width: toRem(12);
          height: toRem(12);
        }
      }
     .load{
      width: toRem(18);
      height: toRem(15);
      cursor: pointer;
     }
      .content{
        color:#3E3D3D;
  
        .checkbox {
          display: flex;
          margin-bottom: 1rem;
          position: relative;
        
          input {
            display: none;
          }
        
          .errorMessage {
            margin: 0;
            position: absolute;
            color: red;
            top: toRem(35);
            right: toRem(0);
            font-family: Heebo-regular;
            font-size: 0.65rem;
          }
        
          label {
            cursor: pointer;
            user-select: none;
            position: relative;
            font-family: Heebo-Regular;
            font-size: toRem(14);
            line-height: toRem(19);
            color:#000000;
            margin-inline-start: toRem(23);
          }
        
          label::before {
            content: "";
            display: inline-block;
            width: toRem(15);
            height: toRem(15);
            position: absolute;
            right: toRem(-23);
            top: toRem(5);
            border: .13rem solid #707070;
            border-radius: 50%;
          }
        
          input:checked + label::before {
            background-size: toRem(8);
            background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
            background-repeat: no-repeat;
            background-position: center center;
            background-origin: border-box;
          }
        }
  
        .tabProgress{
          padding-top: toRem(38);
          display: flex;
          gap: toRem(11);
  
          span{
            width: toRem(35);
            border: 3px solid;
            border-radius: toRem(3);
          }
          
        }
  
        .tab1{
  
          .tab1Title{
            padding-bottom: toRem(8.2);
            font-size: toRem(20);
            line-height: toRem(29);
            font-family: "Heebo-Bold";
          }
  
          .tab1SubTitle{
            padding-bottom: toRem(14);
            font-size: toRem(20);
            line-height: toRem(29);
          }
  
          .tab1Underline1{
            width: 100%;
            margin-left: toRem(0);
            box-sizing: border-box;
            border-top: 1px solid rgba(112, 112, 112, .51);
          }
  
          .tab1Title1{
            padding-top: toRem(20);
            padding-bottom: toRem(15);
            font-family: "Heebo-Bold";
            font-size: toRem(18);
            line-height: toRem(27);
          }
  
          .tab1ContNumbers{
            padding-bottom: toRem(13);
            display: flex;
            flex-direction: column;
            gap: toRem(10);
            div{
              font-size: toRem(16);
              line-height: toRem(24);
            }
          }
  
          .tab1Underline2{
            width: 100%;
            margin-left: toRem(0);
            box-sizing: border-box;
            border-top: 1px solid #000000;
          }
  
          .tab1Cost1{
            padding-top: toRem(13);
            padding-bottom: toRem(9);
            font-size: toRem(18);
            line-height: toRem(27);
  
            img{
                margin-right: toRem(4);
                padding-bottom: toRem(7);
                width: toRem(8);
                height: toRem(8);
              }
          }
  
          .tab1Cost2{
            padding-bottom: toRem(13);
            font-family: "Heebo-Medium";
            font-size: toRem(18);
            line-height: toRem(27);
  
            img{
                margin-right: toRem(4);
                padding-bottom: toRem(7);
                width: toRem(8);
                height: toRem(8);
              }
            
          }
  
          .tab1CostTotal{
            box-sizing: border-box;
            display: flex;
            max-width: fit-content;
            gap: toRem(3);
            flex-direction: column;
            background: #D3DDDC 0% 0% no-repeat padding-box;
            border-radius: toRem(5);
            padding: toRem(9) toRem(13) toRem(12) toRem(12);
            margin-bottom: toRem(59);
  
            .tab1CostTotalTitle{
              display: flex;
              font-size: toRem(20);
              line-height: toRem(29);
              font-family: "Heebo-Bold";
              align-items: flex-end;
  
              img{
                margin-right: toRem(4);
                padding-bottom: toRem(7);
                width: toRem(8);
                height: toRem(8);
              }
            }
  
            .tab1CostTotalDescr{
              font-size: toRem(14);
              line-height: toRem(21);
              color: #707070;
            }
  
  
          }
  
  
  
          .buttons {
            padding-top: toRem(0);
            padding-bottom: toRem(0);
            display: flex;
            width: fit-content;
            flex-direction: column;
            gap: toRem(15);
        
            .save {
              padding: toRem(17) toRem(35);
              border-radius: toRem(5);
              background-color: #405f6a;
              color: #ffffff;
              box-shadow: 0px 20px 40px #221B4A24;
              cursor: pointer;
              font-size: toRem(14);
              line-height: toRem(21);
            }
          }
  
  
          
  
        }
  //
        .tab2{
  
          .tab2Title{
            padding-bottom: toRem(7);
            font-size: toRem(20);
            line-height: toRem(29);
            font-family: "Heebo-Bold";
            color:#000000;
          }
  
          .tab2Text{
            box-sizing: border-box;
            display: flex;
            font-size: toRem(14);
            line-height: toRem(21);
            background: #F6F7F7 0% 0% no-repeat padding-box;
            border-radius: 4px;
            min-height: toRem(273);
            padding: toRem(16) toRem(18);
            margin-bottom: toRem(16);
          }
  
          .download{
            display: flex;
            gap: toRem(3);
            padding-bottom: toRem(17);
  
            .downloadLink{
              cursor: pointer;
              font-size: toRem(16);
              line-height: toRem(24);
              text-decoration: underline;
            }
  
            img{
              cursor: pointer;
              width: toRem(21);
              height: toRem(21);
            }
          }
  
          .signa{
            position: relative;
            box-sizing: border-box;
            height: toRem(144);
            
            padding: toRem(8) toRem(11);
            margin-bottom: toRem(21);
            display: flex;
            gap: toRem(10);
            width: 100%;
            flex-direction: column;
            background: #F7F7F771 0% 0% no-repeat padding-box;
            border: 1px solid #70707060;
            border-radius: 3px;
  
            
            span{
              position: absolute;
              font-size: toRem(12);
              line-height: toRem(18);
              top: toRem(20);
              right: toRem(11);
              color: rgba(0, 0, 0, .42);
            }
  
            .signaContent{
              width: 100%;
              height: 100%;
  
              .sigCanvas{
                height: toRem(102);
                width: toRem(701);
              }
            }
          }
  
  
  
  
          .buttons {
            padding-top: toRem(0);
            padding-bottom: toRem(0);
            display: flex;
            flex-direction: column;
            width: fit-content;
            gap: toRem(15);
        
            .save {
              padding: toRem(17) toRem(38);
              border-radius: toRem(5);
              background-color: #405f6a;
              box-shadow: 0px 20px 40px #221B4A24;
              color: #ffffff;
              cursor: pointer;
              font-size: toRem(14);
              line-height: toRem(21);
            }
          }
  
        }
  
        
  
      }
    }
  }
}
  