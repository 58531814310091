.tableRow {
  background-color: #F7EFEA;
  height: 3.38rem;
  user-select: none;
}

.activRow {
  background-color: #EBD9CD;
}

.tableCell {
  height: 3.38rem;
}

.tableCell:first-child {
  border-radius: 0rem 0.31rem 0.31rem 0rem;
  width: 40%;
  font-family: Heebo-Regular;
  font-size: 1.56rem;
  line-height: 2.31rem;
  padding-inline-start: 0.75rem;
}

.blockForCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableCell:last-child {
  border-radius: 0.31rem 0rem 0rem 0.31rem;
  width: 17.2%;
  padding-inline-end: 1.25rem;
}

.tableCell:nth-child(2n) {
  text-align: center;
  width: 28%;
  font-family: Heebo-Regular;
  font-size: 1.63rem;
  line-height: 2.38rem;
}

.counterButton {
  cursor: pointer;
}

.quontity {
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2.19rem;
}

.shekelSymbol {
  margin-inline-start: 0.19rem;
  box-sizing: border-box;
}

@media (max-width: 40rem) {
  .tableRow {
    height: 2.25rem;
  }
  
  .tableCell {
    height: 2.25rem;
  }

  .tableCell:first-child {
    font-size: 1.06rem;
    line-height: 1.56rem;
  }

  .tableCell:nth-child(2n) {
    font-size: 1.06rem;
    line-height: 1.5rem;
  }

  .quontity {
    font-size: 0.94rem;
    line-height: 1.38rem;
  }

  .counterButton {
    scale: 65%;
  }

  .shekelSymbol {
    scale: 63%;
    margin-bottom: -0.15rem;
    margin-inline-start: 0;
  }
}
