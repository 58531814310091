@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

$rem: 16;

.watsApp{
    z-index: 999;
    box-sizing: border-box;
    width: 14.75rem;
    height: 6rem;
    background-color: #1EE62C;
    color:#FFFFFF;
    border-radius: 1.375rem;
    display: flex;
    padding: 1rem 1.125rem;
    position: fixed;
    bottom: 2.5625rem;
    right: 6rem;
  
    .watsAppIcon{
      width: 3.75rem;
      height: 3.75rem;
    }
    .watsAppText{
      width: 100%;
      font-size: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .desctopVersion{
    display: block;
  }
  .mobileVersion{
    display: none;    
  }
  .mobileTitle{
    margin: calc(20rem/$rem) calc(18rem/$rem) calc(20rem/$rem) 0;
    text-align: right;
    font-family: Heebo-Bold;;
    font-size: calc(24rem/$rem);
    color: #242323;
    direction: ltr;
  }
  .aboutUsMobile{
    height: calc(418rem/$rem - calc(57rem/$rem));
    width: calc(100% - calc(35rem/$rem) );
    background-color: rgba(245, 185, 171, 0.8);
    display: flex;
    padding: calc(32rem/$rem) calc(17rem/$rem) calc(25rem/$rem) calc(18rem/$rem);
  //  opacity: 0.8;
    flex-direction: column;
  }
  .slideWrapper {
    width: calc(550rem/$rem);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .slidefeedback {
    width: calc(514rem/$rem);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .mobileFile{
    width: 100%;
    height: calc(269rem/$rem);
  }
  .mobileVideo{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    user-select: none;
  }
  .mobileImg{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    user-select: none;
  }
  .mobileHeader{
    text-align: right;
    font-size: calc(22rem/$rem);
    font-family: Heebo-Medium;
    color: #242323;
    max-width: calc(648rem/$rem);
    margin: calc(28rem/$rem) calc(1rem/$rem) 0 calc(41rem/$rem);
    height: calc(64rem/$rem);
    overflow: hidden;
  }
  .mobileDescription{
    margin: calc(26rem/$rem) calc(18rem/$rem) calc(47rem/$rem) calc(53rem/$rem);
    text-align: right;
    font-family: Heebo-Regular;
    color: #242323;
    max-height: calc(648rem/$rem);
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-scrollbar { width: 0; }
    -ms-overflow-style: none;
  }
  .mobileDescription::-webkit-scrollbar { width: 0; }
  .title{
    text-align: center;
    font-size: calc(32rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    max-width: calc(648rem/$rem);
    margin: calc(90rem/$rem) auto calc(50rem/$rem);
    height: calc(47rem/$rem);
    overflow: hidden;
  }
  .aboutUs{
    height: calc(644rem/$rem - calc(110rem/$rem));
    background: #EBD9CD 0% 0% no-repeat padding-box;
    width: calc(100% - calc(188rem/$rem) );
    display: flex;
    padding: calc(57rem/$rem) calc(94rem/$rem) calc(53rem/$rem);
  }
  .leftBlock{
    width: 50%;
    color: #242323;
    text-align: right;
    font-size: calc(24rem/$rem);
    font-family: Heebo-Regular;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
    -webkit-scrollbar { width: 0; }
    -ms-overflow-style: none;
  }
  .leftBlock::-webkit-scrollbar { width: 0; }
  .rightBlock{
    width: 50%;
    padding: 0 calc(51rem/$rem) 0 0;
    box-sizing: border-box;
  }
  .aboutUsVideo{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    user-select: none;
  }
  .aboutUsImg{
    width: 100%;
    height: 100%;
    object-fit: cover; 
    user-select: none;
  }
  .feedbackBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - calc(188rem/$rem));
    margin: calc(61rem/$rem) calc(94rem/$rem) 0 calc(94rem/$rem);
  }
  .feedbackForm{
    width: 100%;
    height: calc(281rem/$rem);
    background: #D3DDDC 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
  }
  .feedbackFormSlide{
    width: 100%;
    height: calc(348rem/$rem);
    background: #F5F4F4 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
  }
  .feedbackHeader{
    text-align: right;
    margin: calc(28rem/$rem) calc(35rem/$rem) calc(35rem/$rem) calc(35rem/$rem);
    font-family: Heebo-Bold;
    font-size: calc(32rem/$rem);
    color: #242323;
    overflow: hidden;
    height: calc(47rem/$rem);
    box-sizing: border-box;
  }
  .feedbackHeaderSlider{
    text-align: center;
    margin: calc(38rem/$rem) calc(55rem/$rem) calc(31rem/$rem) 0;
    font-family: Heebo-Bold;
    font-size: calc(20rem/$rem);
    color: #242323;
    overflow: hidden;
    max-width: calc(403rem/$rem);
    height: calc(29rem/$rem);
    box-sizing: border-box;
  }
  .feedbackDescription{
    text-align: right;
    font-family: Heebo-Regular;
    margin: calc(28rem/$rem) calc(35rem/$rem) calc(31rem/$rem) calc(35rem/$rem);
    font-size: calc(24rem/$rem);
    color: #242323;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-scrollbar { width: 0; }
    -ms-overflow-style: none;
    height: calc(140rem/$rem);
    width: calc(100% - calc(70rem/$rem));
  }
  .feedbackDescription::-webkit-scrollbar { width: 0; }
  .feedbackDescriptionSlider{
    text-align: center;
    font-family: Heebo-Regular;
    margin: calc(0rem/$rem) calc(55rem/$rem) calc(19rem/$rem) calc(56rem/$rem);
    font-size: calc(18rem/$rem);
    color: #707070;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-scrollbar { width: 0; }
    -ms-overflow-style: none;
    height: calc(140rem/$rem);
    width: calc(403rem/$rem);
  }
  .feedbackDescriptionSlider::-webkit-scrollbar { width: 0; }
  .clientName{
    text-align: center;
    margin: calc(0rem/$rem) calc(55rem/$rem) 0 0;
    font-family: Heebo-Bold;
    font-size: calc(18rem/$rem);
    color: #E7B1AD;
    overflow: hidden;
    max-width: calc(403rem/$rem);
    // height: calc(27rem/$rem);
    line-height: toRem(27);
    box-sizing: border-box;
  }
  .clientDate{
    text-align: center;
    margin: 0 auto toRem(38);
    font-size: toRem(15);
    line-height: toRem(22);
    height: toRem(22);
    color: #E7B1AD;
    overflow: hidden;
    max-width: calc(403rem/$rem);
    box-sizing: border-box;
  }
  .desctopSlider{
    display: block;
  }
  .desctopSliderFeedback{
    display: block;
    margin: 0 calc(94rem/$rem) calc(151rem/$rem);
  }
  .mobileSliderFeedback{
    display: none;
  }
  .mobileSlider{
    display: none;
  }
  .button_{
    text-align: center;
    font-family: Heebo-Regular;
    font-size: calc(22rem/$rem);
    border: none;
    color: #FFFFFF;
    background: #405F6A 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
    box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A24;
    width: 10.75rem;
    height: 3.5rem;
    cursor: pointer;
  }
  .buttonBlock{
    display: flex;
    justify-content: flex-end;
    margin: calc(58rem/$rem) 0 calc(89rem/$rem) calc(94rem/$rem);
  }
  .feedbackTitle{
    text-align: center;
    color: #3E3D3D;
    text-transform: capitalize;
    font-family: Heebo-Medium;
    font-size: calc(32rem/$rem);
    margin: 0 auto calc(60rem/$rem);
    direction: ltr;
  }
  @media (max-width: calc(1800rem/$rem)) {
    .title{
      max-width: 80%;
      margin: calc(90rem/$rem) 10% calc(50rem/$rem);
      max-width: 90%;
    }
  }
  @media (max-width: calc(1200rem/$rem)) {
  .aboutUs{
    flex-direction:column-reverse;
    align-items: center; 
    height: calc(644rem/$rem - calc(95rem/$rem));
  }
  .leftBlock{
    width: 90%;
    text-align: center;
    margin-top: calc(15rem/$rem);
  }
  .rightBlock{
    width: 90%;
    text-align: center;
    padding: 0;
  }
  .aboutUsVideo{
    height: calc(350rem/$rem);
  }
  .aboutUsImg{
    height: calc(350rem/$rem);
  }
  }
  @media (max-width: calc(900rem/$rem)) {
    .title{
      font-size: calc(24rem/$rem);
      height: calc(39rem/$rem);
    }
  }
  @media (max-width: calc(760rem/$rem)) {
    .desctopSlider{
      display: none;
    }
    .mobileSlider{
      display: block;
    }
    .slideWrapper {
      width: calc(343rem/$rem);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .feedbackBlock{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: calc(100%);
      margin: calc(47rem/$rem) 0 calc(33rem/$rem);
    }
    .feedbackForm{
      width: 100%;
      height: calc(178rem/$rem);
      background: #D3DDDC 0% 0% no-repeat padding-box;
      border-radius: 0;
    }
    .feedbackHeader{
      text-align: right;
      margin: calc(24rem/$rem) calc(18rem/$rem) calc(10rem/$rem) 0;
      font-family: Heebo-Bold;
      font-size: calc(24rem/$rem);
      color: #242323;
      overflow: hidden;
      height: calc(35rem/$rem);
      box-sizing: border-box;
    }
    .feedbackDescription{
      text-align: right;
      font-family: Heebo-Regular;
      font-size: calc(16rem/$rem);
      color: #242323;
      height: calc(96rem/$rem);
      width: calc(100% - calc(30rem/$rem));
      margin: 0 calc(15rem/$rem) calc(23rem/$rem);
    }
  }
  @media (max-width: calc(700rem/$rem)) {
    .clientDate{
      font-size: toRem(12);
    }
    .feedbackTitle{
      font-size: calc(22rem/$rem);
    }
    .desctopVersion{
      display: none;
    }
    .mobileVersion{
      display: block !important;
    }
    .feedbackHeaderSlider{
      margin: calc(27rem/$rem) calc(26rem/$rem) calc(22rem/$rem) 0;
      font-size: calc(18rem/$rem);
      max-width: calc(299rem/$rem);
      height: calc(27rem/$rem);
    }
    .feedbackFormSlide{
      width: 100%;
      margin-bottom: 36px;
      height: calc(258rem/$rem);
      background: #F5F4F4 0% 0% no-repeat padding-box;
      border-radius: calc(5rem/$rem);
    }
    .slidefeedback {
      width: calc(351rem/$rem);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .feedbackDescriptionSlider{
      margin: calc(0rem/$rem) calc(26rem/$rem) calc(11rem/$rem) calc(26rem/$rem);
      font-size: calc(16rem/$rem);
      height: calc(100rem/$rem);
      width: calc(299rem/$rem);
    }
    .clientName{
      margin: calc(0rem/$rem) calc(26rem/$rem) calc(1rem/$rem) calc(26rem/$rem);
      font-size: calc(16rem/$rem);
      max-width: calc(403rem/$rem);
      height: calc(24rem/$rem);
    }
    .desctopSliderFeedback{
      display: none;
    }
    .mobileSliderFeedback{
      display: block !important;
      margin-bottom: calc(32rem/$rem);
    }
    .feedbackTitle{
      margin: 0 auto calc(25rem/$rem);
    }
  }  
  @media (max-width: calc(640rem/$rem)) {
    .button_{
      font-size: calc(18rem/$rem);
      width: calc(149rem/$rem);
      height: calc(51rem/$rem);
      cursor: pointer;
    }
    .buttonBlock{
      display: flex;
      justify-content: flex-end;
      margin: calc(33rem/$rem) 0 calc(75rem/$rem) calc(17rem/$rem);
    }
  }
  @media (max-width: 53.75rem) {
    .watsApp{
      width: 10.375rem;
      height: 4.18rem;
      padding: 0.75rem 0.8125rem;
  
      .watsAppIcon{
        img{
          width: 2.68rem;
          height: 2.68rem;
        }
      }
  
      .watsAppText{
        font-size: 0.875rem;
      }
    }
    .watsApp{
      right: 1.125rem;
      bottom: 3.5rem;
    }
  }

  @media (max-width: 37.5rem) { 
    .desctop{
      display: none;
    }
  }

// iPhone 11 and iPhone XR
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }
}
// iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }}

//iPhone 13, iPhone 12 and iPhone 12 Pro
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  } }    

// iPhone 13 Pro Max and iPhone 12 Pro Max
@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }}

// iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }}

// iPhone 11 Pro Max and iPhone Xs Max
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) { 
  .forIphone{
    height: calc( calc(30rem/$rem) + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }}