.swiperSlideComponentContainer {
  width: 100%;
}

.swiperSlideContainer {
  width: auto;
}

.arrowContainer {
  display: flex;
  direction: ltr;
  margin-top: 0.63rem;
}

.arrow {
  cursor: pointer;
}

.arrow:not(:first-child) {
  margin-left: 1.25rem  ;
}

.swiperSlideSelected, .swiperSlide {
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #242323;
  padding: 1.19rem 2.56rem 1.13rem 2.5rem;
  cursor: pointer;
  border-radius: .31rem;
  background-color: #EEE9E5;
  box-sizing: border-box;
}

.swiperSlide,.swiperSlideSelected:not(:last-child) {
  margin-inline-end: .94rem;
}

/* .swiperSlide:last-child {
  margin-inline-end: 6rem;
} */

.swiperSlideSelected {
  background-color: #E8CFBF;
  // color: #eaa7ad;

}

.disableSliderNextPrev {
  pointer-events: none;
}

.arrowButton {
  background-color: white;
  padding: 0;
  cursor: pointer;
  border: none;
}

/* @media (max-width: 80rem) {
  .swiperSlideSelected, .swiperSlide {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media (max-width: 60rem) {
  .swiperSlideComponentContainer {
    width: 100%;
  }

  .arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .arrowContainer {
    margin-top: 0.69rem;
  }

  .swiperSlideSelected:not(:last-child), .swiperSlide:not(:last-child) {
    margin-inline-end: 1.25rem;
  }
}

@media (max-width: 40rem) { 
  .swiperSlideSelected, .swiperSlide {
    font-size: 1.25rem;
    line-height: 1.81rem;
  }

  .arrow svg{
    scale: 78%;
  }

  .arrow:not(:first-child) {
    margin-left: 1.07rem  ;
  }
} */
