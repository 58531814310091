@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .eventsContainer {
    position: relative;
    box-sizing: border-box;
    max-width: toRem(422);
    width: 100%;
    height: toRem(56);
  }

  .permissionsContainer{
    padding-top: toRem(15);
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: toRem(15)
  }

  .rad {
    // padding-right: toRem(24);
  }

  .radio {
    position: relative;

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      top: -1.5rem;
      right: 0rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      user-select: none;
      position: relative;
      font-family: Heebo-Regular;
      font-size: toRem(14);
      line-height: toRem(21);
      color: #3e3d3d;
      margin-inline-start: toRem(30);
    }

    label::before {
      content: "";
      display: inline-block;
      width: toRem(18);
      height: toRem(18);
      position: absolute;
      right: toRem(-30);
      bottom: -0.05rem;
      border: 0.13rem solid #707070;
      border-radius: 50%;
    }

    input:checked + label::before {
      background-size: toRem(12);
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-origin: border-box;
    }
  }

  .buttons {
    padding-top: toRem(15);
    // padding-bottom: toRem(45);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: toRem(15);

    .save {
      padding: toRem(12) toRem(24);
      border-radius: toRem(5);
      background-color: #405f6a;
      color: #ffffff;
      cursor: pointer;
      font-size: toRem(22);
      line-height: toRem(32);
    }
  }
}

@media (max-width: toRem(960)) {
  .wrapper{

    .eventsContainer {
      position: relative;
      box-sizing: border-box;
      max-width: toRem(422);
      width: 100%;
      height: toRem(42);
    }

    .buttons {
      padding-top: toRem(15);
      // padding-bottom: toRem(45);
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: toRem(15);
  
      .save {
        padding: toRem(10) toRem(16);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(16);
        line-height: toRem(21);
      }
    }
  }

}