$rem: 16;

@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.watsApp{
  z-index: 999;
  box-sizing: border-box;
  width: 14.75rem;
  height: 6rem;
  background-color: #1EE62C;
  color:#FFFFFF;
  border-radius: 1.375rem;
  display: flex;
  padding: 1rem 1.125rem;
  position: fixed;
  bottom: 2.5625rem;
  right: 6rem;

  .watsAppIcon{
    width: 3.75rem;
    height: 3.75rem;
  }

  .watsAppText{
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.tabProgress{
  padding-top: toRem(55);
  padding-bottom: toRem(25);
  display: flex;
  gap: toRem(11);

  span{
    width: toRem(35);
    border: 3px solid;
    border-radius: toRem(3);
  }
  
}

.menuDesctop{
  display: block;
}
.menuMobile{
  display: none;
}
.menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #EBD9CD;
    font-family: Heebo-Bold;
    font-size:  calc(24rem/$rem);
    height: calc(80rem/$rem);
    padding: 0 calc(29rem/$rem) 0 calc(27rem/$rem);
    margin: calc(68rem/$rem) 0 calc(26rem/$rem);
    color: #3E3D3D;
}
.firstPopup{
  // position: absolute;
  // height: calc(125rem/$rem);
  max-height: calc(125rem/$rem);
  width: calc(300rem/$rem);
  right: calc(37rem/$rem);
  // background: #FFFFFF 0% 0% no-repeat padding-box;
  // box-shadow: 0 calc(10rem/$rem) calc(20rem/$rem) #00000029;
  // border: calc(1rem/$rem) solid #242323;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Bold;
  font-size: calc(18rem/$rem);
  color: #242323;
  box-sizing: border-box;
}
.firstPopupTitle{
  margin: calc(4rem/$rem) 0 0 0; //calc(23rem/$rem)
}

.dialogWrapper2{
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);
  
  .mainDialog{
    position: relative;
    box-sizing: border-box;
    display: flex;
    border: toRem(1) solid #E7B1AD;
    box-shadow: 0 toRem(10) toRem(20);
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    max-width: toRem(637);
    width: 100%;
    padding: toRem(16.4) toRem(19.1) toRem(15.7);
    box-shadow: 0px 10px 20px #00000029;
    max-width: toRem(1056.26);
    height: toRem(442.1);
    overflow-y: auto;

    .closeDialog{
      position: absolute;
      top: toRem(23);
      left: toRem(17);
      img{
        cursor: pointer;
        width: toRem(17);
        height: toRem(17);
      }
    }
  }
  .contentSecondDialog{
    border: toRem(2) solid #E7B1AD;
    border-radius: toRem(9);
    height: 100%;
  }
  .secondTitle{
    font-family: Heebo-Bold;
    color: #405F6A;
    font-size: toRem(38);
    text-align: center;
    margin: toRem(52) 0 toRem(40);
  }
  .secondContent{
    font-family: Heebo-Regular;
    color: #000000;
    font-size: toRem(24);
    text-align: center;
    margin-bottom: toRem(57);
  }
  .buttonsContent{
    display: flex;
    justify-content: center;
  }
  .leftBtn{
    font-family: Heebo-Regular;
    color: #FFFFFF;
    font-size: toRem(22);
    background-color: #405F6A;
    width: toRem(181);
    height: toRem(56);
    cursor: pointer;
    border: none;
    border-radius: toRem(4);
  }
  .rightBtn{
    font-family: Heebo-Regular;
    color: #405F6A;
    font-size: toRem(22);
    background-color: #FFFFFF;
    width: toRem(181);
    height: toRem(56);
    cursor: pointer;
    border: toRem(1) solid #405F6A;
    border-radius: toRem(4);
    margin-right: toRem(14);
  }
    .content{
      color:#3E3D3D;

      .checkbox {
        margin-bottom: 1rem;
        position: relative;
      
        input {
          display: none;
        }
      
        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: 1.5rem;
          right: 2.7rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }
      
        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(18);
          line-height: toRem(27);
          color:#000000;
          margin-inline-start: toRem(31);
        }
      
        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-31);
          top: toRem(2);
          border: .13rem solid #707070;
          border-radius: 50%;
        }
      
        input:checked + label::before {
          background-size: toRem(10);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tabProgress{
        padding-top: toRem(55);
        display: flex;
        gap: toRem(11);

        span{
          width: toRem(35);
          border: 3px solid;
          border-radius: toRem(3);
        }
        
      }
    }
}

.secondPopup{
  //position: absolute;
 // height: calc(319rem/$rem);
  max-height: calc(319rem/$rem); /////
  width: calc(358rem/$rem);
  right: calc( (calc(calc(375rem/$rem) - calc(358rem/$rem)) / 2) );
  left: calc( (calc(calc(375rem/$rem) - calc(358rem/$rem)) / 2) );
 // background: #FFFFFF 0% 0% no-repeat padding-box;
 // box-shadow: 0 calc(10rem/$rem) calc(20rem/$rem) #00000029;
 //border: calc(1rem/$rem) solid #242323;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Bold;
  font-size: calc(18rem/$rem);
  color: #242323;
  box-sizing: border-box;
}
.eventName{
  font-family: Heebo-Bold;
  font-size: calc(24rem/$rem);
  margin: calc(15rem/$rem) calc(30rem/$rem) 0 0;
  color: #242323;
}
.desctop{
  margin: 0 calc(90rem/$rem);
  padding-bottom: calc(57rem/$rem);
}
.buttonsBlock{
  display: flex;
  margin-top: calc(5rem/$rem);
}
.paymentButton{
  border: calc(2rem/$rem) solid #3F5E69;
  height: calc(61rem/$rem);
  width: calc(100% - 60rem/$rem);
  margin-right: calc(30rem/$rem);
  margin-top: calc(-10rem/$rem);
  margin-bottom: calc(10rem/$rem);;
  text-align: center;
  background: #3F5E69 0% 0% no-repeat padding-box;
  border-radius: calc(5rem/$rem);
  color: #FFFFFF;
  font-family: Heebo-Medium;
  font-size: calc(20rem/$rem);
}
.buttonContact{
  margin-right: calc(30rem/$rem);
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  height: calc(51rem/$rem);
  font-family: Heebo-Regular;
  font-size:  calc(18rem/$rem);
  color: #FFFFFF;
  padding: 0 calc(24rem/$rem);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
.buttonVideo{
  cursor: pointer;
  margin-right: calc(30rem/$rem);
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  height: calc(51rem/$rem);
  font-family: Heebo-Regular;
  font-size:  calc(18rem/$rem);
  padding: 0 calc(24rem/$rem);
  color: #FFFFFF;
  border: none;
  box-sizing: border-box;
}
.chatBlocks{
    display: flex;
    margin-bottom: calc(107rem/$rem);
}
.linkBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.serviceName{
  margin-right: calc(29rem/$rem);
  font-family: Heebo-Bold;
  font-size:  calc(25rem/$rem);
  color: #242323;
}
.supplierLink{
  margin-left: calc(34rem/$rem);
  color: #242323;
  text-transform: capitalize;
  text-decoration: underline;
  font-family: Heebo-Regular;
  font-size:  calc(20rem/$rem);
  cursor: pointer;
}
.chatBlock{
  display: flex;   
  flex-direction: column;//-reverse;
  overflow: auto;
  height: calc(756rem/$rem);
}
.chatBlock::-webkit-scrollbar { width: 0; }
.UserBlock{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: calc(10rem/$rem);
}
.messageBlock{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: calc(10rem/$rem);
}
.titleInvite{
  color: #3E3D3D;
  font-family: Heebo-Medium;
  font-size: calc(22rem/$rem);
  margin: calc(12rem/$rem) calc(13rem/$rem) 0 0;
}
.messageUser{
  width: 65.55%;
  background: rgba(231, 177, 173, 0.27) 0% 0% no-repeat padding-box;
  border-radius: 0 calc(12rem/$rem) calc(12rem/$rem) calc(12rem/$rem);
  padding: calc(10rem/$rem);
  margin-left: calc(27rem/$rem);
  direction: ltr;
  color: #3E3D3D;
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
}
.message{
  width: 68.4%;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: calc(12rem/$rem) 0 calc(12rem/$rem) calc(12rem/$rem);
  padding: calc(10rem/$rem);
  margin-right: calc(27rem/$rem);
  color: #3E3D3D;
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
}
.messageAcept{
  width: 68.4%;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border-radius: calc(12rem/$rem) 0 calc(12rem/$rem) calc(12rem/$rem);
  padding: calc(10rem/$rem);
  margin-right: calc(27rem/$rem);
  color: #3E3D3D;
  font-family: Heebo-Medium;
  font-size: calc(22rem/$rem);
}
.userName{
  display: flex;
  width: 65.55%;
  color: #3E3D3D;
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  margin-bottom: calc(8rem/$rem);
  margin-left: calc(27rem/$rem);
}
.senderName{
  display: flex;
  width: 65.55%;
  margin-right: calc(27rem/$rem);
  color: #3E3D3D;
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  margin-bottom: calc(8rem/$rem);
}
.item{    
    margin-left: 25%;
}
.leftBlock{
    width: calc(68% - calc(12rem/$rem));
    height: calc(900rem/$rem);
    margin-right: calc(12rem/$rem);
    border-radius: calc(5rem/$rem);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: calc(1rem/$rem) solid #405F6A;
}
.rightBlock{
    width: calc(31.7% - calc(12rem/$rem));
    height: calc(900rem/$rem);
    margin-left: calc(12rem/$rem);
    border-radius: calc(5rem/$rem);
    background: #FFFFFF 0% 0% no-repeat padding-box;    
    border: calc(1rem/$rem) solid #405F6A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.supplierRightBlock{
  display: flex;
  flex-direction: column;
  width: calc(32% - calc(12rem/$rem));
  height: calc(900rem/$rem);
  margin-left: calc(12rem/$rem);
  background: #FFFFFF 0% 0% no-repeat padding-box;    
}
.firstRightBlock{
  border-radius: calc(5rem/$rem);
  background: #FFFFFF 0% 0% no-repeat padding-box;    
  border: calc(1rem/$rem) solid #405F6A;
  height: calc(450rem/$rem);
  margin-bottom: calc(29rem/$rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.secondRightBlock{
  border-radius: calc(5rem/$rem);
  background: #FFFFFF 0% 0% no-repeat padding-box;    
  border: calc(1rem/$rem) solid #405F6A;
  height: calc(421rem/$rem);
}
.messages{
    height: calc(756rem/$rem);
}
.sendMessage{
    border: calc(1rem/$rem) solid #405F6A;
    border-radius: calc(5rem/$rem);
    height: calc(108rem/$rem);
    width: calc(100% - calc(58rem/$rem));
    box-sizing: border-box;
   // background: url(send.svg) no-repeat (39.46rem/$rem) calc(33.83rem/$rem);
   // background-size: auto calc(39.46rem/$rem) calc(33.83rem/$rem);
    margin: 0 calc(29rem/$rem);
    padding-inline-end: 5.5rem;
    padding-inline-start: calc(40rem/$rem);
    font-family: Heebo-Regular;
    font-size: calc(24rem/$rem);
    //margin-top: calc(38rem/$rem);
    color: #242323;
    outline: none;
}
.sendMessage2{
  border: 0.0625rem solid #405F6A;
  border-radius: 0.3125rem;
  height: 81px;
  width: calc(100% - 115px);
  box-sizing: border-box;
  margin-right: 102px;
  margin-left: 13px;
  padding-inline-end: 5.5rem;
  padding-inline-start: 2.5rem;
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  color: #242323;
  outline: none;
}
.buttonSend{
  position: relative;
  height: calc(108rem/$rem);
  width: 100%; 
  margin-top: calc(15rem/$rem);
  //margin: 0 calc(29rem/$rem);
}

.file_upload_title{
  position: absolute;
  right: calc(15rem/$rem);
  top: calc(40rem/$rem);
}
.buttonSend2{
  position: relative;
  height: calc(108rem/$rem);
  width: 100%; 
  margin-top: calc(15rem/$rem);
  margin-right: 27px;
    margin-left: 31px;
    background: #F7F7F7;
    display: flex;
    border: 1px solid #405F6A;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    align-items: center;
    border-radius: 0.3125rem;
    width: calc(100% - 58px);
}
.buttonSend3{
  position: fixed;
  bottom: calc(10rem/$rem);
  height: calc(108rem/$rem);
  width: 100%; 
  margin-top: calc(15rem/$rem);
  margin-right: 27px;
    margin-left: 31px;
    background: #F7F7F7;
    display: flex;
    border: 1px solid #405F6A;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    align-items: center;
    border-radius: 0.3125rem;
    width: calc(100% - 58px);
}
.sendIcon{
  position: absolute;
  left: calc(39.7rem/$rem);
  top: calc(37rem/$rem);
  cursor: pointer;
}
.request_invite{
  position: absolute;
  right: calc(15rem/$rem);
  top: calc(44rem/$rem);
  cursor: pointer;
}
.file_upload{
  position: absolute;
  right: calc(62rem/$rem);
  top: calc(40rem/$rem);
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.sendIcon2{
  position: absolute;
  left: calc(39.7rem/$rem);
  top: calc(37rem/$rem);
  cursor: pointer;
}
  ::placeholder { 
    color: #242323;
  }
  
  :-ms-input-placeholder { 
    color: #242323;
  }
  
  ::-ms-input-placeholder { 
    color: #242323;
  }
  .services{
    margin-top: calc(5rem/$rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(250rem/$rem);
    overflow: auto;
  }
  .services::-webkit-scrollbar { width: 0; }
  .service{
    background: rgba(235, 217, 205, 0.41) 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
    width: calc(100% - calc(63rem/$rem));
    height: calc(50rem/$rem);
    margin-bottom: calc(15rem/$rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .count{
    background: rgba(235, 217, 205, 0.41) 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
    width: calc(100% - calc(63rem/$rem));
    height: calc(50rem/$rem);
    margin-bottom: calc(15rem/$rem);
    margin: 0 calc(30rem/$rem) 0 calc(33rem/$rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .serviceChoosed{
    background: rgba(235, 217, 205, 0.94) 0% 0% no-repeat padding-box;
    border-radius: calc(5rem/$rem);
    width: calc(100% - calc(63rem/$rem));
    margin-bottom: calc(15rem/$rem);
    height: calc(50rem/$rem);
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .servicesBlock{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .line{
    width: calc(100% - calc(63rem/$rem));
    padding: 0 calc(30rem/$rem) 0 calc(33rem/$rem);
    margin-bottom: calc(9rem/$rem);
    box-sizing: border-box;
    border: calc(1rem/$rem) solid #707070;
    opacity: 0.37;
    height: 0;
  }
  .serviceItem{
    font-size: calc(24rem/$rem);
    font-family: Heebo-Regular;
    color: #242323;
    margin-right: calc(13rem/$rem);
    width: 41%;
    height: calc(28rem/$rem);
    overflow: hidden;
    box-sizing: border-box;
  }
  .serviceSum{
    font-size: calc(25rem/$rem);
    font-family: Heebo-Regular;
    color: #242323;
    width: 30%;
    height: calc(27rem/$rem);
    overflow: hidden;
    box-sizing: border-box;
  }
  .serviceCount{
    font-size: calc(24rem/$rem);
    font-family: Heebo-Regular;
    color: #3E3D3D;
    width: 16%;
    height: calc(27rem/$rem);
    text-align: left;
    margin-left: calc(32rem/$rem);
    overflow: hidden;
    box-sizing: border-box;
  }
  .serviceAllItem{
    font-size: calc(25rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    margin-right: calc(13rem/$rem);
    width: 50%;
    height: calc(28rem/$rem);
    overflow: hidden;
    box-sizing: border-box;
  }
  .serviceAllSum{
    font-size: calc(25rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    width: 65%;
    height: calc(27rem/$rem);
    overflow: hidden;
    box-sizing: border-box;
  }
  .result{
    width: calc(100% - calc(63rem/$rem));
    height: calc(50rem/$rem);
    margin-bottom: calc(15rem/$rem);
    margin: 0 calc(30rem/$rem) 0 calc(33rem/$rem);
    box-sizing: border-box;
    display: flex;
  }
  .shekelSymbol {
   // margin-inline-start: calc(3.45rem/$rem);
    box-sizing: border-box;
    width: calc(12.4rem/$rem);
    height: calc(12.4rem/$rem);
  }
.select{
  font-size: 1rem;
  width: calc(319rem/$rem);
  height: calc(45rem/$rem);
}
.selectCategory{
  font-size: 1rem;
  width: calc(100% - calc(63rem/$rem));
  height: calc(45rem/$rem);
}
select{
  font-size: 1rem;
}
.eventTypeBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(68.5rem/$rem);
}
.categoryBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(20rem/$rem);
}
.eventLabel{
  color: #3E3D3D;
  font-size: calc(18rem/$rem);
  font-family: Heebo-Bold;
  margin-bottom: calc(16rem/$rem);
}
.eventMobileLabel{
  display: none;
}
.mobileTitle{
  display: none;
}
.mobileMenu{
  display: none;  
}
.mobileLineChat{
  display: none;
}
.mobile{
  display: none;
}
.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.heeboImportant{
  font-family: Heebo-Regular !important;
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.mainPopup::-webkit-scrollbar { width: 0; }
.blockCalendar{
  background-color: rgba(64, 95, 106, 0.9);
}
.dialog{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(10rem/$rem) calc(20rem/$rem) #00000029;
}
.closeIcon {
  position: absolute;
  cursor: pointer;
}
.closeIconMobile {
  display: none;
}

.closeIconDesktop {
  top: calc(25rem/$rem);
  left: calc(23.45rem/$rem);
}
.dialogTitle{
  direction: ltr;
  text-align: center;
  font-size: calc(28rem/$rem);
  color: #242323;
  font-family: Heebo-Bold;
  max-width: calc(207rem/$rem);
  margin: calc(59rem/$rem) auto calc(37rem/$rem);
}
.supplierDialogTitle{
  direction: rtl;
  text-align: center;
  font-size: calc(28rem/$rem);
  color: #242323;
  font-family: Heebo-Bold;
  max-width: calc(207rem/$rem);
  margin: calc(59rem/$rem) auto calc(37rem/$rem);
}
.supplierDatePopup{
  color: #434242;
  font-size: calc(22rem/$rem);
  font-family: Heebo-Regular;
  margin-bottom: calc(27rem/$rem);
}
.dialogSecondTitle{
  direction: ltr;
  text-align: center;
  font-size: calc(28rem/$rem);
  color: #242323;
  font-family: Heebo-Bold;
  max-width: calc(782rem/$rem);
  margin: calc(116rem/$rem) auto calc(48rem/$rem);
}
.dialogDescription{
  color: #242323;
  text-align: center;
  font-size: calc(24rem/$rem);
 // max-width: calc(416rem/$rem);
  font-family: Heebo-Regular;
  margin: calc(0rem/$rem) calc(110rem/$rem) calc(37rem/$rem) calc(109rem/$rem);
}
.dialogMsgBtn{
  border: none;
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem)  calc(40rem/$rem)  #221B4A21;
  border-radius: calc(5rem/$rem) ;
  color: #FFFFFF;
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  padding: 0 calc(24rem/$rem);
  height: calc(51rem/$rem);
  cursor: pointer;
}
.dialogBtnBlock{
  display: flex;
  justify-content: center;
  margin-bottom: calc(83rem/$rem);
}
.identBlock{
  display: flex;
  justify-content: center;
}
.identNumber{
  // width: 52%;
  width: 100%;
  height: calc(73rem/$rem);
  background: #F7F4F4 0% 0% no-repeat padding-box;
  border: none;
  box-sizing: border-box;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(16rem/$rem);
  margin-bottom: calc(10rem/$rem);
  input {
    outline: none;
    padding-inline-start: calc(9rem/$rem);
  }
}
  input:focus {
    outline: calc(2rem/$rem) solid #405F6A;
  }

.identNumber::placeholder { 
  color: #9B9999;
}

.identNumber:-ms-input-placeholder {
  color: #9B9999;
}

.identNumber::-ms-input-placeholder {
  color: #9B9999;
}
.identLink{
  // width: 51%;
  width: 100%;
  text-align: right;
  margin: 0 auto calc(15rem/$rem);
  text-decoration: underline;
  font-family: Heebo-Regular;
  font-size: calc(12rem/$rem);
  color: #9B9999;
  cursor: pointer;
}
.address{
// width: 51%;
width: 100%;
text-align: right;
margin: 0 auto calc(6rem/$rem);
font-family: Heebo-Medium;
font-size: calc(16rem/$rem);
color: #6E6D6D;
}
.addressBlock{
  display: flex;
  justify-content: center;
  margin-bottom: calc(10rem/$rem);
}
.city{
  width: calc(25.5% - calc(8rem/$rem));
  box-sizing: border-box;
  height: calc(73rem/$rem);
  background: #F7F4F4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(16rem/$rem);
  margin-left: calc(8rem/$rem);
  input {
    outline: none;
  }
}
input.invalidField {
  outline: 0.06rem solid red;
}
.errorMessage {
  //margin: 0;
  position: absolute;
  color: red;
  bottom: -1rem;
  //  right: 0.31rem;
    right: 25%;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.errorMessageBlocks {
  margin: 0;
  position: absolute;
  color: red;
  bottom: -1rem;
  right: 0rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.errorMessageBlock {
 // margin: 0;
  position: absolute;
  color: red;
  bottom: -0.5rem;
//  right: 0.31rem;
  right: 25%;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.errorMessageLeft {
  margin: 0;
  position: absolute;
  color: red;
  bottom: -1rem;
  right: 0.4rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.errorBlock{
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: calc(10rem/$rem);
}
.adressInputs{
  // width: calc(25%);
  width: 100%;
  margin-bottom: calc(10rem/$rem);
  box-sizing: border-box;
}
.countError{
  position: absolute;
  color: red;
  bottom: -1rem;
  margin: 0;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.countErrorBlock{
  display: block;
  margin-bottom: calc(10rem/$rem);
}
.discount{
    padding-inline-start: calc(9rem/$rem) !important;
}
.city{
  input{
    padding-inline-start: calc(32rem/$rem);
  }
}
input{
  padding-inline-start: calc(9rem/$rem);
}
.city::placeholder { 
  color: #9B9999;
}

.city:-ms-input-placeholder {
  color: #9B9999;
}

.city::-ms-input-placeholder {
  color: #9B9999;
}
.street{
  width: calc(25.5% - calc(8rem/$rem));
  box-sizing: border-box;
  height: calc(73rem/$rem);
  background: #F7F4F4 0% 0% no-repeat padding-box;
  border: none;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(16rem/$rem);
 // margin-right: calc(8rem/$rem);
  input {
    outline: none;
  }
}
.street{
  input{
    padding-inline-start: calc(32rem/$rem);
  }
}
.street::placeholder { 
  color: #9B9999;
}

.street:-ms-input-placeholder {
  color: #9B9999;
}

.street::-ms-input-placeholder {
  color: #9B9999;
}
.secondStep{
  padding: 0 59px;
  .dialogBtnBlock{
        // margin-top: calc(33rem/$rem);
  margin-top: calc(83rem/$rem);
  margin-bottom: 0;
  }
}
.suppliersEventType{
  font-family: Heebo-Bold;
  font-size: calc(24rem/$rem);
  color: #242323;
  margin: calc(30rem/$rem) calc(32rem/$rem) calc(10rem/$rem) 0;
}
.slideWrapper {
  cursor: pointer;
  background: #EEE9E5 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(2rem/$rem) calc(5rem/$rem) #00000029;
  border-radius: calc(5rem/$rem);
  display: flex;
  align-items: center;
  width: calc(121rem/$rem);
  height: calc(61rem/$rem);
}
.slideWrapperActive {
  cursor: pointer;
  background: #E8CFBF 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(2rem/$rem) calc(5rem/$rem) #00000029;
  border-radius: calc(5rem/$rem);
  display: flex;
  align-items: center;
  width: calc(121rem/$rem);
  height: calc(61rem/$rem);
}
.supplierSwiper{
  width: calc(257rem/$rem);
  margin-right: calc(29.5rem/$rem);
}
.swiperText{
  text-align: center;
  width: 95%;
  overflow: hidden;
  height: calc(24rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(16rem/$rem);
}
.supplierDate{
  font-family: Heebo-Bold;
  font-size: calc(24rem/$rem);
  color: #242323;
  margin: calc(5rem/$rem) calc(32rem/$rem) calc(5rem/$rem) 0;
}
.supplierDateText{
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  color: #434242;
  margin: calc(5rem/$rem) calc(32rem/$rem) calc(5rem/$rem) 0;
}
.supplierPlace{
  font-family: Heebo-Bold;
  font-size: calc(24rem/$rem);
  color: #242323;
  margin: calc(5rem/$rem) calc(32rem/$rem) calc(5rem/$rem) 0;
}
.paymentContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(48.5rem/$rem);
}
.paymentRight{
  width: calc(50% - 207.5rem/$rem);
  max-width: calc(534rem/$rem);
  max-height: calc(416rem/$rem);
  background-color: #F6F7F7;
  margin-left: calc(49rem/$rem);
  border-radius: calc(6rem/$rem);
  padding: calc(29.5rem/$rem) calc(26rem/$rem) 0 calc(27rem/$rem);
}
.paymentLeft{  
  position: relative;
  width: calc(50% - 207.5rem/$rem);
  background-color: #FFFFFF;
  height: calc(600rem/$rem);

  .iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .absolutLoader{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .ldsDualRing {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .ldsDualRing:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #434242;
      border-color: #434242 transparent #434242 transparent;
      animation: ldsDualRing 1.2s linear infinite;
    }
    @keyframes ldsDualRing {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.paymentTitle{
  font-family: Heebo-Bold;
  font-size: calc(22rem/$rem);
  color: #000000;
}
.paymentSecondTitle{
  font-family: Heebo-Bold;
  font-size: calc(20rem/$rem);
  color: #000000;
  margin-top: calc(12.5rem/$rem);
  margin-bottom: calc(13.5rem/$rem);
}
.paymentLine{
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.51;
  margin: 0;
}
.paymentserviceBlock{
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(7rem/$rem);
}
.paymentBlock{
  max-height: calc(104rem/$rem);
  overflow: auto;
}
.paymentBlock::-webkit-scrollbar { width: 0; }
.paymentLineEnd{
  border: calc(1rem/$rem) solid #000000;
  width: calc(333rem/$rem);
  margin: calc(3rem/$rem) 0 0 0;
}
.paymentPrice{
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(2.5rem/$rem);
  margin-top: calc(16.5rem/$rem);
}
.paymentPriceAfterDiscount{
  font-family: Heebo-Medium;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(9.1rem/$rem);
}
.paymentDiscountBlock{
  width: calc(246.5rem/$rem);
  height: calc(75rem/$rem);
  margin-bottom: calc(29.5rem/$rem);
  background-color: #D3DDDC;
  border-radius: calc(5rem/$rem);
  padding: calc(9.5rem/$rem) calc(12rem/$rem) 0 0;
}
.paymentDiscount{
  font-family: Heebo-Bold;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(6.5rem/$rem);
  overflow: hidden;
  max-height: calc(22rem/$rem);
}
.paymentDiscountPrice{
  font-family: Heebo-Regular;
  font-size: calc(14rem/$rem);
  color: #707070;
}
.supplierPlaceText{
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  color: #434242;
  margin: calc(5rem/$rem) calc(31rem/$rem) calc(5rem/$rem) 0;
}
.supplierButtons{
  margin-right: calc(31rem/$rem);
  margin-bottom: calc(25rem/$rem);
  text-align: right;
}
.textarea{
  width: calc(100% - calc(63rem/$rem));
  height: calc(127rem/$rem);
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Bold !important;
  font-size: calc(20rem/$rem);
  font-size: 1rem;
  color: #242323;
  resize: none;
  padding-inline-start: calc(28rem/$rem);
}
.textareaWrapper{
  margin-top: calc(10rem/$rem);

  margin-bottom: calc(10rem/$rem);
  display: flex;
  justify-content: center;
}
.supplierButton{
  cursor: pointer;
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  color: #FFFFFF;
  border: none;
  height: calc(51rem/$rem);
  margin-left: calc(18rem/$rem);
  padding: 0 calc(20rem/$rem);
  box-sizing: border-box;
}
.supplierButtonDisabled{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  color: #405F6A;
  border: calc(1rem/$rem) solid #405F6A;
  height: calc(51rem/$rem);
  margin-left: calc(18rem/$rem);
  padding: 0 calc(20rem/$rem);
  box-sizing: border-box;
}
.supplierLine{
  width: calc(330rem/$rem);
  text-align: center;
  margin-top: calc(12rem/$rem);
}
.supplierDatePopup{
  display: flex;
}
.supplierContent{
  margin-right: 20.1%;
}
.supplierInfoPopup{
  margin-bottom: calc(14rem/$rem);
  font-family: Heebo-Bold;
  font-size: calc(25rem/$rem);
  color: #242323;
}
.supplierservicePopup{
  display: flex;
  font-family: Heebo-Medium;
  font-size: calc(22rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(23rem/$rem);
}
.supplierBlockPopup{
  height: calc(87rem/$rem);
  overflow: auto;
}
.supplierBlockPopup::-webkit-scrollbar { width: 0; }
.supplierLinePopup{
  width: calc(355rem/$rem);
  font-family: Heebo-Medium;
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.37;
  margin-right: 0;
}
.supplierSumPopup{
  display: flex;
  width: calc(355rem/$rem);
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  justify-content: space-between;
  color: #242323;
  margin-bottom: calc(29rem/$rem);
}
.supplierAllPricePopup{
  margin-left: calc(37rem/$rem);
}
.supplierAllPriceText{
  margin-right: calc(37rem/$rem);
}
.supplierAllPriceMsg{
  margin-left: calc(37rem/$rem);
  font-family: Heebo-Bold;
}
.supplierMsgPopup{
  font-size: calc(22rem/$rem);
  font-family: Heebo-Regular;
  color: #FFFFFF;
}
.supplierPriceForm{
  display: flex;
  margin-bottom: calc(39rem/$rem);
}
.supplierPriceFormTitle{
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  letter-spacing: 0;
  color: #242323;
  direction: ltr;
}
.supplierPriceFormButton{
  background: #EEE9E5 0% 0% no-repeat padding-box;
  border: none;
  height: calc(40rem/$rem);
}
.supplierServiceTitle{
  margin: calc(25rem/$rem) calc(32rem/$rem) 0 0;
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
}
.supplierServices{
  min-height: calc(60rem/$rem);
  max-height: calc(140rem/$rem);
  box-sizing: border-box;
  color: #3E3D3D;
  font-size: calc(22rem/$rem);
  font-family: Heebo-Medium;
  margin-right: calc(32rem/$rem);
  overflow: auto;
  margin-top: calc(40rem/$rem);
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.supplierServices::-webkit-scrollbar { width: 0; }
.supplierServiceDetails{
  margin-bottom: calc(12rem/$rem);
  display: flex;
}
.supplierServiceLine{
  margin: 0 calc(31rem/$rem) calc(8rem/$rem);
}
.supplierServiceAllSum{
  display: flex;
  justify-content: space-between;
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin-bottom: calc(7.5rem/$rem);
}
.supplierPayButton{
  margin: 0 calc(31rem/$rem) 0 calc(32rem/$rem);
  width: calc(100% - calc(63rem/$rem));
  height: calc(61rem/$rem);
  background: #3F5E69 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border: calc(2rem/$rem) solid #3F5E69;
  border-radius: calc(5rem/$rem);
  font-size: calc(20rem/$rem);
  font-family: Heebo-Medium;
  color: #FFFFFF;
}
.buttonContactLine{
  margin: calc(19rem/$rem) calc(17.5rem/$rem);
  display: flex;
  border: calc(1rem/$rem) solid #405F6A;
}
.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.msgTitle{
  font-size: calc(20rem/$rem);
  font-family: Heebo-Bold;
  color: #3E3D3D;
}
.inviteBlock{
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  font-size: calc(22rem/$rem);
  font-family: Heebo-Regular;
  color: #3E3D3D;
  margin-bottom: calc(18rem/$rem);
}
.inviteTitle{
  font-size: calc(22rem/$rem);
  font-family: Heebo-Medium;
  color: #3E3D3D;
  margin-bottom: calc(18rem/$rem);
}
.inviteLinkBlock{
  font-size: calc(22rem/$rem);
  display: flex;
  overflow: hidden;

  .inviteLinkTitle{
    font-family: Heebo-Regular;
    color: #3E3D3D;
    margin-bottom: calc(22rem/$rem);
  }
  .inviteLink{
    font-family: Heebo-Regular;
    color: #0F37D8;
    text-decoration: underline;
    margin-bottom: calc(20rem/$rem);
    direction: ltr;
    word-break: break-all;
    cursor: pointer;
  }
}
.buttonsLinkBlock{
  display: flex;
  flex-direction: row;
  margin-bottom: calc(20rem/$rem);
}
.buttonLinkYes{
  width: calc(83rem/$rem);
  height: calc(51rem/$rem);
  cursor: pointer;
  border: none;
  border-radius: calc(5rem/$rem);
  background-color: #405F6A;
  font-size: calc(18rem/$rem);
  font-family: Heebo-Regular;
  color: #FFFFFF;
  text-align: center;
  margin-left: calc(10rem/$rem);
}
.buttonLinkNo{
  width: calc(79rem/$rem);
  height: calc(51rem/$rem);
  cursor: pointer;
  border: calc(1rem/$rem) solid #405F6A;
  border-radius: calc(5rem/$rem);
  background-color: none;
  font-size: calc(18rem/$rem);
  font-family: Heebo-Regular;
  color: #405F6A;
  text-align: center;
  margin-left: calc(10rem/$rem);
}

.msgBlock{
  display: flex;
  flex-direction: column;
 // max-height: calc(93.5rem/$rem);
  overflow: auto;
  box-sizing: border-box;
  margin: calc(16.5rem/$rem) 0 0;
}
.msgBlock::-webkit-scrollbar { width: 0; }
.msgContent{
  display: flex;
  flex-direction: row;
  color: #3E3D3D;
  font-size: calc(22rem/$rem);
  margin: 0 0 calc(15rem/$rem) 0;
  font-family: Heebo-Medium;
}
.msgLine{
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.37;
  width: 44.486%;
  margin-right: 0;
}
.msgAllSum{
  display: flex;
  font-size: calc(25rem/$rem);
  font-family: Heebo-Regular;
  color: #242323;
}
.msgDiscountSum{
  display: flex;
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
}
.msgblockSum{
  font-size: calc(25rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
}
@media (max-width: calc(1900rem/$rem)) {
  .supplierButton{
    font-size: calc(16rem/$rem);
    margin-left: calc(8rem/$rem);
    padding: 0 calc(5rem/$rem);
  }
  .supplierButtonDisabled{
    font-size: calc(16rem/$rem);
    margin-left: calc(8rem/$rem);
    padding: 0 calc(5rem/$rem);
  }
  .dialogDescription{
    margin-right: auto;
    margin-left: auto;
    max-width: 90%;
    text-align: center;
  }
  .dialogTitle{
    max-width: 100%;
    text-align: center;
    margin: calc(59rem/$rem) 0 calc(37rem/$rem) 0;
  }
  .supplierDialogTitle{
    max-width: 100%;
    text-align: center;
    margin: calc(59rem/$rem) 0 calc(37rem/$rem) 0;
  }
  .dialogSecondTitle{
    max-width: 90%;
    text-align: center;
    margin: calc(59rem/$rem) auto calc(37rem/$rem);
  }
}
@media(max-width: calc(1750rem/$rem)){
.serviceSum, .serviceItem, .serviceCount, .serviceAllItem, .serviceAllSum{
  font-size: calc(17rem/$rem);
}
.result{
  align-items: center;
}
}
@media(max-width: calc(1500rem/$rem)){
  .buttonsBlock{
    padding-left: 15px;
  }
}
  @media (max-width: calc(1400rem/$rem)) {
    .leftBlock{
        width: calc(50% - calc(12rem/$rem));
    }
    .rightBlock{
        width: calc(50% - calc(12rem/$rem));
    }
    .supplierRightBlock{
        width: calc(50% - calc(12rem/$rem));
    }
    .serviceName{
      width: fit-content;
      margin-top: calc(10rem/$rem);
      font-size: calc(16rem/$rem);
    }
    .supplierLink{
      width: fit-content;
      margin-right: calc(30rem/$rem);
      font-size: calc(16rem/$rem);
      cursor: pointer;
    }  
    .serviceItem{
      font-size: calc(16rem/$rem);
    }
    .serviceSum{
      font-size: calc(16rem/$rem);
    }
    .serviceCount{
      font-size: calc(16rem/$rem);
    }
    .serviceAllItem{
      font-size: calc(16rem/$rem);
    }
    .serviceAllSum{
      font-size: calc(16rem/$rem);
    }
  }
  @media (max-width: calc(1300rem/$rem)) {
    // .buttonsBlock{
    //   flex-direction: column;
    // }
    .buttonContact{
      width: fit-content;
     // margin-top: calc(10rem/$rem);
    }
    .buttonVideo{
      width: fit-content;
      margin-right: calc(30rem/$rem);
    }
  }
  @media (max-width: calc(1100rem/$rem)) {
    .dialogWrapper2{
      .mainDialog{
        position: relative;
        border: none !important;
        box-shadow: none;
        max-width: toRem(637);
        width: 100%;
        padding: 0;
        width: 94.1%;
        height: toRem(361.11) !important;
        overflow-y: auto;
      }
  
      .contentSecondDialog{
        border: none;
        border-radius: toRem(9);
        height: 100%;
      }
      .secondTitle{
        font-family: Heebo-Bold;
        color: #405F6A;
        font-size: toRem(18);
        text-align: center;
        margin: toRem(40.8) 0 toRem(14);
      }
      .secondContent{
        font-family: Heebo-Regular;
        color: #242323;
        font-size: toRem(16);
        text-align: center;
        margin-bottom: toRem(29.4);
        margin-left: 10%;
        margin-right: 10%;
      }
      .buttonsContent{
        display: flex;
        justify-content: center;
        gap: 15px;

      }
      .leftBtn{
        font-family: Heebo-Regular;
        color: #FFFFFF;
        font-size: toRem(22);
        background-color: #405F6A;
        width: toRem(181);
        height: toRem(56);
        cursor: pointer;
        border: none;
        border-radius: toRem(4);
      }
      .rightBtn{
        font-family: Heebo-Regular;
        color: #405F6A;
        font-size: toRem(22);
        background-color: #FFFFFF;
        width: toRem(181);
        height: toRem(56);
        cursor: pointer;
        border: toRem(1) solid #405F6A;
        border-radius: toRem(4);
        margin-right: toRem(14);
      }
        .content{
          padding-right: 4px;
          padding-left: 4px;
          color:#3E3D3D;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          .secondTitle{
            margin-top: 0;
          }
          .checkbox {
            margin-bottom: 1rem;
            position: relative;
          
            input {
              display: none;
            }
          
            .errorMessage {
              margin: 0;
              position: absolute;
              color: red;
              top: 1.5rem;
              right: 2.7rem;
              font-family: Heebo-regular;
              font-size: 0.65rem;
            }
          
            label {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-family: Heebo-Regular;
              font-size: toRem(18);
              line-height: toRem(27);
              color:#000000;
              margin-inline-start: toRem(31);
            }
          
            label::before {
              content: "";
              display: inline-block;
              width: toRem(18);
              height: toRem(18);
              position: absolute;
              right: toRem(-31);
              top: toRem(2);
              border: .13rem solid #707070;
              border-radius: 50%;
            }
          
            input:checked + label::before {
              background-size: toRem(10);
              background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
              background-repeat: no-repeat;
              background-position: center center;
              background-origin: border-box;
            }
          }
    
          .tabProgress{
            padding-top: toRem(55);
            display: flex;
            gap: toRem(11);
    
            span{
              width: toRem(35);
              border: 3px solid;
              border-radius: toRem(3);
            }
            
          }
        }
    }
    .paymentLineEnd{
      width: calc(250rem/$rem);
    }
    .paymentTitle{
      font-size: calc(18rem/$rem);
    }
    .paymentSecondTitle{
      font-size: calc(16rem/$rem);
    }
    .dialogTitle{
      font-size: calc(16rem/$rem);
    }
    .supplierDialogTitle{
      font-size: calc(16rem/$rem);
    }
    .dialogSecondTitle{
      font-size: calc(16rem/$rem);
    }
    .dialogDescription{
      font-size: calc(16rem/$rem);
    }
    .msgTitle{
      font-size: calc(16rem/$rem);
    }
    .msgContent{
      font-size: calc(16rem/$rem);
    }
    .msgAllSum{
      font-size: calc(16rem/$rem);
    }
    .msgDiscountSum{
      font-size: calc(16rem/$rem);
    }
    .msgblockSum{
      font-size: calc(16rem/$rem);
    }
  }
  @media (max-width: calc(1100rem/$rem)) {
    .supplierButtons{
      margin-right: calc(15rem/$rem);
    }
  .supplierButton{
    font-size: calc(14rem/$rem);
    margin-left: calc(4rem/$rem);
    padding: 0 calc(3rem/$rem);
  }
  .supplierButtonDisabled{
    font-size: calc(14rem/$rem);
    margin-left: calc(4rem/$rem);
    padding: 0 calc(3rem/$rem);
  }
  }
  .paymentMobile{
    display: none;
  }
  .infoDialogClose{
    background-color: #FFFFFF;
    height: calc(200rem/$rem);
    border-radius: calc(5rem/$rem);
  }
  .infoDialogtitle{
    text-align: center;
    display: flex;
    font-family: Heebo-Bold;
    font-size: calc(16rem/$rem);
    color: #242323;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  .paymentMobileContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(6.3rem/$rem);
  }
  .paymentLineTop{
    display: none;
    border: calc(1rem/$rem) solid #242323;
    color: #242323;
    opacity: 0.19;
    margin-top: calc(-6.3rem/$rem);
    margin-bottom: calc(25.5rem/$rem);
  }
  @media (max-width: calc(1000rem/$rem)) {
  .paymentLineTop{
    display: block;
  }
  .paymentSecondTitle{
    font-family: Heebo-Bold;
    font-size: calc(14rem/$rem);
    color: #000000;
    margin-top: calc(25.5rem/$rem);
    margin-bottom: calc(13rem/$rem);
  }
    .paymentContent{
      flex-direction: column;
      align-items: center;
      margin-right: calc(41rem/$rem);
    }
    .paymentMobile{
      display: block;
    }
    .paymentTitle{
      font-size: calc(16rem/$rem);
    }
    .paymentRight{
      margin-left: 0;
      margin-bottom: calc(22rem/$rem);
      width: 100%;
      max-width: calc(341rem/$rem);
      max-height: calc(389rem/$rem);
      background-color: #F6F7F7;
      margin-left: calc(49rem/$rem);
      box-sizing: border-box;
      border-radius: calc(6rem/$rem);
      padding: calc(8.7rem/$rem) calc(14rem/$rem) 0 calc(18rem/$rem);
    }
    .paymentLeft{  
      width: 100%;
      max-width: calc(341rem/$rem);
      max-height: 100%;
      height: calc(600rem/$rem);
    }
    .paymentserviceBlock{
      font-family: Heebo-Regular;
      font-size: calc(12rem/$rem);
      color: #3E3D3D;
      margin-bottom: calc(6rem/$rem);
    }
    .paymentBlock{
      max-height: calc(74rem/$rem);
      overflow: auto;
    }
    .paymentLineEnd{
      width: calc(309rem/$rem);
    }
    .paymentPrice{
      font-size: calc(16rem/$rem);
      margin-bottom: calc(3.6rem/$rem);
      margin-top: calc(13rem/$rem);
    }
    .paymentPriceAfterDiscount{
      font-size: calc(16rem/$rem);
      margin-bottom: calc(19.1rem/$rem);
    }
    .paymentDiscountBlock{
      width: calc(220.5rem/$rem);
      height: calc(64rem/$rem);
      background-color: #D3DDDC;
      border-radius: calc(5rem/$rem);
      padding: calc(6.5rem/$rem) calc(8rem/$rem) 0 0;
      margin-bottom: calc(14.9rem/$rem);
    }
    .paymentDiscount{
      font-size: calc(18rem/$rem);
      margin-bottom: calc(10.5rem/$rem);
      max-height: calc(20rem/$rem);
    }
  }
  @media (max-width: calc(900rem/$rem)) {
    
    .tabProgress{
      padding-top: toRem(38);
      display: flex;
      gap: toRem(11);

      span{
        width: toRem(35);
        border: 3px solid;
        border-radius: toRem(3);
      }
      
    }
    // .buttonsBlock{
    //   flex-direction: column;
    // }
    .buttonContact{
      width: fit-content;
     // margin-top: calc(10rem/$rem);
    }
    .buttonVideo{
      width: fit-content;
      margin-right: calc(30rem/$rem);
    }
    .buttonContact{
      font-size: calc(14rem/$rem);
      margin-right: calc(14rem/$rem);
    }
    .buttonVideo{
      font-size: calc(14rem/$rem);
    }
    .sendMessage{
      font-size: calc(14rem/$rem);
    }
    .sendMessage2{
      font-size: calc(14rem/$rem);
    }
    .menu{
      font-size: calc(16rem/$rem);
    }
    .supplierDialogTitle{
      font-size: calc(20rem/$rem);
    }
    .supplierDatePopup{
      font-size: calc(20rem/$rem);
    }
    .supplierInfoPopup{
      font-size: calc(20rem/$rem);
    }
    .supplierservicePopup{
      font-size: calc(18rem/$rem);
    }
    .supplierSumPopup{
      font-size: calc(20rem/$rem);
    }
    .supplierPriceFormButton{
      height: calc(39rem/$rem);
      width: calc(114rem/$rem);
    }
    .supplierPriceFormTitle{
      font-size: calc(18rem/$rem);
    }
    .dialogMsgBtn{
      height: calc(48rem/$rem);
      //width: calc(79rem/$rem);
      text-align: center;
      font-size: calc(16rem/$rem) !important;
    }
  }
  @media (max-width: calc(860rem/$rem)) {
    .closeIconMobile {
      display: block;
      top: 0.78rem;
      left: 2.09rem;
      scale: 52%;
    }
    .watsApp{
      width: 10.375rem;
      height: 4.18rem;
      padding: 0.75rem 0.8125rem;
  
      .watsAppIcon{
        img{
          width: 2.68rem;
          height: 2.68rem;
        }
      }
  
      .watsAppText{
        font-size: 0.875rem;
      }
    }
    .watsApp{
      right: 1.125rem;
      bottom: 3.5rem;
    }
  }
  @media (max-width: calc(800rem/$rem)) {
    .paymentButton{
    height: calc(48rem/$rem);
    width: calc(339rem/$rem);
    margin-right: calc(18rem/$rem);
    margin-top: calc(-10rem/$rem);
    margin-bottom: calc(10rem/$rem);
    font-family: Heebo-Regular;
    font-size: calc(16rem/$rem);
  }
    .identNumber{
      // width: 50%;
      width: 100%;
    }
    .menuDesctop{
      display: none;
    }
    .textareaWrapper{
      margin-right: calc(-18rem/$rem);
    }
    .menuMobile{
      display: block;
    }
    .desctop{
      margin: 0;
    }
    .mobileTitle{
      display: block;
      text-align: right;
      font-size: calc(24rem/$rem);
      font-family: Heebo-Bold;
      color: #242323;
      margin: calc(20rem/$rem) calc(17rem/$rem) 0 0;
    }
    .adressInputs{    
      margin-right: 0 !important;
      margin-left: 0 !important;
      // width: 50%;
      width: 100%;
      margin-bottom: calc(16rem/$rem);
    }
    .errorMessageBlocks{
      margin: calc(8rem/$rem);
    }
    .eventTypeBlock{
      margin-top: calc(13rem/$rem);
      align-items: flex-start;
      margin-right: calc(17rem/$rem);
    }
    .menu{
      margin-top: calc(19rem/$rem);
      height: calc(72rem/$rem);
    }
    .eventLabel{
      display: none;
    }
    .eventMobileLabel{
      display: block;
      color: #3E3D3D;
      font-size: calc(18rem/$rem);
      font-family: Heebo-Bold;
      margin-bottom: calc(16rem/$rem);
    }
    .select{
      width: calc(340rem/$rem);
    }
    .chatBlocks{
      display: none;
    }
    .mobileMenu{
      display: flex;
      flex-direction: column;
      margin-right: calc(18rem/$rem);
      color: #242323;
      font-size: calc(18rem/$rem);
      font-family: Heebo-Bold;
    }
    .arrow{
      width: 1.5rem;
      height: 1rem;
      cursor: pointer;
    }
    .mobileSelect{
      display: flex;    
      align-items: center;
      margin-top: calc(17rem/$rem);
    }
    .selectName{
      margin-left: calc(5.6rem/$rem);
      cursor: pointer;
    }
    .selectServiceame{
      width: 100%;
    }
    .buttonsBlock{
      margin-top: calc(38rem/$rem);
    }
    .buttonContact{
      margin-right: calc(25rem/$rem);
      font-size: calc(16rem/$rem);
      padding: 0 calc(24rem/$rem);
    }
    .buttonVideo{
      margin-right: calc(18rem/$rem);
      font-size: calc(16rem/$rem);
      padding: 0 calc(24rem/$rem);
    }
    .mobileLineChat{
      border: calc(1rem/$rem) solid #405F6A;
      margin: calc(19rem/$rem) calc(17.5rem/$rem) 0;
    }
    .sendMessage{
      height: calc(45rem/$rem);
    }
    .sendMessage2{
      height: calc(32rem/$rem);
    }
    .sendIcon{
      height: calc(18.5rem/$rem);
      width: calc(21.5rem/$rem);
      top: calc(14rem/$rem);
    }
    .sendIcon2{
      height: calc(18.5rem/$rem);
      width: calc(21.5rem/$rem);
      top: calc(14rem/$rem);
    }
    .request_invite{
      height: calc(18.5rem/$rem);
      width: calc(21.5rem/$rem);
      top: calc(12rem/$rem);
    }
    .file_upload{
      height: calc(18.5rem/$rem);
      width: calc(21.5rem/$rem);
      top: calc(12rem/$rem);
    }
    .sendMessage{
      margin-bottom: calc(56rem/$rem);
      border: calc(1rem/$rem) solid #405F6A;
      font-size: calc(18rem/$rem);
    }
    .sendMessage2{
     // margin-bottom: calc(56rem/$rem);
      border: calc(1rem/$rem) solid #405F6A;
      font-size: calc(18rem/$rem);
    }
    .buttonSend2{
      height: calc(45rem/$rem);
    }
    .buttonSend3{
      height: calc(45rem/$rem);
    }
    .sendIcon2{
      left: 20px;
    }
    .chatBlock{
      height: calc(400rem/$rem);
      padding-bottom: 65px;
    }
    .message{
      border-radius: calc(5rem/$rem) 0 calc(5rem/$rem) calc(5rem/$rem);
    }
    .messageAcept{     
      border-radius: calc(5rem/$rem) 0 calc(5rem/$rem) calc(5rem/$rem);
    }
    .messageUser{
      border-radius: 0 calc(5rem/$rem) calc(5rem/$rem) calc(5rem/$rem);
    }
    .mobile{
      display: block;
    }
    .addressBlock{
      flex-direction: column;
      align-items: center;
    }
    .city{
      // width: 51%;
      width: 100%;
      margin-bottom: calc(10rem/$rem);
      margin-left: 0;
    }
    .street{
      // width: 51%;
      width: 100%;
      margin-right: 0;
    }
  .services{
    margin-top: calc(27rem/$rem);
    max-height: calc(230rem/$rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - calc(89rem/$rem) );
    overflow: auto;
  }
  .service, .serviceChoosed{
    height: calc(36rem/$rem);
    width:  calc(100% - calc(18rem/$rem));
    font-family: Heebo-Regular;
    margin-bottom: calc(10rem/$rem);
  }
  .serviceItem{
    font-size: calc(17rem/$rem);
    margin-right: calc(16rem/$rem);
  }
  .shekelSymbol{
    width: calc(9rem/$rem);
    height: calc(9rem/$rem);
  }
  .serviceCount{
    font-size: calc(15rem/$rem);
    margin-left: calc(15rem/$rem);
  }
  .line{
    border: calc(1rem/$rem) solid #707070;
    opacity: 0.37;
    width:  calc(100% - calc(12rem/$rem));
    margin: calc(9rem/$rem) calc(8rem/$rem) calc(8rem/$rem) calc(4rem/$rem);
  }
  .result{
    margin: 0 calc(25rem/$rem) 0 0;
    width:  calc(100% - calc(25rem/$rem));
  }
  .serviceAllSum{
    width: 50%;
  }
  .supplierButtons{
    margin: calc(23rem/$rem) 0 calc(12rem/$rem);
   // text-align: center;
  }
  .supplierButton{
    font-family: Heebo-Regular;
    font-size: calc(14rem/$rem);
    height: calc(41rem/$rem);
    margin-left: calc(12rem/$rem);
    padding: 0 calc(12rem/$rem);
    box-sizing: border-box;
  }
  .supplierButtonDisabled{
    font-family: Heebo-Regular;
    font-size: calc(14rem/$rem);
    height: calc(41rem/$rem);
    margin-left: calc(12rem/$rem);
    padding: 0 calc(12rem/$rem);
    box-sizing: border-box;
  }
  .suppliersEventType{
    font-family: Heebo-Bold;
    font-size: calc(18rem/$rem);
    color: #242323;
    margin: calc(21rem/$rem) 0 calc(8rem/$rem) 0;
  }
  .slideWrapper {
    cursor: pointer;
    background: #EEE9E5 0% 0% no-repeat padding-box;
    box-shadow: 0 calc(2rem/$rem) calc(5rem/$rem) #00000029;
    border-radius: calc(5rem/$rem);
    display: flex;
    align-items: center;
    width: calc(121rem/$rem);
    height: calc(43rem/$rem);
  }
  .slideWrapperActive {
    cursor: pointer;
    background: #E8CFBF 0% 0% no-repeat padding-box;
    box-shadow: 0 calc(2rem/$rem) calc(5rem/$rem) #00000029;
    border-radius: calc(5rem/$rem);
    display: flex;
    align-items: center;
    width: calc(121rem/$rem);
    height: calc(43rem/$rem);
  }
  .supplierSwiper{
    width: calc(257rem/$rem);
    margin-right: 0;
  }
  .swiperText{
    text-align: center;
    width: 95%;
    overflow: hidden;
    height: calc(24rem/$rem);
    font-family: Heebo-Regular;
    font-size: calc(16rem/$rem);
  }
}

// @media (max-width: calc(800rem/$rem)) and (min-width: calc(700rem/$rem)) {
//   .buttonSend{
//     height: 0;
//   }
// }
@media (max-width: toRem(700)) {
  .dialogWrapper2{
    .leftBtn{
      font-family: Heebo-Regular;
      color: #FFFFFF;
      font-size: toRem(14);
      background-color: #405F6A;
      width: toRem(150);
      height: toRem(54);
      cursor: pointer;
      border: none;
      border-radius: toRem(4);
      margin-bottom: toRem(10.6);
    }
    .rightBtn{
      font-family: Heebo-Regular;
      color: #405F6A;
      font-size: toRem(14);
      background-color: #FFFFFF;
      width: toRem(150);
      height: toRem(54);
      cursor: pointer;
      border: toRem(1) solid #405F6A;
      border-radius: toRem(4);
      margin-right: 0 !important;
    }
    .secondContent{
      font-size: toRem(12);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
@media (max-width: calc(600rem/$rem)) {
  .secondStep{
    padding: 0 15px;
  }
  .textareaWrapper{
    margin-top: calc(25rem/$rem);
  }
  .textarea{
    width: calc(340rem/$rem);
    height: calc(90rem/$rem);
  }
  .city, .identNumber, .identLink, .address{
    // width: 75%;
    width: 100%;
  }
  .street{
    width: 75%;
  }
  .menu{
    margin-bottom: 0;
  }
  .adressInputs{    
    // width: 75%;
    width: 100%;
  }
  .supplierContent{
    margin-right: calc(23rem/$rem);
  }
  .supplierDialogTitle{
    margin-top: calc(43.89rem/$rem);
    text-align: right;
    margin-right: calc(23rem/$rem);
  }
  .supplierDatePopup{
    margin-top: calc(13.94rem/$rem);
  }
  .supplierInfoPopup{
    margin-top: calc(30rem/$rem);
  }
  .supplierservicePopup{
    margin-bottom: calc(13.94rem/$rem);
  }
  .supplierBlockPopup{
    height: calc(82rem/$rem);
  }
  .supplierLinePopup{
    width: calc(270rem/$rem);
  }
  .supplierSumPopup{
    width: calc(280.8rem/$rem);
    margin-bottom: calc(36.4rem/$rem);
  }
  .supplierPriceForm{
    margin-bottom: calc(45rem/$rem);
    display: flex;
    align-items: center;
  }
  .supplierPriceFormButton{
    width: calc(110rem/$rem);
  }
  .dialogBtnBlock{
  margin-bottom: calc(37.6rem/$rem);
  }
}
@media (max-width: calc(420rem/$rem)) {
  .identNumber, .city, .street{
    font-size: calc(12rem/$rem);
  }
.dialogTitle{
  font-size: calc(20rem/$rem);
  min-width: calc(140rem/$rem);
  text-align: center;
  margin: calc(81rem/$rem) calc(32rem/$rem) calc(33rem/$rem) calc(32rem/$rem);
}
.supplierDialogTitle{
  font-size: calc(20rem/$rem);
  min-width: calc(140rem/$rem);
  text-align: center;
  margin: calc(81rem/$rem) calc(32rem/$rem) calc(33rem/$rem) calc(32rem/$rem);
}
.dialogSecondTitle{
  font-size: calc(20rem/$rem);
  min-width: calc(140rem/$rem);
  text-align: center;
  margin: calc(81rem/$rem) auto calc(33rem/$rem);
}
.dialogDescription{
  font-size: calc(16rem/$rem);
  min-width: calc(248rem/$rem);
  margin: calc(0rem/$rem) calc(32rem/$rem) calc(81rem/$rem) calc(31rem/$rem);
} 
}
@media (max-width: calc(400rem/$rem)) {
  .eventTypeBlock{
    margin-right: calc(17rem/$rem);
    margin-left: calc(18rem/$rem);
  }
  .select{
    width: 100%;
  }
}
@media (max-width: calc(380rem/$rem)) {
  .dialogTitle{
    margin: calc(81rem/$rem) calc(79rem/$rem) calc(33rem/$rem) calc(80rem/$rem);
  }
  .supplierDialogTitle{
    margin: calc(81rem/$rem) calc(79rem/$rem) calc(33rem/$rem) calc(80rem/$rem);
  }
  .dialogSecondTitle{
    margin: calc(81rem/$rem) auto calc(33rem/$rem);
  }
}
@media (max-width: calc(380rem/$rem)) {
.secondPopup{
  right: calc( (calc(100% - 98%) / 2) );
  width: 98%;
}
}
@media (max-width: calc(373rem/$rem)) {
  .paymentLineEnd{
    width: 100%;
  }
}
@media (max-width: calc(350rem/$rem)) {
  .dialogTitle{
    text-align: center;
    margin: calc(81rem/$rem) calc(20rem/$rem) calc(33rem/$rem) calc(20rem/$rem);
  }
  .supplierDialogTitle{
    text-align: center;
    margin: calc(81rem/$rem) calc(20rem/$rem) calc(33rem/$rem) calc(20rem/$rem);
  }
  .dialogSecondTitle{
    text-align: center;
    margin: calc(81rem/$rem) auto calc(33rem/$rem);
  }
}