@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.cardWrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .heart {
    cursor: pointer;
    position: absolute;
    top: 1.275rem;
    right: 1rem;
    z-index: 4;
    width: 2.125rem;
    height: 2.125rem;
  }

  .category {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 8.104rem;
    height: 3.5531rem;
    background-color: #eee9e5;
    font-size: 0.9375rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flashLabel {
    width: 6.125rem;
    height: 6.125rem;
    position: absolute;
    bottom: 3.625rem;
    left: 1.5rem;
    z-index: 3;
    font-size: 1.625rem;
    line-height: 1.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Heebo-Medium";
    background-color: #e7b1ad;
    color: white;

    box-shadow: 0 toRem(3) toRem(6) rgba(#000000, 0.16);
  }

  .image {
    cursor: pointer;
    width: 100%;
    height: 18.125rem;
    .slideImage {
      object-fit: cover;
      user-select: none;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    width: 100%;
    height: 2.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      cursor: pointer;
      font-size: 1.375rem;
      font-family: Heebo-Bold;
    }

    .rating {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      margin-left: 0.625rem;
    }

    .count {
      min-width: 5.8125rem;
      font-size: 1.125em;
      color: #707070;
    }
  }

  .descriptionForVenue {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 1rem;
    line-height: 1rem;
  }
}

@media (max-width: 60rem) {
  .cardWrapper {
    .flashLabel {
      width: toRem(44);
      height: toRem(44);
      bottom: toRem(28);
      left: toRem(16);
      font-size: toRem(11);
      line-height: toRem(10);
    }

    .category {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: toRem(91);
      height: toRem(35);
      background-color: #eee9e5;
      font-size: toRem(15);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .heart {
      top: toRem(13);
      right: toRem(11);
      z-index: 4;
      width: toRem(24);
      height: toRem(22);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .image {
      height: toRem(183);
    }

    .description {
      width: 100%;
      padding-top: toRem(8);
      height: toRem(22);

      .name {
        cursor: pointer;
        font-size: toRem(15);
        line-height: toRem(22);
        font-family: Heebo-Bold;
      }

      .rating {
        display: flex;
        align-items: center;
        font-size: 0.55rem;
        margin-left: 0;
      }

      .count {
        display: none;
        // min-width: 3.625rem;
        // font-size: 0.6875em;
        // color: #707070;
      }
    }

    .descriptionForVenue {
      line-height: 1.125rem;
      font-size: 1.125rem;
    }
  }
}
