@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.nextButton {
  box-sizing: border-box;
  padding: .75rem 1.5rem;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  color: #FFFFFF;
  background-color: #405F6A;
  border: none;
  box-shadow: 0 1.25rem 2.5rem #221B4A24;
  border-radius: 0.31rem;
  cursor: pointer;
  margin-top: 5.06rem;
}

.getButton {
  box-sizing: border-box;
  padding: 1.4375rem 1.875rem;
  font-family: Heebo-Regular;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #FFFFFF;
  background-color: #E8CFBF;
  border: none;
  // box-shadow: 0 1.25rem 2.5rem #221B4A24;
  border-radius: 0.3125rem;
  cursor: pointer;
  margin-bottom: 3.6875rem;
}

.container{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10.8375rem;

  .getBtnContainer{
    display: flex;
  }

  .descr{
    // display: flex;
    padding-top: toRem(27);
    flex-direction: column;
    align-items: center;
    .title{
      font-family: Heebo-Bold;
      color:#405F6A;
      font-size: 1.5rem;
      line-height: 2.25rem;
    }

    .row{
      font-size: 1.5rem;
      line-height: 2.25rem;
      color:#405F6A;
    }
  }

  .nextBtnContainer{
    display: flex;
  }


}

@media (min-width: toRem(961)) {
  .mobile{
    display: none;
  }
  .desctop{
    display: flex;
  }
}

@media (max-width: toRem(960)) {
  .desctop{
    display: none;
  }
  .mobile{
    display: flex;
  }

  .container{
    padding-bottom: toRem(15);
    .getButton {
      box-sizing: border-box;
      padding: toRem(12) toRem(24);
      font-family: Heebo-Regular;
      font-size: toRem(16);
      line-height: toRem(24);
      color: #FFFFFF;
      background-color: #E8CFBF;
      border: none;
      // box-shadow: 0 1.25rem 2.5rem #221B4A24;
      border-radius: 0.3125rem;
      cursor: pointer;
      margin-bottom: 0;
    }

    .descr{
      flex-direction: column;
      align-items: center;
      .title{
        font-family: Heebo-Bold;
        color:#405F6A;
        font-size: toRem(14);
        line-height: toRem(21);
      }
  
      .row{
        font-size: toRem(14);
        line-height: toRem(21);
        color:#405F6A;
      }
    }

  }
  

}