@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.button{
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.375rem;
  border-radius: 0.3125rem;
  background-color: #405F6A;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
}

.container{
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  
  background-color: #405F6A;
  .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65.4375rem;
    height: 23.8125rem;
    padding: 3.6875rem 0 4.1875rem 0;
    box-sizing: border-box;
    background-color: #FFFFFF;

    .title{
      display: flex;
      justify-content: center;
      font-family: Heebo-Bold;
      font-size: 1.75rem;
      padding-bottom: 2.3125rem;
    }

    .description{
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
      line-height: 2.1875rem;
    }

    .buttonContainer{
      display: flex;
      justify-content: center;
      padding-top: 3.25rem;

    }

    
  }
}

@media (min-width: toRem(961)) {
  .mobile{
    display: none;
  }
}

@media (max-width: toRem(960)) {
  .desctop{
    display: none;
  }
  .mobile{
    padding: 0 toRem(15);
    text-align: center;
    display: flex;
  }
  .button{
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: toRem(16);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: toRem(48);
    border-radius: 0.3125rem;
    background-color: #405F6A;
    padding: toRem(12) toRem(24);
    box-sizing: border-box;
  }

  .container{
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    
    background-color: #405F6A;
    .form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: toRem(299);
      height: toRem(304);
      padding: 3.6875rem 0 4.1875rem 0;
      box-sizing: border-box;
      background-color: #FFFFFF;
  
      .title{
        display: flex;
        justify-content: center;
        font-family: Heebo-Bold;
        font-size: toRem(20);
        padding-bottom: toRem(33);
      }
  
      .description{
        display: flex;
        justify-content: center;
        font-size: toRem(16);
        line-height: toRem(24);
      }
  
      .buttonContainer{
        display: flex;
        justify-content: center;
        padding-top: toRem(33);
  
      }
  
      
    }
  }

}

