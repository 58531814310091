@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.rootWrapper {
  min-height: 100vh;
  width: 100%;
  background-color: rgba(64, 95, 106, 0.9);
  padding-left: 11.69rem;
  padding-right: 11.69rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.userLoginContainer {
  // background-color: white;
  display: flex;
  justify-content: space-between;
  max-height: 48.13rem;
  max-width: 96.26rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // background-color: rgba(64, 95, 106, 0.9);
}

.userLoginBackgroundImage {
  height: 100%;
  width: 50%;
}

.userLoginBackgroundImageContent {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.adaptiveWrapperFirstBlock,
.adaptiveWrapperSecondBlock {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.userLoginFormContainer {
  background-color: rgb(255, 255, 255);
  // padding: 6.44rem 4.5rem 1.63rem 4.88rem;
  position: relative;
  height: 100%;
  width: 50%;
}

.userLoginFormContainerContent {
  position: absolute;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 6.44rem 4.5rem 1.63rem 4.88rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.closeIcon {
  position: absolute;
  cursor: pointer;
}

.closeIconMobile {
  display: none;
}

.closeIconDesktop {
  top: 2.02rem;
  left: 1.72rem;
}

.loginHeader {
  cursor: pointer;
  margin: 0;
  font-family: Heebo-Bold;
  font-size: 2rem;
  // line-height: 2.94rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.0001);
  transition: 0.5s;
}
.loginHeader_active {
  border-color: #f5b9ab;
}

.regButtonsWithOtherResContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: toRem(45);
}

.regButtonsWithOtherResContainer > button {
  position: relative;
  background-color: white;
  height: 3.13rem;
  // width: 48%;
  width: toRem(298);
  border: 0.06rem solid #434242;
  border-radius: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 1.13rem;
  line-height: 1.69rem;
  cursor: pointer;
}

.regButtonsWithOtherResContainer > button > img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0.94rem;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 79%;
}

.loginFormBlock {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  position: relative;
}

.errorMessage {
  margin: 0;
  position: absolute;
  color: red;
  bottom: 0.007rem;
  right: 0.31rem;
  font-family: Heebo-regular;
  font-size: 0.65rem;
}

.inputContainer > input {
  background-color: #f7f4f4;
  border: none;
  border-radius: 0.31rem;
  height: 4.56rem;
  margin-bottom: 0.94rem;
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #242323;
  padding-inline-start: 1.69rem;
  padding-inline-end: 2.5rem;
  width: 100%;
  box-sizing: border-box;
}

.inputContainer > input:focus {
  outline: 0.13rem solid #405f6a;
}

.hideShowIcon {
  position: absolute;
  top: 1.2rem;
  left: 0.6rem;
  opacity: 0.13rem;
  cursor: pointer;
}

input.invalidField {
  background-color: #f3e8e8;
  outline: 0.06rem solid red;
}

.forgotPasswordLink {
  font-family: Heebo-Regular;
  font-size: 1.13rem;
  line-height: 1.69rem;
  text-decoration: underline;
  color: #7b7777;
}

.submitButton {
  background-color: #405f6a;
  margin-top: 0.44rem;
  width: 6.88rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  color: white;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
  box-shadow: 0 1.25rem 2.5rem #221b4a24;
}

.submitButton2 {
  background-color: #405f6a;
  margin-top: 0.44rem;
  width: 8.88rem;
  margin-left: auto;
  margin-right: auto;
  height: 3.5rem;
  color: white;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
  box-shadow: 0 1.25rem 2.5rem #221b4a24;
}

.goTeRegContainer {
  display: flex;
  justify-content: center;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  height: 9%;
  display: flex;
  align-items: flex-end;
}

input::placeholder {
  color: #9b9999;
}
.goTeRegContainer > p {
  margin: 0;
  margin-inline-end: 0.63rem;
  color: #7b7777;
}

.goTeRegContainer > a {
  color: #f5b9ab;
}

@media (max-width: 1536px) {
  .loginHeader {
    font-size: 1.5rem;
  }
}

@media (max-width: 100rem) {
  .inputContainer > input {
    height: 3rem;
    margin-bottom: 1.1rem;
  }

  .userLoginFormContainerContent {
    padding: 3rem 3.5rem 1.63rem 3.88rem;
  }

  .regButtonsWithOtherResContainer {
    padding-bottom: toRem(20);
  }

  .submitButton {
    height: 3rem;
  }

  .submitButton2 {
    height: 3rem;
  }
  .hideShowIcon {
    top: 0.5rem;
  }
}
//
//@media (max-width: 83.125rem) {
//  .regButtonsWithOtherResContainer {
//    padding-bottom: toRem(20);
//    /* display: none; */
//  }
//
//}
//
@media (max-width: 80rem) {
  .rootWrapper {
    padding-left: 7.69rem;
    padding-right: 7.69rem;
  }

  .userLoginFormContainerContent {
    padding: 2.34rem 2.5rem 1.63rem 2.88rem;
  }

  .submitButton {
    height: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .submitButton2 {
    height: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .closeIconDesktop {
    scale: 65%;
  }

  .loginHeader {
    font-size: 1.25rem;
    line-height: 2.1rem;
  }

  .inputContainer > input {
    height: 2.5rem;
    margin-bottom: 1.1rem;
  }

  .forgotPasswordLink {
    font-size: 1rem;
    line-height: 1.45rem;
  }

  .goTeRegContainer {
    font-size: 1.05rem;
    line-height: 1.21rem;
  }

  .hideShowIcon {
    top: 0.3rem;
  }
}

@media (max-width: 60rem) {
  .rootWrapper {
    padding-top: 2.31rem;
    padding-bottom: 2.31rem;
    padding-left: 7rem;
    padding-right: 7rem;
    align-items: normal;
  }

  .userLoginContainer {
    flex-direction: column;
    justify-content: flex-start;
    max-height: none;
  }

  .userLoginBackgroundImage {
    height: auto;
    width: 100%;
  }

  .userLoginFormContainer {
    height: auto;
    width: 100%;
  }

  .adaptiveWrapperFirstBlock {
    padding-bottom: 76%;
  }

  .adaptiveWrapperSecondBlock {
    padding-bottom: 0;
  }

  .userLoginFormContainerContent {
    position: relative;
    padding-top: 2.31rem;
  }

  .loginHeader {
    text-align: center;
    // margin-bottom: 2.18rem;
  }

  .inputContainer > input {
    margin-bottom: 1.25rem;
    height: 3.06rem;
  }

  .loginFormBlock {
    margin-bottom: 2rem;
  }

  .goTeRegContainer {
    flex-direction: column;
    align-items: center;
  }

  .goTeRegContainer > p {
    margin: 0;
  }

  .closeIconMobile {
    display: block;
    top: 0.78rem;
    left: 2.09rem;
    scale: 52%;
  }

  .closeIconDesktop {
    display: none;
  }

  .errorMessage {
    bottom: 0.3rem;
  }

  .hideShowIcon {
    top: 0.5rem;
  }
}

@media (max-width: 40rem) {
  .rootWrapper {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 5.23rem;
  }

  .userLoginContainer {
    background-color: white;
    border-radius: 0.31rem;
  }

  .userLoginBackgroundImageContent {
    border-radius: 0.31rem;
  }

  .userLoginFormContainerContent {
    padding: 2.4rem 1.31rem 2.4rem 1.31rem;
    padding-top: 2.4rem;
    border-radius: 0.31rem;
  }

  .loginHeader {
    font-size: 1.05rem;
    line-height: 1.56rem;
    // color: #f5b9ab;
  }

  .forgotPasswordLink {
    font-size: 0.88rem;
    line-height: 1.31rem;
  }

  .submitButton {
    font-size: 1rem;
    height: 3rem;
  }

  .submitButton2 {
    font-size: 1rem;
    height: 3rem;
  }

  .goTeRegContainer {
    font-size: 0.88rem;
    line-height: 1.31rem;
  }

  .goTeRegContainer > a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #f5b9ab;
  }

  .regButtonsWithOtherResContainer > div {
    width: 100%;
  }

  .closeIconMobile {
    top: -2.5rem;
    right: -0.31rem;
  }
}
