@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

$rem: 16;

.first{
    margin: calc(97.5rem/$rem) auto 0;
    max-width: calc(955rem/$rem);
    min-height: calc(327rem/$rem);
    background: #EEE9E5 0% 0% no-repeat padding-box;
}
.firstForm{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: calc(54rem/$rem) 0 calc(30rem/$rem);
}
.title{
  font-family: Heebo-Bold;
  font-size: calc(24rem/$rem);
  color: #3E3D3D;
}
.divWithCalendar{
  position: relative;
  width: 100%;
  .calendar{
    cursor: pointer;
    position: absolute;
    left: calc(80rem/$rem);
    top: calc(20rem/$rem);
    width: calc(208rem/$rem);
    height: calc(37rem/$rem);
  }
  .mobileCalendar{
    display: none;
    cursor: pointer;
    position: absolute;
    left: calc(80rem/$rem);
    top: calc(20rem/$rem);
    width: calc(208rem/$rem);
    height: calc(37rem/$rem);
  }
  .smallMobileCalendar{
    display: none;
    cursor: pointer;
    position: absolute;
    left: calc(80rem/$rem);
    top: calc(20rem/$rem);
    width: calc(208rem/$rem);
    height: calc(37rem/$rem);
  }
}

.checked{
    display: flex;
    margin-top: calc(10rem/$rem);
    align-items: center;
}
.select{
    margin-top: calc(10rem/$rem);
    border-radius: calc(11rem/$rem);
}

.containerCheck{
    display: block;
    position: relative;
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
    
    font-size: 1.06rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    input{
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  
    input:checked ~ .checkmark:after {
      display: block;
    }
  
    .checkmark:after{
      right: 50%;
      top: 45%;
      width: 0.25rem;
      height: 0.45rem;
      border: solid rgb(0, 0, 0);
      border-width: 0 0.125rem 0.125rem 0;
      -webkit-transform: translate(50%, -50%) rotate(45deg);
      -ms-transform: translate(50%, -50%) rotate(45deg);
      transform: translate(50%, -50%) rotate(45deg);
    }
  
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: calc(22rem/$rem);
      width: calc(22rem/$rem);
      border: 0.125rem solid black;
      // background-color: #eee;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
  .secondBlock{
    display: flex;
    flex-direction: column;
    gap: calc(40rem/$rem);
  }
  .containerCheck:hover input ~ .checkmark {
    background-color: #EEE9E5;
  }
  .marginL{
    font-family: Heebo-Light;
    margin-left: calc(25rem/$rem);
    margin-right: calc(9rem/$rem);
    font-size: calc(24rem/$rem);
  }
  .margin{
    font-family: Heebo-Light;
    margin-right: calc(9rem/$rem);
    font-size: calc(24rem/$rem);
  }
  .button{
    font-family: Heebo-Regular;
    font-size: calc(22rem/$rem);
    color: #FFFFFF;
    display: flex;
    background: #405F6A 0% 0% no-repeat padding-box;
    box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A24;
    width: calc(165rem/$rem);
    height: calc(56rem/$rem);
    border-radius: calc(11rem/$rem);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: calc(5rem/$rem) 0 calc(40rem/$rem);
  }
  .paddingButton{
    display: flex;
    justify-content: center;
    margin-top: calc(100rem/$rem);
  }
  .place{
    display: flex;
    justify-content: center;
  }
  .dropdownsBlock{
    display: flex;
    flex-direction: column;
    gap: calc(15rem/$rem);
  }
  .dropdownsSmallBlock{
    display: flex;
    flex-direction: column;
    gap: calc(15rem/$rem);
  }
  .dialog{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px calc(10rem/$rem) calc(20rem/$rem) #00000029;
  }
  .infoDialog{
    background-color: rgba(64, 95, 106, 0.9);
  }

  .closeIcon {
    position: absolute;
    cursor: pointer;
  }
  .closeIconMobile {
    display: none;
  }
  
  .dialogTitle{
    direction: ltr;
    text-align: left;
    font-size: calc(28rem/$rem);
    color: #242323;
    font-family: Heebo-Bold;
    margin: calc(59rem/$rem) auto calc(43rem/$rem);
  }
  .dialogDescription{
    direction: rtl;
    color: #242323;
    text-align: center;
    font-size: calc(24rem/$rem);
    width: calc(77.5%);
    font-family: Heebo-Regular;
    margin: calc(0rem/$rem) auto calc(42rem/$rem);
  }
  .closeIconDesktop {
    top: calc(25rem/$rem);
    left: calc(23.45rem/$rem);
  }
  .dialogButton{
    border: none;
    font-size: calc(18rem/$rem);
    font-family: Heebo-Regular;
    color: #FFFFFF;
    background: #405F6A 0% 0% no-repeat padding-box;
    box-shadow: 0px calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
    border-radius: calc(5rem/$rem);
    width: calc(175rem/$rem);
    height: calc(51rem/$rem);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialogBtnBlock{
    display: flex;
    justify-content: center;
    margin-bottom: calc(42rem/$rem);
  }
.datalist{
    box-sizing: border-box;
    width: 18.875rem;
    min-height: 3.8125rem;
    border: none;
    outline: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 0.75rem;
    padding-inline-end: 0.5rem;
    font-size: 1rem;
    margin-top: calc(10rem/$rem);
}
.datalist::placeholder { 
    font-family: Heebo-Light;
    color: #242323;
    font-size: calc(20rem/$rem);
  }
.input, .datalist{
  border-radius: 11px;

  
:-ms-input-placeholder { 
  color: #242323;
  font-family: Heebo-Light;
  font-size: calc(20rem/$rem);
}
::-ms-input-placeholder { 
  color: #242323;
  font-family: Heebo-Light;
  font-size: calc(20rem/$rem);
}
}
input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.arrow{
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: calc(18rem/$rem);
    left: calc(8rem/$rem);
}
.datalistBlock{
    position: relative;
}
.list{
   min-width: 200px; 
}
.opt{
    width: 100px;
}
.errorMessageSuppliers {
  margin: 0;
  color: red;
  bottom: -1rem;
  right: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
  @media (max-width: calc(1900rem/$rem)) {
    .dialogDescription{
      margin-right: auto;
      margin-left: auto;
      max-width: 90%;
      text-align: center;
    }
    .dialogTitle{
      max-width: 100%;
      text-align: center;
      margin: calc(59rem/$rem) 0 calc(37rem/$rem) 0;
    }
  }
  @media (max-width: calc(1100rem/$rem)) {
    .closeIconDesktop {
      scale: 65%;
    }
  }
  @media (max-width: 53.75rem) {
    .closeIconMobile {
      display: block;
      top: 0.78rem;
      left: 2.09rem;
      scale: 52%;
    }
  }
  @media (max-width: calc(620rem/$rem)) {
    .mobileCalendar{
      display: block !important;
    }
    .calendar{
      display: none;
    }
    .dropdownsBlock{
      margin-top: 0;
      display: flex;
      flex-direction: column;
      gap: calc(15rem/$rem);
    }
    .firstForm{
      flex-direction: column;
      align-items: center;
      padding-right: 0;
    }
    .secondBlock{
      margin-top: calc(10rem/$rem);
    }
    .radioButtonBlock{
      display: flex;
      flex-direction: column;
    }
    .select{
      width: calc(302rem/$rem);
    }
    .title{
      font-size: calc(18rem/$rem);
    }
    .button{
      font-size: calc(18rem/$rem);
    }
    .dialogTitle{
      font-size: calc(18rem/$rem);
    }
    .dialogDescription, .dialogButton{
      font-size: calc(16rem/$rem);
    }
    .marginL{
      font-size: calc(16rem/$rem);
    }
    .margin{
      font-size: calc(16rem/$rem);
    }
    .secondBlock{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(calc(300rem/$rem));
    }
  }
  @media (max-width: calc(390rem/$rem)) {
    .smallMobileCalendar{
      display: block !important;
    }
    .mobileCalendar{
      display: none !important;
    }
    .dropdownsBlock{
      align-items: flex-start;
    }
    .dropdownsBlock{
      align-items: flex-start;
    }
  }