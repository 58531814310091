@function toRem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
  }
  
  $rem: 16;
.desctopVersion, .mobileVersion {
  h1{
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
h2{
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h3{
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h4{
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h5{
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
h6{
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
}
    .desctopVersion{
      display: block;
    }
    .mobileVersion{
      display: none;    
    }
    .headerWrapperMobile{
      height: calc(60rem/$rem);
      display: flex;
      padding: calc(10rem/$rem) auto calc(10rem/$rem);
      //  opacity: 0.8;
      flex-direction: column;
    }
    .mobileTitle{
      text-align: center;
      font-size: calc(22rem/$rem);
      font-family: Heebo-Medium;
      color: #242323;
      max-width: calc(648rem/$rem);
      margin: calc(10rem/$rem) 0 calc(10rem/$rem);
      height: calc(64rem/$rem);
      overflow: hidden;
    }
    .mobileTextBlock{
      margin: calc(26rem/$rem) calc(18rem/$rem) calc(47rem/$rem) calc(18rem/$rem);
      text-align: right;
      font-family: Heebo-Regular;
      color: #242323;
      max-height: calc(648rem/$rem);
      box-sizing: border-box;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-scrollbar { width: 0; }
      -ms-overflow-style: none;
      word-break: break-all;
      a{
        text-decoration: underline;
      }
    }
    .mobileTextBlock::-webkit-scrollbar { width: 0; }
    .title{
    text-align: center;
    font-size: calc(32rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    max-width: calc(400rem/$rem);
    padding: calc(10rem/$rem) auto calc(10rem/$rem);
    margin: 0 auto;
    height: calc(47rem/$rem);
    overflow: hidden;
    }
    .textWrapper{
      width: calc(100% - calc(188rem/$rem) );
      display: flex;
      justify-content: center;
      padding: calc(57rem/$rem) calc(94rem/$rem) calc(53rem/$rem);
    }
    .textBlock{
      color: #242323;
      text-align: right;
      font-size: calc(24rem/$rem);
      font-family: Heebo-Regular;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      box-sizing: border-box;
      -webkit-scrollbar { width: 0; }
      -ms-overflow-style: none;
      word-break: break-word;
        a{
          text-decoration: underline;
        }
    }
    .leftBlock::-webkit-scrollbar { width: 0; }
    @media (max-width: calc(1800rem/$rem)) {
      .title{
        padding: calc(10rem/$rem) 10% calc(10rem/$rem);
        max-width: 90%;
      }
    }
    @media (max-width: calc(1200rem/$rem)) {
        .textBlock{
        width: 90%;
        text-align: center;
        margin-top: calc(15rem/$rem);
        resize: both;
        }
    }
    @media (max-width: calc(900rem/$rem)) {
      .title{
        font-size: calc(24rem/$rem);
        height: calc(39rem/$rem);
      }
    }
    @media (max-width: calc(700rem/$rem)) {
      .desctopVersion{
        display: none;
      }
      .mobileVersion{
        display: block !important;
      }
    }