@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.dialogWrapper {
  box-sizing: border-box;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);

  .mainDialog {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    padding: toRem(67) toRem(78) toRem(56) toRem(78);
    box-shadow: 0px 10px 20px #00000029;
    overflow-y: auto;
    gap: toRem(42);
    max-width: toRem(940);

    .closeDialog {
      position: absolute;
      top: toRem(23);
      left: toRem(17);
      img {
        cursor: pointer;
        width: toRem(17);
        height: toRem(17);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: toRem(42);

      .descr {
        text-align: center;
        font-family: "Heebo-Medium";
        font-size: toRem(26);
        line-height: toRem(38);
      }
      .gogWrap {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .gogBtn {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: toRem(3);
          border-radius: toRem(10);
          border: toRem(1) solid #405f6a;
          padding: toRem(15) toRem(14) toRem(15);
          img {
            width: toRem(33);
            height: toRem(27);
          }
          span {
            color: #405f6a;
            font-size: toRem(18);
            line-height: toRem(27);
          }
        }
      }
    }
  }
}

@media (max-width: 60rem) {
  .dialogWrapper {
    box-sizing: border-box;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: opacify($color: #000000, $amount: 1);
    background-color: rgba(64, 95, 106, 0.9);
    padding-left: toRem(38);
    padding-right: toRem(38);

    .mainDialog {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: toRem(4);
      flex-direction: column;
      background-color: white;
      padding: toRem(48) toRem(26) toRem(40) toRem(26);
      box-shadow: 0px 10px 20px #00000029;
      overflow-y: auto;
      max-width: toRem(940);

      .closeDialog {
        position: absolute;
        top: toRem(23);
        left: toRem(17);
        img {
          cursor: pointer;
          width: toRem(17);
          height: toRem(17);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: toRem(26);

        .descr {
          text-align: center;
          font-family: "Heebo-Medium";
          font-size: toRem(14);
          line-height: toRem(21);
        }
        .gogWrap {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          .gogBtn {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: toRem(3);
            border-radius: toRem(10);
            border: toRem(1) solid #405f6a;
            padding: toRem(12) toRem(12) toRem(10);
            img {
              width: toRem(26);
              height: toRem(21);
            }
            span {
              color: #405f6a;
              font-size: toRem(16);
              line-height: toRem(24);
            }
          }
        }
      }
    }
  }
}
