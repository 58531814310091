@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.phoneInput{
  direction: ltr !important;
  text-align: end !important;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: toRem(97);
  padding-bottom: toRem(17);

  .underline {
    width: 100%;
    border-top: toRem(1) solid rgba(112, 112, 112, 0.28);
  }

  .title {
    width: 100%;
    font-size: toRem(20);
    line-height: toRem(29);
    font-family: "Heebo-Bold";
    padding-bottom: toRem(17);
  }

  .formWrapper {
    box-sizing: border-box;
    max-width: toRem(955);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: toRem(46) toRem(56) toRem(24) toRem(56);
    background-color: #eee9e5;
  }

  .inputContainer {
    position: relative;
    box-sizing: border-box;
    width: toRem(379);
    height: toRem(73);

    input {
      background-color: white;
      border: none;
      border-radius: 0.31rem;
      height: 100%;
      margin-bottom: 0.94rem;
      font-family: Heebo-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #242323;
      padding-inline-start: 1.75rem;
      padding-inline-end: 2.5rem;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0.007rem;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }

  .form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: toRem(38) toRem(33) toRem(0) toRem(33);
    width: 100%;

    .formNamePhone {
      display: flex;
      gap: toRem(20);
      padding-bottom: toRem(20);
    }

    .forgot {
      font-size: toRem(18);
      padding-bottom: toRem(31);
      padding-top: toRem(44);
      line-height: toRem(27);
      text-decoration: underline;
      cursor: pointer;
    }

    .showPasswords {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: toRem(24);
      padding-bottom: toRem(12);
      gap: toRem(5);

      img {
        height: toRem(25);
      }
    }

    .passwords {
      display: flex;
      flex-direction: column;
      gap: toRem(20);

      .oldPassInput {
        display: flex;
      }

      .confirmationFields {
        display: flex;
        gap: toRem(20);
      }
    }

    .buttons {
      padding-top: toRem(28);
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: toRem(32);

      .save {
        padding: toRem(12) toRem(24);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(22);
        line-height: toRem(32);
      }

      .remove {
        cursor: pointer;
        font-size: toRem(16);
        line-height: toRem(24);
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: toRem(961)) {
  .titleMobile {
    display: none;
  }
}

@media (max-width: toRem(960)) {

  .titleMobile{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    font-size: toRem(20);
    line-height: toRem(29);
    font-family: "Heebo-Bold";
    padding-top: toRem(24);
    padding-right: toRem(17);
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: toRem(0);
    padding-bottom: toRem(17);

    .underline {
      display: none;
     
    }

    .title {
      display: none;
      
    }

    .formWrapper {
      box-sizing: border-box;
      max-width: toRem(955);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: toRem(0) toRem(0) toRem(0) toRem(0);
      background-color: white;
    }

    .inputContainer {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      max-height: toRem(54);

      input {
        background-color: #EEE9E5;
        border: none;
        border-radius: 0.31rem;
        height: 100%;
        margin-bottom: 0.94rem;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.75rem;
        padding-inline-end: 2.5rem;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0.007rem;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    .form {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: toRem(29) toRem(17) toRem(0) toRem(17);
      width: 100%;

      .formNamePhone {
        display: flex;
        flex-direction: column;
        gap: toRem(15);
        padding-bottom: toRem(15);
      }

      .forgot {
        font-size: toRem(18);
        padding-bottom: toRem(28);
        padding-top: toRem(21);
        line-height: toRem(27);
        text-decoration: underline;
        cursor: pointer;
      }

      .showPasswords {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: toRem(18);
        padding-bottom: toRem(12);
        gap: toRem(5);

        img {
          height: toRem(25);
        }
      }

      .passwords {
        display: flex;
        flex-direction: column;
        gap: toRem(15);

        .oldPassInput {
          display: flex;
        }

        .confirmationFields {
          display: flex;
          flex-direction: column;
          gap: toRem(15);
        }
      }

      .buttons {
        padding-top: toRem(28);
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: toRem(32);

        .save {
          padding: toRem(12) toRem(24);
          border-radius: toRem(5);
          background-color: #405f6a;
          color: #ffffff;
          cursor: pointer;
          font-size: toRem(16);
          line-height: toRem(24);
        }

        .remove {
          cursor: pointer;
          font-size: toRem(16);
          line-height: toRem(24);
          text-decoration: underline;
        }
      }
    }
  }
}
