.searchFieldComponentContainer {
  width: 27%;
}

.searchFieldContainer {
  position: relative;
  direction: ltr;
}

.searchingField {
  background-color: #EEE9E5;
  border: none;
  border-radius: 0.31rem;
  height: 3.13rem;
  width: 100%;
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2.19rem;
  text-align: center;
  padding-inline-start: 0.94rem;
  padding-inline-end: 3.75rem;
  box-sizing: border-box;
  user-select: none;
}
.link{
  cursor: pointer;
}
.magnifierIcon {
  position: absolute;
  top: 0.63rem;
  right: 1.13rem;
}

.linkForBack {
  font-family: Heebo-Medium;
  font-size: 1.5rem;
  line-height: 2.19rem;
  direction: ltr;
  margin-top: 0.75rem;
  text-decoration: underline;
  user-select: none;
}

@media (max-width: 60rem) { 
  .searchFieldComponentContainer {
    margin-inline-end: 4.38rem;
  }

  .linkForBack {
    display: none;
  }

  .searchFieldComponentContainer {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    width:auto;
  }

  .magnifierIcon {
    top: 0.19rem;
    scale: 70%;
  }

  .searchingField {
    height: 2.19rem;
    background-color: #ffffff;
    padding-inline-end: 1.88rem;
    font-size: 1.125rem;
  }

  .searchingField::placeholder {
    color: #ffffff;
  }

  .magnifierIcon {
    right: -0.25rem;
  }
}
