@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.errorContainer{
  position: fixed;
  box-sizing: border-box;
  z-index: 99999;
  display: flex;
  
  bottom: toRem(50);
  right: toRem(50);
  min-width: toRem(300);
  padding: toRem(8) toRem(15);
  font-size: toRem(18);
  justify-content: center;
  align-items: center;
  color:rgb(255, 0, 0);
  background-color: rgb(247, 197, 197);
  border-radius: toRem(5);
  border: toRem(1) solid rgb(233, 98, 98);
}

.successContainer{
  position: fixed;
  box-sizing: border-box;
  z-index: 99999;
  display: flex;
  
  bottom: toRem(50);
  right: toRem(50);
  min-width: toRem(300);
  padding: toRem(8) toRem(15);
  font-size: toRem(18);
  justify-content: center;
  align-items: center;
  color:rgb(38, 95, 38);
  background-color: rgb(201, 247, 197);
  border-radius: toRem(5);
  border: toRem(1) solid rgb(103, 233, 98);
}

