@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0deg);
  }
  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.inputs_container {
  direction: ltr;
  display: flex;
  // .input_wrapper {
  //   border-radius: 11px;
  //   padding: 1px;
  //   overflow: hidden;
  .one_input {
    padding: 0px;
    padding-inline-start: 0px;
    height: 60px;
    width: 64px;
    background-color: #f7f7f8;
    border-radius: 11px;
    cursor: pointer;
    border: 1px solid #7070702b;
    text-align: center;
    line-height: normal;
  }
  .one_input_error {
    border: 1px solid red;
    animation: tilt-n-move-shaking 1s;
  }
  .one_input:focus,
  .one_input:focus-visible {
    outline: none;
  }
  .one_input:not(.one_input_error):focus,
  .one_input:not(.one_input_error):focus-visible {
    border-color: #405f6a;
  }
  // }
  .one_input:not(:last-child) {
    margin-right: 12px;
  }
}

@media (max-width: 1536px) {
  .inputs_container {
    // .input_wrapper {
    .one_input {
      height: 52px;
      width: 56px;
    }
    // }
    .one_input:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@media (max-width: 600px) {
  .inputs_container {
    // .input_wrapper {
    .one_input {
      height: 47px;
      width: 51px;
    }
    // }
  }
}
