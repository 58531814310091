@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.swiperSlideContainer {
  // margin-top: 8.44rem;
  box-sizing: border-box;
  height: calc(51.75rem - toRem(75) - toRem(30));
  background-color: #EBD9CD;
  align-items: center;
  padding-left: 5.63rem;
  padding-right: 5.63rem;
  padding-top: 4rem;

  
}
.selectedImage{
  object-fit: cover;
  overflow: hidden;
}

.slideWrapper {
  height: 34rem;
  background-color: #ffffff;
  width: 28.38rem;
  display: flex;
  align-items: center;
}

/* .slideWrapper:not(:last-child) {
  margin-inline-end: 1.25rem;
} */

.slideImage {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  user-select: none;
}

.title {
  color: #434242;
  font-family: Heebo-Bold;
  font-size: 2.5rem;
  line-height: 3.69rem;
  text-align: center;
  margin-bottom: 3.19rem;
  margin-top: 0;
}

.arrowContainer {
  margin-top: 2.56rem;
  width: 3.88rem;
  display: flex;
  justify-content: space-between;
  direction: rtl;
  margin-left: 0;
  margin-right: auto;
}

.disableSliderNextPrev {
  pointer-events: none;
}

.arrow {
  cursor: pointer;
}


@media (max-width: 61.25rem) {
  .swiperSlideContainer {
    padding-left: 2.19rem;
    padding-right: 2.19rem;
  }

  .slideWrapper:not(:last-child) {
    margin-inline-end: .75rem;
  }
}

@media (max-width: 40rem) {
  .swiperSlideContainer {
    // margin-top: 3.25rem;
    padding-top: 1.88rem;
    height: calc(31.25rem - toRem(100));
  }

  .title {
    font-size: 1.5rem;
    line-height: 2.19rem;
    margin-bottom: 1.81rem;
  }

  .slideWrapper {
    height: 21.19rem;
    width: 17.75rem;
  }

  .arrowContainer {
    display: none;
  }
}

@media (max-width: 25.75rem) { 
  .swiperSlideContainer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
