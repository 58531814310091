@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.favoritesWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: calc(100vh - 8.75rem - 5.5625rem);
  overflow: auto;
  padding: toRem(62) toRem(120) toRem(115) toRem(120);

  

  .dropWrapper{
    display: flex;
    flex-direction: column;
    width: toRem(312);
    padding-bottom: toRem(92);
    gap: toRem(13);

    .dropTitle{
      font-size: toRem(24);
      line-height: toRem(35);
      font-family: "Heebo-bold";
    }

    .selector{
      box-sizing: border-box;
      width: 100%;
      height: toRem(45);
    }
  }

  .allSuppliers{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: toRem(44) toRem(20);

    .supplierCard{
      width: toRem(540);
    }
  }
}

@media (min-width: toRem(961)) {
  .mobile {
    display: none;
  }

  .desctop {
    display: flex;
  }
}

@media (max-width: toRem(800)) {
  .favoritesWrapper {
    // height: calc(100vh - 7.56rem - 5.5625rem);
  }

}


@media (max-width: toRem(960)) {
  .mobile {
    display: flex;
  }

  .desctop {
    display: none;
  }



  .favoritesWrapper {
    // height: calc(100vh - 7.56rem - 5.5625rem);
    padding: toRem(20) toRem(17) toRem(42) toRem(17);

    .mobileTitle{
      display: flex;
      width: 100%;
      font-size: toRem(24);
      line-height: toRem(35);
      font-family: "Heebo-Bold";
      padding-bottom: toRem(20);
    }

    .dropWrapper{
      padding-bottom: toRem(48);
      max-width: toRem(340);
      width: 100%;
      gap: toRem(15);

      .dropTitle{
        font-size: toRem(20);
        line-height: toRem(29);
        font-family: "Heebo-bold";
      }
  
      .selector{
        height: toRem(45);
      }
    }

    .allSuppliers{
      display: flex;
      flex-wrap: wrap;
      gap: toRem(51) toRem(20);

      .supplierCard{
        width: toRem(160);
        height: toRem(212);
      }
    }
  }
}
