.mainContainer {
  // position: absolute;
  position: fixed;
  z-index: 99999;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsDualRing:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: ldsDualRing 1.2s linear infinite;
}
@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
