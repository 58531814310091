$rem: 16;
//#F26565

.errorMessageSuppliers {
  margin: 0;
  color: red;
  bottom: -1rem;
  right: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.checklist_massage{
  margin: calc(10.5rem/$rem) calc(90rem/$rem) 0;
  background-color: #36606B;
  height: calc(75rem/$rem);
  font-family: Heebo-Medium;
  font-size: calc(20rem/$rem);
  color: #FFFFFF ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.findChecklist{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    font-size: calc(18rem/$rem);
    margin: calc(29.5rem/$rem) calc(90rem/$rem) calc(41.5rem/$rem);
}
.mainContainer{
  position: absolute;
  z-index: 999;
  display: flex;
  width: 100vw;
  height: 500vh;
  justify-content: center;
  align-items: center;
  color:white;
  background-color: rgba(0, 0, 0, 0.5);;
}
.mobile_remove{
  width: calc(36rem/$rem);
  height: calc(36rem/$rem);
}
.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: ldsDualRing 1.2s linear infinite;
}
@keyframes ldsDualRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header{
    margin-left: calc(24rem/$rem);
}
.headerButton{
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  background-color: #405F6A;
  border-radius: calc(3rem/$rem);
  border: none;
  width: calc(98rem/$rem);
  height: calc(45rem/$rem);
  color: #FFFFFF;
  cursor: pointer;
}
.people{
    background-color: #EEE9E5;
    border: none;
    width: calc(187rem/$rem);
    height: calc(45rem/$rem);
    margin-top: calc(10rem/$rem);
    padding-right: calc(10rem/$rem);
}
.headerPrice{
    background-color: #EEE9E5;
    border: none;
    width: calc(153rem/$rem);
    height: calc(45rem/$rem);
    margin-top: calc(10rem/$rem);
    padding-left: calc(34rem/$rem);
    padding-right: calc(10rem/$rem);
}
.shekelSymbol{
  position: absolute;
  left: calc(17rem/$rem);
  top: calc(25rem/$rem);
  cursor: pointer;
}
.shekelContainer{
    position: relative;
}
.checklistSelect{
    height: 2.8125rem;
    margin-top: calc(10rem/$rem);
}
.firstLine{
    width: calc(100% - calc(180rem/$rem));
    margin: 0 calc(90rem/$rem) calc(35.5rem/$rem);
    box-sizing: border-box;
}
.line{
  border: 1px solid rgba(164, 162, 162, 0.31);
}
.calculatorBlock{
    margin: 0 calc(90rem/$rem) calc(94rem/$rem);
    width: calc(100% - calc(180rem/$rem));
    display: flex;
    flex-direction: column;
    align-items: center;
}
.calculatorTitle{
    font-size: calc(28rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    margin-bottom: calc(42rem/$rem);
}
.calculatorInfo{
    font-size: calc(20rem/$rem);
    font-family: Heebo-Medium;
    color:#707070;
    margin-bottom: calc(20rem/$rem);
    direction: ltr;
}
.calculatorInfoSum{
    font-size: calc(14rem/$rem);
    font-family: Heebo-SemiBold;
    color:#434242;
    margin-bottom: calc(9rem/$rem);
}
.calculatorButton{
    height: calc(84rem/$rem);
    width: calc(157rem/$rem);
    background: #E8CFBF;
    border-radius: calc(5rem/$rem);
    border-color: #707070;
    display: flex;
    align-items: center;
    flex-direction: column;    
    justify-content: center;
    margin-left: calc(12rem/$rem);
    position: relative;
}
.buttonsBlock{
    display: flex;
    flex-direction: row;
}
.buttonTitle{
    font-size: calc(16rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
}
.buttonSum{
    direction: ltr;
    font-size: calc(26rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: calc(127rem/$rem);
    position: relative;
}
.buttonSum::-webkit-scrollbar { width: 0; }
.buttonSumDesctop{
    direction: ltr;
    font-size: calc(26rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: calc(127rem/$rem);
    position: relative;
    display: block;
}
.buttonSumDesctop::-webkit-scrollbar { width: 0; }
.buttonSumMobile{
  display: none;
  direction: ltr;
}
.shekel{
  position: absolute;
  left: calc(5rem/$rem);
  top: calc(60rem/$rem);
  cursor: pointer;
}
.shekel_desctop{
  position: absolute;
  left: calc(5rem/$rem);
  top: calc(52rem/$rem);
  cursor: pointer;
}
.mainBlock{
  padding-bottom: 0.1px;
}
.calculatorLine{
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    height: calc(93rem/$rem);
    background-color: #E8CFBF;
    color:#FFFFFF;
    display: flex;
    position: fixed;
    bottom: 0.1rem;
}
.calc_shekel{
  height: calc(12rem/$rem);
  width: calc(11.75rem/$rem);
}
.calculationBlocks{
    display: flex;
    flex-direction: row;
    margin: calc(12rem/$rem) calc(94rem/$rem) 0 0;
}
.calculatorTitles{
    font-size: calc(16rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
    margin-bottom: calc(1rem/$rem);
    direction: ltr;
    text-align: right;
}
.calcSum{
    font-size: calc(20rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
    margin-left: calc(2.3rem/$rem);
}
.calcPrice{
    font-size: calc(24rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
}
.calcButton{
    height: calc(47rem/$rem);
    width: calc(153rem/$rem);
    border-radius: calc(5rem/$rem);
    border: calc(1rem/$rem) solid #405F6A;
    background-color: #FFFFFF;
    opacity: 0.75;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calcBlock{
    margin-right: calc(30.3rem/$rem);
}
.table{
  //height: calc(1580rem/$rem);
  width: 100%;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
}
.tableBlock{
  margin: 0 calc(90rem/$rem);
  width: calc(100% - 180rem/$rem);
}
.categoryColumn{
  height: calc(75rem/$rem);
  width: 1005;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.categoryName{
  display: flex;
  flex-direction: row;
//   margin-top: calc(55rem/$rem);
  align-items: center;
  height: 100%;
  width: 90%;
}
.rowTitle{
  font-size: calc(20rem/$rem);
  font-family: Heebo-Bold;
  color:#434242;
  margin-left: calc(10rem/$rem);
  cursor: pointer;
}
.rowTitleBold{
  font-size: calc(23rem/$rem);
  font-family: Heebo-Bold;
  color:#434242;
  margin-left: calc(10rem/$rem);
  cursor: pointer;
}
.rowText{
  font-size: calc(18rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
}
.rowLine{
  margin: 0;
  border:calc(1rem/$rem) solid rgba(112, 112, 112, 0.26)
}
.remove{
  text-align: left;
  cursor: pointer;
  margin-right: calc(34.36rem/$rem);
}
.close_category{
  text-align: left;
  cursor: pointer;
  margin-right: calc(34.36rem/$rem);
}
.lineEndTable{
    margin-top: calc(30rem/$rem);
   // border: calc(1rem/$rem) solid #3E3D3D;
    margin: calc(30rem/$rem) calc(90rem/$rem) 0;
    width: calc(100% - 180rem/$rem);
}
.supplierblock{
  margin: 0 calc(90rem/$rem) 0;
  width: calc(100% - 180rem/$rem);
 // height: calc(283.3rem/$rem);
 //background-color: bisque;
  display: flex;
  flex-direction: column;
}
.suppliertitle{
  font-size: calc(20rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  margin-top: calc(30rem/$rem);
  margin-bottom: calc(36rem/$rem);
}
.addSupplierBlock{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: calc(4rem/$rem) 0;
}
.supplierCategory{
    height: calc(75rem/$rem);
    border: none;
    background-color: #FFFFFF;
    width: 15%;
    margin-right: calc(2rem/$rem);
    margin-left: calc(10rem/$rem);
    padding-right: calc(32rem/$rem);
}
.supplierCategory::placeholder {
    font-size: calc(18rem/$rem);
    font-family: Heebo-Regular;
    color:#707070;
    // padding-inline-start: calc(32rem/$rem);
}
.supplierCompanyName{
    height: calc(75rem/$rem);
    border: none;
    background-color: #FFFFFF;
    width: 50%;
    margin-left: calc(29.6rem/$rem);
    padding-right: calc(32rem/$rem);
}
.supplierCompanyName::placeholder {
    font-size: calc(18rem/$rem);
    font-family: Heebo-Regular;
    color:#707070;
    // padding-inline-start: calc(32rem/$rem);
}
.supplierSum{
    height: calc(75rem/$rem);
    border: none;
    background-color: #FFFFFF;
    padding-right: calc(32rem/$rem);
    padding-left: calc(42rem/$rem);
}
.supplierSum::placeholder {
    font-size: calc(16rem/$rem);
    font-family: Heebo-Regular;
    color:#A4A2A2;
    // padding-inline-start: calc(32rem/$rem);
}
.shekelSum{
  position: absolute;
  left: calc(30rem/$rem);
  top: calc(34rem/$rem);
}
.supplierIcon{
  cursor: pointer;
}
.supplierContract{
    margin-right: calc(39.4rem/$rem);
    cursor: pointer;
}
.supplierAdd{
  margin-top: calc(15rem/$rem);
  display: flex;
  font-size: calc(16rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  cursor: pointer;
}
.supplierLink{
    font-size: calc(24rem/$rem);
    font-family: Heebo-Bold;
    color:#405F6A;
    text-decoration: underline;
    cursor: pointer;
}
.supplierFooter{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: calc(50rem/$rem);
}
.footer{
    width: 100%;
    margin-bottom: calc(95rem/$rem);
}
.useless_title{ 
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  margin-bottom: calc(9rem/$rem);
  margin-right: calc(-22rem/$rem);
}
.detailsBlock{
  // display: flex;
  // flex-direction: column;
  //height: 300px;
  background-color: #FFFFFF;
  margin-bottom: calc(23rem/$rem);
}
.contracts{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(85rem/$rem);
}
.contract_icon{
  padding-right: calc(10rem/$rem);
}
.contract_name{
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color:#434242;
  text-decoration: underline;
  padding-right: calc(14.5rem/$rem);
}
.contract_sum{
 // position: relative;
  padding-right: calc(37.5rem/$rem);
  font-size: calc(24rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  display: flex;
  flex-direction: row;
}
.contract_shekel{
  // position: absolute;
  // left: calc(-13rem/$rem);
  // top: calc(13rem/$rem);
  cursor: pointer;
}
.contract_eye{
  padding-right: calc(18.5rem/$rem);
}
.contract_eye_padding{
  padding-right: calc(18.5rem/$rem);
  padding-top: calc(18.5rem/$rem);
}
.contract_eye_icon{
  cursor: pointer;
}  
.contract_suppliers_remove{
  cursor: pointer;
  position: absolute;
  left: calc(107rem/$rem);
}  
.contract_line{
  margin: 0 calc(15.5rem/$rem);
  border: calc(1rem/$rem) solid rgba(112, 112, 112, 0.14);
}
.contract_supplier_link{   
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-right: calc(14rem/$rem);
  font-size: calc(20rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  height: calc(87rem/$rem);
  padding-bottom: calc(10rem/$rem);
}

.contract_services_block{    
  // display: flex;
  // flex-direction: row;
  background-color: #FFFFFF;
  // align-items: center;
  // justify-content: flex-start;
  height: calc(20rem/$rem);
}
.contract_services_checkbox{
  margin-right: calc(18rem/$rem);
  width: calc(35rem/$rem);
}
.contract_service_name{
  padding-right: calc(22rem/$rem);
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color:#434242;
  text-decoration: underline;
}
.contract_service_sum{
  position: relative;
  margin-right: calc(59rem/$rem);
  font-size: calc(24rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
}
.contract_service_eye{
  margin-right: calc(18rem/$rem);
}
.contract_service_supplier{
  padding-right: calc(70rem/$rem);
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color:#405F6A;
  text-decoration: underline;
}


.radio {
  position: absolute;
  margin-bottom: .25rem;
  margin-top: .19rem;
  padding-inline-start: 2.63rem;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    font-size: toRem(22);
    // line-height: toRem(32);
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 1rem;
  }

  label::before {
    content: "";
    display: inline-block;
    width: 1.63rem;
    height: 1.63rem;
    position: absolute;
    right: -2.69rem;
    bottom: 0rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
  }
}
.radioContainer {
  display: flex;
  justify-content: center;
  height: 1.9375rem;
  gap: 2.13rem;
  margin-top: 1.5rem;
}

.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.mainPopup::-webkit-scrollbar { width: 0; }

.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.dialog_title{
  font-size: calc(24rem/$rem);
  font-family: Heebo-Bold;
  color:#242323;
  margin: calc(44rem/$rem) calc(68.5rem/$rem) 0 calc(68.5rem/$rem);
}
.dialog_title_line{
  margin: calc(6.5rem/$rem) calc(68.5rem/$rem) calc(46rem/$rem);
  border: calc(1rem/$rem) solid rgba(62, 61, 61, 0.6);
}

.dialog_services_block{
  max-height: calc(155rem/$rem);
  overflow: auto;
}
.dialog_services_block::-webkit-scrollbar { width: 0; }
.dialog_services{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: calc(15rem/$rem);
}
.dialog_service_name{
  font-size: calc(25rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  margin-right: calc(68.5rem/$rem);
  width: calc(56% - calc(80.1rem/$rem));
  height: calc(36rem/$rem);
  overflow-y: hidden;
  overflow-x: auto;
}
.dialog_service_name::-webkit-scrollbar { width: 0; }
.dialog_service_name_delete{
  font-size: calc(25rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  margin-right: calc(68.5rem/$rem);
  width: calc(72% - calc(80.1rem/$rem));
  height: calc(37rem/$rem);
  overflow-y: hidden;
  overflow-x: auto;
}
.dialog_service_name_delete::-webkit-scrollbar { width: 0; }
.dialog_service_sum{
  display: flex;
  position: relative;
  //max-width: calc(26% - calc(89.6rem/$rem));
  font-size: calc(26rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
}
.dialog_service_sum_td{
 // max-width: calc(26% - calc(89.6rem/$rem));
 margin-right: calc(5rem/$rem);
 //width: 50%;
}
.dialog_services_line{
  margin: calc(26.9rem/$rem) calc(68.5rem/$rem) calc(15rem/$rem);
  border: calc(1rem/$rem) solid rgba(62, 61, 61, 0.6);
}
.dialog_services{
  height: calc(37rem/$rem);
}
.dialog_service_delete{
  max-width: calc(16% - calc(105.6rem/$rem));
  text-decoration: underline;
  font-size: calc(22rem/$rem);
  margin-right: calc(60rem/$rem);
  font-family: Heebo-Regular;
  color: #405F6A;
}
.dialog_all_sum{
  font-size: calc(25rem/$rem);
  font-family: Heebo-Medium;
  color:#242323;
  margin: calc(15rem/$rem) calc(68rem/$rem) calc(136.5rem/$rem) 0;
  position: relative;
}
.dialog_shekel{
  position: absolute;
  top: calc(14rem/$rem);
}
.dialog_buttons_block{
  display: flex;
  justify-content: center;
  margin-bottom: calc(39rem/$rem);
}
.dialog_right_button{
  background-color: #405F6A;
  border: none;
  border-radius: calc(5rem/$rem);
  font-size: calc(18rem/$rem);
  font-family: Heebo-Regular;
  color:#FFFFFF;
  margin-left: calc(10rem/$rem);
  height: calc(51rem/$rem);
  padding: 0 calc(24rem/$rem);
  cursor: pointer;
}
.dialog_left_button{
  background-color: #FFFFFF;
  border: calc(1rem/$rem) solid #3F5F69;
  border-radius: calc(5rem/$rem);
  font-size: calc(18rem/$rem);
  font-family: Heebo-Regular;
  color:#405F6A;
  margin-left: calc(10rem/$rem);
  height: calc(51rem/$rem);
  padding: 0 calc(24rem/$rem);
  cursor: pointer;
}
.dialog_supplier_title{
  font-size: calc(18rem/$rem);
  font-family: Heebo-Bold;
  color:#242323;
  margin: calc(51rem/$rem) calc(25rem/$rem) calc(38rem/$rem) 0;
}
.mobile_title{
  color: #242323;
  font-size: calc(20rem/$rem);
  font-family: Heebo-Bold;
  margin: calc(21rem/$rem) calc(18rem/$rem) calc(13rem/$rem) 0;
}
.mobile{
  display: none;
}
.desctop{
  display: block;
}
.mobile_add_checklist{
  display: flex;
  margin-right: calc(14rem/$rem);
}
.mobile_change_checklist{
  margin-right: calc(18rem/$rem);
  color: #242323;
  font-size: calc(18rem/$rem);
  font-family: Heebo-Medium;
}
.mobile_add_supplier{
  margin: calc(14rem/$rem) calc(18rem/$rem) calc(34rem/$rem) 0;
  color: #242323;
  font-size: calc(18rem/$rem);
  font-family: Heebo-Bold;
  text-decoration: underline;
}
.desctop_contract_eye{
  margin-top: calc(10rem/$rem);
}
.mobile_add_suppliers{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 calc(25rem/$rem) calc(48rem/$rem) calc(16rem/$rem);
}
.mobile_add_supplierCategory{
  width: 100%;
  height: calc(49rem/$rem);
  border-radius: calc(5rem/$rem);
  border: none;
  background-color: #F7F4F4;
  font-family: Heebo-Regular;
  color: #9B9999;
  margin-bottom: calc(8rem/$rem);
  padding-right: calc(19.7rem/$rem);
  box-sizing: border-box;
}
.mobile_add_supplierCategory::placeholder {
    font-size: calc(18rem/$rem);
    font-family: Heebo-Regular;
    color: #9B9999;
}
.mobile_add_sum{
  width: 100%;
  height: calc(49rem/$rem);
  border-radius: calc(5rem/$rem);
  border: none;
  background-color: #F7F4F4;
  font-family: Heebo-Regular;
  color: #9B9999;
  margin-bottom: calc(8rem/$rem);
  padding-right: calc(19.7rem/$rem);
  box-sizing: border-box;
}
.mobile_add_sum::placeholder {
    font-size: calc(18rem/$rem);
    font-family: Heebo-Regular;
    color: #9B9999;
}
.mobile_add_block{
  width: 100%;
}
.mobile_shekelSum{
  position: absolute;
  left: calc(17rem/$rem);
  top: calc(19rem/$rem);
  width: calc(10.44rem/$rem);
  height: calc(10.68rem/$rem);
  cursor: pointer;
}
.mobile_add_new_supplier{
  font-size: calc(18rem/$rem);
  font-family: Heebo-bold;
  color: #242323;
  text-decoration: underline;
  text-align: center;
  margin-bottom: calc(33rem/$rem);
  cursor: pointer;
}
.dialog_checklist_block{
  display: flex;
  flex-direction: column;
  margin: calc(32rem/$rem) calc(25rem/$rem) calc(35rem/$rem);
  width: calc(100% - calc(50rem/$rem));
}
.dialog_checklist_title{
  font-size: calc(18rem/$rem);
  font-family: Heebo-Bold;
  color:#242323;
  margin-bottom: calc(16rem/$rem);
}
.dialog_checklist_select_label{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#3E3D3D;
  margin-bottom: calc(5rem/$rem);
}
.dialog_checklist_inputs_block{
  margin-bottom: calc(14rem/$rem);
}
.dialog_checklist_shekelContainer{
    position: relative;
}
.dialog_checklist_headerPrice{
  background-color: #F7F4F4;
  color:#3E3D3D;
  border: none;
  width: 100%;
  height: calc(42rem/$rem);
  border-radius: calc(5rem/$rem);
  padding-left: calc(30rem/$rem);
  padding-right: calc(10rem/$rem);
  box-sizing: border-box;
  margin-bottom: calc(14rem/$rem);
}
.table_margin_bottom{
  margin-bottom: calc(12.5rem/$rem);
  margin-top: calc(12.5rem/$rem);
}
.dialog_checklist_shekelSymbol{
  position: absolute;
  left: calc(16.4rem/$rem);
  top: calc(17rem/$rem);
  cursor: pointer;
  width: calc(8.44rem/$rem);
  height: calc(8.64rem/$rem);
}
.dialog_checklist_headerCount{
  background-color: #F7F4F4;
  color:#3E3D3D;
  border: none;
  width: 100%;
  height: calc(42rem/$rem);
  border-radius: calc(5rem/$rem);
  padding-right: calc(10rem/$rem);
  box-sizing: border-box;
}
.dialog_checklist_button{
  font-size: calc(16rem/$rem);
  font-family: Heebo-Regular;
  color:#FFFFFF;
  text-align: center;
  width: calc(98.48rem/$rem);
  height: calc(37.29rem/$rem);
  background-color: #405F6A;
  border: none;
  border-radius: calc(3rem/$rem);
  cursor: pointer;
}
.dialog_checklist_button_block{
  display: flex;
  justify-content: center;
  margin-bottom: calc(21.7rem/$rem);
}
.dialog_checklist_back{
  font-size: calc(18rem/$rem);
  text-decoration: underline;
  font-family: Heebo-Regular;
  color:#242323;
  cursor: pointer;
}
.dialog_buttons_block_titles{
  display: flex;
  justify-content: space-between;
}

.dialog_services_title{
  font-size: calc(18rem/$rem);
  font-family: Heebo-Bold;
  color:#242323;
  margin-bottom: calc(11rem/$rem);
}
.dialog_supplier_services{
  display: flex;
  flex-direction: row;
}
.dialog_supplier_service_title{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Medium;
  color:#434242;
  text-decoration: underline;
  max-width: 75%;
  height: calc(21rem/$rem);
  overflow: hidden;
}
.dialog_supplier_service_price{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  margin-right: calc(7rem/$rem);
  display: flex;
  align-items: center;
  margin-bottom: calc(17rem/$rem);
}
.dialog_supplier_service_sum{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  height: calc(18rem/$rem);
  overflow: hidden;
  margin-left: calc(2.2rem/$rem);
}
.dialog_supplier_shekelSymbol{
  cursor: pointer;
  width: calc(6.77rem/$rem);
  height: calc(6.93rem/$rem);
}
.dialog_supplier_link{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Bold;
  color:#405F6A;
  cursor: pointer;
  text-decoration: underline;
}
.dialog_supplier_service_select{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  margin-left: calc(7.2rem/$rem);
  color:#434242;
  display: flex;
  align-items: center; 
}
.dialog_supplier_select{
  cursor: pointer;
  width: calc(8.29rem/$rem);
  height: calc(4.52rem/$rem);
}
.dialog_supplier_service_select_block{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  display: flex;
  align-items: center;
  margin-top: calc(6rem/$rem);
}
.dialog_supplier_service_back{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  margin: calc(6rem/$rem) calc(25rem/$rem) calc(19rem/$rem);
  width: calc(100% - calc(50rem/$rem));
  cursor: pointer;
}
.dialog_supplier_services_block{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F8;
  margin-top: calc(6rem/$rem); 
  height: calc(90rem/$rem);
  margin: calc(0rem/$rem) calc(14.5rem/$rem) calc(6.8rem/$rem);
  width: calc(100% - calc(29rem/$rem));
  overflow: auto;
}
.dialog_supplier_services_block::-webkit-scrollbar { width: 0; }
.dialog_supplier_main_block{
  display: flex;
  flex-direction: column;
  margin: calc(32rem/$rem) calc(25rem/$rem) 0;
  width: calc(100% - calc(50rem/$rem));
  box-sizing: border-box;
  padding-top: calc(4.3rem/$rem);
}
.dialog_supplier_services_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#242323;
  margin: calc(4rem/$rem) calc(12rem/$rem) 0 0;
  width: calc(100% - calc(12rem/$rem));
  height: calc(25rem/$rem);
}
.dialog_supplier_services_sum{
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#434242;
  margin-right: calc(7rem/$rem);
  display: flex;
  align-items: flex-start;
  margin-bottom: calc(17rem/$rem);
  margin-left: calc(23.5rem/$rem);
}
.dialog_supplier_services_shekelSymbol{
  cursor: pointer;
  width: calc(6.77rem/$rem);
  height: calc(6.93rem/$rem);
  margin-top: calc(8rem/$rem);
}
.dialog_supplier_services_name{
  width: 28%;
  height: calc(21rem/$rem);
  overflow: hidden;
}
.dialog_supplier_services_delete{
  cursor: pointer;
  font-size: calc(14rem/$rem);
  font-family: Heebo-Regular;
  color:#405F6A;
  text-decoration: underline;
  margin-left: calc(23.5rem/$rem);
}
@media (max-width: calc(1100rem/$rem)) {
  .radio{
  label::before {
    width: calc(16rem/$rem) !important;
    height: calc(16rem/$rem) !important;
    right: -2.69rem;
    bottom: calc(8rem/$rem) !important;
  }
  input:checked+label::before {    
    background-size: 0.6rem;
  }
}
  .checklist_massage{
    font-size: calc(16rem/$rem);
  }
  .tableBlock{
    margin: 0 calc(10rem/$rem);
    width: calc(100% - 20rem/$rem);
  }
  .supplierblock{
    margin: 0 calc(10rem/$rem) 0;
    width: calc(100% - 20rem/$rem);
    display: flex;
    flex-direction: column;
  }
  .supplierCategory{
    width: 25%;
  }
  .supplierSum{
    width: calc(76rem/$rem);
  }
  .suppliertitle, .contract_name, .contract_sum,
  .contract_service_sum, .contract_service_name,
  .contract_supplier_link, .contract_service_supplier,
  .calculatorTitle, .calculatorInfo, .supplierLink {
    font-size: calc(16rem/$rem);
  }
  .rowTitle {
    font-size: calc(18rem/$rem);
  }
  .rowTitleBold {
    font-size: calc(21rem/$rem);
  }
  .contract_shekel{
    top: calc(10rem/$rem);
    left: calc(-10rem/$rem);
    width: calc(7rem/$rem);
    height: calc(7rem/$rem);
  }
  .supplierCategory::placeholder {
      font-size: calc(16rem/$rem);
  }
  .supplierCompanyName::placeholder {
      font-size: calc(16rem/$rem);
  }
  .supplierSum::placeholder {
      font-size: calc(16rem/$rem);
  }
  .contract_suppliers_remove{
    left: calc(24rem/$rem);
    width: calc(28rem/$rem);
    height: calc(28rem/$rem);
  }  
}
@media (max-width: calc(960rem/$rem)) {
  .footer{
      margin-bottom: calc(1rem/$rem);
  }
  .mainBlock{
    padding-bottom: calc(65rem/$rem);
  }
  .findChecklist{
    flex-direction: column;
    align-items: center;
  }
  .header{
    margin-bottom: calc(10rem/$rem);
    margin-left: 0;
  }
  .findChecklist{
    margin-top: calc(24rem/$rem);
    font-size: calc(14rem/$rem);
  }
  .calculationBlocks{
    justify-content: center;
    margin: calc(9rem/$rem) calc(18rem/$rem) 0 0;
  }
  .calcButton{
    justify-content: flex-end;
  }
  .shekel_calc{
    margin: calc(5rem/$rem) 0 0 calc(9.6rem/$rem);
  }
  .calcSum, .calcPrice{
    font-size: calc(16rem/$rem);
  }
  .calcButton{
    width: calc(90rem/$rem);
    height: calc(28rem/$rem);
  }
  .calculatorLine{
    height: calc(93rem/$rem);
  }
  .buttonSumDesctop{
    display: none;
  }
  .buttonSumMobile::-webkit-scrollbar { width: 0; }
  .buttonSumMobile{
    direction: ltr;
    display: block;
    font-size: calc(18rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: calc(127rem/$rem);
    position: relative;
    display: block;
    margin-top: calc(4rem/$rem);
  }
  .buttonSum{
    font-size: calc(18rem/$rem);
  }
  .buttonSum, .buttonSumMobile{  
    direction: ltr;
    overflow: hidden;
    width: 68px;
    text-align: center;
  }
  .shekel_desctop{
    display: none;
  }
  .buttonTitle{
    font-size: calc(9rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
  }
  .calculatorButton{
      height: calc(62rem/$rem);
      width: calc(80rem/$rem);
      background: #E8CFBF;
      border-radius: calc(5rem/$rem);
      border-color: #707070;
      display: flex;
      align-items: center;
      flex-direction: column;    
      justify-content: center;
      margin-left: calc(7rem/$rem);
      position: relative;
  }
  .calculatorInfoSum{
    display: none;
  }
  .calculatorBlock{
      margin: 0 calc(18rem/$rem) calc(31rem/$rem);
      width: calc(100% - calc(36rem/$rem));
  }
  .calculatorInfo{
    font-size: calc(14rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
    margin: 0 calc(18rem/$rem) calc(24rem/$rem) calc(32rem/$rem);
  }
  .calculatorTitle{
    font-size: calc(18rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    text-align: center;
    width: calc(100% - calc(36rem/$rem));
    margin: 0 calc(18rem/$rem) calc(6rem/$rem) 0;
  }
}
@media (max-width: calc(820rem/$rem)) {
  .dialog_service_name, .dialog_service_sum, .dialog_service_delete{
    font-size: calc(16rem/$rem);
  }
  .dialog_service_name{
    height: calc(20rem/$rem);
  }
  .dialog_service_sum{
    height: calc(20rem/$rem);
    overflow: hidden;
  }
  .dialog_service_delete{
    margin-right: 0;
  }
}
@media (max-width: calc(800rem/$rem)) {
  .checklist_massage{
    margin: calc(10.5rem/$rem) calc(0rem/$rem) 0;
    font-size: calc(12rem/$rem);
    height: calc(65rem/$rem);
    padding: 0 calc(15rem/$rem);
  }
  .firstLine{
    width: calc(100% - calc(36rem/$rem));
    margin: 0 calc(18rem/$rem) calc(21.5rem/$rem);
  }
}
@media (max-width: calc(600rem/$rem)) {
  .tableBlock{
    margin: 0;
    width: calc(100%);
  }
  .mobile{
    padding-bottom: calc(13.5rem/$rem);
    display: block;
  }
  .desctop{
    display: none;
  }
  .categoryColumn{
    height: calc(63rem/$rem);
  }
  .rowText{
    font-size: calc(14rem/$rem);
  }
  .mobile_remove{
    width: calc(25rem/$rem);
    height: calc(23.44rem/$rem);
    margin-top: calc(12rem/$rem);
  }
  .calculatorLine{
    height: calc(63rem/$rem);
  }
  .calcBlock{
    margin-right: calc(9rem/$rem);
  }
  .calculatorTitles{
    font-size: calc(10rem/$rem);
    margin: 0 0 calc(5rem/$rem) 0;
  }
  .rowLine{
    border:calc(1rem/$rem) solid rgba(112, 112, 112, 0.2)
  }
  // .footer{
  //   margin-bottom: calc(109em/$rem);
  // }
  .calculatorBlock{
    overflow: hidden;
    margin-right: calc(17em/$rem);
  }
  .close_category{
    width: calc(10rem/$rem);
    height: calc(10rem/$rem);
  }
  .contract_supplier, .supplierIcon{
    width: calc(27rem/$rem);
    height: calc(27rem/$rem);
    margin-top: 10px;
  }
  .mobile_contract_block{
    height : calc(53.8rem/$rem);
  }
  .contract_name{
    font-family: Heebo-Medium;
  }
  .contract_line{
    border: 0.0625rem solid rgba(112, 112, 112, 0.2);
  }
  .contract_name{
    padding-right: calc(8rem/$rem);
  }
  .contract_sum{
    padding-right: calc(8rem/$rem);
  }
  .mobile_contract_eye{
    position: absolute;
    left: 18px;
    width: calc(13.98rem/$rem);
    height: calc(8.74rem/$rem);
  }
  .contract_supplier{
    font-size: calc(12rem/$rem); 
  }
  .contract_supplier_link{
    height : calc(49.2rem/$rem);
  }
  .radio{
  label::before {
    width: calc(11.2rem/$rem) !important;
    height: calc(11.2rem/$rem) !important;
    right: -2.69rem;
    bottom: calc(13rem/$rem) !important;
  }
  input:checked+label::before {    
    background-size: 0.43rem;
  }
}
  .contract_services_block{
    height : calc(41.1rem/$rem);
    display: flex;
    align-items: center;
  }
  .contract_service_name{
    font-size: calc(14rem/$rem);
    font-family: Heebo-Medium;
    margin-right: calc(7.3rem/$rem);
  }
  .contract_services_checkbox{
    width: 0;
    margin-right: calc(18rem/$rem);
  }
  .contract_service_supplier{
    display: none;
  }
  .contract_suppliers_details{
    cursor: pointer;
    position: absolute;
    top: -5px;
    left: 13px;
  } 
  .mobile_table_price{
    width: max-content;
  }
  .categoryColumn{
    margin-right: calc(19.5rem/$rem);
    margin-left: calc(20.5rem/$rem);
    width: calc(100% - 40rem/$rem);
  }
  .mobile_name{
    width: 65%;
    height: calc(21rem/$rem);
    overflow: hidden;
  }
}
@media (max-width: calc(500rem/$rem)) {
  .useless_title{ 
    display: none;
  }
  .calculatorBlock{
    align-items: flex-start;
  }
  .calculatorInfo{
    font-size: calc(14rem/$rem);
    font-family: Heebo-Regular;
    color:#434242;
    text-align: right;
    margin: 0 0 calc(24rem/$rem) calc(14rem/$rem);
  }
  .calculatorTitle{
    font-size: calc(18rem/$rem);
    font-family: Heebo-Bold;
    color:#242323;
    margin: 0 0 calc(6rem/$rem) 0;
  }
  .calculatorTitle{
    text-align: right;
  }
}
@media (max-width: calc(300rem/$rem)) {
  .calculationBlocks{
    flex-direction: column;
  }
}