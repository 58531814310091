@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.dialogWrapper{
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);
  .titleNotes{
    direction: ltr;
    text-align: right;
    margin-bottom: toRem(40.1);
    font-size: toRem(22);
    font-family: Heebo-Regular;
    color: #242323;
  }
  .notes{
    width: 100%;
    border: toRem(1) solid #405F6A;
    background-color: #F6F7F7;
    border-radius: toRem(4);
    height: toRem(503.91);
    font-family: Heebo-Regular;
    color: #242323;
    font-size: toRem(22);
    resize: none;
    padding-inline-start: toRem(26);
  }
  ::placeholder { 
    font-family: Heebo-Regular;
    color: #242323;
    font-size: toRem(22);
    opacity: 0.4;
    //padding-top: toRem(18.9);
  }
  .buttonFirst{
    max-width: toRem(225.96);
    height: toRem(64);
    font-family: Heebo-Regular;
    color: #FFFFFF;
    font-size: toRem(19);
    background-color: #405F6A;
    border: none;
    cursor: pointer;
    border-radius: toRem(3);
    margin-top: toRem(40);
    text-align: center;
  }
  .buttonFirstBlock{
    text-align: center;
  }
  .secondDialog{
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    max-width: toRem(637);
    width: 100%;
    padding: toRem(132.7) toRem(64) toRem(0) toRem(64);
    box-shadow: 0px 10px 20px #00000029;
    max-width: 60.47%;
    overflow-y: auto;
  }
  .mainDialog{
    position: relative;
    box-sizing: border-box;
    display: flex;
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    max-width: toRem(637);
    width: 100%;
    padding: toRem(132.7) toRem(64) toRem(0) toRem(64);
    box-shadow: 0px 10px 20px #00000029;
    max-width: 60.47%;
    overflow-y: auto;

    .closeDialog{
      position: absolute;
      top: toRem(23);
      left: toRem(17);
      img{
        cursor: pointer;
        width: toRem(17);
        height: toRem(17);
      }
    }

    .content{
      color:#3E3D3D;

      .checkbox {
        margin-bottom: 1rem;
        position: relative;
      
        input {
          display: none;
        }
      
        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: 1.5rem;
          right: 2.7rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }
      
        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(18);
          line-height: toRem(27);
          color:#000000;
          margin-inline-start: toRem(31);
        }
      
        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-31);
          top: toRem(2);
          border: .13rem solid #707070;
          border-radius: 50%;
        }
      
        input:checked + label::before {
          background-size: toRem(10);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tabProgress{
        padding-top: toRem(55);
        display: flex;
        gap: toRem(11);

        span{
          width: toRem(35);
          border: 3px solid;
          border-radius: toRem(3);
        }
        
      }
    }
  }

}

.dialogWrapper2{
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);
  
  .mainDialog{
    position: relative;
    box-sizing: border-box;
    display: flex;
    border: toRem(1) solid #E7B1AD;
    box-shadow: 0 toRem(10) toRem(20);
    border-radius: toRem(4);
    flex-direction: column;
    background-color: white;
    max-width: toRem(637);
    width: 100%;
    padding: toRem(16.4) toRem(19.1) toRem(15.7);
    box-shadow: 0px 10px 20px #00000029;
    max-width: toRem(1056.26);
    height: toRem(442.1);
    overflow-y: auto;

    .closeDialog{
      position: absolute;
      top: toRem(23);
      left: toRem(17);
      img{
        cursor: pointer;
        width: toRem(17);
        height: toRem(17);
      }
    }
  }
  .contentSecondDialog{
    border: toRem(2) solid #E7B1AD;
    border-radius: toRem(9);
    height: 100%;
  }
  .secondTitle{
    font-family: Heebo-Bold;
    color: #405F6A;
    font-size: toRem(38);
    text-align: center;
    margin: toRem(52) 0 toRem(40);
  }
  .secondContent{
    font-family: Heebo-Regular;
    color: #000000;
    font-size: toRem(24);
    text-align: center;
    margin-bottom: toRem(57);
  }
  .buttonsContent{
    display: flex;
    justify-content: center;
  }
  .leftBtn{
    font-family: Heebo-Regular;
    color: #FFFFFF;
    font-size: toRem(22);
    background-color: #405F6A;
    width: toRem(181);
    height: toRem(56);
    cursor: pointer;
    border: none;
    border-radius: toRem(4);
  }
  .rightBtn{
    font-family: Heebo-Regular;
    color: #405F6A;
    font-size: toRem(22);
    background-color: #FFFFFF;
    width: toRem(181);
    height: toRem(56);
    cursor: pointer;
    border: toRem(1) solid #405F6A;
    border-radius: toRem(4);
    margin-right: toRem(14);
  }
    .content{
      color:#3E3D3D;

      .checkbox {
        margin-bottom: 1rem;
        position: relative;
      
        input {
          display: none;
        }
      
        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: 1.5rem;
          right: 2.7rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }
      
        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(18);
          line-height: toRem(27);
          color:#000000;
          margin-inline-start: toRem(31);
        }
      
        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-31);
          top: toRem(2);
          border: .13rem solid #707070;
          border-radius: 50%;
        }
      
        input:checked + label::before {
          background-size: toRem(10);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tabProgress{
        padding-top: toRem(55);
        display: flex;
        gap: toRem(11);

        span{
          width: toRem(35);
          border: 3px solid;
          border-radius: toRem(3);
        }
        
      }
    }
}

@media (max-width: toRem(900)) {
  .dialogWrapper{
    justify-content: center;
    .mainDialog{
      margin-top: toRem(149.1);
      max-width: 89.86%;
      max-height: toRem(682);
      padding: toRem(20.7) toRem(16.5) toRem(0) toRem(16);
      box-shadow: 0px 10px 20px #00000029;
      overflow-y: auto;
    }  
    .closeDialog{
      position: relative !important;
      left: 0 !important;
      top: 0 !important; 
      bottom: 0 !important;
      img{
        cursor: pointer;
        width: toRem(12) !important;
        height: toRem(12) !important;
      }
    }
    .titleNotes{
      font-size: toRem(14);
      font-family: Heebo-Regular;
      margin-bottom: toRem(17.2);
    }
    .notes{
      width: calc(100% - toRem(16.5));
      height: toRem(414.91);
      font-size: toRem(16);
      padding-inline-start: toRem(12.5);
    }
    ::placeholder { 
      font-size: toRem(16);
    }
    .buttonFirst{
      width: toRem(217);
      height: toRem(54);
      font-size: toRem(14);
      margin-top: toRem(30);
    }
  }
}
@media (max-width: toRem(1100)) {
  .dialogWrapper2{
    .mainDialog{
      position: relative;
      border: none !important;
      box-shadow: none;
      max-width: toRem(637);
      width: 100%;
      padding: 0;
      width: 94.1%;
      height: toRem(361.11) !important;
      overflow-y: auto;
    }

    .contentSecondDialog{
      border: none;
      border-radius: toRem(9);
      height: 100%;
    }
    .secondTitle{
      font-family: Heebo-Bold;
      color: #405F6A;
      font-size: toRem(18);
      text-align: center;
      margin: toRem(40.8) 0 toRem(14);
    }
    .secondContent{
      font-family: Heebo-Regular;
      color: #242323;
      font-size: toRem(16);
      text-align: center;
      margin-bottom: toRem(29.4);
      margin-left: 10%;
      margin-right: 10%;
    }
    .buttonsContent{
      display: flex;
      justify-content: center;
    }
    .leftBtn{
      font-family: Heebo-Regular;
      color: #FFFFFF;
      font-size: toRem(22);
      background-color: #405F6A;
      width: toRem(181);
      height: toRem(56);
      cursor: pointer;
      border: none;
      border-radius: toRem(4);
    }
    .rightBtn{
      font-family: Heebo-Regular;
      color: #405F6A;
      font-size: toRem(22);
      background-color: #FFFFFF;
      width: toRem(181);
      height: toRem(56);
      cursor: pointer;
      border: toRem(1) solid #405F6A;
      border-radius: toRem(4);
      margin-right: toRem(14);
    }
      .content{
        color:#3E3D3D;
  
        .checkbox {
          margin-bottom: 1rem;
          position: relative;
        
          input {
            display: none;
          }
        
          .errorMessage {
            margin: 0;
            position: absolute;
            color: red;
            top: 1.5rem;
            right: 2.7rem;
            font-family: Heebo-regular;
            font-size: 0.65rem;
          }
        
          label {
            cursor: pointer;
            user-select: none;
            position: relative;
            font-family: Heebo-Regular;
            font-size: toRem(18);
            line-height: toRem(27);
            color:#000000;
            margin-inline-start: toRem(31);
          }
        
          label::before {
            content: "";
            display: inline-block;
            width: toRem(18);
            height: toRem(18);
            position: absolute;
            right: toRem(-31);
            top: toRem(2);
            border: .13rem solid #707070;
            border-radius: 50%;
          }
        
          input:checked + label::before {
            background-size: toRem(10);
            background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
            background-repeat: no-repeat;
            background-position: center center;
            background-origin: border-box;
          }
        }
  
        .tabProgress{
          padding-top: toRem(55);
          display: flex;
          gap: toRem(11);
  
          span{
            width: toRem(35);
            border: 3px solid;
            border-radius: toRem(3);
          }
          
        }
      }
  }
}
@media (max-width: toRem(700)) {
  .buttonsContent{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .dialogWrapper2{
  .leftBtn{
    font-family: Heebo-Regular;
    color: #FFFFFF;
    font-size: toRem(14);
    background-color: #405F6A;
    width: toRem(217);
    height: toRem(54);
    cursor: pointer;
    border: none;
    border-radius: toRem(4);
    margin-bottom: toRem(10.6);
  }
  .rightBtn{
    font-family: Heebo-Regular;
    color: #405F6A;
    font-size: toRem(14);
    background-color: #FFFFFF;
    width: toRem(217);
    height: toRem(54);
    cursor: pointer;
    border: toRem(1) solid #405F6A;
    border-radius: toRem(4);
    margin-right: 0 !important;
  }
  .secondContent{
    font-size: toRem(12);
    margin-left: 0;
    margin-right: 0;
  }
}
}

