@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.inpWithError{
  position: relative;
  display: flex;
  flex-direction: column;      
  .errorMessageLeft {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: toRem(40);
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
  .errorMessageRight {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: toRem(40);
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
  .errorMessageLeftSpec {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: toRem(135);
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
  .errorMessageRightSpec {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: toRem(70);
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}

.mainContainer{
  margin-bottom: 1.9375rem;
  max-width: calc(100vw - toRem(20) );

  input:disabled {
    color: #9B9999;
  }
  .mainContainer{
    max-width: 55.93rem;
    width: 100%;
  }
  
  .priceListTip {
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    text-align: center;
    color: #3E3D3D;
    margin: 0;
    margin-bottom: 3rem;
  
    span {
      font-family: Heebo-Medium;
    }
  }
  
  .gap {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 1.98rem;
  }
  
  .priceListContainer {
    box-sizing: border-box;
    padding: 2rem 2.19rem 1.69rem 2.09rem;
    background-color: rgba(238, 233, 229, .61);;
    border-radius: .13rem;
    width: 100%;
  
    .priceListHeader {
      border-bottom: solid .06rem #70707070;
      display: flex;
      justify-content: flex-start;
      font-family: Heebo-Regular;
      font-size: 1.38rem;
      line-height: 2rem;
      padding-bottom: .44rem;
      color: #3E3D3D;
  
      .firstCol {
        width: 44%;  
      }
    
      .secondCol {
        
        width: 24%;
      }
    
      .thirdCol {
        width: 32%;  
      }
    }
  
  
    .priceListGap {
      display: flex;
      justify-content: flex-start;
  
      .firstCol {
        width: 44%;  
      }
    
      .secondCol {
        width: 24%;
      }

      .secondColHead {
        
        display: flex;
        gap: toRem(19);
        width: 24%;

        span{
          margin-top: toRem(8);
          width: 50%;
          font-size: toRem(12);
        }
      }
    
      .thirdCol {
        width: 32%;
        font-size: .75rem;
        line-height: 1.13rem;
        margin-top: .5rem;
        margin-bottom: -.5rem;
        padding-inline-start: 1.5rem;
        box-sizing: border-box;
      }
  /*     .thirdCol {
        width: 32%;
        height: 3.56rem;
        display: flex;
        align-items: center;
        padding-inline-start: 1.5rem;
        box-sizing: border-box;
      } */
    }
  
    .priceListContent {
  
      .contentRow {
        display: flex;
        
        .firstCol {
          width: 44%;
          height: 3.56rem;
          font-family: Heebo-Regular;
          font-size: 1.38rem;
          line-height: 2rem;
          color: #3E3D3D;
          border-left: solid .06rem #70707070;;
          display: flex;
          align-items: center;
        }
      
        .secondCol {
          display: flex;
          gap: toRem(19);
          width: 24%;
          height: 3.56rem;
          display: flex;
          align-items: center;
  
          p {
            position: relative;
            margin: 0;
            width: 61.2%;
  
            img {
              position: absolute;
              top: 0.9rem;
              left: -0.9rem;
              opacity: 0.4;
            }
  
            span {
              position: absolute;
              color: #A4A2A2;
  
  
              top: 0.4rem;
              left: -1rem;
              font-family: Heebo-regular;
              font-size: 1.38rem;
              line-height: 2rem;
              
            }
  
            input {
              font-size: toRem(18);
              background-color: white;
              box-sizing: border-box;
              width: 100%;
              height: 2.63rem;
              margin-inline-start: 1.63rem;
              border: none;
              padding-bottom: 0;
              padding-inline-end: 2rem;
              padding-inline-start: .8rem;
              text-align: center;
    
              &:focus {
                outline: none;
    
              }
  
            }
          }
        }
      
        .thirdCol {
          width: 32%;
          height: 3.56rem;
          display: flex;
          align-items: center;
          padding-inline-start: 1.5rem;
          box-sizing: border-box;
          font-family: Heebo-regular;
          font-size: 1.13rem;
          line-height: 1.69rem;
          color: #3E3D3D;
        }
      }
  
  
  
    }

    .underline{
      display: flex;
      border-top: 1px solid rgba(20, 20, 20, .12);
      width: 100%;
      margin: 0 0 toRem(13.5) 0 ;
    }
    .moreFields{
      cursor: pointer;
      display: flex;
      font-size: toRem(16);
      line-height: toRem(24);
    }

    .contentRow {
      display: flex;
      // padding-bottom: toRem(13.5);
      
      .firstCol {
        width: 44%;
        height: toRem(42);
        font-family: Heebo-Regular;
        font-size: 1.38rem;
        line-height: 2rem;
        color: #3E3D3D;
        // border-left: solid .06rem #70707070;;
        display: flex;
        align-items: center;
      }
    
      .secondCol {
        width: 23.83%;
        height:  toRem(42);
        display: flex;
        gap: toRem(19);
        align-items: center;

        p {
          position: relative;
          margin: 0;
          width: 61.2%;

          img {
            position: absolute;
            top: 0.9rem;
            left: -0.9rem;
            opacity: 0.4;
          }

          span {
            position: absolute;
            color: #A4A2A2;


            top: 0.4rem;
            left: -1rem;
            font-family: Heebo-regular;
            font-size: 1.38rem;
            line-height: 2rem;
            
          }

          input {
            font-size: toRem(18);
            background-color: white;
            box-sizing: border-box;
            width: 100%;
            height:  toRem(42);
            margin-inline-start: 1.63rem;
            border: none;
            padding-bottom: 0;
            padding-inline-end: 2rem;
            padding-inline-start: 1.3rem;
            text-align: center;
  
            &:focus {
              outline: none;
  
            }

          }
        }
      }
    
      .thirdCol {
        width: 32%;
        height:  toRem(42);
        display: flex;
        align-items: center;
        padding-inline-start: 1.5rem;
        box-sizing: border-box;
        font-family: Heebo-regular;
        font-size: 1.13rem;
        line-height: 1.69rem;
        color: #3E3D3D;
      }
    }


    .unicRow{
      display: flex;
      padding-bottom: toRem(13.5);
      width: 100%;
      align-items: center;

      .inputs{
        display: flex;
        width: 67.83%;

        .firstInput{
          // margin-left: toRem(44);
          width: 65%;
          // max-width: toRem(384);
        }
        .secondInput{
          margin-right: toRem(58);
          width: 52.45%;

          // max-width: toRem(134);
        }
      }

      

      .saveUnic{
        cursor: pointer;
        font-family: "Heebo-Medium";
        padding-right: toRem(15);
        text-decoration: underline;
      }

      input {
        box-sizing: border-box;
        font-size: toRem(16);
        background-color: white;
        box-sizing: border-box;
        width: 100%;
        height: toRem(42);
        // margin-inline-start: 1.63rem;
        border: none;
        // padding-bottom: 0;
        // padding-inline-end: 2rem;
        padding-inline-start: toRem(17);
        // text-align: center;

        &:focus {
          outline: 1px solid #36606B;

        }

      }
    }
  }
  .radio {
    position: relative;
  
    input {
      display: none;
    }
  
    label {
      cursor: pointer;
      user-select: none;
      position: relative;
      font-family: Heebo-Regular;
      font-size: 1.25rem;
      line-height: 1.81rem;
      color: #3E3D3D;
      margin-inline-start: 3.2rem;
    }
  
    label::before {
      content: "";
      display: inline-block;
      width: 1.63rem;
      height: 1.63rem;
      position: absolute;
      right: -3.21rem;
      bottom: -.05rem;
      border: .13rem solid #707070;
      border-radius: 50%;
      background-color: #FFFFFF;
    }
  
    input:checked + label::before {
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-origin: border-box;
    }
  }

}





@media (max-width: toRem(960)) {

  .inpWithError{
    position: relative;
    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0rem;
      right: 0rem;
      font-family: Heebo-regular;
      font-size: 0.5rem;
    }
  }

  .mainContainer{

    display: none;
  }
  
  input:disabled {
    color: #9B9999;
  }

  .button {
    box-sizing: border-box;
    padding: toRem(12) toRem(24);
    font-family: Heebo-Regular;
    font-size: toRem(16);
    line-height: toRem(24);
    color: #FFFFFF;
    background-color: #405F6A;
    border: none;
    box-shadow: 0 1.25rem 2.5rem #221B4A24;
    border-radius: 0.31rem;
    cursor: pointer;
  }


  .firstMobile{
    
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
      font-size: toRem(14);
      line-height: toRem(21);
      padding-bottom: toRem(19);
    }

    .categorySelect{
      font-size: toRem(16);
      height: toRem(42);
      min-width: toRem(155);
      width: 100%;
      max-width: 41.3%;
      padding-bottom: toRem(16);
    }

    .eventSelect{
      font-size: toRem(16);
      height: toRem(42);
      min-width: toRem(155);
      width: 100%;
      max-width: 41.3%;
      padding-bottom: toRem(31);
    }

    .btn{
      display: flex;
      justify-content: center;
      width: 100%;
      
    }

    .second{
      box-sizing: border-box;
      padding: toRem(27);
      z-index: 20;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: toRem(-334);
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      background-color: rgba(64, 95, 106, 0.9);

      .formContainer{
        box-sizing: border-box;
        padding: toRem(12.5) toRem(12) toRem(24) toRem(12);
        
        overflow-y: auto;
        // overflow-x: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100%;
        background-color: white;
        border-radius: toRem(5);

        .close{
          cursor: pointer;
          display: flex;
          width: 100%;
          justify-content: flex-end;

          img{
            height: toRem(12);
            width: toRem(12);
          }
        }

        .title{
          padding-top: toRem(39);
          padding-bottom: toRem(28);
          display: flex;
          font-size: toRem(12);
          line-height: toRem(21);
        }

        .withTax{
          display: flex;
          width: 100%;
          justify-content: flex-end;
          line-height: toRem(18);
          font-size: toRem(12);
        }

        .main{
          display: flex;
          flex-direction: column;
          align-items: center;

          .radio {
            position: relative;
          
            input {
              display: none;
            }
          
            label {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-family: Heebo-Regular;
              font-size:toRem(14);
              line-height: toRem(21);
              color: #3E3D3D;
              margin-inline-start: toRem(30);
            }
          
            label::before {
              box-sizing: border-box;
              content: "";
              display: inline-block;
              width: toRem(22);
              height: toRem(22);
              position: absolute;
              right: toRem(-30);
              bottom: -.05rem;
              border: .13rem solid #707070;
              border-radius: 50%;
              background-color: #FFFFFF;
            }
          
            input:checked + label::before {
              background-size: toRem(10);
              background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
              background-repeat: no-repeat;
              background-position: center center;
              background-origin: border-box;
            }
          }


          .priceListContent {
            width: 100%;
            .contentRow {
              height: toRem(30);
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-bottom: toRem(12);

              
              .firstCol {
                // width: 44%;
                height: 100%;
                font-family: Heebo-Regular;
                font-size: 1.38rem;
                line-height: 2rem;
                color: #3E3D3D;
                display: flex;
                align-items: center;
              }
            
              .secondCol {
                
                width: toRem(100);
                height: 100%;
                display: flex;
                align-items: center;
        
                p {
                  position: relative;
                  margin: 0;
                  width: 61.2%;
        
                  img {
                    position: absolute;
                    top: 0.5rem;
                    left: -1.8rem;
                    opacity: 0.4;
                  }
        
                  span {
                    position: absolute;
                    color: #A4A2A2;
        
        
                    top: 0rem;
                    left: -1.8rem;
                    font-family: Heebo-regular;
                    font-size: 1.38rem;
                    line-height: 2rem;
                    
                  }
        
                  input {
                    background-color: #EEE9E5;
                    // box-sizing: border-box;
                    width: 100%;
                    height: toRem(30);
                    // margin-inline-start: 1.63rem;
                    border: none;
                    padding-bottom: 0;
                    padding-inline-end: 2.2rem;
                    padding-inline-start: .3rem;
                    text-align: center;
          
                    &:focus {
                      outline: none;
          
                    }
        
                  }
                }
              }
            
              // .thirdCol {
              //   width: 32%;
              //   height: 3.56rem;
              //   display: flex;
              //   align-items: center;
              //   padding-inline-start: 1.5rem;
              //   box-sizing: border-box;
              //   font-family: Heebo-regular;
              //   font-size: 1.13rem;
              //   line-height: 1.69rem;
              //   color: #3E3D3D;
              // }
            }
        
        
        
          }
        }

        .contentRow {
          height: toRem(30);
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: toRem(12);

          
          .firstCol {
            // width: 44%;
            height: 100%;
            font-family: Heebo-Regular;
            font-size: 1.38rem;
            line-height: 2rem;
            color: #3E3D3D;
            display: flex;
            align-items: center;
          }
        
          .secondCol {
            width: toRem(100);
            height: 100%;
            display: flex;
            align-items: center;
    
            p {
              position: relative;
              margin: 0;
              width: 61.2%;
    
              img {
                position: absolute;
                top: 0.5rem;
                left: -1.8rem;
                opacity: 0.4;
              }
    
              span {
                position: absolute;
                color: #A4A2A2;
    
    
                top: 0rem;
                left: -1.8rem;
                font-family: Heebo-regular;
                font-size: 1.38rem;
                line-height: 2rem;
                
              }
    
              input {
                background-color: #EEE9E5;
                // box-sizing: border-box;
                width: 100%;
                height: toRem(30);
                // margin-inline-start: 1.63rem;
                border: none;
                font-size: toRem(12);
                padding-bottom: 0;
                padding-inline-end: 2.2rem;
                padding-inline-start: .3rem;
                text-align: center;
      
                &:focus {
                  outline: none;
      
                }
    
              }
            }
          }

          .radio {
            position: relative;
          
            input {
              display: none;
            }
          
            label {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-family: Heebo-Regular;
              font-size:toRem(14);
              line-height: toRem(21);
              color: #3E3D3D;
              margin-inline-start: toRem(30);
            }
          
            label::before {
              box-sizing: border-box;
              content: "";
              display: inline-block;
              width: toRem(22);
              height: toRem(22);
              position: absolute;
              right: toRem(-30);
              bottom: -.05rem;
              border: .13rem solid #707070;
              border-radius: 50%;
              background-color: #FFFFFF;
            }
          
            input:checked + label::before {
              background-size: toRem(10);
              background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
              background-repeat: no-repeat;
              background-position: center center;
              background-origin: border-box;
            }
          }
        
          // .thirdCol {
          //   width: 32%;
          //   height: 3.56rem;
          //   display: flex;
          //   align-items: center;
          //   padding-inline-start: 1.5rem;
          //   box-sizing: border-box;
          //   font-family: Heebo-regular;
          //   font-size: 1.13rem;
          //   line-height: 1.69rem;
          //   color: #3E3D3D;
          // }
        }

        .underline{
          display: flex;
          border-top: 1px solid rgba(20, 20, 20, .12);
          width: 100%;
          margin: 0 0  toRem(10) 0 ;
        }
        .moreFields{
          padding-top: toRem(11);
          cursor: pointer;
          display: flex;
          font-size: toRem(16);
          line-height: toRem(24);
          padding-bottom: toRem(6);
        }
    
        .unicRow{
          display: flex;
          padding-bottom: toRem(18.5);
          align-items: flex-start;
          flex-direction: column;
    
          .inputs{
            display: flex;
            width: 100%;
            justify-content: space-between;
    
            .firstInput{
              margin-left: toRem(14);
              max-width: toRem(197);
              width: 100%
            }
            .secondInput{
              width: toRem(100);
            }
          }
    
          
    
          .saveUnic{
            cursor: pointer;
            font-size: toRem(14);
            line-height: toRem(21);
            padding-top: toRem(3);
            font-family: "Heebo-Medium";
            padding-right: toRem(0);
            text-decoration: underline;
          }
    
          input {
            box-sizing: border-box;
            font-size: toRem(12);
            background-color: #EFE9E4;
            box-sizing: border-box;
            width: 100%;
            height: toRem(30);
            // margin-inline-start: 1.63rem;
            border: none;
            // padding-bottom: 0;
            // padding-inline-end: 2rem;
            padding-inline-start: toRem(10);
            // text-align: center;
    
            &:focus {
              outline: 1px solid #36606B;
    
            }
    
          }
        }

        .dialogBtn{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding-top: toRem(28);
        }


      }
    }


  }

  
  
  
}

@media (min-width: toRem(961)) {
  .firstMobile{
    display: none;
  }
  
}
