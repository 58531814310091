.cards_container {
  padding: 0px 10px 68px;
  position: relative;
}
.size_venues {
  text-align: center;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 80px;
}
.size_venues span {
  font-weight: 600;
}
.fourth {
  display: flex;
  // padding: 0.25rem 1.25rem 0 2.5rem;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 370px;
  column-gap: 31px;
  row-gap: 37px;
  .fourthSlideItem {
    // cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // 21.0625
    // 2.75
    // height: 27.4375rem;
    // height: 23.8125rem;
    // height: toRem(381);
    // box-sizing: border-box;
    // width: 35rem;
    // width: 33.33%;
    max-width: 560px;
    flex: 1 0 25%;
    min-width: 24rem;
    height: 100%;
    // padding-top: 2.75rem;
    // margin-bottom: 37px;
  }
}

@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.cardWrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .heart {
    cursor: pointer;
    position: absolute;
    top: 1.275rem;
    right: 1rem;
    z-index: 4;
    width: 2.125rem;
    height: 2.125rem;
  }

  .category {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 8.104rem;
    height: 3.5531rem;
    background-color: #eee9e5;
    font-size: 0.9375rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flashLabel {
    width: 6.125rem;
    height: 6.125rem;
    position: absolute;
    bottom: 3.625rem;
    left: 1.5rem;
    z-index: 3;
    font-size: 1.625rem;
    line-height: 1.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Heebo-Medium";
    background-color: #e7b1ad;
    color: white;

    box-shadow: 0 toRem(3) toRem(6) rgba(#000000, 0.16);
  }

  .image {
    cursor: pointer;
    width: 100%;
    max-width: 855px;
    padding-bottom: 59.82%;
    position: relative;
    margin-bottom: 16px;

    .slideImage {
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      object-fit: cover;
      user-select: none;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    width: 100%;
    // height: 2.9375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      cursor: pointer;
      font-size: 1.375rem;
      font-family: Heebo-Bold;
      line-height: 1;
      // white-space: nowrap;
      // overflow: hidden;
      // margin-left: 10px;
      // direction: ltr;
    }
    .rate_container {
      display: flex;
      .rating {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        margin-left: 15px;
      }

      .count {
        min-width: 5.8125rem;
        font-size: 1.125em;
        color: #707070;
      }
    }
  }

  .descriptionForVenue {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 1rem;
    line-height: 1rem;
    color: #434242;
  }
}

@media (max-width: 60rem) {
  .size_venues {
    font-size: 16px;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  // .cards_container {
  //   padding-top: 50px;
  // }

  .fourth {
    column-gap: 10px;
    row-gap: 15px;
    .fourthSlideItem {
      // max-width: 250px;
      min-width: 170px;
    }
  }
  .cardWrapper {
    .flashLabel {
      width: toRem(44);
      height: toRem(44);
      bottom: toRem(28);
      left: toRem(16);
      font-size: toRem(11);
      line-height: toRem(10);
    }

    .heart {
      top: toRem(13);
      right: toRem(11);
      z-index: 4;
      // width: toRem(24);
      // height: toRem(22);
      width: 21px;
      height: 21px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .image {
      height: 100px;
      padding-bottom: 20%;
      margin-bottom: 5px;
    }

    .description {
      width: 100%;
      // padding-top: toRem(8);
      // height: 2.125rem;

      .name {
        cursor: pointer;
        font-size: 14px;
        line-height: 21px;
        font-family: Heebo-Bold;
      }
      .rate_container {
        display: none;
        // .rating {
        //   display: flex;
        //   align-items: center;
        //   font-size: 0.55rem;
        //   margin-left: 0.625rem;
        // }

        // .count {
        //   min-width: 3.625rem;
        //   // font-size: 0.6875em;
        //   color: #707070;
        // }
      }
    }

    .descriptionForVenue {
      line-height: 1.125rem;
      font-size: 1.125rem;
    }
  }
}
