$rem: 16;
input {
  font-family: Heebo-Regular;
  font-size: 16px;
}

.wr {
  padding-bottom: calc(98rem / $rem);
}
.input {
  input::placeholder {
    color: #9b9999;
  }
  ::placeholder {
    color: #9b9999;
  }

  :-ms-input-placeholder {
    color: #9b9999;
  }

  ::-ms-input-placeholder {
    color: #9b9999;
  }
}
.watsApp {
  z-index: 999;
  box-sizing: border-box;
  width: 14.75rem;
  height: 6rem;
  background-color: #1ee62c;
  color: #ffffff;
  border-radius: 1.375rem;
  display: flex;
  padding: 1rem 1.125rem;
  position: fixed;
  bottom: 2.5625rem;
  right: 6rem;

  .watsAppIcon {
    width: 3.75rem;
    height: 3.75rem;
  }

  .watsAppText {
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.closeIcon {
  position: absolute;
  cursor: pointer;
}
.closeIconMobile {
  display: none;
}

.closeIconDesktop {
  top: calc(25rem / $rem);
  left: calc(23.45rem / $rem);
}
.infoDialog {
  background-color: rgba(64, 95, 106, 0.9);
}
.dialogTitle {
  text-align: left;
  font-size: calc(28rem / $rem);
  color: #242323;
  font-family: Heebo-Bold;
  max-width: calc(197rem / $rem);
  margin: calc(59rem / $rem) calc(480rem / $rem) calc(37rem / $rem) 0;
}
.dialogDescription {
  color: #242323;
  text-align: center;
  font-size: calc(24rem / $rem);
  max-width: calc(416rem / $rem);
  margin: calc(0rem / $rem) calc(366rem / $rem) calc(71rem / $rem)
    calc(121rem / $rem);
}
.dialog {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px calc(10rem / $rem) calc(20rem / $rem) #00000029;
}
input.invalidField {
  outline: 0.06rem solid red;
}
textarea.invalidField {
  outline: 0.06rem solid red;
}
.errorMessage {
  margin: 0;
  position: absolute;
  color: red;
  bottom: -1rem;
  right: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.errorMessageBlock {
  margin: 0;
  position: absolute;
  color: red;
  bottom: -0.5rem;
  right: 0.31rem;
  font-family: Heebo-Regular;
  font-size: 0.65rem;
}
.mobleTitle {
  display: none;
}
span {
  position: relative;
}
.mobleFormTitle {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: calc(20rem / $rem) calc(15rem / $rem) 0;
    color: #242323;
    font-size: calc(24rem / $rem);
    font-family: Heebo-Bold;
}
.description {
  text-align: center;
  direction: rtl;
  color: #242323;
  font-size: calc(24rem / $rem);
  // display: flex;
  margin: calc(133rem / $rem) auto calc(12rem / $rem);
  max-width: calc(986rem / $rem);
  a {
    cursor: pointer;
    text-decoration: underline;
    display: inline-flex;
  }
}

.contactsBlock {
  margin: 0 auto 0 // calc(98rem/$rem)
;
  background: #eee9e5 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  max-width: calc(986rem / $rem);
}
.firstBlock {
  display: flex;
  margin: calc(55rem / $rem) calc(98rem / $rem) calc(24rem / $rem);
  .inputPhone {
    margin-right: 2rem;
  }
  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: calc(5rem / $rem);
    height: calc(73rem / $rem);
    width: calc(379rem / $rem);
    font-size: 1rem;
    color: #242323;
    box-sizing: border-box;
    padding-inline-start: calc(28rem / $rem);
  }
  input:focus {
    outline: calc(2rem / $rem) solid #405f6a;
  }
}
.secondBlock {
  display: flex;
  margin: 0 calc(98rem / $rem) calc(24rem / $rem);
  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: calc(5rem / $rem);
    height: calc(73rem / $rem);
    width: calc(790rem / $rem);
    font-size: 1rem;
    color: #242323;
    box-sizing: border-box;
    padding-inline-start: calc(28rem / $rem);
  }
  input:focus {
    outline: calc(2rem / $rem) solid #405f6a;
  }
}
.thirdBlock {
  display: flex;
  margin: 0 calc(98rem / $rem) calc(38rem / $rem);
  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    box-sizing: border-box;
    border-radius: calc(5rem / $rem);
    height: calc(188rem / $rem);
    width: calc(790rem / $rem);
    font-family: Heebo-Regular !important;
    font-size: 1rem;
    color: #242323;
    resize: none;
    padding-inline-start: calc(28rem / $rem);
  }
  textarea::placeholder {
    padding-top: calc(25rem / $rem);
    font-size: calc(16em / $rem);
    letter-spacing: 0px;
    color: #9b9999;
  }
  textarea:focus {
    outline: calc(2rem / $rem) solid #405f6a;
  }
}
.btnBlock {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0rem 0 calc(46rem / $rem) 0;
}
.button {
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo-Regular;
  min-height: 3.375rem;
  max-height: calc(56em / $rem);
  border-radius: 0.3125rem;
  background: #405f6a 0% 0% no-repeat padding-box;
  box-shadow: 0rem 1.25rem 2.5rem #221b4a21;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
}
@media (max-width: calc(1900rem/$rem)) {
  .dialogDescription {
    margin-right: auto;
    margin-left: auto;
    max-width: 90%;
    text-align: center;
  }
  .dialogTitle {
    max-width: 100%;
    text-align: center;
    margin: calc(59rem / $rem) 0 calc(37rem / $rem) 0;
  }
}
@media (max-width: calc(1100rem/$rem)) {
  .closeIconDesktop {
    scale: 65%;
  }
  .firstBlock {
    margin: calc(45rem / $rem) calc(60rem / $rem) calc(24rem / $rem);
    input {
      width: 100%;
    }
    span {
      width: calc(340rem / $rem);
    }
  }
  .secondBlock {
    margin: 0 calc(60rem / $rem) calc(24rem / $rem);
    input {
      width: 100%;
    }
    span {
      width: calc(712rem / $rem);
    }
  }
  .thirdBlock {
    margin: 0 calc(60rem / $rem) calc(38rem / $rem);
    textarea {
      width: 100%;
    }
    span {
      width: calc(712rem / $rem);
    }
  }
  .contactsBlock {
    max-width: calc(680rem / $rem);
  }
  .description {
    margin: calc(133rem / $rem) auto calc(12rem / $rem);
    max-width: calc(680rem / $rem);
  }
  .dialogTitle {
    font-size: calc(24rem / $rem);
  }
  .dialogDescription {
    font-size: calc(20rem / $rem);
  }
}

@media (min-width: 53.8125rem) {
  .mobile {
    display: none;
  }
}

@media (max-width: 53.75rem) {
  .desctop {
    display: none;
  }
  .closeIconMobile {
    display: block;
    top: 0.78rem;
    left: 2.09rem;
    scale: 52%;
  }

  .watsApp {
    width: 10.375rem;
    height: 4.18rem;
    padding: 0.75rem 0.8125rem;

    .watsAppIcon {
      img {
        width: 2.68rem;
        height: 2.68rem;
      }
    }

    .watsAppText {
      font-size: 0.875rem;
    }
  }
  .watsApp {
    right: 1.125rem;
    bottom: 3.5rem;
  }
}
@media (max-width: calc(750rem/$rem)) {
  .firstBlock {
    .inputPhone {
      margin-right: 0rem;
      margin-top: calc(24rem / $rem);
    }
    span {
      width: 100%;
    }
    flex-direction: column;
    margin: calc(45rem / $rem) calc(60rem / $rem) calc(24rem / $rem);
    input {
      width: 100%;
    }
    input + input {
      margin-right: 0rem;
      margin-top: calc(24rem / $rem);
    }
  }
  .secondBlock {
    margin: 0 calc(60rem / $rem) calc(24rem / $rem);
    input {
      width: 100%;
    }
  }
  .thirdBlock {
    margin: 0 calc(60rem / $rem) calc(38rem / $rem);
    textarea {
      width: 100%;
    }
  }
  .contactsBlock {
    max-width: 85%;
  }
  .description {
    max-width: 85%;
  }
}

@media (max-width: calc(420rem/$rem)) {
  .wr {
    padding-bottom: calc(95rem / $rem);
  }
  .firstBlock {
    flex-direction: column;
    margin: 0 calc(22rem / $rem) calc(15rem / $rem) calc(21rem / $rem);
    input {
      width: 100%;
    }
    .inputPhone {
      margin-right: 0rem;
      margin-top: calc(15rem / $rem);
    }
    input:focus {
      outline: calc(2rem / $rem) solid #405f6a;
    }
  }
  .secondBlock {
    margin: 0 calc(22rem / $rem) calc(22rem / $rem) calc(21rem / $rem);
    input {
      width: 100%;
    }
  }
  .thirdBlock {
    margin: 0 calc(21rem / $rem) calc(30rem / $rem);
    textarea {
      width: 100%;
      height: calc(261rem / $rem);
    }
    //
  }

  .contactsBlock {
    margin: 0 calc(16rem / $rem) 0 // calc(95rem/$rem)
;
    max-width: 100%;
  }
  .description {
    margin: 0 calc(16rem / $rem) calc(44rem / $rem) calc(17rem / $rem);
    max-width: 100%;
    font-size: calc(18rem / $rem);
    text-align: right;
    letter-spacing: calc(0.14rem / $rem);
  }
  .mobleTitle {
    display: block;
    margin: calc(20rem / $rem) calc(18rem / $rem) calc(22rem / $rem) 0;
    color: #242323;
    text-align: right;
    font-family: Heebo-Bold;
    font-size: calc(24rem / $rem);
  }
  .mobleFormTitle {
    direction: rtl;
    display: block;
    margin: calc(23rem / $rem) calc(22rem / $rem) calc(47rem / $rem)
      calc(107rem / $rem);
    text-align: right;
    color: #242323;
    font-size: calc(20rem / $rem);
    font-family: Heebo-Medium;
    padding: 0;
  }
  .btnBlock {
    padding: 0rem calc(130rem / $rem) calc(32rem / $rem) calc(131rem / $rem);
  }
  .button {
    font-size: calc(18rem / $rem);
  }
  .dialogTitle {
    font-size: calc(20rem / $rem);
    min-width: calc(140rem / $rem);
    text-align: center;
    margin: calc(81rem / $rem) calc(32rem / $rem) calc(33rem / $rem)
      calc(32rem / $rem);
  }
  .dialogDescription {
    font-size: calc(16rem / $rem);
    min-width: calc(248rem / $rem);
    margin: calc(0rem / $rem) calc(32rem / $rem) calc(81rem / $rem)
      calc(31rem / $rem);
  }
}

@media (max-width: calc(380rem/$rem)) {
  .dialogTitle {
    margin: calc(81rem / $rem) calc(79rem / $rem) calc(33rem / $rem)
      calc(80rem / $rem);
  }
}
@media (max-width: calc(350rem/$rem)) {
  .dialogTitle {
    text-align: center;
    margin: calc(81rem / $rem) calc(20rem / $rem) calc(33rem / $rem)
      calc(20rem / $rem);
  }
}
