.root {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    min-height: 6.25rem;
    padding: 0.25rem;
    position: relative;
    padding-right: 0;
}

.root .textDisplay {
    font-family: Heebo-Regular;
    font-size: 1.5rem;
    line-height: 2rem;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}

.root .btnToggle {
    font-family: Heebo-Regular;
    cursor: pointer;
    color:#242323;
    margin-top: 0.2rem;
    background: transparent;
    text-decoration: underline;
    border: 0;
    font-size: 1.5rem;
    line-height: 1.9rem;
}

.root .textDisplay.collapsed {
    animation: mask-collapsing 0.5s;
    mask-image: linear-gradient(black 85%, transparent);
}

.root .textDisplay.expanded {
    animation: mask-expanding 0.5s;
    mask-image: linear-gradient(black 100%, transparent);
}

@keyframes mask-collapsing {
    from { mask-image: linear-gradient(black 100%, transparent); }
    to { mask-image: linear-gradient(black 85%, transparent); }
}

@keyframes mask-expanding {
    from { mask-image: linear-gradient(black 85%, transparent); }
    to { mask-image: linear-gradient(black 100%, transparent); }
}

.showMoreContainer {
    position: absolute;
    background-color: white;
    left: 0;
    top: 2rem;
}

.noDispaly {
    display: none;
}

@media (max-width: 80rem) {
    .root {
        margin-bottom: 1.88rem;
    }
}

@media (max-width: 60rem) {
    .root .textDisplay {
        color: #707070;
    }

    .root .btnToggle {
        color:#707070;
    }
}

@media (max-width: 40rem) {
    .root {
        margin-bottom: 1.25rem;
    }

    .root .textDisplay {
        font-family: Assistant-Regular;
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .root .btnToggle {
        font-family: Assistant-Regular;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0;
    }

    .showMoreContainer {
        position: initial;
        background-color: none;
    }
}
