.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.react-aria-DatePicker,
.react-aria-DateRangePicker {
  color: var(--text-color);
  display: flex;
  align-items: center;
  .react-aria-Group {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;

    &[data-pressed] {
      box-shadow: none;
      /* background: var(--highlight-background); */
    }

    &[data-focus-within] {
      outline-offset: -1px;
    }
    .react-aria-Button {
      display: flex;
      background: inherit;
      border: none;
      margin-left: auto;
      padding: 0;
      box-sizing: content-box;
      flex-shrink: 0;
      position: sticky;
      right: 0;
      cursor: pointer;
      img {
        max-width: 18px;
        max-height: 18px;
        width: 100%;
        height: 100%;
      }
      &.input-button {
        cursor: default;
        position: absolute;
        right: 20px;
        bottom: 50%;
        transform: translate(0, 50%);
        z-index: 99;
      }
      &:hover:not(.react-aria-Button.input-button) img {
        transform: scale(1.2);
      }
    }
    .react-aria-Button:focus-visible {
      outline: none;
    }
  }

  [slot='start'] + span {
    padding: 0 4px;
  }

  [slot='end'] {
    margin-right: 2rem;
    flex: 1;
  }

  .react-aria-DateInput {
    width: unset;
    min-width: unset;
    padding: unset;
    border: unset;
    outline: unset;
  }
}

.react-aria-Popover[data-trigger='DatePicker'],
.react-aria-Popover[data-trigger='DateRangePicker'] {
  padding: 11px 17px 11px 8.5px;
  width: 100%;
  max-width: 349px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border: 1px solid #707070;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  .datePicker_title {
    font-size: 20px;
    /* margin-top: 11px; */
    line-height: 26px;
    opacity: 0.3;
    font-family: 'Assistant-Regular';
    letter-spacing: 0.26px;
  }
  .react-aria-Calendar,
  .react-aria-RangeCalendar {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    header {
      display: flex;
      margin-top: 15.5px;
      flex-direction: row-reverse;
      justify-content: center;
      button {
        border: none;
        background-color: inherit;
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0px 8px;
        img {
          max-width: 11px;
          width: 100%;
          height: 100%;
          max-height: 11px;
        }
      }
      h2 {
        min-width: 110px;
        text-align: center;
        font-size: 18px;
        /* margin-top: 11px; */
        line-height: 23px;
        font-family: 'Assistant-SemiBold';
        letter-spacing: 0.23px;
      }
    }
  }
}
.react-aria-Popover,
.react-aria-Dialog:focus-visible,
.react-aria-Calendar:focus-visible,
.react-aria-RangeCalendar:focus-visible {
  outline: unset;
}
.react-aria-Popover[data-trigger='DateRangePicker'] {
  .react-aria-CalendarGridBody {
    .react-aria-CalendarCell {
      max-width: none;
    }
    .react-aria-CalendarCell[data-selected='true'] {
      background-color: #dfb2b4;
      background-color: rgba($color: #dfb2b4, $alpha: 0.5);
    }
    .react-aria-CalendarCell[data-selection-start='true'] {
      background-color: #dfb2b4;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    .react-aria-CalendarCell[data-selection-end='true'] {
      background-color: #dfb2b4;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}

.react-aria-Dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-aria-CalendarGrid {
  margin-top: 13px;
  border-spacing: 0;
  border-collapse: collapse;
}

.react-aria-CalendarGridHeader tr,
.react-aria-CalendarGridHeader th,
.react-aria-CalendarGridBody tr,
.react-aria-CalendarGridBody tr td {
  padding: 0px;
  margin: 0px;
}

.react-aria-CalendarHeaderCell {
  font-size: 16px;
  font-family: 'Assistant-SemiBold';
  letter-spacing: 0.21px;
  line-height: 21px;
}
.react-aria-CalendarCell {
  max-width: 34px;
  width: 100%;
  margin: 0 auto;
  height: 34px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.21px;
  font-family: 'Assistant-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.react-aria-Popover[data-trigger='DatePicker'] {
  .react-aria-CalendarCell:not([data-disabled='true']):hover,
  .react-aria-CalendarCell[data-selected='true'] {
    background-color: #dfb2b4;
    border-radius: 2px;
  }
}

.react-aria-CalendarCell:focus-visible {
  outline: none;
}
.react-aria-CalendarCell[data-disabled='true'] {
  opacity: 0.5;
  cursor: default;
}
.react-aria-CalendarCell[data-disabled='true']:hover {
  opacity: 0.5;
  background-color: unset;
  border-radius: unset;
}

.react-aria-Calendar .react-aria-Button,
.react-aria-RangeCalendar .react-aria-Button {
  cursor: pointer;
}
.react-aria-Calendar .react-aria-Button[data-disabled='true'],
.react-aria-RangeCalendar .react-aria-Button[data-disabled='true'] {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 720px) {
  .react-aria-Popover[data-trigger='DatePicker'] .react-aria-Calendar .react-aria-Button,
  .react-aria-Popover[data-trigger='DateRangePicker'] .react-aria-RangeCalendar .react-aria-Button {
    width: 25px;
    justify-content: center;
  }
  .react-aria-Popover[data-trigger='DatePicker'],
  .react-aria-Popover[data-trigger='DateRangePicker'] {
    overflow: scroll;
  }
}
