@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.tabs{
  display: flex;
  height: toRem(75);
  padding-top: toRem(90);
  
  .tab{
    cursor: pointer;
    border-top-left-radius: toRem(5);
      border-top-right-radius: toRem(5);
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(265);
    font-size: toRem(27);
    line-height: toRem(41);
    font-family: "Heebo-Medium";

  }
}

.addFeedbackDialog{
  box-sizing: border-box;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  background: opacify($color: #000000, $amount: 1);
  background-color: rgba(64, 95, 106, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: toRem(33);
  padding-top: toRem(145);
  @media (min-width: toRem(961)) {
      justify-content: center;
      padding-top: toRem(0);
  }

  

  .feedbackFrom{
    border-radius: toRem(5);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    max-width: toRem(1047);
    width: 100%;
    padding: toRem(59) toRem(19) toRem(42);

    .buttons {
      padding-top: toRem(31);
      padding-bottom: toRem(0);
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: toRem(15);
  
      .save {
        font-family: "Heebo-Regular";
        border: none;
        padding: toRem(18) toRem(27);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(18);
        line-height: toRem(27);
      }
    }

    .inputs{
      display: flex;
      gap: toRem(10);
      padding-bottom: toRem(15);
      
    }

    .invalidField1 {
      // background-color: #f3e8e8 !important;
      outline: 0.06rem solid red;
    }

    .inputContainer {
      position: relative;
      border-radius: toRem(5);
      box-sizing: border-box;
      width: toRem(311);
      height: toRem(73);
      background-color: #F7F4F4;
      gap: toRem(20);

      span{
        padding-right: toRem(28);
      }

      .invalidField {
        background-color: #f3e8e8;
        outline: 0.06rem solid red;
      }

      input {
        background-color:#F7F4F4;
        border: none;
        border-radius: 0.31rem;
        height: 100%;
        margin-bottom: 0.94rem;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.75rem;
        padding-inline-end: 2.5rem;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .textAreaContainer {
      max-width: toRem(633);

      width: 100%;
      position: relative;
      .invalidField {
        background-color: #f3e8e8;
        outline: 0.06rem solid red;
      }
      textarea {
        box-sizing: border-box;
        background-color: #F7F4F4;
        border: none;
        border-radius: 0.31rem;
        width: 100%;
        height: toRem(239);
        padding: 1.56rem 1.75rem;
        resize: none;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        // color: #9b9999;

        
      }
      textarea::placeholder{
        color: #9B9999;
      }

    

      textarea:focus {
        outline: 0.13rem solid #405f6a;
      }
    }

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0.007rem;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    .formTitle{
      display: flex;
      font-size: toRem(28);
      line-height: toRem(41);
      
      gap: toRem(4);
      padding-bottom: toRem(38);

      span{
        font-family: "Heebo-Bold";
      }
    }
  }

  .closeDialog{
    display: flex;
    max-width: toRem(1047);
    width: 100%;
    padding-bottom: toRem(22);

    img{
      cursor: pointer;
      width: toRem(25);
      height: toRem(25);
    }
  }
}

.swiperAndSearchFieldContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.63rem 5.63rem 0.63rem 5.63rem;
  margin-top: 3.75rem;
}

.categoryNameFrame {
  box-sizing: border-box;
  width: fit-content;
  padding: 0.75rem 1.5rem;
  background-color: #EEE9E5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo-Bold;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  margin-inline-start: 5.63rem;
  margin-inline-end: 5.63rem;
  color: #434242;
  user-select: none;
}

.supplierBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 2.19rem;
  padding: 0rem 5.63rem 0rem 5.63rem;
}

.tablePart {
  padding-inline-end: 1.25rem;
  width: 56%;
  box-sizing: border-box;
}

.beforeFooter{
  min-height: 9.38rem;
  padding: toRem(95) toRem(93) toRem(150);

  

  .feedbackWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedbackTitle{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(10);
      padding-bottom: toRem(113);
      .fTitle{

        font-size: toRem(40);
        font-family: "Heebo-Bold";
        line-height: toRem(59);
      }

      .fRate{
        font-size: toRem(20);
        display: flex;
        align-items: center;
        gap: toRem(21);

        span{
          line-height: toRem(34);
          font-size: toRem(20);
        }
      }
    }

    .allFeedback{
      display: flex;
      gap: toRem(50);
      flex-direction: column;
      width: 100%;

      .fContainer{
        background-color: white;
        padding: toRem(35) toRem(41) toRem(88);
        box-shadow: 0 toRem(20) toRem(40) rgba(0, 0, 0, .08);

        .fContainerTitle{
          padding: toRem(0) toRem(0) toRem(32);
          display: flex;
          gap: toRem(25);

          span{
            display: flex;
            align-items: flex-end;
            font-size: toRem(20);
            line-height: toRem(22);
            font-family: "Heebo-Bold";
          }
        }

        .fContainerDescription{
          color: #707070;
          min-height: toRem(28);
          max-height: toRem(290);
          overflow: hidden;
          font-size: toRem(20);
          line-height: toRem(27);
        }
      }
    }

    .linkAddFeedback{
      cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      font-size: toRem(24);
      line-height: toRem(35);
      font-family: "Heebo-Medium";
      text-decoration: underline;
      padding-top: toRem(37);
    }
  }
}

.supplierImageWithLinkContainer {
  width: 40%;
}
.desctop{
  display: block;
}
@media (max-width: 80rem) {
  .supplierBlock {
    flex-direction: column-reverse;
    justify-content: normal;
    margin-top: 2.5rem;
  }

  .supplierImageWithLinkContainer {
    width: 100%;
  }

  .tablePart {
    margin-top: 1.25rem;
    width: 100%;
  }
}

@media (min-width: toRem(961)) {
  .mobile{
    display: none;
  }
}



@media (max-width: 60rem) {

  .tabs{
    display: flex;
    height: toRem(47);
    padding-top: toRem(57);
    padding-left:toRem(16) ;
    padding-right:toRem(16) ;
    
    .tab{
      border-top-left-radius: toRem(5);
      border-top-right-radius: toRem(5);
      cursor: pointer;
      background-color: #F4F4F4;
      display: flex;
      align-items: center;
      justify-content: center;
      width: toRem(265);
      font-size: toRem(18);
      line-height: toRem(27);
      font-family: "Heebo-Medium";
      
    }
  }
  .desctop1{
    display: none;
  }
  .addFeedbackDialog{
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background: opacify($color: #000000, $amount: 1);
    background-color: rgba(64, 95, 106, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: toRem(33);
    @media (min-height: toRem(682)) {
      padding-top: 0;
      justify-content: center;
    }

    
  
    .feedbackFrom{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      width: 100%;
      padding: toRem(57) toRem(19) toRem(25);
  
      .buttons {
        padding-top: toRem(26);
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: toRem(15);
    
        .save {
          padding: toRem(12) toRem(24);
          border-radius: toRem(5);
          background-color: #405f6a;
          color: #ffffff;
          cursor: pointer;
          font-size: toRem(16);
          line-height: toRem(24);
        }
      }
  
      .inputs{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: toRem(10);
        padding-bottom: toRem(10);
        
      }
  
      .invalidField1 {
        // background-color: #f3e8e8 !important;
        outline: 0.06rem solid red;
      }
  
      .inputContainer {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: toRem(49);
        background-color: #F7F4F4;
        gap: toRem(23);

        span{
          padding-right: toRem(20);
        }
  
        .invalidField {
          background-color: #f3e8e8;
          outline: 0.06rem solid red;
        }
  
        input {
          background-color:#F7F4F4;
          border: none;
          border-radius: 0.31rem;
          height: 100%;
          margin-bottom: 0.94rem;
          font-family: Heebo-Regular;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #242323;
          padding-inline-start: toRem(20);
          padding-inline-end: 2.5rem;
          width: 100%;
          box-sizing: border-box;
        }
      }
  
      .textAreaContainer {
        max-width: toRem(960);
  
        width: 100%;
        position: relative;
        .invalidField {
          background-color: #f3e8e8;
          outline: 0.06rem solid red;
        }
        textarea {
          box-sizing: border-box;
          background-color: #F7F4F4;
          border: none;
          border-radius: 0.31rem;
          width: 100%;
          height: toRem(239);
          padding: toRem(12.8) toRem(20);
          resize: none;
          font-family: "Heebo-Regular";
          font-size: 1rem;
          line-height: 1.5rem;
          // color: #9b9999;
  
          
        }
        textarea::placeholder{
          color: #9B9999;
        }
  
      
  
        textarea:focus {
          outline: 0.13rem solid #405f6a;
        }
      }
  
      .errorMessage {
        margin: 0;
        position: absolute;
        color: red;
        bottom: 0.007rem;
        right: 0.31rem;
        font-family: Heebo-regular;
        font-size: 0.65rem;
      }
  
      .formTitle{
        display: flex;
        font-size: toRem(18);
        line-height: toRem(27);
        
        gap: toRem(4);
        padding-bottom: toRem(32);
  
        span{
          font-family: "Heebo-Bold";
        }
      }
    }
  
    .closeDialog{
      display: flex;
      max-width: toRem(1047);
      width: 100%;
      padding-bottom: toRem(13);
  
      img{
        cursor: pointer;
        width: toRem(13);
        height: toRem(13);
      }
    }
  }

  .swiperAndSearchFieldContainer {
    display: block;
    margin-top: 0.94rem;
    position: relative;
    padding: 0;
    margin: 1.88rem 2.19rem 0.63rem 2.19rem;
  }

  .supplierBlock {
    padding: 0rem 2.19rem 0rem 2.19rem;
  }

  .tablePart {
    padding-inline-end: 0rem;
  }

  .beforeFooter{
    min-height: 9.38rem;
    padding: toRem(61) toRem(15) toRem(70);
  
    
  

    .feedbackWrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .feedbackTitle{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: toRem(10);
        padding-bottom: toRem(36);
        .fTitle{
  
          font-size: toRem(24);
          font-family: "Heebo-Bold";
          line-height: toRem(35);
        }
  
        .fRate{
          font-size: toRem(14);
          display: flex;
          align-items: center;
          gap: toRem(11);
  
          span{
            line-height: toRem(34);
            font-size: toRem(14);
          }
        }
      }
  
      .allFeedback{
        display: flex;
        gap: toRem(20);
        flex-direction: column;
        width: 100%;
  
        .fContainer{
          padding: toRem(10) toRem(11) toRem(16) toRem(22);
          box-shadow: 0 toRem(20) toRem(40) rgba(0, 0, 0, .08);
  
          .fContainerTitle{
            padding: toRem(0) toRem(0) toRem(10);
            display: flex;
            gap: toRem(21);
  
            span{
              display: flex;
              align-items: flex-start;
              font-size: toRem(14);
              line-height: toRem(21);
              font-family: "Heebo-Bold";
            }
          }
  
          .fContainerDescription{
            min-height: toRem(22);
            max-height: toRem(96);
            font-size: toRem(14);
            line-height: toRem(21);
          }
        }
      }
  
      .linkAddFeedback{
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        font-size: toRem(16);
        line-height: toRem(24);
        font-family: "Heebo-Medium";
        text-decoration: underline;
        padding-top: toRem(51);
      }
    }
  }

  .categoryNameFrame {
    margin-inline-start: 2.19rem;
    margin-inline-end: 2.19rem;
    margin-top: 0.94rem;
    color: #242323;

  }
}

@media (max-width: 38.75rem) {
  .desctop{
    display: none;
  }
}
@media (max-width: 25.75rem) {
  .swiperAndSearchFieldContainer {
    margin: 1.88rem 1.25rem 0.63rem 1.25rem;
  }

  .categoryNameFrame {
    margin-inline-start: 1.25rem;
    margin-inline-end: 1.25rem;
  }

  .supplierBlock {
    padding: 0rem 1.25rem 0rem 1.25rem;
  }
}

