@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.containerDesctop {
  display: flex;
  justify-content: center;
  input{
    color: #3E3D3D;
  }

  .content {
    box-sizing: border-box;
    padding-top: 3.69rem;
    padding-bottom: 3.75rem;
/*     width: 64.69rem; */
    width: 100%;
    // max-width: 60.88rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      color: #242323;
      font-family: Heebo-Bold;
      font-size: 1.5rem;
      line-height: 2.19rem;
      text-align: center;
      margin: 0;
    }
  }
}

.radioContainer {
  display: flex;
  justify-content: center;
  height: 1.9375rem;
  gap: 2.13rem;
  margin-top: 1.5rem;
}

.radio {
  position: relative;
  margin-bottom: .25rem;
  margin-top: .19rem;
  padding-inline-start: 2.63rem;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    font-size: toRem(22);
    // line-height: toRem(32);
    cursor: pointer;
    user-select: none;
    position: relative;
  }

  label::before {
    content: "";
    display: inline-block;
    width: 1.63rem;
    height: 1.63rem;
    position: absolute;
    right: -2.69rem;
    bottom: 0rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
  }
}

.progressContainer {
  display: flex;
  justify-content: center;
  gap: 1.58rem;
  margin-top: 3.38rem;
  margin-bottom: 4.69rem;

  .navItem {
    display: flex;
    align-items: center;
    height: 2.19rem;
    cursor: pointer;

    p {
      margin: 0;
      font-family: Heebo-Regular;
      font-size: 1.5rem;
      line-height: 2.19rem;
      color: #242323;
      opacity: .48;
      padding-inline-start: .77rem;      
      white-space: nowrap;
    }

    svg {
      opacity: .48;
    }

    svg:last-child {
      padding-inline-start: 1.67rem;
    }    
  }
}

.formsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nextButton {
  box-sizing: border-box;
  padding: .75rem 1.5rem;
  font-family: Heebo-Regular;
  font-size: 1.38rem;
  line-height: 2rem;
  color: #FFFFFF;
  background-color: #405F6A;
  border: none;
  box-shadow: 0 1.25rem 2.5rem #221B4A24;
  border-radius: 0.31rem;
  cursor: pointer;
  // margin-top: 5.06rem;
}

.nextMobileButton {
  z-index: 1;
  box-sizing: border-box;
  padding: toRem(12) toRem(24);
  font-family: Heebo-Regular;
  font-size: 1rem;
  line-height: toRem(24);
  color: #FFFFFF;
  background-color: #405F6A;
  border: none;
  box-shadow: 0 1.25rem 2.5rem #221B4A24;
  border-radius: 0.31rem;
  cursor: pointer;
  // margin-top: 5.06rem;
}

.containerMobile {
  display: flex;
  flex-direction: column;
  
  margin: toRem(20) toRem(17) toRem(54) toRem(17);
  // justify-content: center;
  input{
    color: #3E3D3D;
  }

  .accordion{
    padding-bottom: toRem(44);
    background-color: #EEE9E5;
  }

  .mobileTitle{
    font-size: toRem(20);
    font-family: "Heebo-Bold";
    padding-bottom: toRem(22);
  }

  .tabTitle{
    line-height: toRem(29);
    padding-bottom: toRem(2);
    font-size: toRem(20);
    font-family: "Heebo-Bold";
  }

  .radioMobileContainer{
    padding-bottom: toRem(22);
    gap: toRem(20);
    display: flex;
    
    .radio {
      align-items: center;
      margin-bottom: .25rem;
      margin-top: .19rem;
      padding-inline-start: toRem(32);
      box-sizing: border-box;
    
      input {
        display: none;
      }
    
      label {
        font-size: toRem(20);
        cursor: pointer;
        user-select: none;
        position: relative;
      }
    
      label::before {
        box-sizing: border-box;
        content: "";
        display: inline-block;
        width: toRem(22);
        height: toRem(22);
        position: absolute;
        right: -2rem;
        bottom: .15rem;
        border: .13rem solid #707070;
        border-radius: 50%;
      }
    
      input:checked+label::before {
        background-size: toRem(10);
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-origin: border-box;
      }
    }
  }

  

  .content {
    box-sizing: border-box;
    padding-top: 3.69rem;
    padding-bottom: 3.75rem;
/*     width: 64.69rem; */
    width: 100%;
    // max-width: 60.88rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      color: #242323;
      font-family: Heebo-Bold;
      font-size: 1.5rem;
      line-height: 2.19rem;
      text-align: center;
      margin: 0;
    }
  }


}

@media (max-width: toRem(990)) {
  .progressContainer {
    gap: toRem(20)
  }
}



@media (max-width: toRem(960)) {
  .containerDesctop {
    display: none;
  }

  .radio {
    position: relative;
    margin-bottom: .25rem;
    margin-top: .19rem;
    padding-inline-start: 2.63rem;
    box-sizing: border-box;
  
    input {
      display: none;
    }
  
    label {
      cursor: pointer;
      user-select: none;
      position: relative;
    }
  
    label::before {
      content: "";
      display: inline-block;
      width: 1.63rem;
      height: 1.63rem;
      position: absolute;
      right: -2.69rem;
      bottom: -.25rem;
      border: .13rem solid #707070;
      border-radius: 50%;
    }
  
    input:checked+label::before {
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-origin: border-box;
    }
  }

  .calendarMobile{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: toRem(22) toRem(17);
    width: 100%;

    .mainBtns{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      




      .link{
        padding-top: toRem(27);
        text-decoration: underline;
        font-size: toRem(14);
        line-height: toRem(21);

      }
    }
  }
}

@media (min-width: toRem(961)) {
  .containerMobile {
    display: none;
  }

  .calendarMobile{
    display: none;
  }
}
