// form{
  
// }
@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.gap+.gap {
  margin-top: 0.94rem;
}

.mainContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5.3125rem;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9b9999;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9b9999;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #9b9999;
  }

  .invalidField {
    background-color: #f3e8e8;
    outline: 0.06rem solid red;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0.007rem;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }

  .inputFlash1{
    width: calc(100% / 3 - toRem(10));
    margin-top: toRem(15);
    position: relative;
    box-sizing: border-box;
    // width: toRem(379);
    height: toRem(73);
    font-size: toRem(22);
    line-height: toRem(32);
    padding: toRem(15) toRem(28);
    display: flex;
    align-items: center;
    background-color: #EEE9E5;
    border-radius: toRem(5);
    
    input{
      background-color: #EEE9E5;

      width: toRem(100);
      padding: 0;
      height: 100%;
      border: none;
      width: 100%;

      &:focus{
        outline: none;
      }
    }
    
  }

  .radioOwnContract{
    // padding-top: toRem(15);
    display: flex;
    align-items: center;

    .radio {
    position: relative;

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      top: -1.5rem;
      right: 0rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      user-select: none;
      position: relative;
      font-family: Heebo-Regular;
      font-size: toRem(20);
      line-height: toRem(27);
      color: #3e3d3d;
      margin-inline-start: toRem(39);
    }

    label::before {
      content: "";
      display: inline-block;
      width: toRem(26);
      height: toRem(26);
      position: absolute;
      right: toRem(-39);
      bottom: -0.05rem;
      border: 0.13rem solid #707070;
      border-radius: 50%;
    }

    input:checked + label::before {
      // background-size: toRem(12);
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-origin: border-box;
    }
  }

  .loadContract{
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    height: toRem(27);
    text-decoration: underline;
    font-size: toRem(20);
    line-height: toRem(27);
    padding-right: toRem(10);
  }

  .showDeleteContract{
    position: relative;
    display: flex;
    gap: toRem(8);
    padding-right: toRem(10);

    img{
      max-height: toRem(27);
    }
    
    .contractName{
      position: absolute;
      bottom: toRem(25);
      font-size: toRem(10);
      right: 0;
    }
  }


    
  }
}

.width33{
  width: 33%;
}

.width50{
  width: 50%;
}

.dataInputsForm{
  max-width: 64.6875rem;
  width: 100%;
}

.dataInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .94rem
}


// .addedDataInputs {
  // margin-top: .94rem;
// }

.secondBlock {
  margin-top: .94rem;
}

.businessDescription {
  margin-top: 1.5rem;
  margin-bottom: 0.9375rem;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #3E3D3D;
}

.textAreaContainer {
  textarea {
    box-sizing: border-box;
    background-color: #EEE9E5;
    border: none;
    border-radius: .31rem;
    width: 100%;
    height: 9.56rem;
    padding: 1.56rem 1.75rem;
    resize: none;
    font-family: Heebo-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    // color: #9b9999;
  }

  textarea:focus {
    outline: .13rem solid #405F6A;
  }
}

.lessFields,
.moreFields {
  width: fit-content;
  margin-top: .88rem;
  margin-bottom: 0;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  cursor: pointer;
}

.lessFields {
  margin-top: 0rem;
}

.line {
  margin-top: 2.78rem;
  margin-bottom: 0;
  height: 0.06rem;
  background-color: #707070;
  border: none;
}

.questionAboutContacts {
  margin-top: 1.47rem;
  margin-bottom: 0;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #3E3D3D;
}

.doubleRadio{
  display: flex;
  gap: toRem(15);
  align-items: flex-end;
}

.inputContainer {
  position: relative;
  flex-grow: 1;
  
  .invalidField {
      background-color: #f3e8e8;
      outline: .06rem solid red;
    }  

  input {
    background-color: #EEE9E5;
    border: none;
    border-radius: 0.31rem;
    height: 4.56rem;
    font-family: Heebo-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    // color: #9b9999;
    padding-inline-start: 1.75rem;
    padding-inline-end: 1.5rem;
    width: 100%;
    box-sizing: border-box;

    
  }

  input:focus {
    outline: .13rem solid #405F6A;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0.007rem;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}

.secondBlock .inputContainer {
  width: calc(100% / 3);
}

.selectInputContainer {
  color: #9B9999;
  height: 4.56rem;
}

.radio {
  position: relative;

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    top: -1.5rem;
    right: 0rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
  

  input {
    display: none;
  }

  label {
    cursor: pointer;
    user-select: none;
    position: relative;
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    color: #3E3D3D;
    margin-inline-start: 2.8rem;
  }

  label::before {
    content: "";
    display: inline-block;
    width: toRem(26);
      height: toRem(26);
    position: absolute;
    right: -2.81rem;
    bottom: -.05rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked + label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
  }
}

.radio1 {
  margin-top: 1.06rem;
}

.radio2 {
  margin-top: 1.38rem;
}

@media (min-width: toRem(961)) {
  .mobile{
    display: none;
  }
}

@media (max-width: toRem(960)) {

  .desctop{
    display: none;
  }

  .mainContainer{
    padding-bottom: toRem(55);

    input{
      width: 100%;
    }

    .inputFlash1{
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: toRem(55);
      font-size: toRem(18);
      line-height: toRem(21);
      padding: 0 toRem(11);
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: toRem(5);
      
      input{
        width: toRem(100);
        padding: 0;
        height: 100%;
        background-color: white;
        border: none;
        width: 100%;

        &:focus{
          outline: none;
        }
      }
      
    }

    .radioOwnContract{
      display: flex;
      align-items: center;
      margin-top: toRem(5);
      .radio {
      position: relative;

      .errorMessage {
        margin: 0;
        position: absolute;
        color: red;
        top: -1.5rem;
        right: 0rem;
        font-family: Heebo-regular;
        font-size: 0.65rem;
      }

      input {
        display: none;
      }

      label {
        cursor: pointer;
        user-select: none;
        position: relative;
        font-family: Heebo-Regular;
        font-size: toRem(20);
        line-height: toRem(27);
        color: #3e3d3d;
        margin-inline-start: toRem(32);
      }

      label::before {
        content: "";
        display: inline-block;
        width: toRem(22);
        height: toRem(22);
        position: absolute;
        right: toRem(-32);
        top: 0.11rem;
        border: 0.13rem solid #707070;
        border-radius: 50%;
      }

      input:checked + label::before {
        background-size: toRem(10);
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-origin: border-box;
      }
    }

    .loadContract{
      cursor: pointer;
      text-decoration: underline;
      font-size: toRem(20);
      line-height: toRem(27);
      padding-right: toRem(10);
    }


      
    }
  }
  .dataInputsForm{
    max-width: 64.6875rem;
    width: 100%;
  }
  
  .dataInputs {
    width: 100%;
    // display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .94rem

    
  }

  .width33, .width50{
    width: 100%;
  }
  
  
  // .addedDataInputs {
    // margin-top: .94rem;
  // }
  
  .secondBlock {
    margin-top: .94rem;
  }
  
  .businessDescription {
    margin-top: 1.5rem;
    margin-bottom: 0.9375rem;
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    color: #3E3D3D;
  }
  
  .textAreaContainer {
    textarea {
      box-sizing: border-box;
      background-color: white;
      border: none;
      border-radius: .31rem;
      width: 100%;
      height: toRem(303);
      padding: 1.56rem 1.75rem;
      resize: none;
      font-family: Heebo-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
      // color: #9b9999;
    }
  
    textarea:focus {
      outline: .13rem solid #405F6A;
    }
  }
  
  .lessFields,
  .moreFields {
    margin-top: .88rem;
    margin-bottom: 0;
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    cursor: pointer;
  }
  
  .lessFields {
    margin-top: 0rem;
  }
  
  .line {
    margin-top: toRem(25);
    margin-bottom: 0;
    height: 0.06rem;
    background-color: #707070;
    border: none;
  }
  
  .questionAboutContacts {
    margin-top: toRem(12);
    margin-bottom: 0;
    font-family: Heebo-Regular;
    font-size: toRem(18);
    line-height: 1.81rem;
    color: #3E3D3D;
  }

  .doubleRadio{
    display: flex;
    align-items: initial;
    flex-direction: column;
    gap: toRem(15);
  }
  
  .inputContainer {
    position: relative;
    flex-grow: 1;
    
    .invalidField {
        background-color: #f3e8e8;
        outline: .06rem solid red;
      }  
  
    input {
      background-color: white;
      border: none;
      border-radius: 0.31rem;
      height: toRem(54);
      font-family: Heebo-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
      // color: #9b9999;
      padding-inline-start: 1.75rem;
      padding-inline-end: 1.5rem;
      width: 100%;
      box-sizing: border-box;
  
      
    }
  
    input:focus {
      outline: .13rem solid #405F6A;
    }
  
    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0.007rem;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }
  }
  
  .secondBlock .inputContainer {
    width: 100%;
  }
  
  .selectInputContainer {
    color: #9B9999;
    height: toRem(54);
  }
  
  .radio {
    position: relative;
  
    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      top: -1.5rem;
      right: 0rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }
    
  
    input {
      display: none;
    }
  
    label {
      display: flex;
      cursor: pointer;
      user-select: none;
      position: relative;
      font-family: Heebo-Regular;
      font-size: toRem(16);
      line-height: toRem(24);
      color: #3E3D3D;
      margin-inline-start: toRem(30);
    }
  
    label::before {
      box-sizing: border-box;
      background-color: white;
      content: "";
      display: inline-block;
      width: toRem(22);
      height: toRem(22);
      position: absolute;
      right: toRem(-30);
      top: toRem(2);
      border: .13rem solid #707070;
      border-radius: 50%;
    }
  
    input:checked + label::before {
      background-size: toRem(10);
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-origin: border-box;
    }
  }
  
  .radio1 {
    margin-top: 1.06rem;
  }
  
  .radio2 {
    margin-top: 1.38rem;
  }
}
