.container {
  background-color: #eee9e5;
  padding: 31px 0px 36px 0px;
  .row {
    max-width: 1068px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
.one_advantage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 272px;
  .img_container {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: white;
  }
  .img_container img {
    width: 100%;
  }
  .title {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
  }
  .description {
    margin-top: 15px;
    font-size: 14px;
    line-height: 24.5px;
    text-align: center;
    display: block;
  }
}

@media (max-width: 900px) {
  .one_advantage {
    .title {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
    .description {
      margin-top: 10px;
      font-size: 12px;
      text-align: center;
    }
  }
}
@media (max-width: 600px) {
  .container {
    padding: 18px 0px;
  }
  .one_advantage {
    .img_container {
      width: 49px;
      height: 49px;
    }
    .title {
      margin-top: 5px;
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
    }
    .description {
      display: none;
    }
  }
}
