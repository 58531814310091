$rem:16;

@mixin css-arrow($height: 10px, $border: 2px, $color: #efefef, $border-color: #999, $direction: bottom, $outside: true) {
  $border-shift: $border;
  
  @if $border > 0px {
    $border: round($border * 1.41421356);
  }
  
  position: relative;
  
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    
    @if $direction == top {
      @if $outside {
        bottom: 100%;
      }
      @else {
        margin-top: -$border-shift;
        top: 0;
      }
    }
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $color;
      }
      @else {
        border-top-color: $color;
      }
    }
    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -$height;
    }
    border-width: $height;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $border-color;
      }
      @else {
        border-top-color: $border-color;
      }
    }

    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -($height + $border);
    }
    border-width: $height + $border;
  }
}

$background: white;
$border-color:  black;
$border-size: 1px;

.filtersBlock{
    width: calc(100% - calc(180rem/$rem));
    display: flex;
    flex-direction: column;
    margin: calc(30rem/$rem) calc(90rem/$rem) 0;
}
.tableBlock{
    background-color: #f4f4f4;
    width: calc(100% - calc(180rem/$rem));
    display: flex;
    padding: 0 calc(90rem/$rem) 0 ;
    flex-direction: column;
}
.firstFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.firstFilterTitle{
    font-size: calc(26rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    margin-left: calc(18rem/$rem);
}
.secondFilterTitle{
    font-size: calc(20rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    margin-left: calc(18rem/$rem);
}
.secondFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: calc(20.5rem/$rem) 0 calc(23rem/$rem);
}
.secondBlock{
    position: relative;
}
.exportImage{
    position: absolute;
    left: 0;
    bottom: 23px;
    cursor: pointer;
}
.secondFilterDocument{
    margin-left: calc(15rem/$rem);
}
.searchFieldContainer {
    position: relative;
    direction: ltr;
}
.searchingField {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border: calc(1rem/$rem) solid #405F6A;
  border-radius: calc(2rem/$rem);
  height: calc(47rem/$rem);
  width: calc(292rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  line-height: 2.19rem;
  color: #242323;
  text-align: center;
  padding-inline-start: calc(14rem/$rem);
  padding-inline-end: 3.75rem;
  box-sizing: border-box;
  user-select: none;
  &:focus{
    outline: none;
}
}
.searchingField::placeholder { 
    margin-right: calc(-100rem/$rem) !important;
}
.magnifierIcon {
  position: absolute;
  top: 0.63rem;
  right: 1.13rem;
}
.tableTitle{
  font-size: calc(26rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin: calc(29rem/$rem) 0 calc(3.5rem/$rem) 0;
}
.hrTable{
  width: 100%;
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.18;
  margin: 0;
}

.tableMonth{
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin-bottom: calc(22rem/$rem);
  div{
    width: calc(100% / 12);
  }
}
.tableContents{
  display: flex;
  flex-direction: column;
}
.headerContent{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin-bottom: calc(22rem/$rem);
  margin-top: calc(50rem/$rem);
  text-align: center;
  div{
    width: calc(100% / 11);
    overflow: hidden;
    margin: 0 calc(2rem/$rem);
  }
}
.empty{
  background-color: #f4f4f4;
  height: 100%;
  margin: 0 !important;
}
.mainContent{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: calc(18rem/$rem);
  height: calc(56rem/$rem);   
  margin-bottom: calc(10rem/$rem);
  background-color: #ffffff;
  font-family: Heebo-Regular;
  div{
    margin: 0 calc(2rem/$rem);
    width: calc(100% / 12);
  }
  .textBlock{
    overflow: hidden;
    white-space: nowrap;
  }
}
.supplierName{
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}
.shekel{
  margin-right: 4px;
}
.file{
  cursor: pointer;
  position: absolute;
}
.download{
  cursor: pointer;
}
.fileBlock{
  text-align: center;
  position: relative;
  bottom: calc(10rem/$rem);
}
.file_title{
  opacity: 0;
  position: absolute; 
}
.file_title:hover{
  opacity: 1;
  position: absolute;    
  top: calc(20rem/$rem);
  left: calc(10rem/$rem);
}
.tooltiptext{
  @include css-arrow(10px, $border-size, $background, $border-color, top);
  position:absolute;
  box-sizing: border-box;
  font-size: calc(10rem/$rem);
  background-color: white;
  border: calc(1rem/$rem) solid black;
  border-radius: calc(5rem/$rem);
  visibility: hidden;
  width: calc(85rem/$rem);
  z-index: 1;
  padding: calc(5rem/$rem) calc(9rem/$rem);
  top: 107%;
  left: 62%;
  margin-left: calc(-60rem/$rem); /* Use half of the width (120/2 = 60), to center the tooltip */

}
.fileDownload:hover .tooltiptext {
  visibility: visible;
}
.download:hover .tooltiptext {
  visibility: visible;
}
.fileDownload{
  position: relative;
}

.radio {
  position: absolute;
  margin-bottom: .25rem;
  margin-top: .19rem;
  padding-inline-start: 2.63rem;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    font-size: calc(22rem/$rem);
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: calc(13rem/$rem);
  }

  label::before {
    content: "";
    display: inline-block;
    width: calc(21rem/$rem);
    height: calc(21rem/$rem);
    position: absolute;
    right: -2.69rem;
    bottom: 0rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
    background-size: calc(17rem/$rem);
  }
}
.radioContainer {
  display: flex;
  justify-content: center;
  height: 1.9375rem;
  gap: 2.13rem;
  margin-top: 1.5rem;
}
.desctop{
  display: block;
}
.mobile{
  display: none;
}
.filters{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.filtersMobile{
  display: none;
  flex-direction: column;
}
.details{
  text-align: left;
  display: none;
  margin: calc(5rem/$rem) 0 0 calc(7rem/$rem) !important;
}
.table{
  margin-bottom: calc(121rem/$rem);
}

@media (max-width: calc(1890rem/$rem)) {
  .tableTitle{
    font-size: calc(23rem/$rem);
  }
  .tableMonth{
    font-size: calc(20rem/$rem);
  }
  .mainContent{
    font-size: calc(16rem/$rem);
  }
  .firstFilterTitle{
      font-size: calc(23rem/$rem);
  }
  .secondFilterTitle{
      font-size: calc(18rem/$rem);
  }
  .headerContent{
    font-size: calc(16rem/$rem);
  }
}
@media (max-width: calc(950rem/$rem)) {
  .exportImage{
    left:  calc(-80rem/$rem);
  }
}
@media (max-width: calc(900rem/$rem)){
  .searchingField{
    width: calc(220rem/$rem);
    padding-inline-end: 7rem;
  }
}
@media (max-width: calc(800rem/$rem)) {
  .tableContents{
    display: none;
  }
  .firstFilterTitle{
      font-size: calc(18rem/$rem);
  }
  .secondFilterTitle{
      font-size: calc(16rem/$rem);
  }
  .tableBlock, .filtersBlock{
    margin-left: 0;
    margin-right: 0;
    padding: 0 calc(18rem/$rem);
    width: calc(100% - calc(36rem/$rem));
  }
  .tableTitle{
    font-size: calc(20rem/$rem);
  }
  .tableMonth{
    font-size: calc(16rem/$rem);
    font-family: Heebo-regular;
    margin: calc(23rem/$rem) 0 calc(12rem/$rem);
    div{
      width: 100%;
    }
  }
  .mainContent{
    font-size: calc(14rem/$rem);
    height: calc(37rem/$rem);
  }
  .empty{
    display: none;
  }
}
@media (max-width: calc(700rem/$rem)) {
  .searchingField{
    width: calc(284rem/$rem);
    height: calc(40rem/$rem);
    padding-inline-start: calc(12rem/$rem);
  }
  .tableTitle{
    margin: 0;
  }
  .filters{
    align-items: center;
  }
  .magnifierIcon{
    width: calc(17rem/$rem);
    height: calc(17rem/$rem);
  }
  .mobile{
    display: block;
  }
  .filtersMobile{
    display: flex;
    flex-direction: column;
  }
  .desctop{
    display: none;
  }
  .secondBlock{
    display: none;
  }
  .filtersBlock{
    margin: calc(34rem/$rem) 0  calc(27rem/$rem);
  }
  .mainContent{
    div{
      width: calc(100% / 5);
    }
  }
  .supplierName, .contractId{
    text-decoration: none;
    font-family: Heebo-Bold;
    cursor: default;
  }
  .details{
    display: block;
  }
  .table{
    margin-bottom: calc(273rem/$rem);
  }
}
@media (max-width: calc(600rem/$rem)) {
  .radio{
  label::before {
    width: calc(11.2rem/$rem) !important;
    height: calc(11.2rem/$rem) !important;
    right: -2.69rem;
    bottom: calc(13rem/$rem) !important;
  }
  input:checked+label::before {    
    background-size: 0.43rem;
  }
}
}