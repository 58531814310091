$rem: 16;
.watsApp{
    z-index: 999;
    box-sizing: border-box;
    width: 14.75rem;
    height: 6rem;
    background-color: #1EE62C;
    color:#FFFFFF;
    border-radius: 1.375rem;
    display: flex;
    padding: 1rem 1.125rem;
    position: fixed;
    bottom: 2.5625rem;
    right: 6rem;
  
    .watsAppIcon{
      width: 3.75rem;
      height: 3.75rem;
    }
  
    .watsAppText{
      width: 100%;
      font-size: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .phoneInput{
    direction: ltr !important;
    text-align: end !important;
  }
  .description{
    a{
      text-decoration: underline;
    }
  }
  input{
    font-family: Heebo-Regular;
  }
  .infoDialog{
    background-color: rgba(64, 95, 106, 0.9);
  }
  .mainPopup{
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .close{
    position: relative;
    padding-bottom: 0.7rem;
    background-color: rgba(64, 95, 106, 0.9);
  }
  .blockCalendar{
    background-color: rgba(64, 95, 106, 0.9);
  }
  .infoDialogClose{
    background-color: #FFFFFF;
    height: calc(200rem/$rem);
    border-radius: calc(5rem/$rem);
  }
  .infoDialogtitle{
    text-align: center;
    display: flex;
    font-family: Heebo-Bold;
    font-size: calc(16rem/$rem);
    color: #242323;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    align-items: center;
  }
  .mainPopup::-webkit-scrollbar { width: 0; }
  
  .inputContainer {
    position: relative;
    flex-grow: 1;

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }
  }
  .fifth{
    width: 100%;
    background-color: #EEE9E5;
    min-height: 21.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2.68rem 6rem 0 6rem;
  
    .fifthTitle{
      width: 100%;
      text-align: center;
      font-family: Heebo-Medium;
      font-size: 2rem;
      padding-bottom: 3.875rem ;
    }
  
    .fifthFields{
      display: flex;
      input + input {
        margin-right: 2rem;
      }
      input {
        // min-width: 23.6875rem;
        background-color: #FFFFFF;
        border: none;
        height: 4.5625rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.69rem;
        padding-inline-end: 1.5rem;
      }
    }
    
    .fifthBtn{
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 2.4375rem 0 2.8125rem 0 ;
    }
  }
  
.button{
    color: #FFFFFF;
    font-size: 1.375rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.375rem;
    border-radius: 0.3125rem;
    background: #405F6A 0% 0% no-repeat padding-box;
    box-shadow: 0rem 1.25rem 2.5rem #221B4A21;
    padding: 0.75rem 1.5rem;
    box-sizing: border-box;
  }
  .beforeFooter{
    height: 4.625rem;
  }
  .imgBlock{
    width: 100%;
    height: 28.4375rem;
    margin-top: 4.75rem;
  }
  .img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title{    
    //height: 4.375rem;
    overflow: hidden;
    font-family: Heebo-Bold;
    margin: 4.75rem 22.4375rem 3.125rem 29.5625rem;
    text-align: center;
    color: #242323;
    font-size: 3rem;
  }
  .description{
    margin: 0 22.4375rem 1.25rem 22.4375rem;
    color: #242323;
    font-size: 1.5rem;
  }
  .backLink{
    margin: 0 0 4.75rem 22rem;
    color: #242323;
    font-size: 1.5rem;
    text-align: end;
    text-decoration: underline;
    font-family: Heebo-Medium;
    a { 
        text-decoration-color: #242323;
        cursor: pointer;
    }
  }

  .mobile{
    display: none;
  }

  @media (max-width: 87.5rem) {
    .title{
        margin: 4.75rem 15rem 3.125rem 15rem;
      }
      .description{
        margin: 0 15rem 1.25rem 15rem;
      }
      .backLink{
        margin: 0 0 4.75rem 15rem;
      }
  }

  @media (min-width: 69.125rem) {
    .fifthFields{
      input {
        min-width: 20.5rem;
      }
    }
  }

  @media (max-width: 69.0625rem) {
    .fifthFields{
      input {
        width: 15.6875rem ;
      }
    }
    .title{
        margin: 4.75rem 15rem 3.125rem 15rem;
        font-size: 1.5rem;
    }
    .description{
        margin: 0 15rem 1.25rem 15rem;
        font-size: 1.125rem;
    }
    .backLink{
        margin: 0 0 4.75rem 15rem;
        font-size: 1.125rem;
    }
  }

  @media (max-width: 59.75rem) {
    .title{
        margin: 4.75rem 10rem 3.125rem 10rem;
      }
      .description{
        margin: 0 10rem 1.25rem 10rem;
      }
      .backLink{
        margin: 0 0 4.75rem 10rem;
      }
  }
  
  @media (max-width: 53.75rem) {
    .inputContainer{
      margin-left: 0 !important;
    }
    .errorMessage{
      bottom: 0;
    }
    .watsApp{
      width: 10.375rem;
      height: 4.18rem;
      padding: 0.75rem 0.8125rem;
  
      .watsAppIcon{
        img{
          width: 2.68rem;
          height: 2.68rem;
        }
      }
  
      .watsAppText{
        font-size: 0.875rem;
      }
    }
    .watsApp{
      right: 1.125rem;
      bottom: 3.5rem;
    }
    .beforeFooter{
        height: 0rem;
    }
   .fifth{
    align-items: flex-start;
    padding: 2.25rem 1.125rem 0 1.0625rem;

    .fifthTitle{
      font-family: Heebo-Medium;
      font-size: 1.5rem; 
      text-align: start;
      padding-bottom: 2.1875rem;
    }

    .fifthFields{
      display: flex;
      flex-direction: column;
      width: 100%;

      input{
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 0.9375rem;
      }

      input + input {
        margin-right: 0rem;
      }

      .fifthBtn{
        padding: 1.5625rem 0 2.5625rem 0 ;
      }
    }
  }   
    
  }
  @media (max-width: 46.875rem) {
    .title{
      margin: 4.75rem 5rem 3.125rem 5rem;
    }
    .description{
      margin: 0 5rem 1.25rem 5rem;
    }
    .backLink{
      margin: 0 0 4.75rem 5rem;
    }
    .button{
      font-size: 1.125rem;
    }
  }
  @media (max-width: 37.5rem) {
    .title{
      font-family: Heebo-Medium;
      text-align: right;
      margin: 0rem 1.125rem 1.3125rem 2.5625rem;
    }
    .description{
      margin: 0 1rem 2.125rem 0.9375rem;
    }
    .backLink{
      margin: 1.25rem 1.125rem 2.0625rem 0rem;
      font-family: Assistant-Bold;
      text-align: start;
      font-size: 1.125rem;
      color: #434242;
      font-size: 1rem;
    }
    .desctop{
      display: none;
    }
    .imgBlock{
        width: 100%;
        height: 16.8125rem;
        margin: 0 0 1.5625rem 0;
    }
    .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .mobile{
      display: block;
    }
    .fifthTitle{
      direction: ltr;
      max-width: 256px;
      margin: 0.0625rem 0rem 1.75rem 6.3125rem;
      padding-bottom: 0rem !important;
      text-align: right !important;
    }
    .fifthBtn{
      margin: 0.8125rem 0rem 2.4375rem 0rem;
      padding: 0 !important;
    }
    .button{
        font-size: 1.125rem;
        width: 5.125rem;
        max-width: 5.125rem;
        height: 3.1875rem;
        max-height: 3.1875rem;
    }
    .fifth{
      margin-bottom: 3.75rem;
    }
  }