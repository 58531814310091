$rem:16;

@mixin css-arrow($height: 10px, $border: 2px, $color: #efefef, $border-color: #999, $direction: bottom, $outside: true) {
  $border-shift: $border;
  
  @if $border > 0px {
    $border: round($border * 1.41421356);
  }
  
  position: relative;
  
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    
    @if $direction == top {
      @if $outside {
        bottom: 100%;
      }
      @else {
        margin-top: -$border-shift;
        top: 0;
      }
    }
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $color;
      }
      @else {
        border-top-color: $color;
      }
    }
    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -$height;
    }
    border-width: $height;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $border-color;
      }
      @else {
        border-top-color: $border-color;
      }
    }

    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -($height + $border);
    }
    border-width: $height + $border;
  }
}

$background: white;
$border-color:  black;
$border-size: 1px;


.paymentContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(48.5rem/$rem);
}
.paymentRight{
  width: calc(50% - 207.5rem/$rem);
  max-width: calc(534rem/$rem);
  max-height: calc(416rem/$rem);
  background-color: #F6F7F7;
  margin-left: calc(49rem/$rem);
  border-radius: calc(6rem/$rem);
  padding: calc(29.5rem/$rem) calc(26rem/$rem) 0 calc(27rem/$rem);
}
.paymentLeft{  
  position: relative;
  width: calc(50% - 207.5rem/$rem);
  background-color: #FFFFFF;
  height: calc(600rem/$rem);
  .iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .absolutLoader{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .ldsDualRing {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .ldsDualRing:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #434242;
      border-color: #434242 transparent #434242 transparent;
      animation: ldsDualRing 1.2s linear infinite;
    }
    @keyframes ldsDualRing {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.paymentTitle{
  font-family: Heebo-Bold;
  font-size: calc(22rem/$rem);
  color: #000000;
}
.paymentSecondTitle{
  font-family: Heebo-Bold;
  font-size: calc(20rem/$rem);
  color: #000000;
  margin-top: calc(12.5rem/$rem);
  margin-bottom: calc(13.5rem/$rem);
}
.paymentLine{
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.51;
  margin: 0;
}
.paymentserviceBlock{
  font-family: Heebo-Regular;
  font-size: calc(18rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(7rem/$rem);
}
.paymentBlock{
  max-height: calc(104rem/$rem);
  overflow: auto;
}
.paymentBlock::-webkit-scrollbar { width: 0; }
.paymentLineEnd{
  border: calc(1rem/$rem) solid #000000;
  width: calc(333rem/$rem);
  margin: calc(3rem/$rem) 0 0 0;
}
.paymentPrice{
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(2.5rem/$rem);
  margin-top: calc(16.5rem/$rem);
}
.paymentPriceAfterDiscount{
  font-family: Heebo-Medium;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(9.1rem/$rem);
}
.paymentDiscountBlock{
  width: calc(246.5rem/$rem);
  height: calc(75rem/$rem);
  margin-bottom: calc(29.5rem/$rem);
  background-color: #D3DDDC;
  border-radius: calc(5rem/$rem);
  padding: calc(9.5rem/$rem) calc(12rem/$rem) 0 0;
}
.paymentDiscount{
  font-family: Heebo-Bold;
  font-size: calc(20rem/$rem);
  color: #3E3D3D;
  margin-bottom: calc(6.5rem/$rem);
  overflow: hidden;
  max-height: calc(22rem/$rem);
}
.paymentDiscountPrice{
  font-family: Heebo-Regular;
  font-size: calc(14rem/$rem);
  color: #707070;
}

.paymentMobile{
  display: none;
}
.paymentMobileContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(6.3rem/$rem);
}
.paymentLineTop{
  display: none;
  border: calc(1rem/$rem) solid #242323;
  color: #242323;
  opacity: 0.19;
  margin-top: calc(-6.3rem/$rem);
  margin-bottom: calc(25.5rem/$rem);
}


.filtersBlock{
    width: calc(100% - calc(180rem/$rem));
    display: flex;
    flex-direction: column;
    margin: calc(30rem/$rem) calc(90rem/$rem) 0;
}
.tableBlock{
    background-color: #f4f4f4;
    width: calc(100% - calc(180rem/$rem));
    display: flex;
    padding: 0 calc(90rem/$rem) 0 ;
    flex-direction: column;
}
.firstFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.firstFilterTitle{
    font-size: calc(26rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    margin-left: calc(18rem/$rem);
}
.secondFilterTitle{
    font-size: calc(20rem/$rem);
    font-family: Heebo-Bold;
    color: #242323;
    margin-left: calc(18rem/$rem);
}
.secondFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: calc(38rem/$rem) 0 calc(23rem/$rem);
}
.secondBlock{
    position: relative;
}
.exportImage{
    position: absolute;
    left: 0;
    bottom: 23px;
    cursor: pointer;
}
.secondFilterDocument{
    margin-left: calc(15rem/$rem);
}
.searchFieldContainer {
    position: relative;
    direction: ltr;
}
.searchingField {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border: calc(1rem/$rem) solid #405F6A;
  border-radius: calc(2rem/$rem);
  height: calc(47rem/$rem);
  width: calc(292rem/$rem);
  font-family: Heebo-Regular;
  font-size: calc(20rem/$rem);
  line-height: 2.19rem;
  color: #242323;
  text-align: center;
  padding-inline-start: calc(14rem/$rem);
  padding-inline-end: 3.75rem;
  box-sizing: border-box;
  user-select: none;
  &:focus{
    outline: none;
}
}
.searchingField::placeholder { 
    margin-right: calc(-100rem/$rem) !important;
}
.magnifierIcon {
  position: absolute;
  top: 0.63rem;
  right: 1.13rem;
}
.tableTitle{
  font-size: calc(26rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin: calc(29rem/$rem) 0 calc(3.5rem/$rem) 0;
  direction: ltr;
}
.hrTable{
  width: 100%;
  border: calc(1rem/$rem) solid #707070;
  opacity: 0.18;
  margin: 0;
}

.tableMonth{
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin-bottom: calc(22rem/$rem);
  div{
    width: calc(100% / 12);
  }
}
.tableContents{
  display: flex;
  flex-direction: column;
}
.headerContent{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: calc(22rem/$rem);
  font-family: Heebo-Bold;
  color: #242323;
  margin-bottom: calc(22rem/$rem);
  margin-top: calc(43.5rem/$rem);
  text-align: center;
  div{
    width: calc(100% / 11);
    overflow: hidden;
    margin: 0 calc(2rem/$rem);
  }
}
.empty{
  background-color: #f4f4f4;
  height: 100%;
  margin: 0 !important;
}
.mainContent{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: calc(18rem/$rem);
  height: calc(56rem/$rem);   
  margin-bottom: calc(10rem/$rem);
  background-color: #ffffff;
  font-family: Heebo-Regular;
  div{
    margin: 0 calc(2rem/$rem);
    width: calc(100% / 12);
  }
  .textBlock{
    overflow: hidden;
    white-space: nowrap;
  }
}
.supplierName{
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}
.shekel{
  margin-right: 4px;
}
.file{
  cursor: pointer;
  position: absolute;
}
.download{
  cursor: pointer;
}
.fileBlock{
  text-align: center;
  position: relative;
  bottom: calc(10rem/$rem);
}
.file_title{
  opacity: 0;
  position: absolute; 
}
.file_title:hover{
  opacity: 1;
  position: absolute;    
  top: calc(20rem/$rem);
  left: calc(10rem/$rem);
}
.tooltiptext{
  @include css-arrow(10px, $border-size, $background, $border-color, top);
  position:absolute;
  box-sizing: border-box;
  font-size: calc(10rem/$rem);
  background-color: white;
  border: calc(1rem/$rem) solid black;
  border-radius: calc(5rem/$rem);
  visibility: hidden;
  width: calc(85rem/$rem);
  z-index: 1;
  padding: calc(5rem/$rem) calc(9rem/$rem);
  top: 107%;
  left: 62%;
  margin-left: calc(-60rem/$rem); /* Use half of the width (120/2 = 60), to center the tooltip */

}
.fileDownload:hover .tooltiptext {
  visibility: visible;
}
.download:hover .tooltiptext {
  visibility: visible;
}
.fileDownload{
  position: relative;
}

.radio {
  position: absolute;
  margin-bottom: .25rem;
  margin-top: .19rem;
  padding-inline-start: 2.63rem;
  box-sizing: border-box;

  input {
    display: none;
  }

  label {
    font-size: calc(22rem/$rem);
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: calc(13rem/$rem);
  }

  label::before {
    content: "";
    display: inline-block;
    width: calc(21rem/$rem);
    height: calc(21rem/$rem);
    position: absolute;
    right: -2.69rem;
    bottom: 0rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked+label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
    background-size: calc(17rem/$rem);
  }
}
.radioContainer {
  display: flex;
  justify-content: center;
  height: 1.9375rem;
  gap: 2.13rem;
  margin-top: 1.5rem;
}
.desctop{
  display: block;
}
.mobile{
  display: none;
}
.filters{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.details{
  text-align: left;
  display: none;
  margin: calc(5rem/$rem) 0 0 calc(7rem/$rem) !important;
}
.table{
  margin-bottom: calc(121rem/$rem);
}

@media (max-width: calc(1890rem/$rem)) {
  .tableTitle{
    font-size: calc(23rem/$rem);
  }
  .tableMonth{
    font-size: calc(20rem/$rem);
  }
  .mainContent{
    font-size: calc(16rem/$rem);
  }
  .firstFilterTitle{
      font-size: calc(23rem/$rem);
  }
  .secondFilterTitle{
      font-size: calc(18rem/$rem);
  }
  .headerContent{
    font-size: calc(16rem/$rem);
  }
}
@media (max-width: calc(1100rem/$rem)) {
  .paymentLineEnd{
    width: calc(250rem/$rem);
  }
  .paymentTitle{
    font-size: calc(18rem/$rem);
  }
  .paymentSecondTitle{
    font-size: calc(16rem/$rem);
  }
}
@media (max-width: calc(1000rem/$rem)) {
  .paymentLineTop{
    display: block;
  }
  .paymentSecondTitle{
    font-family: Heebo-Bold;
    font-size: calc(14rem/$rem);
    color: #000000;
    margin-top: calc(25.5rem/$rem);
    margin-bottom: calc(13rem/$rem);
  }
    .paymentContent{
      flex-direction: column;
      align-items: center;
      margin-right: calc(41rem/$rem);
    }
    .paymentMobile{
      display: block;
    }
    .paymentTitle{
      font-size: calc(16rem/$rem);
    }
    .paymentRight{
      margin-left: 0;
      margin-bottom: calc(22rem/$rem);
      width: 100%;
      max-width: calc(341rem/$rem);
      max-height: calc(389rem/$rem);
      background-color: #F6F7F7;
      margin-left: calc(49rem/$rem);
      box-sizing: border-box;
      border-radius: calc(6rem/$rem);
      padding: calc(8.7rem/$rem) calc(14rem/$rem) 0 calc(18rem/$rem);
    }
    .paymentLeft{  
      width: 100%;
      max-width: calc(341rem/$rem);
      max-height: 100%;
      height: calc(600rem/$rem);
    }
    .paymentserviceBlock{
      font-family: Heebo-Regular;
      font-size: calc(12rem/$rem);
      color: #3E3D3D;
      margin-bottom: calc(6rem/$rem);
    }
    .paymentBlock{
      max-height: calc(74rem/$rem);
      overflow: auto;
    }
    .paymentLineEnd{
      width: calc(309rem/$rem);
    }
    .paymentPrice{
      font-size: calc(16rem/$rem);
      margin-bottom: calc(3.6rem/$rem);
      margin-top: calc(13rem/$rem);
    }
    .paymentPriceAfterDiscount{
      font-size: calc(16rem/$rem);
      margin-bottom: calc(19.1rem/$rem);
    }
    .paymentDiscountBlock{
      width: calc(220.5rem/$rem);
      height: calc(64rem/$rem);
      background-color: #D3DDDC;
      border-radius: calc(5rem/$rem);
      padding: calc(6.5rem/$rem) calc(8rem/$rem) 0 0;
      margin-bottom: calc(14.9rem/$rem);
    }
    .paymentDiscount{
      font-size: calc(18rem/$rem);
      margin-bottom: calc(10.5rem/$rem);
      max-height: calc(20rem/$rem);
    }
  }

@media (max-width: calc(800rem/$rem)) {
  .tableContents{
    display: none;
  }
  .firstFilterTitle{
      font-size: calc(18rem/$rem);
  }
  .secondFilterTitle{
      font-size: calc(16rem/$rem);
  }
  .tableBlock, .filtersBlock{
    margin-left: 0;
    margin-right: 0;
    padding: 0 calc(18rem/$rem);
    width: calc(100% - calc(36rem/$rem));
  }
  .tableTitle{
    font-size: calc(20rem/$rem);
  }
  .tableMonth{
    font-size: calc(16rem/$rem);
    font-family: Heebo-regular;
    margin: calc(23rem/$rem) 0 calc(12rem/$rem);
    div{
      width: 100%;
    }
  }
  .mainContent{
    font-size: calc(14rem/$rem);
    height: calc(37rem/$rem);
  }
  .empty{
    display: none;
  }
}
@media (max-width: calc(700rem/$rem)) {
  .mobile{
    display: block;
  }
  .desctop{
    display: none;
  }
  .secondBlock{
    display: none;
  }
  .filtersBlock{
    margin: calc(34rem/$rem) 0  calc(27rem/$rem);
  }
  .mainContent{
    div{
      width: calc(100% / 5);
    }
  }
  .supplierName, .contractId{
    text-decoration: none;
    font-family: Heebo-Bold;
    cursor: default;
  }
  .details{
    display: block;
  }
  .table{
    margin-bottom: calc(273rem/$rem);
  }
}
@media (max-width: calc(600rem/$rem)) {
  .radio{
  label::before {
    width: calc(11.2rem/$rem) !important;
    height: calc(11.2rem/$rem) !important;
    right: -2.69rem;
    bottom: calc(13rem/$rem) !important;
  }
  input:checked+label::before {    
    background-size: 0.43rem;
  }
}
}