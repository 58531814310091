@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.title{
  font-weight: bold;
  margin: 20% auto 5%;
  color: #242323;
  text-align: center;
  font-family: Heebo-Regular;
  font-size: toRem(40);
}
.img{
  text-align: center;
}
.areaButton{
  cursor: pointer;
  border: none;
  padding: toRem(12) toRem(24);
  background-color: #E7B1AD;
  font-size: 1.38rem;
  color: white;
  box-shadow: 0 1.25rem 2.5rem #405F6A1F;
  border-radius: 0.31rem;
  font-size: 1.5rem;
  white-space: nowrap;
  height: 3.5rem;
}
.paddingButton{
  box-shadow: 0px 20px 40px #A29B9C61;
  padding-top:0.75rem;
}
.btn{
  text-align: center;
  padding-top: toRem(20);
}
@media (max-width: 53.75rem) {
  .title{
    font-size: toRem(26);
  }
}