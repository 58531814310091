.footerContainer {
  /* margin-top: 9.38rem; */
  /* padding: 3.13rem 5.63rem 2.5rem 5.63rem; */
  padding: 48px 0px 63px;
  background-color: #405f6a;
  color: white;
}

.logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  user-select: none;
}

.lists {
  display: flex;
  margin-top: 43px;
  justify-content: center;
}

.lists ul {
  list-style: none;
  padding-inline-start: 0;
  margin-top: 24px;
  margin-bottom: 0px;
}
.pointer {
  cursor: pointer;
}
.lists h3 {
  margin: 0;
  font-family: Assistant-Bold;
  font-size: 24px;
  line-height: 32px;
  user-select: none;
}

.remove {
  cursor: pointer;
  font-size: toRem(16);
  line-height: toRem(24);
}

.lists li,
.lists p {
  font-family: Assistant-Regular;
  font-size: 16px;
}

.lists li {
  line-height: 21px;
}
.lists li:not(:first-child) {
  margin-top: 6px;
}
.lists li:hover {
  text-decoration: underline;
}

.lists p {
  margin-top: 16.96px;
  line-height: 20.96px;
  margin-bottom: 0px;
}
.callToActionButton {
  margin-top: 24px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.listBlock:not(:last-child) {
  margin-inline-end: 155px;
}

.listItemWithIcons {
  margin-top: 10.08px;
  display: flex;
  align-items: center;
}

.instagrammIcon {
  margin-inline-end: 11.5px;
}

.link,
.listLinks {
  cursor: pointer;
}

.lists a {
  text-decoration: none;
  color: white;
}

.underlineLink {
  text-decoration: underline;
}

@media (max-width: 100rem) {
  .listBlock:not(:last-child) {
    margin-inline-end: 60px;
  }
}

@media (max-width: 60rem) {
  .lists {
    flex-direction: column;
    align-items: center;
  }

  .listBlock:not(:last-child) {
    margin-inline-end: 0px;
  }

  .flexColumn {
    align-items: center;
  }

  .suppliersList {
    order: 1;
  }

  .suppliersList ul {
    margin-top: 15px;
  }
  .lists h3 {
    font-size: 20px;
    line-height: 13px;
  }

  .supplierPageLink {
    order: 3;
  }

  .supplierPageLink .titleSupplier {
    margin-top: 27px;
  }

  .navList {
    order: 2;
  }

  .navList ul {
    margin-top: 24px;
  }
  .navList ul li {
    line-height: 29px;
    font-size: 20px;
    font-family: 'Assistant-Bold';
    margin-top: 0px !important;
  }
  .lists p {
    margin-top: 12px;
    line-height: 24px;
  }
  .contacts div {
    margin-top: 29px;
  }

  .contacts {
    order: 4;
  }

  .contacts ul {
    margin-top: 15px;
  }
  .contacts ul li:not(:first-child) {
    margin-top: 3px;
  }
  .listItemWithIcons {
    margin-top: 21.3px !important;
  }
  .contacts ul li {
    line-height: 24px;
  }

  .instagrammIcon {
    order: 1;
    margin-inline-end: 10.08px;
  }

  .facebookIcon {
    order: 2;
    margin-inline-end: 0px;
  }
  .mobile_dissabled {
    display: none;
  }
}

.totalValueCell:last-child {
  width: 26%;
}

@media (max-width: 40rem) {
  .footerContainer {
    padding: 28px 10.08px 34px;
  }
}
