@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}
.phoneInput{
  direction: ltr !important;
  text-align: end !important;
}
.mainContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4.0625rem;
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9b9999;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9b9999;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #9b9999;
  }
 
}
.checkboxDiv{
  font-family: Heebo-Regular;
  font-size: 1.25rem;
}
.form{
  display: flex;
  flex-direction: column;
  
  
  max-width: 64.6875rem;
  width: 100%;
}
.radio {
	margin-bottom: 1rem;
  position: relative;

  input {
    display: none;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    top: 1.5rem;
    right: 2.7rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }

  label {
    cursor: pointer;
    user-select: none;
    position: relative;
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    color: #3E3D3D;
    margin-inline-start: 2.8rem;
  }

  label::before {
    content: "";
    display: inline-block;
    width: 1.63rem;
    height: 1.63rem;
    position: absolute;
    right: -2.81rem;
    bottom: -.05rem;
    border: .13rem solid #707070;
    border-radius: 50%;
  }

  input:checked + label::before {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
  }
}

.inputContainer {
  position: relative;
  flex-grow: 1;

  .invalidField {
    background-color: #f3e8e8;
    outline: .06rem solid red;
  }  

  input {
    background-color: #EEE9E5;
    border: none;
    border-radius: 0.31rem;
    height: 4.56rem;
    font-family: Heebo-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    // color: #9b9999;
    padding-inline-start: 1.75rem;
    padding-inline-end: 1.5rem;
    width: 100%;
    box-sizing: border-box;  

  }
  
  input:focus {
    outline: .13rem solid #405F6A;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0.007rem;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}

.hideShowIcon {
  position: absolute;
  top: 1.2rem;
  left: 0.6rem;
  opacity: 0.3;
  cursor: pointer;
}

.dataInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .94rem
}

.passwordChoosingNote {
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.8125rem;
  margin-top: 1.81rem;
  margin-bottom: 0rem;
}

.passwordContainer {
  margin-top: .75rem;
  margin-bottom: 2.69rem;
}

@media (max-width: toRem(960)) {

  .checkboxDiv{
    font-size: 0.875rem;
  }
  .mainContainer{
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // width: 100%;
    padding-bottom: toRem(33);
  }
  
  .form{
    display: flex;
    flex-direction: column;
    max-width: 64.6875rem;
    width: 100%;
  }

  .radio {
    z-index: 50;
    margin-bottom: 1rem;
    // position: relative;
  
    // input {
    //   display: none;
    // }
  
    // .errorMessage {
    //   margin: 0;
    //   position: absolute;
    //   color: red;
    //   top: 1.5rem;
    //   right: 2.7rem;
    //   font-family: Heebo-regular;
    //   font-size: 0.65rem;
    // }
  
    label {
      cursor: pointer;
      user-select: none;
      position: relative;
      font-family: Heebo-Regular;
      font-size: toRem(14);
      line-height: toRem(21);
      color: #3E3D3D;
      margin-inline-start: toRem(30);
    }
  
    label::before {
      box-sizing: border-box;
      content: "";
      display: inline-block;
      width: toRem(22);
      height: toRem(22);
      position: absolute;
      right: toRem(-30);
      bottom: -.05rem;
      border: .13rem solid #707070;
      border-radius: 50%;
    }
  
    input:checked + label::before {
      background-size: toRem(10);
      // background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      // background-repeat: no-repeat;
      // background-position: center center;
      // background-origin: border-box;
    }
  }
  
  .inputContainer {
    // position: relative;
    // flex-grow: 1;
  
    // .invalidField {
    //   background-color: #f3e8e8;
    //   outline: .06rem solid red;
    // }  
  
    input {
      background-color: #FFFFFF;
      border: none;
      border-radius: toRem(5);
      height: toRem(54);
      font-family: Heebo-Regular;
      font-size: 1rem;
      line-height: 1.5rem;
      // color: #9b9999;
      padding-inline-start: 1.75rem;
      padding-inline-end: 1.5rem;
      width: 100%;
      box-sizing: border-box;    
    }
  
    // input:focus {
    //   outline: .13rem solid #405F6A;
    // }
  
    // .errorMessage {
    //   margin: 0;
    //   position: absolute;
    //   color: red;
    //   bottom: 0.007rem;
    //   right: 0.31rem;
    //   font-family: Heebo-regular;
    //   font-size: 0.65rem;
    // }
  }
  
  // .hideShowIcon {
  //   position: absolute;
  //   top: 1.2rem;
  //   left: 0.6rem;
  //   opacity: 0.3;
  //   cursor: pointer;
  // }
  
  .dataInputs {
    // width: 100%;
    // display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // gap: .94rem
  }
  
  // .passwordChoosingNote {
  //   font-family: Heebo-Regular;
  //   font-size: 1.25rem;
  //   line-height: 1.8125rem;
  //   margin-top: 1.81rem;
  //   margin-bottom: 0rem;
  // }
  
  .passwordContainer {
    // margin-top: .75rem;
    margin-bottom: toRem(25);
  }
  
}

@media (min-width: toRem(961)) {
  .containerMobile {
    display: none;
  }
}
