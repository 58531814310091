.modal_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 594px;
  width: 100%;
  // height: 400px;
  background-color: white;
  box-shadow: 3px 6px #00000029;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 0px 0px 24px;
  // border: 1px solid #707070;
  border-radius: 10px;
  // form {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: -34.75px;
    right: 0;
    width: 21px;
    color: white;
  }
  .boxImg {
    background-image: url("../../../assets/images/liteLogin/stilnye-svadby-min-scaled.jpg");
    height: 258px;
    width: 100%;
    background-size: cover;
    border-radius: 10px 10px 0px 0px;
  }
}
.steps_container {
  max-width: 506px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .steps_button {
    height: 42px;
    max-width: 145px;
  }
  .phone_title {
    margin-top: 26px;
    font-size: 22px;
    line-height: 32px;
    font-family: "Heebo-Bold";
    letter-spacing: 0.29px;
  }
  .phone_button {
    margin-top: 34px;
  }
  .description {
    margin-top: 9.13px;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.21px;
    text-align: center;
  }
  .checkBox_container {
    margin: 0 auto;
    margin-top: 15px;
    width: 100%;
  }
  .text_helper {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.18px;
    text-align: center;
    margin-top: 19px;
    transition: 0.5s;
  }
  .text_helper_active {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      scale: 1.1;
    }
  }
  .text_helper_disable {
    opacity: 0.8;
  }
  .code_button {
    margin-top: 19px;
  }
}
.modal_container:focus-visible {
  outline: none;
}

@media (max-width: 1536px) {
  .modal_container {
    max-width: 450px;
    .closeIcon {
      width: 18px;
    }
    .boxImg {
      height: 180px;
    }
  }
  .steps_container {
    max-width: 362px;
    .phone_title {
      margin-top: 22px;
      font-size: 20px;
      line-height: 28px;
    }
    .phone_button {
      margin-top: 28px;
    }
    .description {
      font-size: 14px;
      line-height: 20px;
    }
    .checkBox_container {
      margin-top: 13px;
    }
    .text_helper {
      font-size: 12px;
      line-height: 19px;
      margin-top: 17px;
    }
    .code_button {
      margin-top: 17px;
    }
  }
}

@media (max-width: 600px) {
  .modal_container {
    padding: 0px 0px 12px;
    max-width: 352px;
    .closeIcon {
      width: 14.25px;
    }
    .boxImg {
      height: 141px;
    }
  }
  .steps_container {
    max-width: 316px;
    .steps_button {
      height: 45px;
      max-width: 83px;
      font-size: 14px;
      line-height: 21px;
    }
    .phone_title {
      margin-top: 18px;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0px;
    }
    .phone_button {
      margin-top: 27px;
    }
    .description {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    .checkBox_container {
      margin-top: 10px;
    }
    .text_helper {
      letter-spacing: 0.16px;
      line-height: 18px;
      margin-top: 12px;
      &:hover {
        scale: none;
      }
    }
    .code_button {
      margin-top: 12px;
    }
  }
}
@media (max-height: 700px) {
  .modal_container {
    max-height: calc(100vh - 110px);
    overflow-y: scroll;
  }
}
