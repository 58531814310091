.header_suppliers {
  background-color: #f8f4ee;
  position: relative;
}
.helper_text_container {
  position: absolute;
  width: 100%;
  height: 47px;
  background-color: #405f6a;
  font-size: 14px;
  line-height: 21px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type_event_container {
  margin: 0 auto;
  padding-top: 55px;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 350px;
  width: 100%;
  .form_arrow {
    position: absolute;
    top: 75%;
    left: -20%;
  }
  .label {
    margin-left: 18px;
    font-size: 20px;
    line-height: 29px;
    font-family: "Heebo-Medium";
  }
}

.categories_container {
  display: flex;
  max-width: 1655px;
  padding: 0px 0px 77px;
  margin: 69px auto 0px;
  justify-content: space-around;
  .category_container {
    cursor: pointer;
    max-width: 99px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .category_img_container {
      height: 61px;
      max-width: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .category_name {
      text-align: center;
      font-size: 16px;
      position: relative;
      line-height: 24px;
      transition: 0.5s;
    }
    .category_name::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 120%;
      transition: 0.5s;
      border-bottom: 3px solid #f8f4ee;
    }
    &:hover:not(.select_category) > .category_name::after {
      width: 100%;
      border-bottom: 3px solid #405f6a;
    }
    &.select_category > .category_name::after {
      border-bottom: 3px solid #405f6a;
      width: 100%;
    }
    &:not(:last-child) {
      margin-left: 5px;
    }
    &:not(:first-child) {
      margin-right: 5px;
    }
  }
}

.filters_container {
  margin-top: -70px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .more_box{

    // position: absolute;
    display: flex;
    flex-direction: column;
    gap: 14px;
    z-index: 90;
    background-color: white;
    // height: 40px;
    padding: 17px 32px 16px;
    max-width: 1476px;
    box-sizing: border-box;
    border: 1px solid #405f6a;
    border-radius: 15px;
    box-shadow: 0px 3px 20px #00000029;
    .filters_box {
      display: flex;
      .filters_edit_text {
        margin-left: 3px;
        text-decoration: underline;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 16px;
        min-width: 80px;
        line-height: 24px;
        cursor: pointer;
      }
    }
    .filters_one_input_full_row{
      align-self: center;
      display: flex;
      justify-content: center;
      width: 600px;
      height: 42px;
  // &:not(:last-child) {
    }
  }
}
.filters_one_input {
  // max-width: 250px;
  // width: 100%;
  width: 260px;
  height: 42px;
  // &:not(:last-child) {
  margin-left: 10px;
  // }
}
.filters_one_input_range {
  width: 300px;
  height: 42px;
  margin-left: 10px;
}

.content_container {
  max-width: 1742px;
  margin: 0 auto;
  padding: 0px 10px;
  .content_title {
    text-align: center;
    font-size: 24px;
    line-height: 35px;
    font-family: "Heebo-Bold";
  }
}
.category_filters_container,
.mobile_category_filters_container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 7px;
  .category_filter {
    cursor: pointer;
    background-color: #f7f7f7;
    border: 1px solid #0000004d;
    text-align: center;
    max-width: 145px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover:not(.selected) {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    }
    &:focus-visible {
      outline: none;
      border: 1px solid #405f6a;
    }
    &.selected {
      background-color: #405f6a;
      color: white;
      border: 1px solid #405f6a;
    }
  }
}
.mobile_category_filters_container {
  gap: 0px;
  flex-direction: column;
  padding: 0px 19px;
  .mobile_category_filters_row {
    margin-top: 33.5px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .category_filter {
      height: unset;
      font-size: 14px;
      line-height: 21px;
      width: unset;
      padding: 11px 30px;
    }
  }
}
.mobile_filters_container {
  margin-top: 15px;
}
.mobile_filters_container {
  .filters_sidebar_title {
    padding-right: 19px;
    box-sizing: border-box;
  }
}
.filters_sidebar_container {
  hr {
    position: relative;
    right: -19px;
    width: calc(100% + 19px);
  }
  .filters_sidebar_title {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    font-family: "Heebo-Bold";
    margin-bottom: 6.5px;
  }
}

.action_buttons_container {
  border: 1px solid #667e87;
  border-width: 1px 0px;
  display: none;
  height: 48px;
  width: 100vw;
  box-sizing: border-box;
  padding: 9.5px 0px 8.5px;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  .action_button_container {
    flex: 1 0 50%;
    text-align: center;
    .action_button {
      display: inline-block;
      color: #405f6a;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1500px) {
  .filters_one_input {
    width: 205px;
  }
  .filters_one_input_range {
    width: 240px;
  }
}

@media (max-width: 1200px) {
  .categories_container {
    overflow: scroll;
    justify-content: unset;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    // margin: 0px 20px;
    .category_container {
      // min-width: 99px;
      // max-width: unset;
      flex: 1 0 auto;
    }
  }
  .filters_one_input {
    width: 100%;
    max-width: 220px;
    // height: 38px;
  }
  .filters_one_input_range {
    width: 100%;
    max-width: 220px;
  }
}

@media (max-width: 900px) {
  .filters_container {
    .filters_box {
      max-width: 96vw;
      padding: 12px 16px 0 12px;
      overflow: hidden;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 10px;
    }
  }
  .filters_one_input {
    flex: 1 0 auto;
  }
  .filters_one_input_range {
    flex: 1 0 auto;
  }
}

@media (max-width: 720px) {
  .filters_one_input {
    height: 35px;
    max-width: 231px;
  }
  .filters_one_input_range {
    height: 35px;
    max-width: 231px;
  }
  .action_buttons_container {
    display: flex;
  }
  .filters_container {
    margin-bottom: 0px;
    margin-top: 0;
    .filters_box {
      display: none;
    }
  }
  .categories_container {
    margin-top: 24.5px;
    padding-bottom: 27.5px;
    .category_container {
      min-width: unset;
      // &:not(:last-child) {
      //   margin-left: 7.5px;
      // }
      // &:not(:first-child) {
      //   margin-right: 7.5px;
      // }
      // flex: 1 0 auto;
      max-width: 77px;
      .category_img_container {
        img {
          height: 100%;
          width: 100%;
        }
        //   margin: 0 auto;
        height: 36px;
        max-width: 36px;
      }

      .category_name {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .content_container {
    padding: 0px;
    .content_title {
      display: none;
    }
  }
  .type_event_container {
    flex-direction: column;
    .label {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5.5px;
    }

    .form_arrow {
      max-width: 36px;
      left: 30px;
      top: 80%;
    }
  }
}
