.imageContainer {
  width: 100%;
}

.imageContainer > div {
  width: 100%;
  padding-bottom: 65%;
  position: relative;
}

.imageContainer > div > div {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.defaultImg {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

.linkForSuppliers {
  text-decoration: underline;
  margin-top: 0.94rem;
  font-family: Heebo-Medium;
  font-size: 1.5rem;
  line-height: 2.19rem;
  text-align: end;
}

@media (max-width: 80rem) {
  .imageContainer {
    width: 100%;
  }

  .imageContainer {
    max-width: none;
  }

  .linkForSuppliers {
    text-align: end;
  }
}

@media (max-width: 40rem) {
  .linkForSuppliers {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
