$rem: 16;
.main{
  background: #F4F4F4 0% 0% no-repeat padding-box;
  // height: 100vh;
  width: 100%;
  height: 100%;
}

.dropDownContainer{
  position: relative;
  
  width: 100%;
  height: 100%;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  //  width: 12.75rem;
  // margin: 0 auto;
}

.arrow{
  width: calc(19rem/$rem);
  height: calc(19rem/$rem);
}

.headerOption{
  font-family: Heebo-Regular;
  color: inherit;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-size: calc(20rem/$rem);
  // max-width: 10.18rem;
}

.dropDownHeader{
  box-sizing: border-box;
  border: 0.0625rem solid #405F6A;
  width: calc(218rem/$rem);
  min-height: calc(47rem/$rem);
  border-radius: calc(2rem/$rem);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.5rem;
  // box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  // font-size: 1.25rem;
  font-size: 1em;
  // color: #242323;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropDownListContainer{
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  // width: 100%;
  // width: 12.75rem;
}

.dropDownList{
  background: #F4F4F4 0% 0% no-repeat padding-box;
  max-width: 100%;
  box-sizing: border-box;
  max-height: 20rem;
  overflow-y: auto;
  margin: 0;
  padding-left: 1rem;
  // border: 2px solid #e5e5e5;

  box-shadow: 0px 10px 20px #00000029;
  &:first-child {
    padding-top: 1rem;
  }
}

.listItem{
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  display: flex;
  align-items: center;
  // height:1.5rem ;
  list-style: none;
  margin-bottom: 0.78rem;
  padding-right: 0.75rem;

  &:hover {
    cursor: pointer;
    font-family: Heebo-Bold;
  }
}
