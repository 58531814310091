.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f8f4ef;
  padding: 500px 0px 46px;

  .img_container {
    flex: 1 0 50%;
    position: relative;
    top: -40px;
    img {
      max-height: 826px;
      max-width: 733px;
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 50%;
    position: relative;
    right: -30px;
    .text_title {
      font-size: 24px;
      line-height: 35px;
      color: #405f6a;
      font-family: "Heebo-Bold";
      .text_title_additional {
        display: none;
      }
    }
    .text_descriprion {
      font-size: 18px;
      line-height: 30px;
      margin-top: 33px;
    }
    .button_container {
      margin-top: 33px;
    }
  }
}
.row_items {
  margin-top: 50px;
}
.one_item {
  font-size: 18px;
  line-height: 27px;
}
.one_item:not(:last-child) {
  margin-bottom: 13px;
}

@media (max-width: 1536px) {
  .text_block {
    margin-right: 20px;
    max-width: 600px;
  }
  .container {
    padding: 500px 19px 46px;
    .img_container {
      top: 0px;
      display: flex;
      align-items: center;
    }
    .text_container {
      right: 0px;
      .text_title {
        font-size: 23px;
      }

      .text_descriprion {
        font-size: 16px;
      }
      .text_descriprion br {
        display: none;
      }
      .button_container {
        margin-top: 28px;
      }
    }
  }
  .one_item {
    font-size: 16px;
  }
  .one_item br {
    display: none;
  }
  .row_items {
    margin-top: 25px;
  }
}

@media (max-width: 900px) {
  .text_block {
    margin-right: 0px;
  }
  .container {
    flex-direction: column;
    // padding: 259px 19px 43px;
    .img_container {
      top: 530px;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      img {
        width: auto;
        max-width: 296px;
        max-height: 377px;
      }
    }
    .text_container {
      .text_title {
        font-size: 18px;
        line-height: 29px;
        .text_title_additional {
          display: inline;
          font-size: 18px;
        }
      }
      .text_descriprion {
        margin-top: 18px;
        font-size: 14px;
        line-height: 23px;
      }
      .button_container {
        margin-top: 18px;
      }
    }
  }
  .row_items {
    margin-top: 18px;
  }
  .one_item {
    font-size: 14px;
    line-height: 22px;
  }
  .one_item:not(:last-child) {
    margin-bottom: 11px;
  }
}
