@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(1px);
  }
  50% {
    transform: translateX(-1px);
  }
  75% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox + label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox + label::before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.checkbox.circle_agree + label::before {
  height: 18px;
  width: 18px;
  border: 1px solid #405f6a;
  border-radius: 50%;
  margin-left: 10px;
}

.checkbox.circle_select + label::before {
  height: 12px;
  width: 12px;
  border: 1px solid #707070;
  border-radius: 50%;
  margin-left: 12px;
}
.checkbox.basic + label::before {
  height: 12px;
  width: 12px;
  border: 1px solid #707070;
  margin-left: 9px;
  border-radius: 2px;
}
.checkbox.circle_agree:checked + label::before {
  border-color: #405f6a;
  background-image: url('../../../assets/images/customCheckBox/circle_agree.svg');
  background-position: center;
  background-size: contain;
}
.checkbox.circle_select:checked + label::before {
  border-color: #405f6a;
  background-image: url('../../../assets/images/customCheckBox/circle_select.svg');
  background-position: center;
  background-size: contain;
}
.checkbox.basic:checked + label::before {
  border-color: #707070;
  background-image: url('../../../assets/images/customCheckBox/Vector4.svg');
  background-position: center;
  background-size: contain;
}

.checkbox.circle_agree + .label {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.21px;
}
@media (max-width: 600px) {
  .checkbox.circle_agree + label::before {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
  .checkbox.circle_agree + .label {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.18px;
  }
}
// .checkbox.error + .label {
//   color: red;
// }
.checkbox.error + label::before {
  animation: horizontal-shaking 1s;
  border: 1px solid red;
}

// .checkbox:not(:disabled):not(:checked) + label:hover::before {
//   border-color: rgba(0, 0, 255, 0.33);
// }
// .checkbox:not(:disabled):active + label::before {
//   background-color: rgba(0, 0, 255, 0.66);
// }
// .checkbox:focus + label::before {
//   box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.125);
// }
// .checkbox:focus:not(:checked) + label::before {
//   border-color: #c3c3c3;
// }
// .checkbox:disabled + label::before {
//   background-color: black;
// }

// .checkbox.basic:not(:disabled):not(:checked) + label:hover::before {
//   border-color: rgba(0, 0, 255, 0.33);
// }
// .checkbox.basic:not(:disabled):active + label::before {
//   background-color: rgba(0, 0, 255, 0.66);
// }
// .checkbox.basic:focus + label::before {
//   box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.125);
// }
// .checkbox.basic:focus:not(:checked) + label::before {
//   border-color: #405f6a;
// }
// .checkbox.basic:disabled + label::before {
//   background-color: black;
// }
