.react-aria-Calendar,
.react-aria-RangeCalendar {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    margin-top: 15.5px;
    flex-direction: row-reverse;
    justify-content: center;
    button {
      border: none;
      background-color: inherit;
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0px 8px;
      img {
        max-width: 11px;
        width: 100%;
        height: 100%;
        max-height: 11px;
      }
    }
    h2 {
      min-width: 110px;
      text-align: center;
      font-size: 18px;
      /* margin-top: 11px; */
      line-height: 23px;
      font-family: 'Assistant-SemiBold';
      letter-spacing: 0.23px;
    }
  }
}

.react-aria-CalendarGridBody {
  .react-aria-CalendarCell {
    max-width: none;
  }
  .react-aria-CalendarCell[data-selected='true'] {
    background-color: #dfb2b4;
    background-color: rgba($color: #dfb2b4, $alpha: 0.5);
  }
  .react-aria-CalendarCell[data-selection-start='true'] {
    background-color: #dfb2b4;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .react-aria-CalendarCell[data-selection-end='true'] {
    background-color: #dfb2b4;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.react-aria-CalendarGrid {
  margin-top: 13px;
  border-spacing: 0;
  border-collapse: collapse;
}

.react-aria-CalendarGridHeader tr,
.react-aria-CalendarGridHeader th,
.react-aria-CalendarGridBody tr,
.react-aria-CalendarGridBody tr td {
  padding: 0px;
  margin: 0px;
}

.react-aria-CalendarHeaderCell {
  font-size: 16px;
  font-family: 'Assistant-SemiBold';
  letter-spacing: 0.21px;
  line-height: 21px;
}
.react-aria-CalendarCell {
  max-width: 34px;
  width: 100%;
  margin: 0 auto;
  height: 34px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.21px;
  font-family: 'Assistant-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.react-aria-CalendarCell:hover,
.react-aria-CalendarCell[data-selected='true'] {
  background-color: #dfb2b4;
  border-radius: 2px;
}

.react-aria-CalendarCell:focus-visible {
  outline: none;
}
.react-aria-CalendarCell[data-disabled='true'] {
  opacity: 0.5;
  cursor: default;
}
.react-aria-CalendarCell[data-disabled='true']:hover {
  opacity: 0.4;
  background-color: unset;
  border-radius: unset;
}
@media (max-width: 720px) {
  .react-aria-Calendar .react-aria-Button,
  .react-aria-RangeCalendar .react-aria-Button {
    width: 25px;
    justify-content: center;
  }
}
