.swiperSlideComponentContainer {
  width: 71%;
}

.swiperSlideContainer {
  border-bottom: 0.06rem solid #A4A2A2;
  width: auto;
}

.arrowContainer {
  display: flex;
  direction: ltr;
  margin-top: 0.63rem;
}

.arrow {
  cursor: pointer;
}

.arrow:not(:first-child) {
  margin-left: 1.25rem  ;
}

.swiperSlideSelected, .swiperSlide {
  font-family: Heebo-Regular;
  font-size: 1.5rem;
  line-height: 2.19rem;
  color: #434242;
  padding-top: 0.63rem;
  padding-bottom: 0.94rem;
  cursor: pointer;
}

.swiperSlideSelected:not(:last-child), .swiperSlide:not(:last-child) {
  padding-left: 3.69rem;
}

.swiperSlideSelected {
  color: #eaa7ad;
  font-weight: bold;
}

.disableSliderNextPrev {
  pointer-events: none;
}

.arrowButton {
  background-color: white;
  padding: 0;
  cursor: pointer;
  border: none;
}

@media (max-width: 80rem) {
  .swiperSlideSelected, .swiperSlide {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media (max-width: 60rem) {
  .swiperSlideComponentContainer {
    width: 100%;
  }

  .arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .arrowContainer {
    margin-top: 0.69rem;
  }

  .swiperSlideSelected:not(:last-child), .swiperSlide:not(:last-child) {
    padding-left: 1.25rem;
  }
}

@media (max-width: 40rem) { 
  .swiperSlideSelected, .swiperSlide {
    font-size: 1.25rem;
    line-height: 1.81rem;
  }

  .arrow svg{
    scale: 78%;
  }

  .arrow:not(:first-child) {
    margin-left: 1.07rem  ;
  }
}
