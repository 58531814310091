
$rem: 16;
.main{
  height: 48.4375rem;
  display: flex;
  background-color: #E7B1AD;
  justify-content: center;
}
input{
  font-family: Heebo-Regular;
}
.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.blockCalendar{
  background-color: rgba(64, 95, 106, 0.9);
}
.phoneInput{
  direction: ltr !important;
  text-align: end !important;
}
.infoDialogClose{
  background-color: #FFFFFF;
  height: calc(200rem/$rem);
  border-radius: calc(5rem/$rem);
}
.infoDialogtitle{
  text-align: center;
  display: flex;
  font-family: Heebo-Bold;
  font-size: calc(16rem/$rem);
  color: #242323;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  align-items: center;
}
.mainPopup::-webkit-scrollbar { width: 0; }

.info{
  height: 36.4375rem;
  display: flex;
  margin: 6rem 6rem 6rem 8.375rem;
  width: calc(100% - 14.375rem);
}
.infoLeft{
  width: 53.5%;
  background-color: #EAE8E8;
}
.infoRight{
  width: 46.5%;
}
.mainImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: auto !important; 
}
.infoTitle{
  font-family: Heebo-Medium;
  text-align: center;
  padding-top: 10.25rem;
  margin-right: 5rem;
  font-size: 2rem;
  font-weight: 500;
  height: 2.9375rem;
  overflow: hidden;
}
.description{
 padding: 1.5rem 5.6875rem 0rem 0.625rem;
 font-size: 1.5rem;
 height: 11.4rem;
 overflow: hidden;
 text-align: center;
 overflow: auto;
 a{
  text-decoration: underline;
 }
}
.description::-webkit-scrollbar{
  width: 0;
}
.desctopMainBlock{
  display: block;
  height: 3.3125rem;
}
.infoButton{
  font-family: Heebo-Regular;
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0rem 1.25rem 2.5rem #405F6A24;
  color: white;
  cursor: pointer;
  height: 3.5rem;    
  border-radius: 0.31rem;
  border: none;
  font-size: 1.375rem;
  padding: 0.75rem 1.5rem;
}
.infoLink{
  color: red;
  font-size: 1.375rem;    
  padding-left: 7.55rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.blockButton{
  margin-top: 2.0625rem;
  display: flex;
  padding-right: 5.6875rem;
  justify-content: center;
}

.inputContainer {
  position: relative;
  flex-grow: 1;

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}
.watsApp{
  z-index: 999;
  box-sizing: border-box;
  width: 14.75rem;
  height: 6rem;
  background-color: #1EE62C;
  color:#FFFFFF;
  border-radius: 1.375rem;
  display: flex;
  padding: 1rem 1.125rem;
  position: fixed;
  bottom: 2.5625rem;
  right: 6rem;

  .watsAppIcon{
    width: 3.75rem;
    height: 3.75rem;
  }
  .watsAppText{
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.swiperSlideContainer {
  margin-top: 5.0625rem;
  box-sizing: border-box;
  //height: 51.75rem;
  align-items: center;
  padding-left: 6rem;
  padding-right: 5.875rem;
}
.slideWrapper {
  background-color: #ffffff;
  width: 33.5rem;
  display: flex;
  align-items: center;
}
.mobileSwiperSlide {
  display: none;
}
.slideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer; 
}
.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
.slideVideo {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  user-select: none;
}
.arrowContainer {
  margin-top: 2.56rem;
  width: 3.88rem;
  display: flex;
  justify-content: space-between;
  direction: rtl;
  margin-left: 0;
  margin-right: auto;
}
.imgBlock{
  width: 33.5rem;
  height: 36.4375rem;
}
.slideTitle{
  cursor: pointer;
  font-size: 1.5rem;
  color: #242323;
  margin-top: 2rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;    
  font-weight: 500;
  font-family: Heebo-Medium;
}
.slideDescription{
  cursor: pointer;
  font-size: 1.5rem;
  color: #242323;
  margin-top: 1.1875rem;
  padding: 0 0 0rem 0;
  margin-bottom: 9.6875rem;
  height: 17.75rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  a{
    text-decoration: underline;
  }
}

.blogTitle{
  display: none;
}

.button{
    box-sizing: border-box;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.375rem;
    border-radius: 0.3125rem;
    background: #405F6A 0% 0% no-repeat padding-box;
    box-shadow: 0rem 1.25rem 2.5rem #221B4A21;
    padding: 0.75rem 1.5rem;
    box-sizing: border-box;
  }
  .beforeFooter{
    height: 4.625rem;
  }
  
.fifth{
    width: 100%;
    background-color: #EEE9E5;
    min-height: 21.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2.125rem 6rem 0 6rem;
  
    .fifthTitle{
      width: 100%;
      text-align: center;
      font-family: Heebo-Medium;
      font-size: 2rem;
      padding-bottom: 3.5625rem ;
    }
  
    .fifthFields{
      display: flex;
      input + input {
        margin-right: 2rem;
      }
      input {
        // min-width: 23.6875rem;
        background-color: #FFFFFF;
        border: none;
        height: 4.5625rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.69rem;
        padding-inline-end: 1.5rem;
      }
    }
    
    .fifthBtn{
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 2.4375rem 0 2.8125rem 0 ;
    }
  }

  @media (max-width:106.25rem) {
    .infoTitle{
      padding-top: 4rem;   
      height: 2.5rem;
    }
  }

  @media (max-width:87.5rem) {
    .infoTitle{
      padding-top: 6.25rem;    
      font-size: 1.5rem;
      margin-right: 2.6875rem;
    }
    .description{
     padding: 1.5rem 2.6875rem 0rem 0.625rem;
     font-size: 1rem;
     height: 10.8rem;
     text-align: center;
    }
    .blockButton{
      margin-top: 2.0625rem;
      padding-right: 2.6875rem;
    }
    .infoButton{
      font-size: 0.875rem;
    }
    .infoLink{
      font-size: 0.875rem;    
      padding-left: 0;
    }
    .info{
      height: 36.4375rem;
      display: flex;
      margin: 6rem 2rem 6rem 2.375rem;
      width: calc(100% - 4.375rem);
    }
  }
  @media (max-width: 53.75rem) {
    .inputContainer{
      margin-left: 0 !important;
    }
    .errorMessage{
      bottom: 0;
    }
    .watsApp{
      width: 10.375rem;
      height: 4.18rem;
      padding: 0.75rem 0.8125rem;
  
      .watsAppIcon{
        img{
          width: 2.68rem;
          height: 2.68rem;
        }
      }
  
      .watsAppText{
        font-size: 0.875rem;
      }
    }
    .watsApp{
      right: 1.125rem;
      bottom: 3.5rem;
    }
  .fifth{
    align-items: flex-start;
    padding: 2.25rem 1.0625rem 0 1.0625rem;

    .fifthTitle{
      font-size: 1.5rem; 
      text-align: start;
      padding-bottom: 2.1875rem;
    }

    .fifthFields{
      display: flex;
      flex-direction: column;
      width: 100%;

      input{
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 0.9375rem;
      }

      input + input {
        margin-right: 0rem;
      }

      .fifthBtn{
        padding: 1.5625rem 0 2.5625rem 0 ;
      }
    }
  }

  .beforeFooter{
    height: 0rem;
  }

  .info{
    flex-direction: column;
  }
  .infoLeft{
    width: 100%;
    height: 50%;
  }
  .infoTitle{
    justify-content: center;
    padding-top: 2.25rem;
  }
  .infoRight{
    width: 100%;
    height: 100%;
  }
.beforeFooter{
    height: 4.625rem;
  }
  
  .beforeFooter{
    height: 0rem;
  }
}

@media (max-width: 69.0625rem) {
  .fifthFields{
    input {
      width: 15.6875rem ;
    }
  }
}

@media (min-width: 69.125rem) {
  .fifthFields{
    input {
      max-width: 20.5rem;
      width: 20.5rem;
    }
  }
}


@media (max-width: 61.25rem) {
  .swiperSlideContainer {
    padding-left: 2.19rem;
    padding-right: 2.19rem;
  }
  .description{
    padding: 1.5rem 0.625rem 0rem 0.625rem;
  }
  .blockButton{
    margin-top: 1.0625rem;
    padding-right: 0.625rem;
  }
}
@media (max-width: 43.75rem) {
  .swiperSlideContainer {
    display: none;
  }  
  .content{
    width: 100%;
  }
  .slideVideo {
    height: 16.8125rem;
  }
  .imgContainer{
    margin: 0 1.0625rem 0 1.125rem;
  }
  .main{
    display: none;
  }
  .blogTitle{
    display: flex;
    text-align: right;
    color: #242323;
    font-size: 1.5rem;
    margin: 1.25rem 1.125rem 2.75rem;
    font-weight: bold;
  }
  .slide{
    height: 100%;
  }
  .mobileSwiperSlide {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
    height: 100%;
  }
  .mobileImgBlock{
    height: 16.8125rem;
    background-color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
  }  
  .mobileSlideTitle{
    cursor: pointer;
    font-family: Heebo-Medium;
    font-size: 1.5rem;
    color: #242323;
    margin: 1.0625rem 1.125rem 1.0625rem 2.5625rem;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;    
    font-weight: 500;
  }
  .mobileSlideWrapper{
    background-color: #ffffff;
    width: 100%;
    display: flex;
    height: fit-content !important;
  }
  .sliderInfoButton{
    display: flex;
  }
  .mobileSliderButton{
    font-family: Heebo-Regular;
    cursor: pointer;
    border-radius: 0.3125rem;
    box-shadow: 0rem 1.25rem 2.5rem #E799A061;;
    background: #405F6A 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    font-size: 1.125rem;
    width: 7.5625rem;
    height: 3.1875rem;
    border: none;
  }
  .mobileSlideDescription{
    cursor: pointer;
    font-size: 1.125rem;
    color: #242323;
    margin: 0rem 1.125rem 1.375rem 1.0625rem;
    max-height: 11.9375rem;
    overflow: hidden;
    a{
      text-decoration: underline;
    }
  }
  .mobileButtonBlock{
    margin-bottom: 2.1875rem;
    margin-right: 1.125rem;
  }
  .mobileImg{
    width: 100%;
  }
}
@media (max-width: 40rem) {
  .swiperSlideContainer {
    margin-top: 3.25rem;
    padding-top: 1.88rem;
  }
  .imgBlock{
    width: 90%;
  }
  .slideTitle{
    width: 90%;
  }
  .slideDescription{
    width: 90%;
  }
  .arrowContainer {
    display: none;
  }
}
@media (max-width: 37.5rem) { 
  .fifthTitle{
    direction: ltr;
    text-align: right !important;
    max-width: 256px;
    font-family: Heebo-Medium !important;
    margin: 0.0625rem 0rem 1.75rem 6.3125rem;
    padding-bottom: 0rem !important;
  }
  .fifthBtn{
    margin: 0.8125rem 0rem 2.4375rem 0rem;
    padding: 0 !important;
  }
  .button{
    font-size: 1.125rem;
    width: 5.125rem;
    max-width: 5.125rem;
    height: 3.1875rem;
    max-height: 3.1875rem;
  }
  .fifth{
    margin-bottom: 3.75rem;
  }
  .desctop{
    display: none;
  }
}
@media (max-width: 35.625rem) { 
  .imgBlock{
    width: 80%;
  }
  .slideTitle{
    width: 80%;
  }
  .slideDescription{
    width: 80%;
  }
}
@media (max-width: 32.5rem) { 
  .imgBlock{
    width: 70%;
  }
  .slideTitle{
    width: 70%;
  }
  .slideDescription{
    width: 70%;
  }
}
@media (max-width: 29.375rem) { 
  .imgBlock{
    width: 62%;
  }
  .slideTitle{
    width: 62%;
  }
  .slideDescription{
    width: 62%;
  }
}
@media (max-width: 26.5625rem) { 
  .imgBlock{
    width: 60%;
  }
  .slideTitle{
    width: 60%;
  }
  .slideDescription{
    width: 60%;
  }
}
@media (max-width: 25.75rem) {
  .desctopMainBlock{
    display: none;
  } 
  .swiperSlideContainer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .swiperSlideContainer {
    display: none;
  }
  .mobileSwiperSlide {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin: 0;
    height: 100%;
  }
  .content{
    width: 100%;
  }
  .slideVideo {
    height: 16.8125rem;
  }
  .imgContainer{
    margin: 0 1.0625rem 0 1.125rem;
  }
  .main{
    display: none;
  }
  .blogTitle{
    display: flex;
    text-align: right;
    color: #242323;
    font-size: 1.5rem;
    margin: 1.25rem 1.125rem 2.75rem;
    font-weight: bold;
  }
  .slide{
    height: 100%;
  }
  .mobileImgBlock{
    height: 16.8125rem;
    background-color: #FFFFFF;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
  }  
  .mobileSlideTitle{
    cursor: pointer;
    font-family: Heebo-Medium;
    font-size: 1.5rem;
    color: #242323;
    margin: 1.0625rem 1.125rem 1.0625rem 2.5625rem;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;    
    font-weight: 500;
  }
  .mobileSlideWrapper{
    background-color: #ffffff;
    width: 100%;
    display: flex;
    height: fit-content !important;
  }
  .sliderInfoButton{
    display: flex;
  }
  .mobileSliderButton{
    font-family: Heebo-Regular;
    cursor: pointer;
    border-radius: 0.3125rem;
    box-shadow: 0rem 1.25rem 2.5rem #E799A061;;
    background: #405F6A 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    font-size: 1.125rem;
    width: 7.5625rem;
    height: 3.1875rem;
    border: none;
  }
  .mobileSlideDescription{
    cursor: pointer;
    font-size: 1.125rem;
    color: #242323;
    margin: 0rem 1.125rem 1.375rem 1.0625rem;
    max-height: 11.9375rem;
    overflow: hidden;
    a{
      text-decoration: underline;
    }
  }
  .mobileButtonBlock{
    margin-bottom: 2.1875rem;
    margin-right: 1.125rem;
  }
  .mobileImg{
    width: 100%;
  }
}
  