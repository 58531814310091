@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}
$rem: 16;
.inp,.priceServ,.inpWithError{
  position: relative;
  
  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: 0rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}
.select{
  font-size: 1rem;
  width: calc(319rem/$rem);
  height: calc(45rem/$rem);
  margin: 40px auto 0;
}
.doubleRadio {
  margin-top: toRem(5);
  display: flex;
  gap: toRem(15);
  align-items: flex-end;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: toRem(97);

  .buttons {
    padding-top: toRem(70);
    padding-bottom: toRem(45);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: toRem(15);

    .save {
      padding: toRem(12) toRem(24);
      border-radius: toRem(5);
      background-color: #405f6a;
      color: #ffffff;
      cursor: pointer;
      font-size: toRem(22);
      line-height: toRem(32);
    }
  }

  .accordion {
    max-width: toRem(955);
    width: 100%;
    padding-bottom: toRem(47);
    padding-top: toRem(19);
    background-color: #eee9e5;

    .tabTitle {
      line-height: toRem(29);
      padding-bottom: toRem(15);
      font-size: toRem(20);
      font-family: "Heebo-Bold";
    }

    .catTitle {
      margin-bottom: toRem(-7);
      padding-top: toRem(7);
      font-size: toRem(22);
      line-height: toRem(32);
    }

    .goToImages {
      cursor: pointer;
      text-decoration: underline;
      font-size: toRem(20);
      line-height: toRem(29);
    }

    .eventsContainer {
      position: relative;
      box-sizing: border-box;
      width: toRem(422);
      height: toRem(56);
    }

    .inputContainer {
      position: relative;
      box-sizing: border-box;
      width: toRem(379);
      height: toRem(73);

      .invalidField {
        background-color: #f3e8e8;
        outline: 0.06rem solid red;
      }

      input {
        background-color: white;
        border: none;
        border-radius: 0.31rem;
        height: 100%;
        margin-bottom: 0.94rem;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.75rem;
        padding-inline-end: 2.5rem;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .textAreaContainer {
      position: relative;
      .invalidField {
        background-color: #f3e8e8;
        outline: 0.06rem solid red;
      }
      textarea {
        box-sizing: border-box;
        background-color: white;
        border: none;
        border-radius: 0.31rem;
        width: 100%;
        height: 9.56rem;
        padding: 1.56rem 1.75rem;
        resize: none;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        // color: #9b9999;
      }

      textarea::placeholder {
        color: #9b9999;
      }

      textarea:focus {
        outline: 0.13rem solid #405f6a;
      }
    }

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0.007rem;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    .form {
      display: flex;
      flex-direction: column;
      padding-left: toRem(32);
      padding-right: toRem(32);
      gap: toRem(20);

      .doubleFields {
        display: flex;
        gap: toRem(20);
      }

      .invalidField {
        background-color: #f3e8e8;
        outline: 0.06rem solid red;
      }

      .inputFlash1 {
        position: relative;
        box-sizing: border-box;
        width: toRem(379);
        height: toRem(73);
        font-size: toRem(22);
        line-height: toRem(32);
        padding: 0 toRem(11);
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: toRem(5);

        input {
          width: toRem(100);
          padding: 0;
          height: 100%;
          background-color: white;
          border: none;
          width: 100%;

          &:focus {
            outline: none;
          }
        }
      }

      .radioOwnContract {
        display: flex;
        align-items: center;

        .radio {
          position: relative;

          .errorMessage {
            margin: 0;
            position: absolute;
            color: red;
            top: -1.5rem;
            right: 0rem;
            font-family: Heebo-regular;
            font-size: 0.65rem;
          }

          input {
            display: none;
          }

          label {
            cursor: pointer;
            user-select: none;
            position: relative;
            font-family: Heebo-Regular;
            font-size: toRem(20);
            line-height: toRem(27);
            color: #3e3d3d;
            margin-inline-start: toRem(39);
          }

          label::before {
            content: "";
            display: inline-block;
            width: toRem(26);
            height: toRem(26);
            position: absolute;
            right: toRem(-39);
            bottom: -0.05rem;
            border: 0.13rem solid #707070;
            border-radius: 50%;
          }

          input:checked + label::before {
            // background-size: toRem(12);
            background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
            background-repeat: no-repeat;
            background-position: center center;
            background-origin: border-box;
          }
        }

        .loadContract {
          cursor: pointer;
          text-decoration: underline;
          font-size: toRem(20);
          line-height: toRem(27);
          padding-right: toRem(10);
        }

        .showDeleteContract {
          position: relative;
          display: flex;
          gap: toRem(8);
          padding-right: toRem(10);

          img {
            max-height: toRem(27);
          }

          .contractName{
            position: absolute;
            bottom: toRem(25);
            font-size: toRem(10);
            right: 0;
          }
        }
      }
    }

    .underline {
      width: 100%;
      border-top: toRem(1) solid #707070;
    }

    .contracts {
      .contractsTitle {
        padding-top: toRem(3);
        padding-bottom: toRem(16);

        .first {
          font-size: toRem(20);
          line-height: toRem(29);
        }

        .second {
          cursor: pointer;
          font-size: toRem(20);
          font-family: "Heebo-Medium";
          text-decoration: underline;
          padding: toRem(23) toRem(13) toRem(16) 0;
        }
      }
      .radBtns {
        display: flex;
        flex-direction: column;
        gap: toRem(22);

        .radio {
          position: relative;

          .errorMessage {
            margin: 0;
            position: absolute;
            color: red;
            top: -1.5rem;
            right: 0rem;
            font-family: Heebo-regular;
            font-size: 0.65rem;
          }

          input {
            display: none;
          }

          label {
            cursor: pointer;
            user-select: none;
            position: relative;
            font-family: Heebo-Regular;
            font-size: 1.25rem;
            line-height: 1.81rem;
            color: #3e3d3d;
            margin-inline-start: 2.8rem;
          }

          label::before {
            content: "";
            display: inline-block;
            width: 1.63rem;
            height: 1.63rem;
            position: absolute;
            right: -2.81rem;
            bottom: -0.05rem;
            border: 0.13rem solid #707070;
            border-radius: 50%;
          }

          input:checked + label::before {
            background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
            background-repeat: no-repeat;
            background-position: center center;
            background-origin: border-box;
          }
        }

        .radio1 {
          // margin-top: 1.06rem;
        }

        .radio2 {
          // margin-top: 1.38rem;
        }
      }
    }

    .tableSupplierContainer {
      padding-top: toRem(34);
      table {
        width: 100%;
        // border-spacing: 0 toRem(10) ;
        text-align: center;
        border-spacing: 0 toRem(0);
      }
      thead {
        tr {
          // margin-top: toRem(-10);
          line-height: toRem(21);
          font-size: toRem(14);
          font-family: "Heebo-Medium";
        }

        th:first-child {
          padding-right: toRem(22);
          text-align: start;
        }

        th:last-child {
          padding-left: toRem(22);
          text-align: end;
        }
      }
      tbody {
        tr {
          padding: 0 toRem(22);
          height: toRem(61);
          background-color: white;

          td {
            font-size: toRem(20);
            border-bottom: toRem(10) solid #eee9e5;
            border-right: 0;
            border-left: 0;
            // margin-top: 5px;

            input {
              max-width: toRem(150);
              background-color: white;
              font-size: toRem(20);
              color: #242323;
              border: none;
              text-align: center;
            }

            input:disabled {
              color: #242323;
            }
          }

          .editServ {
            cursor: pointer;
            font-size: toRem(18);
            line-height: toRem(27);
            text-decoration: underline;
            color: #3e3d3d;
          }

          .saveServ {
            cursor: pointer;
            color: #405f6a;
            font-size: toRem(18);
            line-height: toRem(27);
            text-decoration: underline;
          }
        }

        td:first-child {
          padding-right: toRem(22);
          text-align: start;
        }

        td:last-child {
          padding-left: toRem(22);
          text-align: end;
        }
      }
    }

    .tableVenueContainer {
      .inputFlash {
        font-size: toRem(22);
        line-height: toRem(32);
        padding: 0 toRem(11);
        display: flex;
        align-items: center;
        width: toRem(129);
        height: toRem(42);
        position: relative;
        background-color: white;

        input {
          width: toRem(100);
          padding: 0;
          height: 100%;
          background-color: white;
          border: none;
          width: 100%;
        }
      }

      .rad {
        padding-right: toRem(24);
      }

      .radio {
        position: relative;

        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: -1.5rem;
          right: 0rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }

        input {
          display: none;
        }

        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(14);
          line-height: toRem(21);
          color: #3e3d3d;
          margin-inline-start: toRem(30);
        }

        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-30);
          bottom: -0.05rem;
          border: 0.13rem solid #707070;
          border-radius: 50%;
        }

        input:checked + label::before {
          background-size: toRem(12);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tableVenue {
        display: flex;
        flex-direction: column;

        input:focus {
          outline: none;
        }

        .venueSelect {
          width: toRem(230);
          height: toRem(42);
        }

        .tableVenueTitles {
          position: relative;
          width: toRem(320);
          font-size: toRem(20);
          line-height: toRem(29);
          div {
            font-size: toRem(12);
            line-height: toRem(18);
          }
        }

        .priceRange {
          display: flex;
          align-items: center;
          gap: toRem(6);

          img {
            margin: toRem(11);
            margin-top: toRem(15);
            opacity: 0.55;
            width: toRem(11);
            height: toRem(11);
          }

          .inp {
            width: toRem(129);
            background-color: white;
            display: flex;
          }
          input {
            text-align: center;
            padding: 0;
            width: toRem(96);
            border: none;
            height: toRem(42);
            background-color: white;
            font-size: toRem(18);
          }
        }

        .guestsRange {
          display: flex;
          align-items: center;
          gap: toRem(6);

          .inp {
            position: relative;
            width: toRem(129);
            background-color: white;
            display: flex;
            .abbr {
              position: absolute;
              top: toRem(3);
              right: toRem(6);
              font-size: toRem(10);
              line-height: toRem(14);
              color: rgba(62, 61, 61, 0.46);
            }
          }

          input {
            text-align: center;
            padding: 0;
            width: 100%;
            border: none;
            height: toRem(42);
            background-color: white;
            font-size: toRem(18);
          }
        }

        .saveRow {
          cursor: pointer;
          font-size: toRem(18);
          line-height: toRem(27);
          text-decoration: underline;
          color: #405f6a;
        }

        .row1,
        .row2,
        .row3,
        .row4,
        .row5,
        .row6 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: toRem(19);
          padding-bottom: toRem(16);

          height: toRem(42);
          border-bottom: toRem(1) solid rgba(36, 35, 35, 0.17);
          .spacebetween {
            display: flex;
            align-items: center;
          }
        }
      }

      .flashVenue {
        display: flex;
        align-items: center;
        padding-top: toRem(13);
        padding-bottom: toRem(27);
        height: toRem(42);

        input:focus {
          outline: none;
        }

        .flashTitle {
          padding-left: toRem(32);
          font-size: toRem(20);
          line-height: toRem(29);
        }
      }
    }
  }

  .unicServices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: toRem(7);
    padding-bottom: toRem(30);

    .unicRow {
      display: flex;
      padding-bottom: toRem(13.5);
      align-items: center;

      .inputs {
        display: flex;

        .firstInput {
          margin-left: toRem(14);
          width: toRem(340);

          padding-inline-start: toRem(19);
        }
        .secondInput {
          width: toRem(130);
          margin-left: toRem(14);

          padding-inline-start: toRem(9);
        }
      }

      .saveUnic {
        font-size: toRem(16);
        line-height: toRem(24);
        cursor: pointer;
        font-family: "Heebo-Medium";
        text-decoration: underline;
      }

      input {
        box-sizing: border-box;
        font-size: toRem(14);
        background-color: white;
        box-sizing: border-box;
        width: 100%;
        height: toRem(42);
        // margin-inline-start: 1.63rem;
        border: none;
        // padding-bottom: 0;
        // padding-inline-end: 2rem;

        // text-align: center;

        &:focus {
          outline: 1px solid #36606b;
        }
      }
    }

    .addUnicServ {
      cursor: pointer;
      font-size: toRem(16);
      line-height: toRem(24);
    }
  }
}
@media (max-width: calc(800rem/$rem)) {
  .select{
    width: calc(340rem/$rem);
  }
}
@media (min-width: toRem(961)) {
  .mobile {
    display: none !important;
  }
  .desctop {
    display: initial;
  }

  .venueTable1 {
    display: none;
  }
  .mobileDescription {
    display: none;
  }
  .mobielArrow {
    padding: toRem(15) 0;
  }
}

@media (max-width: toRem(960)) {
  .spacebetween {
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .inp,.inpWithError,.priceServ{
    position: relative;
    
    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0rem;
      right: 0rem;
      font-family: Heebo-regular;
      font-size: 0.5rem;
    }
  }

  .doubleRadio {
    display: flex;
    align-items: initial;
    flex-direction: column;
    gap: toRem(15);
  }

  .mobielArrow {
    padding: 0;
  }
  .desctop {
    display: none !important;
  }
  .mainContainer {
    padding: 0 toRem(17) toRem(17) toRem(17);

    .buttons {
      padding-top: toRem(26);
      padding-bottom: toRem(40);
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: toRem(15);

      .save {
        padding: toRem(12) toRem(24);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(16);
        line-height: toRem(24);
      }
    }

    .mobile {
      display: flex;
      width: 100%;

      .title {
        font-family: "Heebo-Bold";
        font-size: toRem(20);
        line-height: toRem(29);
        padding: toRem(24) 0 toRem(22) 0;
      }

      .titelEventSelect {
        display: flex;
        width: 100%;
        padding-top: toRem(8);
        padding-bottom: toRem(27);
        font-size: toRem(14);
        line-height: toRem(21);
      }

      .eventSelector {
        display: flex;
        width: toRem(155);
        height: toRem(42);
      }

      .mobileDialog {
        box-sizing: border-box;
        top: 0;
        right: 0;
        padding-top: toRem(50);
        padding-bottom: toRem(17);
        z-index: 20;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        overflow: scroll;
        width: 100vw;
        height: 100vh;
        background-color: #405f6a;

        .container {
          box-sizing: border-box;
          border-radius: toRem(5);
          background-color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: toRem(320);
          padding: toRem(23) toRem(12) toRem(32) toRem(12);

          .title1 {
            display: flex;
            font-size: toRem(12);
            line-height: toRem(21);
            width: 100%;
            padding-bottom: toRem(39);
          }

          .table1 {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
            gap: toRem(12);

            .table1Row {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;

              input {
                padding: 0;
                border: none;
                box-sizing: border-box;
                width: toRem(64);
                height: toRem(30);
                font-size: toRem(16);
                text-align: center;
                background-color: #eee9e5;
                border: toRem(6) solid #eee9e5;
              }

              .image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
              }

              input:focus {
                background-color: white;
                outline: none;
                border: none;
                border-top: toRem(4) solid #eee9e5;
                border-bottom: toRem(4) solid #eee9e5;
                border-right: toRem(6) solid #eee9e5;
                border-left: toRem(6) solid #eee9e5;
              }

              .radio {
                position: relative;

                .errorMessage {
                  margin: 0;
                  position: absolute;
                  color: red;
                  top: -1.5rem;
                  right: 0rem;
                  font-family: Heebo-regular;
                  font-size: 0.5rem;
                }

                input {
                  display: none;
                }

                label {
                  display: flex;
                  cursor: pointer;
                  user-select: none;
                  position: relative;
                  font-family: "Heebo-Regular";
                  font-size: toRem(14);
                  line-height: toRem(21);
                  color: #3e3d3d;
                  margin-inline-start: toRem(28);
                }

                label::before {
                  content: "";
                  display: inline-block;
                  width: toRem(17);
                  height: toRem(18);
                  position: absolute;
                  right: toRem(-31);
                  top: 0;
                  border: 0.13rem solid #707070;
                  border-radius: 50%;
                }

                input:checked + label::before {
                  background-size: toRem(10);
                  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-origin: border-box;
                }
              }
            }
          }

          .saveDialog {
            height: toRem(48);
            box-sizing: border-box;
            margin-top: toRem(48);
            display: flex;
            padding: toRem(12) toRem(24);
            border-radius: toRem(5);
            background-color: #405f6a;
            color: #ffffff;
            cursor: pointer;
            font-size: toRem(16);
            line-height: toRem(24);
          }
        }

        .close {
          display: flex;
          width: toRem(320);
          padding-bottom: toRem(8);

          img {
            width: toRem(13);
            height: toRem(13);
          }
        }
      }

      .goTo {
        margin-top: toRem(37);
        margin-bottom: toRem(16);
        display: flex;
        padding: toRem(12) toRem(24);
        border-radius: toRem(5);
        background-color: #405f6a;
        color: #ffffff;
        cursor: pointer;
        font-size: toRem(16);
        line-height: toRem(24);
      }
    }

    .mobileDescription {
      display: flex;
      padding-bottom: toRem(12);
      font-size: toRem(18);
      line-height: toRem(27);
    }

    .accordion {
      .tabTitle {
        line-height: toRem(27);
        padding-bottom: toRem(2);
        font-size: toRem(18);
        font-family: "Heebo-Bold";
      }

      .catTitle {
        margin-bottom: toRem(0);
        padding-top: toRem(16);
        font-size: toRem(18);
        line-height: toRem(27);
      }

      .inputContainer {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: toRem(55);

        input {
          background-color: white;
          border: none;
          border-radius: 0.31rem;
          height: 100%;
          margin-bottom: 0.94rem;
          font-family: Heebo-Regular;
          font-size: toRem(15);
          line-height: toRem(22);
          color: #242323;
          padding-inline-start: 1.75rem;
          padding-inline-end: 2.5rem;
          width: 100%;
          box-sizing: border-box;
        }
      }

      .errorMessage {
        margin: 0;
        position: absolute;
        color: red;
        bottom: 0.007rem;
        right: 0.31rem;
        font-family: Heebo-regular;
        font-size: 0.5rem;
      }

      .form {
        display: flex;
        flex-direction: column;
        padding-top: toRem(30);
        padding-left: toRem(0);
        padding-right: toRem(0);
        gap: toRem(15);

        .inputFlash1 {
          position: relative;
          box-sizing: border-box;
          width: 100%;
          height: toRem(55);
          font-size: toRem(18);
          line-height: toRem(21);
          padding: 0 toRem(11);
          display: flex;
          align-items: center;
          background-color: white;
          border-radius: toRem(5);

          input {
            width: toRem(100);
            padding: 0;
            height: 100%;
            background-color: white;
            border: none;
            width: 100%;

            &:focus {
              outline: none;
            }
          }
        }

        .radioOwnContract {
          display: flex;
          align-items: center;
          margin-top: toRem(5);
          .radio {
            position: relative;

            .errorMessage {
              margin: 0;
              position: absolute;
              color: red;
              top: -1.5rem;
              right: 0rem;
              font-family: Heebo-regular;
              font-size: 0.5rem;
            }

            input {
              display: none;
            }

            label {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-family: Heebo-Regular;
              font-size: toRem(20);
              line-height: toRem(27);
              color: #3e3d3d;
              margin-inline-start: toRem(30);
            }

            label::before {
              content: "";
              display: inline-block;
              width: toRem(18);
              height: toRem(18);
              position: absolute;
              right: toRem(-30);
              top: 0.15rem;
              border: 0.13rem solid #707070;
              border-radius: 50%;
            }

            input:checked + label::before {
              background-size: toRem(12);
              background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
              background-repeat: no-repeat;
              background-position: center center;
              background-origin: border-box;
            }
          }

          .loadContract {
            cursor: pointer;
            text-decoration: underline;
            font-size: toRem(20);
            line-height: toRem(27);
            padding-right: toRem(10);
          }
        }

        .doubleFields {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: toRem(15);
        }

        .textAreaContainer {
          margin-bottom: toRem(16);
          textArea {
            height: toRem(303);
          }
        }

        .contracts {
          .contractsTitle {
            padding-top: toRem(16);
            padding-bottom: toRem(10);

            .first {
              font-size: toRem(18);
              line-height: toRem(27);
            }

            .second {
              cursor: pointer;
              font-size: toRem(16);
              line-height: toRem(24);
              font-family: "Heebo-Medium";
              text-decoration: underline;
              padding: toRem(0) toRem(9) toRem(16) 0;
            }
          }
          .radBtns {
            display: flex;
            flex-direction: column;
            gap: toRem(22);

            .radio {
              position: relative;

              .errorMessage {
                margin: 0;
                position: absolute;
                color: red;
                top: -1.5rem;
                right: 0rem;
                font-family: Heebo-regular;
                font-size: 0.5rem;
              }

              input {
                display: none;
              }

              label {
                display: flex;
                cursor: pointer;
                user-select: none;
                position: relative;
                font-family: "Heebo-Regular";
                font-size: toRem(16);
                line-height: toRem(24);
                color: #3e3d3d;
                margin-inline-start: toRem(28);
              }

              label::before {
                content: "";
                display: inline-block;
                width: toRem(18);
                height: toRem(18);
                position: absolute;
                right: toRem(-31);
                top: 0;
                border: 0.13rem solid #707070;
                border-radius: 50%;
              }

              input:checked + label::before {
                background-size: toRem(10);
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
                background-repeat: no-repeat;
                background-position: center center;
                background-origin: border-box;
              }
            }

            .radio1 {
              // margin-top: 1.06rem;
            }

            .radio2 {
              // margin-top: 1.38rem;
            }
          }
        }
      }

      .venueTable1 {
        display: block;
        width: 100%;

        .tableVenueContainer {
          .mainTitle {
            padding-top: toRem(24);
            padding-bottom: toRem(13);
            display: flex;
            flex-direction: column;
            width: 100%;

            .underline1 {
              border-bottom: 1px solid #242323;
              width: 100%;
            }

            .mainTitle1 {
              font-size: toRem(12);
              line-height: toRem(21);
              padding-bottom: toRem(9);
            }
            .mainTitle2 {
              font-family: "Heebo-Medium";
              line-height: toRem(27);
              font-size: toRem(18);
            }
            .mainTitle3 {
              padding-top: toRem(13);
              line-height: toRem(24);
              font-size: toRem(16);
            }
            .mainTitle4 {
              padding-bottom: toRem(13);
              font-size: toRem(12);
              line-height: toRem(18);
            }
          }
          .inputFlash {
            font-size: toRem(22);
            line-height: toRem(32);
            padding: 0 toRem(11);
            display: flex;
            align-items: center;
            width: toRem(99);
            height: toRem(42);
            position: relative;

            background-color: #eee9e5;

            input {
              width: toRem(70);
              padding: 0;
              height: 100%;
              background-color: #eee9e5;
              border: none;
              width: 100%;
            }
          }

          .rad {
            padding-right: toRem(24);
          }

          .radio {
            position: relative;

            .errorMessage {
              margin: 0;
              position: absolute;
              color: red;
              top: -1.5rem;
              right: 0rem;
              font-family: Heebo-regular;
              font-size: 0.5rem;
            }

            input {
              display: none;
            }

            label {
              cursor: pointer;
              user-select: none;
              position: relative;
              font-family: Heebo-Regular;
              font-size: toRem(14);
              line-height: toRem(21);
              color: #3e3d3d;
              margin-inline-start: toRem(30);
            }

            label::before {
              content: "";
              display: inline-block;
              width: toRem(18);
              height: toRem(18);
              position: absolute;
              right: toRem(-30);
              bottom: -0.05rem;
              border: 0.13rem solid #707070;
              border-radius: 50%;
            }

            input:checked + label::before {
              background-size: toRem(12);
              background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
              background-repeat: no-repeat;
              background-position: center center;
              background-origin: border-box;
            }
          }

          .tableVenue {
            display: flex;
            flex-direction: column;

            input:focus {
              outline: none;
            }

            .venueSelect {
              position: relative;
              width: 100%;
              height: toRem(42);

              .errorMessage {
                margin: 0;
                position: absolute;
                color: red;
                bottom: 0rem;
                right: 0rem;
                font-family: Heebo-regular;
                font-size: 0.5rem;
              }
            }

            .tableVenueTitles {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              position: relative;
              font-size: toRem(14);
              line-height: toRem(21);
              padding: toRem(9) toRem(0);
              div {
                font-size: toRem(12);
                line-height: toRem(18);
              }
            }

            .priceRange {
              display: flex;
              align-items: center;
              gap: toRem(6);

              img {
                margin: toRem(11);
                margin-top: toRem(15);
                opacity: 0.55;
                width: toRem(11);
                height: toRem(11);
              }

              .inp {
                width: toRem(129);
                background-color: #eee9e5;
                display: flex;
              }
              input {
                text-align: center;
                padding: 0;
                width: toRem(96);
                border: none;
                height: toRem(42);
                background-color: #eee9e5;
                font-size: toRem(18);
              }
            }

            .guestsRange {
              display: flex;
              align-items: center;
              gap: toRem(6);

              .inp {
                position: relative;
                width: toRem(129);
                background-color: #eee9e5;
                display: flex;
                .abbr {
                  position: absolute;
                  top: toRem(3);
                  right: toRem(6);
                  font-size: toRem(10);
                  line-height: toRem(14);
                  color: rgba(62, 61, 61, 0.46);
                }
              }

              input {
                text-align: center;
                padding: 0;
                width: 100%;
                border: none;
                height: toRem(42);
                background-color: #eee9e5;
                font-size: toRem(18);
              }
            }

            .saveRow {
              cursor: pointer;
              font-size: toRem(18);
              line-height: toRem(27);
              text-decoration: underline;
              color: #405f6a;
            }

            .row1,
            .row2 {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: flex-start;
              padding-top: toRem(0);
              // padding-bottom: toRem(12);
              padding-bottom: toRem(12);
              height: initial;
              border-bottom: none;
            }
            .row3,
            .row4,
            .row5,
            .row6 {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: flex-start;
              padding-top: toRem(0);
              padding-bottom: toRem(12);

              height: toRem(42);
              border-bottom: none;
            }

            .underline2 {
              padding-top: toRem(0);
              border-bottom: 1px solid;
              opacity: 0.14;
              width: 100%;
            }
            .row3 {
              padding-top: toRem(20);
            }
          }

          .flashVenue {
            display: flex;
            align-items: center;
            padding-top: toRem(9);
            padding-bottom: toRem(27);
            height: toRem(42);

            input:focus {
              outline: none;
            }

            .flashTitle {
              padding-left: toRem(32);
              font-size: toRem(20);
              line-height: toRem(29);
            }
          }
        }
        // .tableVenueContainer{

        //   .inputFlash{
        //     font-size: toRem(22);
        //     line-height: toRem(32);
        //     padding: 0 toRem(11);
        //     display: flex;
        //     align-items: center;
        //     width: toRem(99);
        //     height: toRem(42);
        //     position: relative;

        //     background-color: #EEE9E5;

        //     input{
        //       width: toRem(70);
        //       padding: 0;
        //       height: 100%;
        //       background-color: #EEE9E5;
        //       border: none;
        //       width: 100%;
        //     }
        //   }

        //   .rad{
        //     padding-right: toRem(24);
        //   }

        //   .radio {
        //     position: relative;

        //     .errorMessage {
        //       margin: 0;
        //       position: absolute;
        //       color: red;
        //       top: -1.5rem;
        //       right: 0rem;
        //       font-family: Heebo-regular;
        //       font-size: 0.65rem;
        //     }

        //     input {
        //       display: none;
        //     }

        //     label {
        //       cursor: pointer;
        //       user-select: none;
        //       position: relative;
        //       font-family: Heebo-Regular;
        //       font-size: toRem(14);
        //       line-height: toRem(21);
        //       color: #3e3d3d;
        //       margin-inline-start: toRem(30);
        //     }

        //     label::before {
        //       content: "";
        //       display: inline-block;
        //       width: toRem(18);
        //       height: toRem(18);
        //       position: absolute;
        //       right: toRem(-30);
        //       bottom: -0.05rem;
        //       border: 0.13rem solid #707070;
        //       border-radius: 50%;
        //     }

        //     input:checked + label::before {
        //       background-size: toRem(12);
        //       background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        //       background-repeat: no-repeat;
        //       background-position: center center;
        //       background-origin: border-box;
        //     }
        //   }

        //   .tableVenue{
        //     display: flex;
        //     flex-direction: column;

        //     input:focus{outline: none;}

        //     .venueSelect{
        //       width: toRem(230);
        //       height: toRem(42);
        //     }

        //     .tableVenueTitles{
        //       position: relative;
        //       width: fit-content;
        //       font-size: toRem(16);
        //       line-height: toRem(21);
        //       div{
        //         font-size: toRem(12);
        //         line-height: toRem(18);
        //       }
        //     }

        //     .priceRange{
        //       display: flex;
        //       align-items: center;
        //       gap: toRem(6);

        //       img{
        //         margin: toRem(11);
        //         margin-top: toRem(15);
        //         opacity: .55;
        //         width: toRem(11);
        //         height: toRem(11);
        //       }

        //       .inp{
        //         width: toRem(129);
        //         background-color: #EEE9E5;
        //         display: flex;
        //       }
        //       input{
        //         text-align: center;
        //         padding: 0;
        //         width: toRem(96);
        //         border: none;
        //         height: toRem(42);
        //         background-color: #EEE9E5;
        //         font-size: toRem(18);
        //       }
        //     }

        //     .guestsRange{
        //       display: flex;
        //       align-items: center;
        //       gap: toRem(6);

        //       .inp{
        //         position: relative;
        //         width: toRem(129);
        //         background-color: #EEE9E5;
        //         display: flex;
        //         .abbr{
        //           position: absolute;
        //           top: toRem(3);
        //           right: toRem(6);
        //           font-size: toRem(10);
        //           line-height: toRem(14);
        //           color: rgba(62, 61, 61, .46);
        //         }
        //       }

        //       input{
        //         text-align: center;
        //         padding: 0;
        //         width: 100%;
        //         border: none;
        //         height: toRem(42);
        //         background-color: #EEE9E5;
        //         font-size: toRem(18);
        //       }
        //     }

        //     .saveRow{
        //       cursor: pointer;
        //       font-size: toRem(18);
        //       line-height: toRem(27);
        //       text-decoration: underline;
        //       color: #405f6a;
        //     }

        //     .row1,.row2,.row3,.row4,.row5,.row6{
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       padding-top: toRem(19);
        //       padding-bottom: toRem(30);

        //       height: toRem(42);
        //       border-bottom: toRem(1) solid rgba(36, 35, 35, .17);
        //       .spacebetween{
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //       }
        //     }

        //   }

        //   .flashVenue{
        //     display: flex;
        //     align-items: center;
        //     padding-top: toRem(13);
        //     padding-bottom: toRem(27);
        //     height: toRem(42);

        //     input:focus{outline: none;}

        //     .flashTitle{
        //       padding-left: toRem(32);
        //       font-size: toRem(20);
        //       line-height: toRem(29);

        //     }
        //   }
        // }
      }
    }

    .unicServices {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: toRem(15);
      padding-bottom: toRem(0);

      .unicRow {
        width: 100%;
        display: flex;

        padding-bottom: toRem(13.5);
        align-items: center;

        .inputs {
          width: 100%;
          max-width: toRem(296);
          display: flex;
          justify-content: space-between;

          .firstInput {
            width: 100%;
            margin-left: toRem(14);
            max-width: toRem(165);

            padding-inline-start: toRem(19);
          }
          .secondInput {
            width: 100%;
            max-width: toRem(86);

            padding-inline-start: toRem(9);
          }
        }

        // .saveUnic{
        //   font-size: toRem(16);
        //   line-height: toRem(24);
        //   cursor: pointer;
        //   font-family: "Heebo-Medium";
        //   padding-right: toRem(14);
        //   text-decoration: underline;
        // }

        input {
          box-sizing: border-box;
          font-size: toRem(14);
          background-color: #eee9e5;
          box-sizing: border-box;
          width: 100%;
          height: toRem(30);
          // margin-inline-start: 1.63rem;
          border: none;
          // padding-bottom: 0;
          // padding-inline-end: 2rem;

          // text-align: center;

          &:focus {
            outline: 1px solid #36606b;
          }
        }
      }

      .addUnicServ {
        font-size: toRem(16);
        line-height: toRem(24);
      }
    }
  }
}
@media (max-width: calc(400rem/$rem)) {
  .select{
    width: 90%;
  }
}
