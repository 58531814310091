@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

.customScroll::-webkit-scrollbar {
  width: toRem(11);
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #405f6a;
  border-radius: 5px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #213136;
}

$rem: 16;

@mixin css-arrow(
  $height: 10px,
  $border: 2px,
  $color: #efefef,
  $border-color: #999,
  $direction: bottom,
  $outside: true
) {
  $border-shift: $border;

  @if $border > 0px {
    $border: round($border * 1.41421356);
  }

  position: relative;

  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;

    @if $direction == top {
      @if $outside {
        bottom: 100%;
      } @else {
        margin-top: -$border-shift;
        top: 0;
      }
    }
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $color;
      } @else {
        border-top-color: $color;
      }
    }
    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -$height;
    }
    border-width: $height;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $border-color;
      } @else {
        border-top-color: $border-color;
      }
    }

    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -($height + $border);
    }
    border-width: $height + $border;
  }
}

$background: white;
$border-color: black;
$border-size: 1px;

.tooltipWrapper {
  position: relative;
  width: max-content;

  .tooltiptext {
    @include css-arrow(10px, $border-size, $background, $border-color, top);
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    font-size: calc(10rem / $rem);
    background-color: white;
    border: calc(1rem / $rem) solid black;
    border-radius: calc(5rem / $rem);
    visibility: hidden;
    width: calc(75rem / $rem);
    z-index: 1;
    padding: calc(5rem / $rem) calc(9rem / $rem);
    top: 120%;
    left: 62%;
    margin-left: calc(
      -43rem / $rem
    ); /* Use half of the width (120/2 = 60), to center the tooltip */
  }
}

.tooltipWrapper:hover .tooltiptext {
  visibility: visible;
}

.radio {
  margin-bottom: 0.5rem;
  position: relative;

  input {
    display: none;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    top: 1.5rem;
    right: 2.7rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }

  label {
    cursor: pointer;
    user-select: none;
    position: relative;
    font-family: Heebo-Regular;
    font-size: 1.25rem;
    line-height: 1.81rem;
    color: #3e3d3d;
    margin-inline-start: 2.8rem;
  }

  label::before {
    box-sizing: border-box;
    content: "";
    display: inline-block;
    width: 1.56rem;
    // height: 1.63rem;
    height: 1.56rem;
    position: absolute;
    right: -2.81rem;
    bottom: -0.05rem;
    border: 0.13rem solid #707070;
    border-radius: 50%;
  }

  input:checked + label::before {
    background-size: 0.8125rem;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: border-box;
  }
}

// input[type='radio']:after {
//   box-sizing: border-box;
//   width: 15px;
//   height: 15px;
//   border-radius: 15px;
//   top: -2px;
//   left: 0px;
//   position: relative;
//   background-color: #d1d3d1;
//   content: '';
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }

// input[type='radio']:checked:after {
//   box-sizing: border-box;
//   width: 15px;
//   height: 15px;
//   border-radius: 15px;
//   top: -2px;
//   left: 0px;
//   position: relative;
//   background-color: #ffa500;
//   content: '';
//   display: inline-block;
//   visibility: visible;
//   border: 2px solid white;
// }
.row {
  img {
    width: 100%;
    height: 100%;
  }
}

.desctop {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
}

.mobile {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .addBtn {
    padding-top: toRem(17);
    padding-bottom: toRem(0);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: toRem(8);
    height: toRem(49);
    font-size: toRem(14);

    .imgWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: toRem(48);
      height: toRem(48);
      border-radius: 50%;
      background-color: #e7b1ad;
      box-shadow: 0px 3px 6px #00000029;

      img {
        width: toRem(32);

        height: toRem(32);
      }
    }
  }
}

.mainWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 toRem(20) toRem(71) toRem(20);
  // align-items: center;

  .allEvents {
    box-sizing: border-box;
    margin-right: toRem(30);
    padding: toRem(28) toRem(36) toRem(22) toRem(16);
    box-shadow: 0px toRem(20) toRem(40) #0000001a;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: toRem(434);
    max-height: toRem(630);
    overflow-y: auto;

    .allEventsContainer {
      box-sizing: border-box;
      padding-left: toRem(10);
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .row {
      padding-top: 0;
      display: flex;
      align-items: center;
    }

    .add {
      padding-left: toRem(11);
      width: toRem(20);
      height: toRem(20);
    }

    .evetnConteiner {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: toRem(15);
      // padding-top: toRem(19);
      padding-top: toRem(22);

      .linkRow {
        display: flex;
        gap: toRem(13);
        font-size: toRem(18);
        line-height: toRem(27);
        color: #707070;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .withCube {
          display: flex;
          .cube {
            border-radius: toRem(5);
            width: toRem(25);
            height: toRem(25);
            margin-left: toRem(8);
          }
          .cubeDescr {
            display: flex;
            height: 100%;
            align-items: center;
            font-size: toRem(16);
            line-height: toRem(21);
          }
        }

        .change {
          cursor: pointer;
          display: flex;
          height: 100%;
          align-items: flex-end;
          font-size: toRem(14);
          text-decoration: underline;
        }
      }

      .times {
        display: flex;
        gap: toRem(10);

        .date {
          width: toRem(137);
          height: toRem(31);
          background-color: #f5f5f5;
        }
        .time {
          width: toRem(131);
          height: toRem(31);
          background-color: #f5f5f5;
        }
      }

      .others {
      }
    }
  }

  .secondPart {
    box-sizing: border-box;
    margin-right: toRem(30);
    padding: toRem(28) toRem(36) toRem(22) toRem(16);
    box-shadow: 0px toRem(20) toRem(40) #0000001a;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: toRem(434);

    .titleInput {
      display: flex;
      justify-content: space-between;
      padding-bottom: toRem(8);

      input {
        font-size: toRem(24);
        border: none;
      }

      img {
        cursor: pointer;
        width: toRem(30);
        height: toRem(30);
      }
    }
    .titleInput1 {
      width: 100%;
      padding-left: toRem(10);
      input {
        width: 100%;
        font-size: toRem(16);
        border: none;
      }
    }

    .titleBusy {
      padding-top: toRem(10);
      font-size: toRem(14);
    }

    .cubsWrapper {
      position: relative;
      display: flex;
      gap: toRem(11);
      padding-top: toRem(15);
      padding-bottom: toRem(22);

      .cubeWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: toRem(10);
        }

        .cube {
          box-sizing: border-box;
          cursor: pointer;
          border-radius: toRem(5);
          width: toRem(39);
          height: toRem(39);
        }

        .activeCube {
          box-sizing: border-box;
          cursor: pointer;
          border-radius: toRem(5);
          border: 2px solid #363636;
          width: toRem(39);
          height: toRem(39);
        }
      }

      .error {
        position: absolute;
        bottom: 0;
        font-size: toRem(14);
        color: red;
      }
    }

    .timeRow {
      display: flex;
      width: 100%;
      align-items: center;
      gap: toRem(8);

      span {
        margin-left: toRem(15);
        font-size: toRem(20);
      }
      .date {
        max-width: toRem(185);
        width: 100%;
        height: toRem(42);
      }
      .time {
        max-width: toRem(116);
        width: 100%;
        height: toRem(42);
      }
    }

    .row {
      display: flex;
      align-items: center;
    }

    .add {
      padding-left: toRem(11);
      width: toRem(20);
      height: toRem(20);
    }

    .location {
      margin-right: toRem(-4);
      padding-left: toRem(13);
      width: toRem(24);
      height: toRem(23);
    }

    .screpka {
      padding-left: toRem(22);
      width: toRem(13);
      height: toRem(25);
    }

    .btnContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: toRem(41);

      .addBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: toRem(5);
        border: 2px solid #405f6a;
        box-shadow: 0px 20px 40px #221b4a24;
        height: toRem(51);
        width: toRem(141);
      }
    }
  }
}

@media (min-width: toRem(961)) {
  .mobile {
    display: none;
  }
  .desctop {
    display: flex;
  }
}

@media (max-width: toRem(960)) {
  .desctop {
    display: none;
  }
  .mobile {
    display: flex;
  }

  .mainWrapper {
    padding: 0;

    .secondPart {
      padding: 0;
      margin: 0;
      box-shadow: none;
      .linkBack {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: toRem(14);
        line-height: toRem(21);
        text-decoration: underline;
        padding-bottom: toRem(27);
      }
      .timeRow {
        display: flex;
        width: 100%;
        align-items: center;
        gap: toRem(8);

        .marginLeft1 {
          padding-left: toRem(37);
        }
        .marginLeft2 {
          padding-left: toRem(33);
        }

        span {
          font-size: toRem(16);
        }
        .date {
          max-width: toRem(137);
          width: 100%;
          height: toRem(31);
        }
        .time {
          max-width: toRem(86);
          width: 100%;
          height: toRem(31);
        }
      }

      .btnContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: toRem(115);
        gap: toRem(10);

        .cancellBtn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: toRem(5);
          border: 2px solid #405f6a;
          box-shadow: 0px 20px 40px #221b4a24;
          height: toRem(48);
          width: toRem(128);
        }

        .addBtn1 {
          color: white;
          background-color: #405f6a;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: toRem(5);
          border: 2px solid #405f6a;
          box-shadow: 0px 20px 40px #221b4a24;
          height: toRem(48);
          width: toRem(128);
        }
      }
    }

    .allEvents {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      flex-direction: column;
      border-radius: toRem(5);
      margin: toRem(20) toRem(17) toRem(17);
      border: 1px solid #405f6a;
      padding: toRem(6) toRem(16) toRem(18) toRem(14);

      .evetnConteiner {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: toRem(15);
        // padding-top: toRem(19);
        padding-bottom: toRem(22);

        .linkRow {
          display: flex;
          gap: toRem(13);
          font-size: toRem(18);
          line-height: toRem(27);
          color: #707070;
        }

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .withCube {
            display: flex;
            .cube {
              border-radius: toRem(5);
              width: toRem(25);
              height: toRem(25);
              margin-left: toRem(8);
            }
            .cubeDescr {
              display: flex;
              height: 100%;
              align-items: center;
              font-size: toRem(16);
              line-height: toRem(21);
            }
          }

          .change {
            display: flex;
            height: 100%;
            align-items: flex-end;
            font-size: toRem(14);
            text-decoration: underline;
          }
        }

        .times {
          display: flex;
          gap: toRem(10);

          .date {
            width: toRem(137);
            height: toRem(31);
            background-color: #f5f5f5;
          }
          .time {
            width: toRem(131);
            height: toRem(31);
            background-color: #f5f5f5;
          }
        }

        .others {
        }
      }
    }
  }
}
