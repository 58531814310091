$rem: 16;

.tab{
    display: flex;
    justify-content: center;
    margin: calc(36rem/$rem) calc(90rem/$rem) calc(17.5rem/$rem);
    text-align: left;
    font-family: Heebo-Regular;
    color: #242323;
    font-size: calc(24rem/$rem);
    height: calc(30rem/$rem);
}
.block{
    margin-left: calc(50rem/$rem);
    display: flex;
    align-items: center;
}
.img{
  margin-top: calc(9rem/$rem);
}
.calendar{
  margin-top: calc(9rem/$rem);
}
.endBlock{
    display: flex;
}
.textBlock{
    margin-right: calc(10rem/$rem);
    cursor: pointer;
}
.textBlockActive{
    margin-right: calc(10rem/$rem);
    cursor: pointer;
    color: #E7B1AD;
    font-family: Heebo-Bold;
}

.textBlockDocumentActive{
    margin-right: calc(10rem/$rem);
    cursor: pointer;
    color: #242323;
    font-family: Heebo-Bold;
}
.img{
    cursor: pointer;
    // width: calc(24rem/$rem);
    // height: calc(24rem/$rem);
}
.imgActive{
    svg path{
        fill:red !important;
    }
}
.line{
    margin: 0 calc(90rem/$rem);
}
/* .content{
    margin: 0 calc(90rem/$rem);
} */

@media (max-width: calc(1500rem/$rem)) {
    .tab{
        font-size: calc(14rem/$rem);
    }
    .img{
        cursor: pointer;
        // width: calc(25rem/$rem);
        // height: calc(25rem/$rem);
    }
}
@media (max-width: 37.5rem) {
    .desctop{
      display: none;
    }
}
@media (max-width: calc(800rem/$rem)) {
    .tab{
        display: none;
    }
    .line{
        display: none;
    }
    .content{
        margin: 0;
    }
}