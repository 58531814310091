$rem: 16;
a {
  color: inherit;
  text-decoration-color: #e7b1ad;
}

.headerContainer {
  position: relative;
  height: calc(109rem / $rem);
}

.headerContainer1 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 30;
  box-sizing: border-box;
  background-color: #fcf8f5;
  display: flex;
  justify-content: space-between;
  // padding: 1.8125rem 5.9375rem 1.375rem 5.9375rem;
  padding: 29px 95px 22px;
  height: var(--header-height);
  align-items: flex-end;
  box-shadow: 0rem 0.19rem 0.38rem #00000030;
}
.notificationImg {
  position: absolute;
  top: -10px;
  left: -10px;
}
.areaButton {
  position: relative;
}

.headerListLogin {
  width: 20%;
  color: #3e3d3d;
}

.headerListLogin,
.headerListMenu {
  height: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.headerListLogin li,
.headerListMenu li {
  margin-top: 12px;
  // height: 3.5rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.31rem;
  font-size: 20px;
  line-height: 29px;
  white-space: nowrap;
  user-select: none;
}

.headerListLogin li:first-child {
  // width: 7.31rem;
  // margin-inline-end: 2.13rem;
  margin-left: 0.96875rem;
}

.headerListLogin li:last-child {
  width: 94px;
}

.headerListMenu li:not(:last-child) {
  margin-inline-end: 29px;
}

.headerListMenu li:last-child {
  // cursor: pointer;
  // padding: 0.75rem 1.5rem;
  // background-color: #e7b1ad;
  // font-size: 1.38rem;
  // line-height: 1.28rem;
  // color: white;
  // box-shadow: 0 1.25rem 2.5rem #405f6a1f;
}

.linkToSupplier {
  font-family: Heebo-Bold;
}
.linkToCustomerEnry1 div,
.linkToCustomerEnry2 div {
  border-bottom: 3px solid #fcf8f5;
  position: relative;
  padding: 0px 3.5px;
  bottom: -3px;
  transition: border-color 0.5s ease, font-family 0.5s ease;
}

.linkToCustomerEnry1 div:hover,
.linkToCustomerEnry2 div:hover {
  padding: 0px 2px;
  border-bottom-color: #e7b1ad;
  font-family: Heebo-Medium;
  cursor: pointer;
}

.logoContainer {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  user-select: none;
}

.logo {
  cursor: pointer;
  margin-bottom: -0.31rem;
  width: 115px;
  height: 55px;
  user-select: none;
}

@media (max-width: 100rem) {
  .headerContainer1 {
    padding: 29px 55px 22px;
  }
  .headerListLogin li,
  .headerListMenu li {
    // height: 3.13rem;
    font-size: 18px;
    line-height: 24px;
  }

  // .headerListMenu li:last-child {
  //   font-size: 1.22rem;
  //   line-height: 1.2rem;
  // }

  .headerListLogin li:first-child {
    width: 6.31rem;
    margin-inline-end: 24px;
  }

  .headerListMenu li:not(:last-child) {
    margin-inline-end: 24px;
  }

  .firstLink {
    width: 7.03rem;
  }

  .secondLink {
    width: 5.66rem;
  }
  .headerListLogin {
    width: unset;
  }
  .logo {
    scale: 90%;
    margin-bottom: -0.44rem;
    margin-inline-end: -0.44rem;
  }
}

@media (max-width: 80rem) {
  .headerContainer1 {
    padding: 10px 25px;
  }
  .headerListLogin li,
  .headerListMenu li {
    // height: 2.81rem;
    font-size: 16px;
    line-height: 22px;
  }

  // .headerListMenu li:last-child {
  //   padding: 0.65rem 1.2rem;
  //   font-size: 0.8rem;
  //   line-height: 0.7rem;
  // }

  .headerListLogin li:first-child {
    width: 5.21rem;
    margin-inline-end: 22px;
  }

  .headerListMenu li:not(:last-child) {
    margin-inline-end: 22px;
  }

  .firstLink {
    width: 5.06rem;
  }

  .secondLink {
    width: 4.07rem;
  }

  .logo {
    scale: 75%;
    // margin-bottom: -0.56rem;
    // margin-inline-end: -0.56rem;
  }
}

@media (max-width: 64rem) {
  .headerList li {
    font-size: 1rem;
    line-height: 1.31rem;
  }

  .logo {
    margin-inline-start: -0.5rem;
  }
}

@media (max-width: 60rem) {
  .headerContainer1,
  .headerContainer {
    display: none;
  }
}
