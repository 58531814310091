:root {
  --header-height: 106px;
  --header-height-mobile: 60px;
}
a:active, /* активная/посещенная ссылка */
a:hover,  /* при наведении */
a {
  cursor: pointer;
  /* color: rgb(0, 0, 0); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

html,
body {
  font-size: 12px;
  overflow: hidden !important;
}

.samyBody {
  box-sizing: border-box;
  width: 100vw;
  height: calc(100dvh - var(--header-height));
  overflow: auto;
  position: relative;
  max-width: 1950px;
  margin: 0 auto 0 auto;
  padding: 0;
  /* max-width: 120rem; */
  font-size: 1rem;
  font-family: Heebo-Regular;
  color: #242323;
  overflow-x: hidden;
  margin-block-start: var(--header-height);
}
@media (max-width: 60rem) {
  .samyBody {
    height: calc(100dvh - var(--header-height-mobile));
    margin-block-start: var(--header-height-mobile);
  }
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  /* color: rgb(0, 0, 0); */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.chosenLink {
  /* color: white; */
  font-family: Heebo-Bold;
  /* background-color: #E7B1AD;
  box-shadow: 0px 20px 40px #405F6A1F; */
}

@font-face {
  font-family: 'Assistant-Bold';
  src: url(./assets/fonts/Assistant-Bold.ttf);
}

@font-face {
  font-family: 'Assistant-Regular';
  src: url(./assets/fonts/Assistant-Regular.ttf);
}

@font-face {
  font-family: 'Assistant-SemiBold';
  src: url(./assets/fonts/Assistant-SemiBold.ttf);
}

@font-face {
  font-family: 'Heebo-Bold';
  src: url(./assets/fonts/Heebo-Bold.ttf);
}

@font-face {
  font-family: 'Heebo-Regular';
  src: url(./assets/fonts/Heebo-Regular.ttf);
}

@font-face {
  font-family: 'Heebo-Light';
  src: url(./assets/fonts/Heebo-Light.ttf);
}

@font-face {
  font-family: 'Heebo-Medium';
  src: url(./assets/fonts/Heebo-Medium.ttf);
}

@font-face {
  font-family: 'Heebo-SemiBold';
  src: url(./assets/fonts/Heebo-SemiBold.ttf);
}

@font-face {
  font-family: 'Heebo-ExtraBold';
  src: url(./assets/fonts/Heebo-ExtraBold.ttf);
}

body {
  margin: 0 auto 0 auto;
  padding: 0;
  /* max-width: 120rem; */
  font-size: 1rem;
  font-family: Heebo-Regular;
  position: fixed;
  color: #242323;
}

@media screen and (max-width: 960px) {
  :root {
    --header-height: 5.4325rem;
  }
}
