.feedback_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee9e5;
  max-width: 1620px;
  margin: 0 auto;
  padding: 60px 0px 61px;
  .feedback_title {
    line-height: 41px;
    font-size: 28px;
    font-family: "Heebo-Medium";
  }
}

.cards_container {
  display: flex;
  margin-top: 63px;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-color: rgba(0, 0, 0, 0.6);
}
// - Chrome: from version 4 (current version 114)
// - Safari: from version 5.1 (current version 16.5)
// - Edge (Chromium): from version 79 (current version 114)
// - Opera: from version 15 (current version 98)
// - Android WebView: since version 4.4 (current version 114)
.cards_container::-webkit-scrollbar {
  height: 4px;
  background-color: white;
}
.cards_container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
.cards_container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}
// Styles for Firefox
// Support:
// - Firefox: from version 64 to scrollbar-width (current version 113)

.cards_container::-moz-scrollbar {
  background: white;
  border-radius: 10px;
}
.cards_container::-moz-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
.cards_container::-moz-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}
// Styles for older versions of Edge and Internet Explorer
// Support:
// - Edge (up to version 18)
// - Internet Explorer: versions 10 and 11 (current version 11)
.cards_container::-ms-scrollbar {
  background: white;
  border-radius: 10px;
}
.cards_container::-ms-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
.cards_container::-ms-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.8);
}
.one_card {
  display: flex;
  flex: 1 0 calc(33.3%);
  min-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .card_title {
    font-size: 20px;
    line-height: 29px;
    font-family: "Heebo-Medium";
  }
  .card_message {
    max-width: 350px;
    margin-top: 19px;
    text-align: center;
  }
  .card_who {
    font-size: 18px;
    line-height: 27px;
    margin-top: 21px;
    font-family: "Heebo-Bold";
  }
}

@media (max-width: 1200px) {
  .one_card {
    flex: 1 0 50%;
    scroll-snap-align: start;
  }
}

@media (max-width: 900px) {
  .cards_container {
    margin-top: 0px;
  }
  .feedback_container {
    .feedback_title {
      padding: 0px 10px;
      line-height: 24px;
      font-size: 22px;
      text-align: center;
    }
  }
  .one_card {
    flex: 1 0 100%;
    scroll-snap-align: start;
  }
}
