.starsWrapper{
  font-size: 1em;
  display: flex;

  .star{
    width: 1.8375em;
    height: 1.737em;

    img{
      width: 100%;
      height: 100%;
    }
  }
}
