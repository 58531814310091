@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

// .venueTable1{
//   display: block;
//   max-width: toRem(955);
//   width: 100%;
//   padding-bottom: toRem(47);
//   padding-top: toRem(19);
//   background-color: #eee9e5;
.inp{
  position: relative;
  
  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0rem;
    right: 0rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}

.wrapper {
  box-sizing: border-box;
  max-width: toRem(955);
  width: 100%;
  padding: toRem(30) toRem(35.5) toRem(50) toRem(33);
  background-color: #eee9e5;

  .wrapperTitle {
    font-size: toRem(22);
    font-family: "Heebo-Medium";
    padding-bottom: toRem(9);
  }

  .underline {
    width: 100%;
    border-top: toRem(1) solid #242323;
    padding-bottom: toRem(7);
  }

  .tableVenueContainer {
    .inputFlash {
      box-sizing: border-box;
      font-size: toRem(22);
      line-height: toRem(32);
      padding: 0 toRem(11);
      display: flex;
      align-items: center;
      width: toRem(129);
      height: toRem(42);
      position: relative;
      background-color: white;

      input {
        width: toRem(100);
        padding: 0;
        height: 100%;
        background-color: white;
        border: none;
        width: 100%;
      }
    }

    .rad {
      padding-right: toRem(24);
    }

    .radio {
      position: relative;

      .errorMessage {
        margin: 0;
        position: absolute;
        color: red;
        top: -1.5rem;
        right: 0rem;
        font-family: Heebo-regular;
        font-size: 0.65rem;
      }

      input {
        display: none;
      }

      label {
        cursor: pointer;
        user-select: none;
        position: relative;
        font-family: Heebo-Regular;
        font-size: toRem(14);
        line-height: toRem(21);
        color: #3e3d3d;
        margin-inline-start: toRem(30);
      }

      label::before {
        content: "";
        display: inline-block;
        width: toRem(18);
        height: toRem(18);
        position: absolute;
        right: toRem(-30);
        bottom: -0.05rem;
        border: 0.13rem solid #707070;
        border-radius: 50%;
      }

      input:checked + label::before {
        background-size: toRem(12);
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-position: center center;
        background-origin: border-box;
      }
    }

    .tableVenue {
      display: flex;
      flex-direction: column;

      input:focus {
        outline: none;
      }

      .venueSelect {
        position: relative;
        width: toRem(230);
        height: toRem(42);

        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          bottom: 0rem;
          right: 0rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }
      }

      .tableVenueTitles {
        position: relative;
        width: toRem(320);
        font-size: toRem(20);
        line-height: toRem(29);
        div {
          font-size: toRem(12);
          line-height: toRem(18);
        }
      }

      

      .priceRange {
        display: flex;
        align-items: center;
        gap: toRem(6);

        img {
          margin: toRem(11);
          margin-top: toRem(15);
          opacity: 0.55;
          width: toRem(11);
          height: toRem(11);
        }

        .inp {
          width: toRem(129);
          background-color: white;
          display: flex;
        }
        input {
          text-align: center;
          padding: 0;
          width: toRem(96);
          border: none;
          height: toRem(42);
          background-color: white;
          font-size: toRem(18);
        }
      }

      .guestsRange {
        display: flex;
        align-items: center;
        gap: toRem(6);

        .inp {
          position: relative;
          width: toRem(129);
          background-color: white;
          display: flex;
          .abbr {
            position: absolute;
            top: toRem(3);
            right: toRem(6);
            font-size: toRem(10);
            line-height: toRem(14);
            color: rgba(62, 61, 61, 0.46);
          }
        }

        input {
          text-align: center;
          padding: 0;
          width: 100%;
          border: none;
          height: toRem(42);
          background-color: white;
          font-size: toRem(18);
        }
      }

      .saveRow {
        cursor: pointer;
        font-size: toRem(18);
        line-height: toRem(27);
        text-decoration: underline;
        color: #405f6a;
      }

      .row1,
      .row2,
      .row3,
      .row4,
      .row5,
      .row6 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: toRem(19);
        padding-bottom: toRem(16);

        height: toRem(42);
        border-bottom: toRem(1) solid rgba(36, 35, 35, 0.17);
        .spacebetween {
          display: flex;
          align-items: center;
        }
      }
    }

    .flashVenue {
      display: flex;
      align-items: center;
      padding-top: toRem(13);
      padding-bottom: toRem(27);
      height: toRem(42);

      input:focus {
        outline: none;
      }

      .flashTitle {
        padding-left: toRem(32);
        font-size: toRem(20);
        line-height: toRem(29);
      }
    }
  }
}

@media (min-width: toRem(961)) {
  .mobile {
    display: none !important;
  }
  .desctop {
    display: initial;
  }

  .mobileDescription {
    display: none;
  }
  .mobielArrow {
    padding: toRem(15) 0;
  }
}

@media (max-width: toRem(960)) {
  .venueTable1 {
    display: block;

    .tableVenueContainer {
      .mainTitle{
        padding-top: toRem(24);
        padding-bottom: toRem(13);
        display: flex;
        flex-direction: column;
        width: 100%;

        .underline1 {
          border-bottom: 1px solid #242323;
          width: 100%;
        }

        .mainTitle1{
          font-size: toRem(12);
          line-height: toRem(21);
          padding-bottom: toRem(9);
        }
        .mainTitle2{
          font-family: "Heebo-Medium";
          line-height: toRem(27);
          font-size: toRem(18);
        }
        .mainTitle3{
          padding-top: toRem(13);
          line-height: toRem(24);
          font-size: toRem(16);
        }
        .mainTitle4{
          padding-bottom: toRem(13);
          font-size: toRem(12);
          line-height: toRem(18);
        }
      }
      .inputFlash {
        font-size: toRem(22);
        line-height: toRem(32);
        padding: 0 toRem(11);
        display: flex;
        align-items: center;
        width: toRem(99);
        height: toRem(42);
        position: relative;

        background-color: #eee9e5;

        input {
          width: toRem(70);
          padding: 0;
          height: 100%;
          background-color: #eee9e5;
          border: none;
          width: 100%;
        }
      }

      .rad {
        padding-right: toRem(24);
      }

      .radio {
        position: relative;

        .errorMessage {
          margin: 0;
          position: absolute;
          color: red;
          top: -1.5rem;
          right: 0rem;
          font-family: Heebo-regular;
          font-size: 0.65rem;
        }

        input {
          display: none;
        }

        label {
          cursor: pointer;
          user-select: none;
          position: relative;
          font-family: Heebo-Regular;
          font-size: toRem(14);
          line-height: toRem(21);
          color: #3e3d3d;
          margin-inline-start: toRem(30);
        }

        label::before {
          content: "";
          display: inline-block;
          width: toRem(18);
          height: toRem(18);
          position: absolute;
          right: toRem(-30);
          bottom: -0.05rem;
          border: 0.13rem solid #707070;
          border-radius: 50%;
        }

        input:checked + label::before {
          background-size: toRem(12);
          background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Ccircle%20id%3D%22Ellipse_7%22%20data-name%3D%22Ellipse%207%22%20cx%3D%229%22%20cy%3D%229%22%20r%3D%229%22%20fill%3D%22%23405f6a%22%2F%3E%0A%3C%2Fsvg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
          background-origin: border-box;
        }
      }

      .tableVenue {
        display: flex;
        flex-direction: column;

        input:focus {
          outline: none;
        }

        .venueSelect {
          position: relative;
          width: 100%;
          height: toRem(42);

          .errorMessage {
            margin: 0;
            position: absolute;
            color: red;
            bottom: 0rem;
            right: 0rem;
            font-family: Heebo-regular;
            font-size: 0.65rem;
          }
        }

        .tableVenueTitles {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          position: relative;
          font-size: toRem(14);
          line-height: toRem(21);
          padding: toRem(9) toRem(0);
          div {
            font-size: toRem(12);
            line-height: toRem(18);
          }
        }

        .priceRange {
          display: flex;
          align-items: center;
          gap: toRem(6);

          img {
            margin: toRem(11);
            margin-top: toRem(15);
            opacity: 0.55;
            width: toRem(11);
            height: toRem(11);
          }

          .inp {
            width: toRem(129);
            background-color: #eee9e5;
            display: flex;
          }
          input {
            text-align: center;
            padding: 0;
            width: toRem(96);
            border: none;
            height: toRem(42);
            background-color: #eee9e5;
            font-size: toRem(18);
          }
        }

        .guestsRange {
          display: flex;
          align-items: center;
          gap: toRem(6);

          .inp {
            position: relative;
            width: toRem(129);
            background-color: #eee9e5;
            display: flex;
            .abbr {
              position: absolute;
              top: toRem(3);
              right: toRem(6);
              font-size: toRem(10);
              line-height: toRem(14);
              color: rgba(62, 61, 61, 0.46);
            }
          }

          input {
            text-align: center;
            padding: 0;
            width: 100%;
            border: none;
            height: toRem(42);
            background-color: #eee9e5;
            font-size: toRem(18);
          }
        }

        .saveRow {
          cursor: pointer;
          font-size: toRem(18);
          line-height: toRem(27);
          text-decoration: underline;
          color: #405f6a;
        }

        .row1,
        .row2 {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          // padding-top: toRem(19);
          // padding-bottom: toRem(12);
          padding-bottom: toRem(12);
          height: initial;
          border-bottom: none;
        }
        .row3,
        .row4,
        .row5,
        .row6 {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          // padding-top: toRem(19);
          padding-bottom: toRem(12);

          height: toRem(42);
          border-bottom: none;
        }
        
        .underline2 {
          padding-top: toRem(0);
          border-bottom: 1px solid ;
          opacity: 0.14;
          width: 100%;
        }
      }

      .row3{
        padding-top: toRem(20);
      }

      .flashVenue {
        display: flex;
        align-items: center;
        padding-top: toRem(9);
        padding-bottom: toRem(27);
        height: toRem(42);

        input:focus {
          outline: none;
        }

        .flashTitle {
          padding-left: toRem(32);
          font-size: toRem(20);
          line-height: toRem(29);
        }
      }
    }
  }
}
