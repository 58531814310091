$rem: 16;

.whatsApp{
    z-index: 101;
    box-sizing: border-box;
    width: calc(205rem/$rem);
    height: calc(67rem/$rem);
    background-color: #1EE62C;
    color:#FFFFFF;
    border-radius: calc(9rem/$rem);
    display: flex;
    padding: calc(9rem/$rem) calc(10.1rem/$rem) calc(8.7rem/$rem) calc(11rem/$rem);
    position: fixed;
    bottom: 2.3rem;
    left: calc(96em/$rem); 
    gap: 0.35rem;
  
    .watsAppIcon > img{
      width: calc(47.46em/$rem);
      height: calc(48.99rem/$rem);
    }
  
    .watsAppText{
      width: 100%;
      font-size: calc(17rem/$rem);
      font-family: 'Heebo-Regular';
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .watsAppText > span:first-child{
        font-size: calc(19rem/$rem);
        font-family: 'Heebo-Medium';
    }
  }

@media (max-width: calc(960rem/$rem)) {
    .whatsApp{
      width: calc(175em/$rem);
      height: calc(57rem/$rem);    
      padding: calc(8.6rem/$rem) calc(6.7rem/$rem) calc(9rem/$rem) calc(9rem/$rem);
      left: 1.5rem;
      bottom: 1rem;
      
    .watsAppIcon > img{
        width: calc(38.15rem/$rem) !important;
        height: calc(39.38rem/$rem) !important;
    }

      .watsAppIcon{
        img{
          width: 2.68rem;
          height: 2.68rem;
        }
      }
      .watsAppText{
        font-size: calc(13rem/$rem);
      }
      .watsAppText > span:first-child{
          font-size: calc(16rem/$rem);
      }
    }
}