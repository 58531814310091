$rem: 16;

.wrapper{
    z-index: 19;
    box-sizing: border-box;
    width: calc(75rem/$rem);
    height: calc(75rem/$rem);
    border-radius: 50%;
    background-color: #F3BEBC;
    display: flex;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: calc(16rem/$rem);
    position: fixed;
    align-items: center;
    justify-content: center;
    bottom: calc(30em/$rem);
    right: calc(94em/$rem); 
    gap: 0.35rem;
  
    img{
      width: calc(51rem/$rem);
      height: calc(51rem/$rem);
    }
  }

@media (max-width: calc(960rem/$rem)) {
    .wrapper{
      width: calc(58rem/$rem);
      height: calc(58rem/$rem);    
      padding: calc(11rem/$rem);
      right: 1rem;
      bottom: 3.5rem;
      
    img{
        width: calc(40rem/$rem) !important;
        height: calc(40rem/$rem) !important;
    }
    }
}