@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}
.imagesWrapper{
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // width: 91rem;
  max-width: 101.88rem;
  gap: 2.65625rem;
  // justify-content: space-between;
  .imageWrap{
    position: relative;
    cursor: pointer;
    width: 16.09375rem;
    height: 16.09375rem;
    background-color: #E6E5EE;
    text-align: center;
    font-size: toRem(40);

    .first{
      position: absolute;
      width: 100%;
      bottom: 0.71rem;
      right: 0;
      font-size: 0.875rem;
      color:#141414;
    }

    img{
      width: 100%;
      height: 100%;
      
      object-fit: cover; 
      user-select: none;
    }
    video{
      width: 100%;
      height: 100%;
      
      // object-fit: cover; 
      user-select: none;
    }
  }
}

@media (max-width: toRem(960)) {
  .imagesWrapper{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // width: 91rem;
    max-width: 101.88rem;
    gap: toRem(14);
    // justify-content: space-between;
    .imageWrap{
      position: relative;
      cursor: pointer;
      width: toRem(147);
      height: toRem(147);
      background-color: #FFFFFF;
      text-align: center;
      font-size: toRem(40);
  
      .first{
        box-sizing: border-box;
        padding: toRem(5);
        position: absolute;
        width: 100%;
        bottom: 0.71rem;
        right: 0;
        font-size: toRem(14);
        color:#141414;
      }
  
      img{
        width: 100%;
        height: 100%;
        
        object-fit: cover; 
        user-select: none;
      }
      video{
        width: 100%;
        height: 100%;
        
        // object-fit: cover; 
        user-select: none;
      }
    }
  }
}