@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}
$rem: 16;
.infoDialog{
  background-color: rgba(64, 95, 106, 0.9);
}
.phoneInput{
  direction: ltr !important;
  text-align: end !important;
}
.mainPopup{
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.mainPopup::-webkit-scrollbar { width: 0; }
.blockCalendar{
  background-color: rgba(64, 95, 106, 0.9);
}
.buyme_image{
  width: toRem(39);
  height: toRem(39);
  margin-right: toRem(13);
}
.buyme_block{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.redTitle{
  font-family: Heebo-Bold;
  font-size:  calc(15rem/$rem);
  color: #F41616;
}
.buyme_inActiveButton{
  width: toRem(164);
  height: toRem(52);
  border-radius: toRem(4);
  background-color: #B1B1B1;
  border: toRem(1.5) solid #242323;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo-Bold;
  font-size:  calc(18rem/$rem);
  color: rgba(36, 35, 35, 0.4);
}
.buyme_activeButton{
  width: toRem(164);
  height: toRem(52);
  border-radius: toRem(4);
  background-color: #FFC400;
  border: toRem(1.5) solid #242323;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo-Bold;
  font-size:  calc(18rem/$rem);
  color: #242323;
  cursor: pointer;
}
.userPoints{ 
  font-family: Heebo-Regular;
  font-size:  calc(18rem/$rem);
  color: #242323;
}
.pointsInfo{
  font-family: Heebo-Regular;
  font-size:  calc(14rem/$rem);
  color: #242323;
}
.userNumbers{
  font-family: Heebo-Bold;
  font-size:  calc(18rem/$rem);
  color: #242323;
}
.infoDialogClose{
  background-color: #FFFFFF;
  height: calc(200rem/$rem);
  border-radius: calc(5rem/$rem);
}
.close{
  position: relative;
  padding-bottom: 0.7rem;
  background-color: rgba(64, 95, 106, 0.9);
}
.infoDialogtitle{
  text-align: center;
  display: flex;
  font-family: Heebo-Bold;
  font-size: calc(16rem/$rem);
  color: #242323;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  align-items: center;
  flex-direction: column;
}
.dialogButtons{
  display: flex;
}
.buttonVideo{
  cursor: pointer;
  margin-right: calc(10rem/$rem);
  margin-top: calc(30rem/$rem);
  background: #405F6A 0% 0% no-repeat padding-box;
  box-shadow: 0 calc(20rem/$rem) calc(40rem/$rem) #221B4A21;
  border-radius: calc(5rem/$rem);
  height: calc(45rem/$rem);
  font-family: Heebo-Regular;
  font-size:  calc(14rem/$rem);
  padding: 0 calc(18rem/$rem);
  color: #FFFFFF;
  border: none;
  box-sizing: border-box;
}
.mainContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: toRem(97);

  .accordion{
    max-width: toRem(955);
    width: 100%;
    padding-bottom: toRem(44);
    background-color: #EEE9E5;

      .tabTitle{
      line-height: toRem(29);
      padding-bottom: toRem(2);
      font-size: toRem(20);
      font-family: "Heebo-Bold";  
    }

    .regLink{
      cursor: pointer;
      font-size: toRem(22);
      font-family: "Heebo-Bold";
      text-decoration: underline;
    }

    .inputContainer{
      position: relative;
      box-sizing: border-box;
      width: toRem(379);
      height: toRem(73);

      input{
        background-color: white;
        border: none;
        border-radius: 0.31rem;
        height: 100%;
        margin-bottom: 0.94rem;
        font-family: Heebo-Regular;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #242323;
        padding-inline-start: 1.75rem;
        padding-inline-end: 2.5rem;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .errorMessage {
      margin: 0;
      position: absolute;
      color: red;
      bottom: 0.007rem;
      right: 0.31rem;
      font-family: Heebo-regular;
      font-size: 0.65rem;
    }

    .form{
      display: flex;
      flex-direction: column;
      padding-left: toRem(32);
      padding-right: toRem(32);

      .formNamePhone{
        display: flex;
        gap: toRem(20);
        padding-bottom: toRem(20);
      }

      .forgot{
        font-size: toRem(18);
        padding-bottom: toRem(23);
        line-height: toRem(27);
        text-decoration: underline;
        cursor: pointer;
      }

      .showPasswords{
        cursor: pointer;
        display: flex;
        font-size: toRem(20);
        padding-bottom: toRem(12);
        gap: toRem(5);
      }

      .passwords{
        display: flex;
        flex-direction: column;
        gap: toRem(20);

        .oldPassInput{
          display: flex;
        }

        .confirmationFields{
          display: flex;
          gap: toRem(20);
        }
      }

      .buttons{
        padding-top: toRem(32);
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: toRem(15);

        .save{
          padding: toRem(12) toRem(24);
          border-radius: toRem(5);
          background-color: #405F6A;
          color:#FFFFFF;
          cursor: pointer;
          font-size: toRem(22);
          line-height: toRem(32);
        }

        .remove{
          cursor: pointer;
          font-size: toRem(16);
          line-height: toRem(24);
          text-decoration: underline;
        }
      }

      

    }

    .addEventTitle{
      font-size: toRem(16);
      line-height: toRem(24);
    }

    .addEventLink{
      cursor: pointer;
      font-size: toRem(22);
      font-family: "Heebo-Bold";
      text-decoration: underline;
    }

    .noContractTitle{
      font-size: toRem(20);
      line-height: toRem(29);
    }

    .contractsTitle{
      font-size: toRem(18);
      padding-bottom: toRem(4);
    }

    .underline{
      width: 100%;
      border-top: toRem(1) solid rgba(112, 112, 112, 0.09);;

    }

    .contractsWrapper{
      table{
        border-spacing: toRem(56) 0;
        margin: 0 toRem(-56);
      }
      padding-top: toRem(11);
      tr{
        
        font-size: toRem(18);
        line-height: toRem(27);
       

        td{
           padding-top: toRem(10);
        }
      }

      .goCell{
        font-family: "Heebo-Medium";
        font-size: toRem(18);
        text-decoration: underline;
        color: #405F6A;
      }

    }

    .eventsWrapper{
      table{
        border-spacing: toRem(33) 0;
        margin: 0 toRem(-33);
      }
      tr{
        
        font-size: toRem(18);
        line-height: toRem(27);
       

        td{
           padding-top: toRem(15);
        }

        
      }
      .goCell{
        display: flex;
        align-items: center;

        .img{
          cursor: pointer;
          width: toRem(24);
          height: toRem(24);

          img{
            width: 100%;
            height: 100%;
          }
        }
        

        
      }
      

    }



  
  }
}


@media (min-width: toRem(961)) {
    .mobile{
      display: none;
    }
}

@media (max-width: toRem(960)) { 
  .buyme_image{
    width: toRem(34);
    height: toRem(34);
    margin-right: toRem(8);
  }
  .mainContainer{
    padding: 0 toRem(17) toRem(17) toRem(17);


    .mobile{
      display: flex;
      width: 100%;

      .title{
        font-size: toRem(16);
        line-height: toRem(24);
        padding: toRem(12) 0 toRem(52) 0;
      }
    }

    
  
    .accordion{
        .tabTitle{
        line-height: toRem(27);
        padding-bottom: toRem(2);
        font-size: toRem(18);
        font-family: "Heebo-Bold";  
      }
  
      .regLink{
        cursor: pointer;
        font-size: toRem(16);
        font-family: "Heebo-Bold";
        text-decoration: underline;
      }
  
      .inputContainer{
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: toRem(55);
  
        input{
          background-color: white;
          border: none;
          border-radius: 0.31rem;
          height: 100%;
          margin-bottom: 0.94rem;
          font-family: Heebo-Regular;
          font-size: toRem(15);
          line-height: toRem(22);
          color: #242323;
          padding-inline-start: 1.75rem;
          padding-inline-end: 2.5rem;
          width: 100%;
          box-sizing: border-box;
        }
      }
  
      .errorMessage {
        margin: 0;
        position: absolute;
        color: red;
        bottom: 0.007rem;
        right: 0.31rem;
        font-family: Heebo-regular;
        font-size: 0.65rem;
      }
  
      .form{
        display: flex;
        flex-direction: column;
        padding-left: toRem(0);
        padding-right: toRem(0);
  
        .formNamePhone{
          display: flex;
          flex-direction: column;
          gap: toRem(10);
          padding-bottom: toRem(10);
        }
  
        .forgot{
          font-size: toRem(16);
          padding-bottom: toRem(11);
          line-height: toRem(27);
          text-decoration: underline;
          cursor: pointer;
        }
  
        .showPasswords{
          pointer-events: none;
          cursor: pointer;
          display: flex;
          font-size: toRem(18);
          padding-bottom: toRem(10);
          gap: toRem(5);
          img{display: none;}
        }
  
        .passwords{
          display: flex;
          flex-direction: column;
          gap: toRem(10);
  
          .oldPassInput{
            display: flex;
          }
  
          .confirmationFields{
            display: flex;
            flex-direction: column;
            gap: toRem(10);
          }
        }
  
        .buttons{
          padding-top: toRem(22);
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          gap: toRem(10);
  
          .save{
            padding: toRem(12) toRem(24);
            border-radius: toRem(5);
            background-color: #405F6A;
            color:#FFFFFF;
            cursor: pointer;
            font-size: toRem(16);
            line-height: toRem(24);
          }
  
          .remove{
            cursor: pointer;
            font-size: toRem(12);
            line-height: toRem(18);
            text-decoration: underline;
          }
        }
  
        
  
      }
  
      .addEventTitle{
        font-size: toRem(16);
        line-height: toRem(24);
      }
  
      .addEventLink{
        cursor: pointer;
        font-size: toRem(22);
        font-family: "Heebo-Bold";
        text-decoration: underline;
      }
  
      .noContractTitle{
        font-size: toRem(20);
        line-height: toRem(29);
      }
  
      .contractsTitle{
        font-size: toRem(16);
        padding-bottom: toRem(6);
      }
  
      .underline{
        width: 100%;
        border-top: toRem(1) solid rgba(112, 112, 112, 0.09);;
  
      }
  
      .contractsWrapper{
        table{
          width: 100%;
          border-spacing: 0;
          margin: 0 ;
        }
        padding-top: toRem(0);
        tr{
          
          font-size: toRem(16);
          line-height: toRem(24);
         
  
          td{
             padding-top: toRem(15);
          }
        }
  
        .goCell{
          text-align: end;
          font-size: toRem(16);
        }
  
      }
  
      .eventsWrapper{
        table{
          width: 100%;
          border-spacing: 0;
          margin: 0;
        }
        tr{
          
          font-size: toRem(14);
          line-height: toRem(21);
         
  
          td{
             padding-top: toRem(20);
          }
  
          
        }
        .goCell{

          display: flex;
          justify-content: flex-end;
          width: 100%;


          .img{
            height: toRem(24);
            width: toRem(24);
            img{
              width: 100%;
              height: 100%;
            }
          }    
        }
      }
    }
  }
}
@media (max-width: toRem(800)) { 
  .mainContainer{
    padding: 0 toRem(17) toRem(17) toRem(17);
    .mobile{
      display: flex;
      width: 100%;
      .title{
        padding: toRem(30) 0 toRem(52) 0;
      }
    }
  }
}
@media (max-width: toRem(600)) { 
  .buyme_block{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .userPoints{ 
    font-family: Heebo-Regular;
    font-size: calc(16rem/$rem);
    color: #242323;
  }
  .pointsInfo{
    font-family: Heebo-Regular;
    font-size: calc(12rem/$rem);
    color: #242323;
  }
  .userNumbers{
    font-family: Heebo-Bold;
    font-size: calc(16rem/$rem);
    color: #242323;
  }
  .redTitle{
    font-size: calc(12rem/$rem);
  }
  .buyme_inActiveButton{
    width: toRem(146);
    height: toRem(47);
    font-size: calc(16rem/$rem);
  }
  .buyme_activeButton{
    width: toRem(164);
    height: toRem(52);
    font-size: calc(18rem/$rem);
  }
}