@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;  
  font-size: inherit;

  .arrow{
    margin-top: -0.6rem;
    padding: toRem(8);
    height: toRem(13);
    width: toRem(8);
  }
}

@media (max-width: toRem(960)) {
  .wrapper{
    .arrow{
      margin-top: -0.55rem;
      padding: toRem(8);
      
      img{
        height: toRem(10);
        width: toRem(6);
      }
    }
  }

}