@function toRem($value) {
  $remValue: calc($value / 16) + rem; 
  @return $remValue;
}

.dataInputsForm{
  max-width: 40.4375rem;
  width: 100%;
  padding-bottom: 15.8125rem;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9b9999;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9b9999;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #9b9999;
  }
}

.width33{
  width: 33%;
}

.width50{
  width: 50%;
}

.dataInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: .94rem
}

.addedDataInputs {
  margin-top: .94rem;
}

.secondBlock {
  margin-top: .94rem;
}

.businessDescription {
  margin-top: 1.5rem;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #3E3D3D;
}

.textAreaContainer {
  textarea {
    box-sizing: border-box;
    background-color: #EEE9E5;
    border: none;
    border-radius: .31rem;
    width: 100%;
    height: 9.56rem;
    padding: 1.56rem 1.75rem;
    resize: none;
    font-family: Heebo-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    // color: #9b9999;
  }

  textarea:focus {
    outline: .13rem solid #405F6A;
  }
}

.lessFields,
.moreFields {
  margin-top: .88rem;
  margin-bottom: 0;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  cursor: pointer;
}

.lessFields {
  margin-top: 0rem;
}

.line {
  margin-top: 2.78rem;
  margin-bottom: 0;
  height: 0.06rem;
  background-color: #707070;
  border: none;
}

.questionAboutContacts {
  margin-top: 1.47rem;
  margin-bottom: 0;
  font-family: Heebo-Regular;
  font-size: 1.25rem;
  line-height: 1.81rem;
  color: #3E3D3D;
}

.inputContainer {
  position: relative;
  flex-grow: 1;
  
  .invalidField {
    background-color: #f3e8e8;
    outline: .06rem solid red;
  }  

  input {
    background-color: #EEE9E5;
    border: none;
    border-radius: 0.31rem;
    height: 4.56rem;
    font-family: Heebo-Regular;
    font-size: 1rem;
    line-height: 1.5rem;
    // color: #9b9999;
    padding-inline-start: 1.75rem;
    padding-inline-end: 1.5rem;
    width: 100%;
    box-sizing: border-box;

    
  }

  input:focus {
    outline: .13rem solid #405F6A;
  }

  .errorMessage {
    margin: 0;
    position: absolute;
    color: red;
    bottom: 0.007rem;
    right: 0.31rem;
    font-family: Heebo-regular;
    font-size: 0.65rem;
  }
}

.secondBlock .inputContainer {
  width: calc(100% / 3);
}

.selectInputContainer {
  color: #9B9999;
  height: 4.56rem;
}

@media ( min-width: toRem(961)){
  .mobile{
    display: none;
  }
}

@media ( max-width: toRem(960)){

  .desctop{
    display: none;
  }

  .width33, .width50{
    width: 100%;
  }

  .dataInputsForm{
    max-width: 100%;
    width: 100%;
    padding-bottom: toRem(40);

    input{
      width: 100%;
    }

  }
  
  .dataInputs {
    // width: 100%;
    // display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // gap: .94rem
  }
  
  // .addedDataInputs {
  //   margin-top: .94rem;
  // }
  
  // .secondBlock {
  //   margin-top: .94rem;
  // }
  
  // .businessDescription {
  //   margin-top: 1.5rem;
  //   font-family: Heebo-Regular;
  //   font-size: 1.25rem;
  //   line-height: 1.81rem;
  //   color: #3E3D3D;
  // }
  
  // .textAreaContainer {
  //   textarea {
  //     box-sizing: border-box;
  //     background-color: #EEE9E5;
  //     border: none;
  //     border-radius: .31rem;
  //     width: 100%;
  //     height: 9.56rem;
  //     padding: 1.56rem 1.75rem;
  //     resize: none;
  //     font-family: Heebo-Regular;
  //     font-size: 1rem;
  //     line-height: 1.5rem;
  //     // color: #9b9999;
  //   }
  
  //   textarea:focus {
  //     outline: .13rem solid #405F6A;
  //   }
  // }
  
  // .lessFields,
  // .moreFields {
  //   margin-top: .88rem;
  //   margin-bottom: 0;
  //   font-family: Heebo-Regular;
  //   font-size: 1.25rem;
  //   line-height: 1.81rem;
  //   cursor: pointer;
  // }
  
  // .lessFields {
  //   margin-top: 0rem;
  // }
  
  // .line {
  //   margin-top: 2.78rem;
  //   margin-bottom: 0;
  //   height: 0.06rem;
  //   background-color: #707070;
  //   border: none;
  // }
  
  // .questionAboutContacts {
  //   margin-top: 1.47rem;
  //   margin-bottom: 0;
  //   font-family: Heebo-Regular;
  //   font-size: 1.25rem;
  //   line-height: 1.81rem;
  //   color: #3E3D3D;
  // }
  
  .inputContainer {
    // position: relative;
    // flex-grow: 1;
    
    // .invalidField {
    //   background-color: #f3e8e8;
    //   outline: .06rem solid red;
    // }  
  
    input {
      background-color: white;
      // border: none;
      // border-radius: 0.31rem;
      height: toRem(54);
      // font-family: Heebo-Regular;
      // font-size: 1rem;
      // line-height: 1.5rem;
      // // color: #9b9999;
      // padding-inline-start: 1.75rem;
      // padding-inline-end: 1.5rem;
      // width: 100%;
      // box-sizing: border-box;
  
      
    }
  
    // input:focus {
    //   outline: .13rem solid #405F6A;
    // }
  
    // .errorMessage {
    //   margin: 0;
    //   position: absolute;
    //   color: red;
    //   bottom: 0.007rem;
    //   right: 0.31rem;
    //   font-family: Heebo-regular;
    //   font-size: 0.65rem;
    // }
  }
  
  .secondBlock .inputContainer {
    width: 100%;
  }
  
  // .selectInputContainer {
  //   color: #9B9999;
  //   height: 4.56rem;
  // }
  
}
