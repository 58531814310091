$rem: 16;

.watsApp{
  z-index: 999;
  box-sizing: border-box;
  width: 14.75rem;
  height: 6rem;
  background-color: #1EE62C;
  color:#FFFFFF;
  border-radius: 1.375rem;
  display: flex;
  padding: 1rem 1.125rem;
  position: fixed;
  bottom: 2.5625rem;
  right: 6rem;

  .watsAppIcon{
    width: 3.75rem;
    height: 3.75rem;
  }

  .watsAppText{
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.eventTypeBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(68.5rem/$rem);
  }
  .desctop{
    margin: 0 calc(90rem/$rem);
  }
  .mobileLine{
    display: none;
  }
  .mobileTitle{
    display: none;
  }
  .eventLabel{
    color: #3E3D3D;
    font-size: calc(18rem/$rem);
    font-family: Heebo-Bold;
    margin-top: calc(16rem/$rem);
    margin-bottom: calc(16rem/$rem);
  }
  .select{
    font-size: 1rem;
    width: calc(319rem/$rem);
    height: calc(45rem/$rem);
  }
  select{
    font-size: 1rem;
  }
  .menu{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #EBD9CD;
      font-family: Heebo-Bold;
      font-size:  calc(24rem/$rem);
      height: calc(80rem/$rem);
      padding: 0 calc(49rem/$rem) 0 0;
      margin: calc(68rem/$rem) 0 calc(26rem/$rem);
      color: #3E3D3D;
  }
  .chatBlocks{
      display: flex;
      margin-bottom: calc(107rem/$rem);
  }
  .leftBlock{
      // width: calc(72.98% - calc(18rem/$rem));
      width: calc(100%);
      height: calc(900rem/$rem);
      // margin-right: calc(18rem/$rem);
      border-radius: calc(5rem/$rem);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: calc(1rem/$rem) solid #405F6A;
      overflow: auto;
  }
  .leftBlock::-webkit-scrollbar { width: 0; }
  .rightBlock{
      width: calc(27.02% - calc(18rem/$rem));
      height: calc(900rem/$rem);
      margin-left: calc(18rem/$rem);
      border-radius: calc(5rem/$rem);
      background: #FFFFFF 0% 0% no-repeat padding-box;    
      border: calc(1rem/$rem) solid #405F6A;
  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 120px;
    margin: 0 calc(49rem/$rem) 0 calc(42rem/$rem);
  }
  .mobileRow{
    display: none;
  }
  .category{
    color: #434242;
    font-family: Heebo-Bold;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(5rem/$rem);
    width: calc(15.74% - calc(5rem/$rem));
    height: calc(35rem/$rem);
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
  }
  .name{
    color: #434242;
    font-family: Heebo-Bold;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(5rem/$rem);
    width: calc(10.31% - calc(5rem/$rem));
    height: calc(35rem/$rem);
    box-sizing: border-box;
    overflow: hidden;
  }
  .eventDate{
    color: #405F6A;
    font-family: Heebo-Regular;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(5rem/$rem);
    width: calc(8.31% - calc(5rem/$rem));
    height: calc(35rem/$rem);
    box-sizing: border-box;
    overflow: hidden;
  }
  .document_first{
    color: #405F6A;
    font-family: Heebo-Bold;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(5rem/$rem);
    width: calc(25.31% - calc(5rem/$rem));
    height: calc(35rem/$rem);
    box-sizing: border-box;
    overflow: hidden;
    direction: rtl;
    display: flex;
  }
  .events{
    background: #D3DDDC 0% 0% no-repeat padding-box;
    width: calc(35rem/$rem);
    height: calc(35rem/$rem);
    border-radius: 50%;
    font-family: Heebo-regular;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(37rem/$rem);
    box-sizing: border-box;
    color: #434242;
    text-align: center;
  }
  .eventsOne{
    width: calc(31rem/$rem);
    height: calc(31rem/$rem);
    border-radius: 50%;
    font-family: Heebo-regular;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(37rem/$rem);
    height: calc(35rem/$rem);
    box-sizing: border-box;
    color: #434242;
    text-align: center;
  }
  .lastMessage{
    color: #434242;
    font-family: Heebo-Regular;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(80rem/$rem);
    width: calc(53.37% - calc(80rem/$rem));
    height: calc(42rem/$rem);
    background: #ECEAEA7A 0% 0% no-repeat padding-box;
    box-sizing: border-box;
    padding-right: calc(14rem/$rem);
    padding-top: calc(4rem/$rem);
    overflow: hidden;
  }
  .date{
    color: #434242;
    font-family: Heebo-Bold;
    font-size:  calc(24rem/$rem);
    margin: calc(54rem/$rem) 0 0 calc(5rem/$rem);
    width: 15.5%;
    height: calc(35rem/$rem);
    box-sizing: border-box;
    overflow: hidden;
    direction: rtl;
  }
  .tableLine{
    margin: 0 calc(49rem/$rem) 0 calc(42rem/$rem);
  }

@media (max-width: calc(1850rem/$rem)) {
  .lastMessage{
    width: calc(43.37% - calc(80rem/$rem));
    font-size: calc(16rem/$rem);
    height: calc(32rem/$rem);
  }
  .date{
    width: 20.5%;
    font-size: calc(16rem/$rem);
    height: calc(27rem/$rem);
  }
  .name{
    width: calc(15.31% - calc(5rem/$rem));
    font-size: calc(16rem/$rem);
    height: calc(27rem/$rem);
  }
  .document_first{
    width: calc(20.31% - calc(5rem/$rem));
    font-size: calc(16rem/$rem);
    height: calc(27rem/$rem);
  }
  .eventDate{
    width: calc(15.31% - calc(5rem/$rem));
    font-size: calc(16rem/$rem);
    height: calc(27rem/$rem);
  }
  .category{
    font-size: calc(16rem/$rem);
    height: calc(27rem/$rem);
  }
  .events{
    width: calc(27rem/$rem);
    height: calc(27rem/$rem);
    font-size: calc(16rem/$rem);
  }
}

@media (max-width: calc(1200rem/$rem)) {
    .chatBlocks{
      flex-direction: column;
    }
    .rightBlock{
      width: 100%;
      margin-left: 0;
      height: calc(80rem/$rem);
    }
    .leftBlock{
      width: 100%;
      margin-right: 0;
      margin-top: calc(20rem/$rem);
    }
}

@media (max-width: calc(900rem/$rem)) {
    .menu{
      font-size: calc(16rem/$rem);
    }
}
@media (max-width: calc(860rem/$rem)) {
  .watsApp{
    width: 10.375rem;
    height: 4.18rem;
    padding: 0.75rem 0.8125rem;

    .watsAppIcon{
      img{
        width: 2.68rem;
        height: 2.68rem;
      }
    }

    .watsAppText{
      font-size: 0.875rem;
    }
  }
  .watsApp{
    right: 1.125rem;
    bottom: 3.5rem;
  }
}
@media (max-width: calc(800rem/$rem)) {
    .desctop{
      margin: 0;
    }
    .eventTypeBlock{
      margin-top: calc(13rem/$rem);
      align-items: flex-start;
      margin-right: calc(17rem/$rem);
    }
    .select{
      width: calc(340rem/$rem);
    }
    .menu{
      margin-top: calc(19rem/$rem);
      height: calc(72rem/$rem);
    }
    .rightBlock{
      border: none;
    }
    .leftBlock{
      border: none;
    }
}
@media (max-width: calc(500rem/$rem)) {
  .row{
    display: none;
  }
  .rightBlock{
    display: none;
  }
  .tableLine{
    display: none;
  }
  .mobileLine{
    margin: calc(16rem/$rem) 0 calc(20rem/$rem) 0;
    display: block;
  }
  .mobileTitle{
    display: block;
    font-family: Heebo-Bold;
    font-size: calc(24rem/$rem);
    color: #242323;
    margin: calc(20rem/$rem) calc(17rem/$rem) 0 0;
  }
  .mobileRow{
    display: flex;
    flex-direction: column;
    margin: 0 calc(18rem/$rem) 0 calc(17rem/$rem);
  }
  .mobileBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .events{
    margin: calc(54rem/$rem) 0 0 0;
  }
  .category{
    width: 33%;
    margin: 0;
    font-family: Heebo-Bold;
    font-size: calc(18rem/$rem);
  }
  .name{
    margin: 0;
    width: 33%;
    font-family: Heebo-Medium;
    font-size: calc(18rem/$rem);
  }
  .document_first{
    margin: 0;
    width: 33%;
    font-family: Heebo-Medium;
    font-size: calc(18rem/$rem);
  }
  .eventDate{
    margin: 0;
    color: #434242;
    width: 33%;
    font-family: Heebo-Medium;
    font-size: calc(14rem/$rem);
  }
  .date{
    margin: 0;
    width: 33%;
    text-align: left;
    font-family: Heebo-Bold;
    font-size: calc(14rem/$rem);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .lastMessage{
    width: 80%;
    margin-top: calc(20rem/$rem);
    font-family: Heebo-Regular;
    background: none;
    font-size: calc(18rem/$rem);
    padding-right: 0;
  }
  .eventsOne{
    margin: 0;
    width: 20%;
  }
  .events{
    width: calc(30rem/$rem);
    height: calc(27rem/$rem);
    font-size: calc(16rem/$rem);
    padding-top: calc(2rem/$rem);
    margin-top: calc(20rem/$rem);
  }
  .menu{
    padding-right: calc(18rem/$rem);
    margin-top: calc(24rem/$rem);
  }
  .chatBlocks{
    margin-bottom: calc(47rem/$rem);
  }
}

@media (max-width: calc(400rem/$rem)) {
  .eventTypeBlock{
    margin-right: calc(17rem/$rem);
    margin-left: calc(18rem/$rem);
  }
  .select{
    width: 100%;
  }
}